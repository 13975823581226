/* eslint-disable no-undef */
import axios from "axios";
// const withQuery = require('with-query');
import NProgress from 'nprogress';
import ProgressbarStatus from 'helper-components/ProgressBarStatus';

const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({})
};

let allAbortControlllers = {};

function addAbortController(page) {
    let abortController = new AbortController();
    if (!allAbortControlllers.hasOwnProperty(page)){
        allAbortControlllers[page] = [abortController];
    }else {
        allAbortControlllers[page].push(abortController);
    }
    return abortController;
}


function checkIsAuthorised (response) {
    if (response.hasOwnProperty("message")){
        if (response.message.indexOf("401 Unauthorized:") !== -1 || response.message.indexOf("Current user is not authorised to perform operations") !== -1){
            sessionStorage.clear();
            window.location.hash = "/login";
        }
    }
    return response;
}

function login(user_details,twoFactorKey) {
    NProgress.start();
    return fetch('/api/login/twoFactorKey/'+ twoFactorKey, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
               "twoFactorKey": twoFactorKey},
        body: JSON.stringify(user_details)
    }).then(function (response) {
        ProgressbarStatus();
        return response.json();
    }).then(function (json) {
        return json;
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
    // .then(data => this.setState({ postId: data.id }));
}

function getHirarchy() {
    NProgress.start();
    return fetch('/api/hirarchy').then(function(response) {
       ProgressbarStatus();
       return response.json();
    }).then(function(data) {
        return checkIsAuthorised(data);
    });
}

function getHirarchyOnlineDeviceCount() {
    NProgress.start();
    return fetch('/api/hirarchy-online-device-count').then(function(response) {
        ProgressbarStatus();
        return response.json();
    }).then(function(data) {
        return checkIsAuthorised(data);
    });
}

function getScopesLatestVersion(page) {
    let abortController = addAbortController(page);
    NProgress.start();
    return fetch('/api/scope/latest-versions', {signal: abortController.signal}).then(function(response) {
       ProgressbarStatus();
       return response.json();
    }).then(function(data) {
        return checkIsAuthorised(data);
    });
}

function getLatestVersionsForPartialOS(page) {
    let abortController = addAbortController(page);
    NProgress.start();
    return fetch('/api/scope/latest-versions-for-partial-os', {signal: abortController.signal}).then(function(response) {
        ProgressbarStatus();
        return response.json();
    }).then(function(data) {
        return checkIsAuthorised(data);
    });
}

function getPortalUsers(scopeId) {
    NProgress.start();
    return fetch('/api/getPortalUsers/portalUsers?scopeId=' + scopeId)
    .then((res) => {
       ProgressbarStatus();
       return res.json()
    }).then((json) => {
        console.info(json);
        return checkIsAuthorised(json);
    }).catch((err) => {
        console.error(err);
        ProgressbarStatus();
        return err;
    });
}

function deletePortalUser(userId, scopeId){
    NProgress.start();
    return fetch("/api/delete-portal-user/userId/"+userId+"/scopeId/"+scopeId,{
        method:'DELETE',
    })
    .then((response)=>{
        ProgressbarStatus();
        return response;
    })
}

function checkDuplicateUserEmail(email) {
    return fetch('/api/check-duplicate-user-email/' +email).then(res => {
        if (res === ""){
            return ""
        }else {
            return res.json();
        }
    }).catch((err) => {
        return err;
    });
}

function getScope(scopeId) {
    return fetch('/api/scope/' +scopeId).then(function(response) {
            return response.json();
    }).then(function(data) {
            return data;
    });
}


function updateScope(callback, scopeDetails) {
    return fetch('/api/updateScope', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(scopeDetails)
    }).then(callback)
}

function updateTenant(scopeDetails){
    NProgress.start();
    return fetch('/api/updateScope', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(scopeDetails)
    }).then(function (response) {
        ProgressbarStatus();
        return response.json();
    }).then(function (json) {
        return checkIsAuthorised(json);
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}


function createScope(callback, scopeDetails) {
    return fetch('/api/createScope', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(scopeDetails)
    })
//    .then(callback)
    .then((response) => {
         if(response.status == 201) {
            return response.json();
         }
    }).then(function(data) {
        callback(data);
    });
}

function createTenant(scopeDetails) {
    NProgress.start();
    return fetch("/api/createScope", {
        method : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(scopeDetails)
    }).then(function (response) {
        ProgressbarStatus();
        return response.json();
    }).then(function (json) {
        return checkIsAuthorised(json);
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}

function createPortalUser(user_details) {
    NProgress.start();
    return fetch('/api/createPortalUser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then((res) => {
       ProgressbarStatus();
       return res;
    }).catch((err) => {
       ProgressbarStatus();
       return err;
    });
    // .then(data => this.setState({ postId: data.id }));
}

function updatePortalUser(user_details) {
    NProgress.start();
    return fetch('/api/updatePortalUser', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then(function (res) {
        ProgressbarStatus();
        return res;
    }).catch((err) => {
         ProgressbarStatus();
         return err;
    });
}

function updateEmailTemplate(callback, user_details) {
    return fetch('/api/updateEmailTemplate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then(callback)
    // .then(data => this.setState({ postId: data.id }));
}
function fetchUserDetails() {
    return fetch('/api/user-details').then(function(response) {
        return response.json();
    }).then(function(data) {
        return data;
    });
}

function changePassword(user_details) {
    NProgress.start();
    return fetch('/api/changePassword', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then((response)=>{
        if (response.status !== 200){
            ProgressbarStatus();
            return response.json();
        }else {
            ProgressbarStatus();
            return response;
        }
    })
    // .then(data => this.setState({ postId: data.id }));
}

function forgotPassword(callback, user_details) {
    return fetch('/api/forgotPassword', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details),
        // body: user_details
    }).then(callback)
    // .then(data => this.setState({ postId: data.id }));
}

function resetPassword(user_details) {
    NProgress.start();
    return fetch('/api/resetPassword', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user_details)
    }).then((response) => {
        if (response.ok){
            ProgressbarStatus();
            return response;
        }else {
            ProgressbarStatus();
            return response.json();
        }
    }).then((data) => {
      return data;
    });
    // .then(data => this.setState({ postId: data.id }));
}


function fetchFreeTierDetails() {
    return fetch('/freeTierDetails').then(function(response) {
        return response.json();
    }).then(function(data) {
        return data;
    });
}

function getHierarchyScope(scope) {

    return fetch('/api/hierarchy/scope?scopeId=' + scope).then(res => res.json()).then((json) => {
            console.info(json);
            checkIsAuthorised(json)
            return json;
        }).catch((err) => {
            console.error(err);
            return err;
        });
}

function getIotDevices(scopeId) {
    return fetch('/iotdevices?scopeId=' + scopeId)
        .then(res => res.json())
        .then((json) => {
            console.info(json);
            return json;
        })
        .catch((err) => {
            console.error(err);
            return err;
        });
}

function uploadFirmware(url, formData, authToken) {
    NProgress.start();
    return fetch(url, {
        method: 'POST',
        header: {
            cookie: {
                'auth-token' : authToken
            }
        },
        body: formData
    }).then((response)=> {
        ProgressbarStatus();
        return response.json();
    })
    .catch((err) => {
        console.error(err);
        ProgressbarStatus();
        return err;
    });
}

function uploadImageFiles (url, formData, authToken) {
    NProgress.start();
    return fetch(url, {
        method: 'POST',
        header: {
            cookie: {
                'auth-token' : authToken
            }
        },
        body: formData
    }).then((response)=> {
        ProgressbarStatus();
        return response;
    })
    .catch((err) => {
        console.error(err);
        ProgressbarStatus();
        return err;
    });
}

function applyFirmware(json,fileStoreId, scopeId) {
    NProgress.start();
    return fetch("/api/apply-firmware/fileStoreId/"+fileStoreId+"/scopeId/"+scopeId,{
        method:"PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
    }).then((response)=>{
        ProgressbarStatus();
        return response;
    })
    .catch((err) => {
        console.error(err);
        ProgressbarStatus();
        return err;
    });
}

function updateOs(json,fileStoreId, scopeId) {
    NProgress.start();
    return fetch("/api/update-Os/fileStoreId/"+fileStoreId+"/scopeId/"+scopeId,{
        method:"PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(json)
    }).then((response)=>{
        ProgressbarStatus();
        return response;
    })
    .catch((err) => {
        console.error(err);
        ProgressbarStatus();
        return err;
    });
}

function getHirarchy1(callback) {
    return axios('/hirarchy').then( response => {}).then(callback);
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    throw error;
}


function getAuthAppDetails() {
    NProgress.start();
    return fetch("/api/auth-app-details").then((response)=>{
        ProgressbarStatus();
        return response.json();
    }).catch((err)=>{
        ProgressbarStatus();
        return err;
    })
}

function getPortalUsersData(scopeId) {
    return fetch('/api/getPortalUsers/portalUsers?scopeId=' + scopeId)
    .then((response) => {
        return response.json();
    }).catch((err) => {
        return err;
    });
}

function deleteTenantScope(id) {
    NProgress.start();
    return fetch('/api/removeTenant/id/' + id, {
        method:"DELETE"
    }).then((response) => {
        ProgressbarStatus();
        return response;
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}

function deleteTenantUsersByScope(scopeId) {
    NProgress.start();
    return fetch('/api/removeTenantPortalUsers-by-scope/id/' + scopeId,{
        method:"DELETE"
    }).then((response) => {
        ProgressbarStatus();
        return response;
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}

function updateCustomerStatus(scopeId) {
    NProgress.start();
    return fetch('api/updateCustomerStatus/id/' + scopeId, {
        method:"PUT"
    }).then((response) => {
        ProgressbarStatus();
        return response;
    }).catch ((err) => {
        ProgressbarStatus();
        return err;
    })
}

function getScopeData(scopeId) {
    return fetch('/api/scope/' +scopeId).then((response) => {
        return response.json();
    }).catch((err) => {
        return err;
    });
}

function getRootTenantId() {
    NProgress.start();
    return fetch('/api/getRootTenantId/').then((response) => {
        ProgressbarStatus();
        return response.json();
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}

function getAllFirmwareVersions() {
    NProgress.start();
    return fetch('/api/getAllFirmwareVersions/').then((response) => {
         ProgressbarStatus();
         return response.json();
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}

function deleteFirmwareVersions(versions) {
    NProgress.start();
    return fetch('/api/deleteFirmwareVersions', {
        method:"PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(versions)
    }).then((response) => {
        ProgressbarStatus();
        return response;
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}

function getAllOSPatchVersions() {
    NProgress.start();
    return fetch("/api/getAllOSPatchVersions/").then((response) => {
        ProgressbarStatus();
        return response.json();
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}

function deleteOSVersions(versions) {
    NProgress.start();
    return fetch("/api/deleteOSVersions", {
        method:"PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(versions)
    }).then((response) => {
        ProgressbarStatus();
        return response;
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}

function getQrCodeUrl(emailId) {
    let payload = {
        "email": emailId
    }
    NProgress.start();
    return fetch("/api/getQrCodeUrl", {
        method : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    }).then(function (response) {
        ProgressbarStatus();
        return response.json();
    }).then(function (json) {
        return checkIsAuthorised(json);
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}

function enableTwoFactorAuthentication(emailId,verificationCode) {
    let payload = {
        "email": emailId,
        "code" : verificationCode
    }
    NProgress.start();
    return fetch("/api/enableTwoFA", {
        method : 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    }).then(function (response) {
        ProgressbarStatus();
        return response;
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}

function DisableTwoFA(emailId) {
    NProgress.start();
    return fetch('/api/disableTwoFA/user?email=' + emailId,{
        method:"DELETE"
    }).then((response) => {
        ProgressbarStatus();
        return response;
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}

function TwoStepVerification(emailId,verificationCode,fingerPrintKey) {
    let payload = {
        "email": emailId,
        "code": verificationCode
    }
    NProgress.start();
    return fetch("/api/twoStepVerification", {
        method : 'POST',
        headers: { 'Content-Type': 'application/json',
                   'twoFactorKey': fingerPrintKey
                 },
        body: JSON.stringify(payload)
    }).then(function (response) {
        ProgressbarStatus();
        return response;
    }).catch((err) => {
        ProgressbarStatus();
        return err;
    });
}

function parseJSON(response) {
    return response.json();
}

function abortSignal(page) {
    let abortSignals = allAbortControlllers.hasOwnProperty(page) ? allAbortControlllers[page] : [];
    _.forEach(abortSignals, (abortController)=> {
        abortController.abort()
    })
}


const AuthClient = { login, getAuthAppDetails , getPortalUsersData, getScopeData, getHirarchy, getHirarchy1, uploadFirmware, fetchFreeTierDetails, getHierarchyScope, getIotDevices,
    getScopesLatestVersion, getLatestVersionsForPartialOS, checkDuplicateUserEmail, getScope, createScope, updateScope,
     getPortalUsers, createPortalUser, updatePortalUser, updateTenant,getRootTenantId,deleteFirmwareVersions,
    changePassword, forgotPassword, resetPassword,fetchUserDetails,updateEmailTemplate, deletePortalUser , applyFirmware, updateOs, abortSignal, createTenant, uploadImageFiles, deleteTenantScope, deleteTenantUsersByScope, updateCustomerStatus, getHirarchyOnlineDeviceCount, getAllFirmwareVersions, getAllOSPatchVersions, deleteOSVersions,
    getQrCodeUrl, enableTwoFactorAuthentication, TwoStepVerification, DisableTwoFA};
export default AuthClient;
