import * as React from 'react';
import { Box,Paper,Grid,Typography,Button,TextField,CircularProgress } from '@mui/material';
import './ChangePassword.css';
import 'stylesheet/UpdateFirmware.css';
import {useNavigate, useParams} from "react-router-dom";
import { ArrowBack,ErrorOutline,Visibility,VisibilityOff } from '@mui/icons-material';
import AuthClient from "clients/AuthClient";
import IotServiceClient from "clients/IotServiceClient";
import { useSnackbar } from 'notistack';
import md5 from 'md5';
import DraggableComponent from '../DraggableComponent';
import {useLocation} from 'react-router-dom'
import checkDataExistsOrNot from '../../helper-components/checkDataExistsOrNot'

export default function Login(props) {

    const nav = useNavigate();
    const Location = useLocation();


    const [errorForOldPassword, setErrorForOldPassword] = React.useState(false);
    const [oldPassword, setOldPassword] = React.useState(false);
    const [errorForNewPassword, setErrorForNewPassword] = React.useState(false);
    const [newPassword, setNewPassword] = React.useState(false);
    const [errorForConfirmedPassword, setErrorForConfirmedPassword] = React.useState(false);
    const [confirmedPassword, setConfirmedPassword] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    const [oldPassordIsIncorrect, setOldPassordIsIncorrect] = React.useState(false);
    const [unauthorisedPassword,setUnauthorisedPassword] = React.useState(false);
    const [isIotUserResetPassword, setIsIotUserResetPassword] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [showConfirmationDialog , setShowConfirmationDialog] = React.useState(false);
    const [passwordType, setPasswordType] = React.useState("password");
    const [passwordTypeNew, setPasswordTypeNew] = React.useState("password");
    const [passwordTypeNewCnf, setPasswordTypeNewCnf] = React.useState("password");
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")


    const getHref = () => {
      let location = window.location.origin + "/#";
      return location;
    }
    React.useState(false);
    let user_details = {};
    const onNewPasswordBlur = (value) => {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9@&#!]{5,}$/;
        if (value) {
            setErrorForNewPassword(!value.match(passwordRegex));
        } else {
            setErrorForNewPassword(true);
        }
    };

    const onConfirmedPasswordBlur = (value,type) => {
        let condition = type === "newPassword" ? confirmedPassword === value : value === newPassword;
        if(value && condition) {
            setErrorForConfirmedPassword(false);
        } else {
            setErrorForConfirmedPassword(true);
        }
    };
    
    // const confirmChangePassword = () => {
    //     confirmAlert({
    //         title: 'Confirm to submit',
    //         message: 'Are you sure to do this.',
    //         buttons: [
    //             {
    //                 label: 'Yes',
    //                 onClick: () => changePassword()
    //             },
    //             {
    //                 label: 'No',
    //                 onClick: () => alert('Click No')
    //             }
    //         ]
    //     });
    // }

    const changePassword = () => {
        user_details.oldPassword = oldPassword;
        user_details.newPassword = newPassword;
        if (oldPassword !== newPassword){
          setIsLoading(true);
          AuthClient.changePassword(user_details).then((response) => {
              setIsLoading(false);
              if (response.status === 200) {                                
                enqueueSnackbar("Successfully updated password")
                if (!props.hasOwnProperty("navigatedThroughMail")){
                  sessionStorage.clear()
                  setTimeout(()=>{                      
                      window.location.hash = "/logout";
                      Location.hash = "#/logout"
                  },2000)
                }
              } else {
                let message = response.message;
                if (message){
                  enqueueSnackbar("Failed to update new password. error:"+ message.toString().toLowerCase().substring(message.lastIndexOf(": [")+3, message.length-1))
                }
              }
          })
      }else {
        enqueueSnackbar("old password and new password cannot be same")
      }
    };

    const handleChangePassword = () => {
      setShowConfirmationDialog(true)
    }

    const resetPassword = () => {
        user_details.password = newPassword;
        if (!props.hasOwnProperty("navigatedThroughMail")){
          user_details.activationCode = nav.activationCode;        
        }else {
          let locationHref = window.location.href; 
          let code = locationHref.substring(locationHref.indexOf("/reset-password/")+16 , locationHref.length);
          user_details.activationCode = code;
          user_details.confirm_password = confirmedPassword;
        }
        setIsLoading(true);
        if (props.resetIotUserPassword === false) {          
          AuthClient.resetPassword(user_details).then((response)=> {
              setIsLoading(false);
              if (response.status === 200) {
                if (!props.hasOwnProperty("navigatedThroughMail")){
                  if(sessionStorage.isRootUser === "true"){
                    window.location.hash= "/admin/tenants";
                    Location.hash = "#/admin/tenants"
                  }else {
                    window.location.hash= "/cli/dashboard";
                    Location.hash = "#/cli/dashboard"
                  }
                }else {
                  setShowSuccessMessage(true)
                }
              } else {
                  if (props.hasOwnProperty("navigatedThroughMail")){
                    if (response.message.indexOf("401") !== -1){
                      setShowSuccessMessage(true)
                      setUnauthorisedPassword(true);
                      setErrorMessage("Unauthorized link. Please try resetting your password again by clicking forgot password on login page.")
                    }else if (response.message.indexOf("410") !== -1){
                      setUnauthorisedPassword(true);
                      setErrorMessage("The link has expired. Please try resetting your password again by clicking forgot password on login page.")
                    }else if (response.message.indexOf("404") !== -1){
                      setUnauthorisedPassword(true);
                      setErrorMessage("Invalid reset password link. Please try resetting your password again by clicking forgot password on login page.")
                    }
                  }
                  enqueueSnackbar("Failed to change password");
              }
          });
        }else {
          setIsIotUserResetPassword(true);
          IotServiceClient.resetUserPassword(response => {
            setIsLoading(false);
              if (response.status === 200) {
                if (!props.hasOwnProperty("navigatedThroughMail")){
                  if(sessionStorage.isRootUser === "true"){
                    window.location.hash= "/admin/tenants";
                    Location.hash = "#/admin/tenants"
                  }else {
                    window.location.hash= "/cli/dashboard";
                    Location.hash = "#/cli/dashboard"
                  }
                }else {
                  setShowSuccessMessage(true)
                }
              } else {
                  if (props.hasOwnProperty("navigatedThroughMail")){
                    if (response.message.indexOf("410") !== -1){
                      setUnauthorisedPassword(true);
                      setErrorMessage("Reset password link has expired")
                    }else {
                      setUnauthorisedPassword(true);
                      setErrorMessage("Invalid reset password link. Contact administrator to reset your password.")
                    }
                  }
                  enqueueSnackbar("Failed to change password");
              }
          }, user_details);
        }
    };

    const togglePassword =()=>{
        if(passwordType==="password")
        {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const toggleNewPassword =()=>{
        if(passwordTypeNew==="password")
        {
            setPasswordTypeNew("text")
            return;
        }
        setPasswordTypeNew("password")
    }

    const toggleNewPasswordCnf =()=>{
        if(passwordTypeNewCnf==="password")
        {
            setPasswordTypeNewCnf("text")
            return;
        }
        setPasswordTypeNewCnf("password")
    }

    return (
    <>
      {
        !props.hasOwnProperty("navigatedThroughMail") ?
          <Box sx={{height:"56px"}}>
              <ArrowBack
                sx={{color: sessionStorage.isRootUser === "true" ? "#4190c7" : primaryThemeColor}}
                onClick={() => {
                  if(sessionStorage.isRootUser === "true"){
                    window.location.hash= "/admin/tenants";
                    Location.hash = "#/admin/tenants";
                  }else {
                    delete sessionStorage.sidenavAlreadySelectedItem;
                    window.location.hash= "/cli/dashboard";
                    Location.hash = "#/cli/dashboard";
                  }
                }}
                className="backArrow"
              />
          </Box>
        :
          null
      }
      <Box sx={{flexGrow: 1, margin: '8px', padding: '8px'}}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={5} lg={5} xl={4} sm={5}>
            <Paper elevation={1}>
             {
              !showSuccessMessage ?
                    <Box
                      sx={{position: 'relative', margin: '24px 12px', padding: '32px'}}
                    >
                      <Typography
                        mt={2}
                        variant="h2"
                        style={{
                          display: 'block',
                          fontSize: '1.5em',
                          marginBlockStart: '1em',
                          marginBlockEnd: '1em',
                          marginInlineStart: '0px',
                          marginInlineEnd: '0px',
                          color: 'black',
                          textAlign: 'center',
                        }}
                      >

                        Please enter your new password
                      </Typography>

                      {
                        !isLoading ?
                        <>
                          <Box
                            sx={{
                              display: props.isResetPassword ? 'none' : 'flex',
                              alignItems: 'flex-end',
                              margin: '18px 0',
                            }}
                          >
                            <TextField
                              fullWidth
                              type={passwordType}
                              id="input-with-sx"
                              label="Old Password"
                              variant="standard"
                              helperText={
                                errorForOldPassword ? 'Old password is required.' : oldPassordIsIncorrect ? 'Old Password is incorrect.' : ''
                              }
                              error={errorForOldPassword || oldPassordIsIncorrect}
                              InputLabelProps={{style: {color: '#BfBfBf'}}}
                              onChange={(event) => {
                                let value = event.target.value;
                                setOldPassword(value);
                                if (md5(value) !== sessionStorage.userPasscode) {
                                  setOldPassordIsIncorrect(true);
                                } else {
                                  setOldPassordIsIncorrect(false);
                                }
                                value !== "" && value && typeof value !== 'undefined' ? setErrorForOldPassword(false): setErrorForOldPassword(true);
                              }}
                            />

                              <button onClick={togglePassword} style={{width: '36px', height: '32px', border: "none",backgroundColor: "white"}}>
                                  { passwordType==="password"? <VisibilityOff></VisibilityOff> :<Visibility></Visibility>}
                              </button>
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-end',
                              margin: '18px 0',
                            }}
                          >
                          <TextField
                              fullWidth
                              type={passwordTypeNew}
                              id="input-with-sx"
                              label="New Password"
                              variant="standard"
                              error={errorForNewPassword}
                              helperText={
                                errorForNewPassword
                                  ? 'Password should have at least one alphabet[a-z, A-Z], should have at least one number[0-9]. ' +
                                    'Allowed special characters are @, #, ! and minimum length should be 5.'
                                  : ''
                              }
                              InputLabelProps={{style: {color: '#BfBfBf'}}}
                              onChange={(event) => {
                                let value = event.target.value;
                                setNewPassword(value);                                
                                onNewPasswordBlur(value);
                                onConfirmedPasswordBlur(value,"newPassword");
                              }}

                          />
                              <button onClick={toggleNewPassword} style={{width: '36px', height: '32px', border: "none",backgroundColor: "white"}}>
                                  { passwordTypeNew==="password"? <VisibilityOff></VisibilityOff> :<Visibility></Visibility>}
                              </button>
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-end',
                              margin: '18px 0',
                            }}
                          >
                            <TextField
                              fullWidth
                              type={passwordTypeNewCnf}
                              id="input-with-sx"
                              label="Confirm New Password"
                              variant="standard"
                              error={errorForConfirmedPassword}
                              helperText={
                                errorForConfirmedPassword
                                  ? 'Confirm new password is required and should be same as new password'
                                  : ''
                              }
                              InputLabelProps={{style: {color: '#BfBfBf'}}}
                              onChange={(event) => {
                                let value = event.target.value;
                                setConfirmedPassword(value)
                                if(value !== "" && value && typeof value !== 'undefined') {
                                  if (value === newPassword){
                                    setErrorForConfirmedPassword(false);
                                  }else {
                                    setErrorForConfirmedPassword(true);
                                  }
                                } else {
                                    setErrorForConfirmedPassword(true);
                                }
                              }}
                            />
                              <button onClick={toggleNewPasswordCnf} style={{width: '36px', height: '32px', border: "none",backgroundColor: "white"}}>
                                  { passwordTypeNewCnf==="password"? <VisibilityOff></VisibilityOff> :<Visibility></Visibility>}
                              </button>
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              margin: '8px',
                              textAlign: 'center',
                              flexDirection: 'row-reverse',
                            }}
                          >
                            <Button
                              disabled={errorForOldPassword || errorForConfirmedPassword || !confirmedPassword || oldPassordIsIncorrect}
                              variant="contained"
                              style={{minWidth: '88px', margin: '8px 6px'}}
                              onClick={() => props.isResetPassword ? resetPassword() : handleChangePassword()}
                            >
                              SAVE PASSWORD
                            </Button>
                            {
                              !props.hasOwnProperty("navigatedThroughMail") ?
                                <Button
                                  variant="text"
                                  style={{
                                    minWidth: '88px',
                                    margin: '8px 6px',
                                    color: 'red',
                                  }}
                                  onClick={() => {
                                    if(sessionStorage.isRootUser === "true"){
                                      window.location.hash= "/admin/tenants";
                                      Location.hash = "#/admin/tenants"
                                    }else {
                                      window.location.hash= "/cli/dashboard";
                                      Location.hash = "#/cli/dashboard"
                                    }
                                  }}
                                >
                                  CANCEL
                                </Button>
                              :
                                null
                            }
                          </Box>
                        </>
                      :
                        <Box sx={{flexDirection:"column",width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100px'}}>
                          <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                          <Box>&nbsp;</Box>
                          <Box>
                            Resetting your password ...
                          </Box>
                        </Box>
                  }
                </Box>
              :
                !unauthorisedPassword ?
                  !isIotUserResetPassword ?
                    <Box sx={{position: 'relative',textAlign:'center',fontSize:"16px", margin: '24px 12px', padding: '32px',display:'flex',flexDirection:'column'}}>
                      <Box sx={{margin:"10px"}}>
                        <Box sx={{margin:"10px"}}>
                          New password saved successfully.
                        </Box>
                      </Box>
                      <Box sx={{margin:"10px"}}>
                        <Box sx={{margin:"10px"}}>
                          You can now login into Gateways Management portal.
                        </Box>
                      </Box>
                      <Box sx={{margin:"10px"}}>
                        <Box sx={{margin:"10px"}}>
                          Click <a href={getHref()}>here</a> to Login
                        </Box>
                      </Box>
                    </Box>
                  :
                    <Box sx={{position: 'relative',textAlign:'center',fontSize:"16px", margin: '24px 12px', padding: '32px',display:'flex',flexDirection:'column'}}>
                      <Box sx={{margin:"10px"}}>
                        <Box sx={{margin:"10px"}}>
                          New password saved successfully.
                        </Box>
                      </Box>
                      <Box sx={{margin:"10px"}}>
                        <Box sx={{margin:"10px"}}>
                          You can now login into Gateways Management app.
                        </Box>
                      </Box>
                    </Box>
                :
                  <Box sx={{position: 'relative',textAlign:'center',fontSize:"16px", margin: '24px 12px', padding: '32px',display:'flex',flexDirection:'column'}}>
                    <Box sx={{margin:"10px"}}>
                      <Box sx={{margin:"10px",color:'red'}}>
                        <ErrorOutline sx={{position:"relative",top:"6px"}} /> Error
                      </Box>
                    </Box>
                    <Box sx={{margin:"10px"}}>
                      <Box sx={{margin:"10px",color:"red"}}>
                        {errorMessage}                          
                      </Box>
                    </Box>
                    <Box sx={{margin:"10px"}}>
                      <Box sx={{margin:"10px"}}>
                        Click <a href={getHref()}>here</a> to Login
                      </Box>
                    </Box>
                  </Box>
              }
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {
        showConfirmationDialog ?
            <DraggableComponent
            title="Session Will Expire!"
            data={[]}
            show_dialog={true}
            change_password={() => changePassword()}
            close_confirmation_password_dialog={() => setShowConfirmationDialog(false)}
            dialog="changePasswordConfirmationDialog"
            />
        :
            null
     }
    </>
  );
}
