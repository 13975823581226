import NProgress from 'nprogress';
import ProgressbarStatus from 'helper-components/ProgressBarStatus';

function getFrontendAppDetails() {
    NProgress.start();
    return fetch("/api/frontend-app-details").then((response)=>{
        ProgressbarStatus();
        return response.json();
    }).catch((err)=>{
        ProgressbarStatus();
        return err;
    })
}

const frontendServiceClient = { getFrontendAppDetails};
export default frontendServiceClient;