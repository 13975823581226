import * as React from 'react';
import { Box, Button, TextField, InputAdornment, IconButton, MenuItem, FormHelperText, DialogActions, Tooltip, Divider } from '@mui/material';
import { ArrowBack, VisibilityOff, Visibility, CloudSync, InfoOutlined } from '@mui/icons-material';
import 'stylesheet/UpdateFirmware.css'
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import { useSnackbar } from 'notistack';
import IotServiceClient from 'clients/IotServiceClient';
import _ from 'lodash';
import ConfigDataUpdateAlert from './ConfigDataUpdateAlert'

export default function AgentConfig(){

  const [mqttBaseUrl, setMqttBaseUrl] = React.useState("");
  const [mqttAliveTime, setMqttAliveTime] = React.useState("");
  const [mqttPort, setMqttPort] = React.useState("");
  const [mqttPassword, setMqttPassword] = React.useState("");
  const [mqttCertificatePath, setMqttCertificatePath] = React.useState("");
  const [mqttWaitTime, setMqttWaitTime] = React.useState("");
  const [mqttMakto, setMqttMakto] = React.useState("");
  const [gatewayPassword, setGatewayPassword] = React.useState("");
  const [webServerPassword, setWebServerPassword ] = React.useState("");
  const [PYOrder, setPYOrder] = React.useState("");
  const [watchDogTimer, setWatchDogTimer] = React.useState("");
  const [CCF, setCCF] = React.useState("");
  const [APITimeOut, setAPITimeOut] = React.useState("");
  const [APIWaitTime, setAPIWaitTime] = React.useState("");
  const [logLevel, setLogLevel] = React.useState("");
  const [logSize, setLogSize] = React.useState("");
  const [s3BucketName, setS3BucketName] = React.useState("");
  const [s3BucketPath, setS3BucketPath] = React.useState("");
  const [elkHostUrl, setElkHostUrl] = React.useState("");
  const [elkPort, setElkPort] = React.useState("");
  const [elkLogSts, setElkLogSts] = React.useState("");
  const [elkUsername, setElkUsername] = React.useState("");
  const [elkPassword, setElkPassword] = React.useState("");
  const [pollFrequency, setPollFrequency] = React.useState("");
  const [dataRecordFrequency, setDataRecordFrequency] = React.useState("");
  const [dataPostFrequency, setDataPostFrequency] = React.useState("");
  const [crbDataSts, setCrbDataSts] = React.useState("");
  const [loginUrl, setLoginUrl] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [userPassword, setUserPassword] = React.useState("");
  const [scopeUrl, setScopeUrl] = React.useState("");
  const [configVersion, setConfigVersion] = React.useState("");
  const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
  const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
  const { enqueueSnackbar } = useSnackbar();
  const [mqttPasswordType, setMqttPasswordType] = React.useState("password");
  const [gWPasswordType, setGWPasswordType] = React.useState("password");
  const [webServerPasswordType, setWebServerPasswordType] = React.useState("password");
  const [elkPasswordType, setElkPasswordType] = React.useState("password");
  const [loginPasswordType, setLoginPasswordType] = React.useState("password");
  const [isValid, setIsValid] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [updateDialogContent, setUpdateDialogContent] = React.useState("");
  const [aliveTimeHelper, setAliveTimeHelper] = React.useState(false);
  const [randomTimeHelper, setRandomTimeHelper] = React.useState(false);
  const [ackTimeHelper, setAckTimeHelper] = React.useState(false);
  const [watchDogHelper, setWatchDogHelper] = React.useState(false);
  const [ccfHelper, setCcfHelper] = React.useState(false);
  const [logSizeHelper, setLogSizeHelper] = React.useState(false);
  const [pollFrequencyHelper, setPollFrequencyHelper] = React.useState(false);
  const [dataRecordFrequencyHelper, setDataRecordFrequencyHelper] = React.useState(false);
  const [dataPostFrequencyHelper, setDataPostFrequencyHelper] = React.useState(false);
  const [mqttPortHelper, setMqttPortHelper] = React.useState(false);
  const [apiTOutHelper, setApiTOutHelper] = React.useState(false);
  const [apiWaitTHelper, setApiWaitTHelper] = React.useState(false);
  const [elkPortHelper, setElkPortHelper] = React.useState(false);
  const [disableResetBtn, setDisableResetBtn] = React.useState(false);

  const options = [
    {
      value: true,
      label: 'true',
    },
    {
      value: false,
      label: 'false',
    }
  ];
  const debugOptions = [
    {
      value: "debug",
      label: 'Debug',
    },
    {
      value: "info",
      label: 'Info',
    },
    {
      value: "warning",
      label: 'Warning',
    },
    {
      value: "error",
      label: 'Error',
    },
    {
      value: "critical",
      label: 'Critical',
    }
  ];
  const dropDownOptions = [...Array(900).keys()].map(i => ({ value : i+1, label : String(i+1) }))

  const tooltipText = {
      'b_URL' : 'Portal URL referred by GW',
      'Keep_Ati': 'Time after which the MQTT broker issues a disconnect in case of inactivity over LTE connection',
      'Mq_Pno.': 'MQTT port on the Portal',
      'Mq_pwd': 'Password to connect MQTT',
      'Mq_CrtPth': 'Location of client certificate on the GW',
      'Rnd_wt': 'Time after which the MQTT broker issues a disconnect in case of inactivity over Ethernet or WiFi connection',
      'Mq_Ack_to': 'Timeout for receiving data packet ACK from the Portal',
      'Gw_Pwd': 'Password to access GW terminal',
      'Ws_Pwd': 'Password to connect to webserver on GW',
      'cus_app_exec_order': 'Order for executing customer’s apps',
      'Wtch_dog_timr': 'Hardware timer used to detect and recover from system failures',
      'api_to': 'Timeout limit for API calls response',
      'api_wt': '',
      'Dbg_Loglv': 'Verbosity of GW logs',
      'Dbg_Logsz': 'Max size of GW log file',
      'S3_bktnm': 'S3 bucket name used for upload / download',
      'S3_bktpth': 'Path to S3 bucket',
      'elk_hst_url': 'ELK URL to send device logs',
      'elk_prt': 'ELK port number to send device logs',
      'elk_logst': 'Enable / Disable uploading of GW logs',
      'ELK_Unm': 'Userename to access ELK',
      'ELK_pwd': ' Password to access ELK',
      'Poll_fq': 'Frequency of checking device data',
      'Data_rec_fq': 'Frequency of recording device data',
      'Data_post_fq': ' Frequency to post device data to the Portal',
      'CRB_data_st': 'Enable / Disable Caribou data or System data',
      'CCF': 'Wait time for reconnecting MQTT',
      'scope_info': 'Portal URL to get Scope & Config details',
      'auth_url': 'Portal URL to login',
      'login_mail': 'Email to login to the Portal',
      'login_pwd': 'Password to login to the Portal',
  }

  const getTooltipText = (fieldName) => {
     return tooltipText[fieldName];
  }

  const fetchAgentConfigData = () => {
    IotServiceClient.getAgentConfigData(sessionStorage.tenantId).then((res) => {
        if(res.hasOwnProperty("SID")){
            setConfigData(res);
        }
    })
  }

  const setConfigData = (configData) => {
    window.oldData = _.cloneDeep(configData);
    window.configData = _.cloneDeep(configData);

    setMqttBaseUrl( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.mqtt) && checkDataExistsOrNot(configData.cfg.mqtt.burl)) ? configData.cfg.mqtt.burl : null);
    setMqttAliveTime( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.mqtt) && checkDataExistsOrNot(configData.cfg.mqtt.mat)) ? configData.cfg.mqtt.mat : null);
    setMqttPort( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.mqtt) && checkDataExistsOrNot(configData.cfg.mqtt.mprt)) ? configData.cfg.mqtt.mprt : null);
    setMqttPassword( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.mqtt) && checkDataExistsOrNot(configData.cfg.mqtt.mcpwd)) ? configData.cfg.mqtt.mcpwd : null);
    setMqttCertificatePath( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.mqtt) && checkDataExistsOrNot(configData.cfg.mqtt.cacrtpth)) ? configData.cfg.mqtt.cacrtpth : null);
    setMqttWaitTime( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.mqtt) && checkDataExistsOrNot(configData.cfg.mqtt.mate0)) ? configData.cfg.mqtt.mate0 : null);
    setMqttMakto( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.mqtt) && checkDataExistsOrNot(configData.cfg.mqtt.makto)) ? configData.cfg.mqtt.makto : null )
    setGatewayPassword( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.gateway) && checkDataExistsOrNot(configData.cfg.gateway.gwpwd)) ? configData.cfg.gateway.gwpwd: null);
    setWebServerPassword( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.gateway) && checkDataExistsOrNot(configData.cfg.gateway.wspwd)) ? configData.cfg.gateway.wspwd : null);
    setPYOrder( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.gateway) && checkDataExistsOrNot(configData.cfg.gateway.pylorder)) ? configData.cfg.gateway.pylorder : null);
    setWatchDogTimer( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.gateway) && checkDataExistsOrNot(configData.cfg.gateway.wdtinvl)) ? configData.cfg.gateway.wdtinvl : null);
    setCCF( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.gateway) && checkDataExistsOrNot(configData.cfg.gateway.ccf )) ? configData.cfg.gateway.ccf  : null);
    setAPITimeOut( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.apiData) && checkDataExistsOrNot(configData.cfg.apiData.apito)) ? configData.cfg.apiData.apito : null);
    setLogLevel( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.logging) && checkDataExistsOrNot(configData.cfg.logging.lvl)) ? configData.cfg.logging.lvl: null);
    setLogSize( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.logging) && checkDataExistsOrNot(configData.cfg.logging.lsze)) ? configData.cfg.logging.lsze : null);
    setS3BucketName( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.s3BKT) && checkDataExistsOrNot(configData.cfg.s3BKT.nme)) ? configData.cfg.s3BKT.nme : null);
    setS3BucketPath( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.s3BKT) && checkDataExistsOrNot(configData.cfg.s3BKT.pth)) ? configData.cfg.s3BKT.pth : null);
    setElkHostUrl( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.elk) && checkDataExistsOrNot(configData.cfg.elk.hst)) ? configData.cfg.elk.hst : null);
    setElkPort( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.elk) && checkDataExistsOrNot(configData.cfg.elk.prt)) ? configData.cfg.elk.prt : null);
    setElkLogSts( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.elk) && checkDataExistsOrNot(configData.cfg.elk.logsts)) ? configData.cfg.elk.logsts : null)
    setElkUsername( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.elk) && checkDataExistsOrNot(configData.cfg.elk.unme)) ? configData.cfg.elk.unme : null);
    setElkPassword( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.elk) && checkDataExistsOrNot(configData.cfg.elk.upswd)) ? configData.cfg.elk.upswd : null);
    setPollFrequency( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.poll) && checkDataExistsOrNot(configData.cfg.poll.pti)) ? configData.cfg.poll.pti : null);
    setDataRecordFrequency( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.poll) && checkDataExistsOrNot(configData.cfg.poll.dpf)) ? configData.cfg.poll.dpf : null);
    setDataPostFrequency( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.poll) && checkDataExistsOrNot(configData.cfg.poll.ppf)) ? configData.cfg.poll.ppf : null);
    setCrbDataSts( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.poll) && checkDataExistsOrNot(configData.cfg.poll.crbsts)) ? configData.cfg.poll.crbsts : null)
    setLoginUrl( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.auth) && checkDataExistsOrNot(configData.cfg.auth.surl)) ? configData.cfg.auth.surl : null);
    setUserEmail( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.auth) && checkDataExistsOrNot(configData.cfg.auth.eml)) ? configData.cfg.auth.eml : null);
    setUserPassword( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.auth) && checkDataExistsOrNot(configData.cfg.auth.pwd)) ? configData.cfg.auth.pwd : null);
    setScopeUrl( (checkDataExistsOrNot(configData.cfg) && checkDataExistsOrNot(configData.cfg.auth) && checkDataExistsOrNot(configData.cfg.scope.surl)) ? configData.cfg.scope.surl : null);
    setConfigVersion( checkDataExistsOrNot(configData.cfv) ? configData.cfv : null);
    setDisableResetBtn(configData.cfv<=1)
  }

  const togglePassword =(type)=>{
      if(type === "mqttPassword")
      {
          setMqttPasswordType(mqttPasswordType === "text" ? "password" : "text")
          return;
      }else if(type === "gateWayPassword"){
        setGWPasswordType(gWPasswordType === "text" ? "password" : "text");
        return;
      }else if(type === "webServerPassword"){
        setWebServerPasswordType(webServerPasswordType === "text" ? "password" : "text");
        return;
      }else if(type === "elkPassword"){
        setElkPasswordType(elkPasswordType === "text" ? "password" : "text");
        return;
      }else if(type === "loginPassword"){
        setLoginPasswordType(loginPasswordType === "text" ? "password" : "text");
        return;
      }
      return;
  }

  const checkSpace = (value, flag) => {
    if(value === " " || value.indexOf(" ") === 0){
        if(value.length <= 1)
            return "";
        return value ? value.trim() : "";
    }
    return value;
  }

  const checkWithOldValue = (oldValue, newValue) => {
    if(typeof oldValue == 'number')
        newValue = parseInt(newValue,10);

    return oldValue === newValue ? false : true ;
  }

  const checkForEmptyEntryAndDataExistence = () => {
    return ( checkDataExistsOrNot(window.configData) && checkDataExistsOrNot(window.configData.cfg) && checkDataExistsOrNot(window.configData.cfg.mqtt) && checkDataExistsOrNot(window.configData.cfg.mqtt.burl) && checkDataExistsOrNot(window.configData.cfg.mqtt.mat) &&
           checkDataExistsOrNot(window.configData.cfg.mqtt.mprt) && checkDataExistsOrNot(window.configData.cfg.mqtt.mcpwd) && checkDataExistsOrNot(window.configData.cfg.mqtt.cacrtpth) && checkDataExistsOrNot(window.configData.cfg.mqtt.mate0) &&
           checkDataExistsOrNot(window.configData.cfg.mqtt.makto) && checkDataExistsOrNot(window.configData.cfg.gateway) && checkDataExistsOrNot(window.configData.cfg.gateway.gwpwd) && checkDataExistsOrNot(window.configData.cfg.gateway.wspwd) &&
           checkDataExistsOrNot(window.configData.cfg.gateway.pylorder) && checkDataExistsOrNot(window.configData.cfg.gateway.wdtinvl) && checkDataExistsOrNot(window.configData.cfg.gateway.ccf) && checkDataExistsOrNot(window.configData.cfg.apiData) &&
           checkDataExistsOrNot(window.configData.cfg.apiData.apito) && checkDataExistsOrNot(window.configData.cfg.apiData.apiwti) && checkDataExistsOrNot(window.configData.cfg.logging) && checkDataExistsOrNot(window.configData.cfg.logging.lvl) &&
           checkDataExistsOrNot(window.configData.cfg.logging.lsze) && checkDataExistsOrNot(window.configData.cfg.s3BKT) && checkDataExistsOrNot(window.configData.cfg.s3BKT.nme) && checkDataExistsOrNot(window.configData.cfg.s3BKT.pth) && checkDataExistsOrNot(window.configData.cfg.elk) &&
           checkDataExistsOrNot(window.configData.cfg.elk.hst) && checkDataExistsOrNot(window.configData.cfg.elk.prt) && checkDataExistsOrNot(window.configData.cfg.elk.logsts) && checkDataExistsOrNot(window.configData.cfg.elk.unme) && checkDataExistsOrNot(window.configData.cfg.elk.upswd) &&
           checkDataExistsOrNot(window.configData.cfg.poll) && checkDataExistsOrNot(window.configData.cfg.poll.pti) && checkDataExistsOrNot(window.configData.cfg.poll.dpf) && checkDataExistsOrNot(window.configData.cfg.poll.ppf) && checkDataExistsOrNot(window.configData.cfg.poll.crbsts) &&
           checkDataExistsOrNot(window.configData.cfg.auth) && checkDataExistsOrNot(window.configData.cfg.auth.surl) && checkDataExistsOrNot(window.configData.cfg.auth.eml) && checkDataExistsOrNot(window.configData.cfg.auth.pwd) && checkDataExistsOrNot(window.configData.cfg.scope) &&
           checkDataExistsOrNot(window.configData.cfg.scope.surl) && checkDataExistsOrNot(window.configData.cfv) &&
           ((window.configData.cfg.mqtt.mat >= 30 && window.configData.cfg.mqtt.mat <= 600) && (window.configData.cfg.mqtt.mprt >= 2000 && window.configData.cfg.mqtt.mprt <= 9999) && (window.configData.cfg.mqtt.mate0 >=30 && window.configData.cfg.mqtt.mate0 <= 60) &&
           (window.configData.cfg.mqtt.makto >= 30 && window.configData.cfg.mqtt.makto <= 120) && (window.configData.cfg.apiData.apito >= 1 && window.configData.cfg.apiData.apito <= 60) && (window.configData.cfg.gateway.wdtinvl >=30 && window.configData.cfg.gateway.wdtinvl <= 60) &&
           (window.configData.cfg.logging.lsze >= 1000000 && window.configData.cfg.logging.lsze <= 10000000) && (window.configData.cfg.elk.prt >= 2000 && window.configData.cfg.elk.prt <= 9999) &&
           (window.configData.cfg.poll.pti >= 1 && window.configData.cfg.poll.pti <= 30) && (window.configData.cfg.poll.dpf >= window.configData.cfg.poll.pti && window.configData.cfg.poll.dpf <=900) &&
           (window.configData.cfg.poll.ppf >= window.configData.cfg.poll.dpf && window.configData.cfg.poll.ppf <= 900) && (window.configData.cfg.gateway.ccf >= window.configData.cfg.poll.dpf && window.configData.cfg.gateway.ccf <= 900))
           )
  }

  const checkForValidity = () => {
    return  (  checkForEmptyEntryAndDataExistence()) ?
            ((checkDataExistsOrNot(window.configData.cfg.mqtt.burl) ?
                checkDataExistsOrNot(window.oldData.cfg.mqtt.burl) ? checkWithOldValue(window.oldData.cfg.mqtt.burl, window.configData.cfg.mqtt.burl) :
                                                            checkDataExistsOrNot(window.configData.cfg.mqtt.burl) : checkDataExistsOrNot(window.configData.cfg.mqtt.burl)) ||
            (checkDataExistsOrNot(window.configData.cfg.mqtt.mat) ?
                checkDataExistsOrNot(window.oldData.cfg.mqtt.mat) ? checkWithOldValue(window.oldData.cfg.mqtt.mat, window.configData.cfg.mqtt.mat) :
                                                            checkDataExistsOrNot(window.configData.cfg.mqtt.mat) : checkDataExistsOrNot(window.configData.cfg.mqtt.mat)) ||
            (checkDataExistsOrNot(window.configData.cfg.mqtt.mprt) ?
                checkDataExistsOrNot(window.oldData.cfg.mqtt.mprt) ? checkWithOldValue(window.oldData.cfg.mqtt.mprt, window.configData.cfg.mqtt.mprt) :
                                                            checkDataExistsOrNot(window.configData.cfg.mqtt.mprt) : checkDataExistsOrNot(window.configData.cfg.mqtt.mprt)) ||
            (checkDataExistsOrNot(window.configData.cfg.mqtt.mcpwd) ?
                checkDataExistsOrNot(window.oldData.cfg.mqtt.mcpwd) ? checkWithOldValue(window.oldData.cfg.mqtt.mcpwd, window.configData.cfg.mqtt.mcpwd) :
                                                            checkDataExistsOrNot(window.configData.cfg.mqtt.mcpwd) : checkDataExistsOrNot(window.configData.cfg.mqtt.mcpwd)) ||
            (checkDataExistsOrNot(window.configData.cfg.mqtt.cacrtpth) ?
                checkDataExistsOrNot(window.oldData.cfg.mqtt.cacrtpth) ? checkWithOldValue(window.oldData.cfg.mqtt.cacrtpth, window.configData.cfg.mqtt.cacrtpth) :
                                                            checkDataExistsOrNot(window.configData.cfg.mqtt.cacrtpth) : checkDataExistsOrNot(window.configData.cfg.mqtt.cacrtpth)) ||
            (checkDataExistsOrNot(window.configData.cfg.mqtt.mate0) ?
                checkDataExistsOrNot(window.oldData.cfg.mqtt.mate0) ? checkWithOldValue(window.oldData.cfg.mqtt.mate0, window.configData.cfg.mqtt.mate0) :
                                                            checkDataExistsOrNot(window.configData.cfg.mqtt.mate0) : checkDataExistsOrNot(window.configData.cfg.mqtt.mate0)) ||
            (checkDataExistsOrNot(window.configData.cfg.mqtt.makto) ?
                checkDataExistsOrNot(window.oldData.cfg.mqtt.makto) ? checkWithOldValue(window.oldData.cfg.mqtt.makto, window.configData.cfg.mqtt.makto) :
                                                            checkDataExistsOrNot(window.configData.cfg.mqtt.makto) : checkDataExistsOrNot(window.configData.cfg.mqtt.makto)) ||
            (checkDataExistsOrNot(window.configData.cfg.gateway.gwpwd) ?
                checkDataExistsOrNot(window.oldData.cfg.gateway.gwpwd) ? checkWithOldValue(window.oldData.cfg.gateway.gwpwd, window.configData.cfg.gateway.gwpwd) :
                                                            checkDataExistsOrNot(window.configData.cfg.gateway.gwpwd) : checkDataExistsOrNot(window.configData.cfg.gateway.gwpwd)) ||
            (checkDataExistsOrNot(window.configData.cfg.gateway.wspwd) ?
                checkDataExistsOrNot(window.oldData.cfg.gateway.wspwd) ? checkWithOldValue(window.oldData.cfg.gateway.wspwd, window.configData.cfg.gateway.wspwd) :
                                                            checkDataExistsOrNot(window.configData.cfg.gateway.wspwd) : checkDataExistsOrNot(window.configData.cfg.gateway.wspwd)) ||
            (checkDataExistsOrNot(window.configData.cfg.gateway.pylorder) ?
                checkDataExistsOrNot(window.oldData.cfg.gateway.pylorder) ? checkWithOldValue(window.oldData.cfg.gateway.pylorder, window.configData.cfg.gateway.pylorder) :
                                                            checkDataExistsOrNot(window.configData.cfg.gateway.pylorder) : checkDataExistsOrNot(window.configData.cfg.gateway.pylorder)) ||
            (checkDataExistsOrNot(window.configData.cfg.gateway.wdtinvl) ?
                checkDataExistsOrNot(window.oldData.cfg.gateway.wdtinvl) ? checkWithOldValue(window.oldData.cfg.gateway.wdtinvl, window.configData.cfg.gateway.wdtinvl) :
                                                            checkDataExistsOrNot(window.configData.cfg.gateway.wdtinvl) : checkDataExistsOrNot(window.configData.cfg.gateway.wdtinvl)) ||
            (checkDataExistsOrNot(window.configData.cfg.gateway.ccf) ?
                checkDataExistsOrNot(window.oldData.cfg.gateway.ccf) ? checkWithOldValue(window.oldData.cfg.gateway.ccf, window.configData.cfg.gateway.ccf) :
                                                            checkDataExistsOrNot(window.configData.cfg.gateway.ccf) : checkDataExistsOrNot(window.configData.cfg.gateway.ccf)) ||
            (checkDataExistsOrNot(window.configData.cfg.apiData.apito) ?
                checkDataExistsOrNot(window.oldData.cfg.apiData.apito) ? checkWithOldValue(window.oldData.cfg.apiData.apito, window.configData.cfg.apiData.apito) :
                                                            checkDataExistsOrNot(window.configData.cfg.apiData.apito) : checkDataExistsOrNot(window.configData.cfg.apiData.apito)) ||
            (checkDataExistsOrNot(window.configData.cfg.apiData.apiwti) ?
                checkDataExistsOrNot(window.oldData.cfg.apiData.apiwti) ? checkWithOldValue(window.oldData.cfg.apiData.apiwti, window.configData.cfg.apiData.apiwti) :
                                                            checkDataExistsOrNot(window.configData.cfg.apiData.apiwti) : checkDataExistsOrNot(window.configData.cfg.apiData.apiwti)) ||
            (checkDataExistsOrNot(window.configData.cfg.logging.lvl) ?
                checkDataExistsOrNot(window.oldData.cfg.logging.lvl) ? checkWithOldValue(window.oldData.cfg.logging.lvl, window.configData.cfg.logging.lvl) :
                                                            checkDataExistsOrNot(window.configData.cfg.logging.lvl) : checkDataExistsOrNot(window.configData.cfg.logging.lvl)) ||
            (checkDataExistsOrNot(window.configData.cfg.logging.lsze) ?
                checkDataExistsOrNot(window.oldData.cfg.logging.lsze) ? checkWithOldValue(window.oldData.cfg.logging.lsze, window.configData.cfg.logging.lsze) :
                                                            checkDataExistsOrNot(window.configData.cfg.logging.lsze) : checkDataExistsOrNot(window.configData.cfg.logging.lsze)) ||
            (checkDataExistsOrNot(window.configData.cfg.s3BKT.nme) ?
                checkDataExistsOrNot(window.oldData.cfg.s3BKT.nme) ? checkWithOldValue(window.oldData.cfg.s3BKT.nme, window.configData.cfg.s3BKT.nme) :
                                                            checkDataExistsOrNot(window.configData.cfg.s3BKT.nme) : checkDataExistsOrNot(window.configData.cfg.s3BKT.nme)) ||
            (checkDataExistsOrNot(window.configData.cfg.s3BKT.pth) ?
                checkDataExistsOrNot(window.oldData.cfg.s3BKT.pth) ? checkWithOldValue(window.oldData.cfg.s3BKT.pth, window.configData.cfg.s3BKT.pth) :
                                                            checkDataExistsOrNot(window.configData.cfg.s3BKT.pth) : checkDataExistsOrNot(window.configData.cfg.s3BKT.pth)) ||
            (checkDataExistsOrNot(window.configData.cfg.elk.hst) ?
                checkDataExistsOrNot(window.oldData.cfg.elk.hst) ? checkWithOldValue(window.oldData.cfg.elk.hst, window.configData.cfg.elk.hst) :
                                                            checkDataExistsOrNot(window.configData.cfg.elk.hst) : checkDataExistsOrNot(window.configData.cfg.elk.hst)) ||
            (checkDataExistsOrNot(window.configData.cfg.elk.prt) ?
                checkDataExistsOrNot(window.oldData.cfg.elk.prt) ? checkWithOldValue(window.oldData.cfg.elk.prt, window.configData.cfg.elk.prt) :
                                                            checkDataExistsOrNot(window.configData.cfg.elk.prt) : checkDataExistsOrNot(window.configData.cfg.elk.prt)) ||
            (checkDataExistsOrNot(window.configData.cfg.elk.logsts) ?
                checkDataExistsOrNot(window.oldData.cfg.elk.logsts) ? checkWithOldValue(window.oldData.cfg.elk.logsts, window.configData.cfg.elk.logsts) :
                                                            checkDataExistsOrNot(window.configData.cfg.elk.logsts) : checkDataExistsOrNot(window.configData.cfg.elk.logsts)) ||
            (checkDataExistsOrNot(window.configData.cfg.elk.unme) ?
                checkDataExistsOrNot(window.oldData.cfg.elk.unme) ? checkWithOldValue(window.oldData.cfg.elk.unme, window.configData.cfg.elk.unme) :
                                                            checkDataExistsOrNot(window.configData.cfg.elk.unme) : checkDataExistsOrNot(window.configData.cfg.elk.unme)) ||
            (checkDataExistsOrNot(window.configData.cfg.elk.upswd) ?
                checkDataExistsOrNot(window.oldData.cfg.elk.upswd) ? checkWithOldValue(window.oldData.cfg.elk.upswd, window.configData.cfg.elk.upswd) :
                                                            checkDataExistsOrNot(window.configData.cfg.elk.upswd) : checkDataExistsOrNot(window.configData.cfg.elk.upswd)) ||
            (checkDataExistsOrNot(window.configData.cfg.poll.pti) ?
                checkDataExistsOrNot(window.oldData.cfg.poll.pti) ? checkWithOldValue(window.oldData.cfg.poll.pti, window.configData.cfg.poll.pti) :
                                                            checkDataExistsOrNot(window.configData.cfg.poll.pti) : checkDataExistsOrNot(window.configData.cfg.poll.pti)) ||
            (checkDataExistsOrNot(window.configData.cfg.poll.dpf) ?
                checkDataExistsOrNot(window.oldData.cfg.poll.dpf) ? checkWithOldValue(window.oldData.cfg.poll.dpf, window.configData.cfg.poll.dpf) :
                                                            checkDataExistsOrNot(window.configData.cfg.poll.dpf) : checkDataExistsOrNot(window.configData.cfg.poll.dpf)) ||
            (checkDataExistsOrNot(window.configData.cfg.poll.ppf) ?
                checkDataExistsOrNot(window.oldData.cfg.poll.ppf) ? checkWithOldValue(window.oldData.cfg.poll.ppf, window.configData.cfg.poll.ppf) :
                                                            checkDataExistsOrNot(window.configData.cfg.poll.ppf) : checkDataExistsOrNot(window.configData.cfg.poll.ppf)) ||
            (checkDataExistsOrNot(window.configData.cfg.poll.crbsts) ?
                checkDataExistsOrNot(window.oldData.cfg.poll.crbsts) ? checkWithOldValue(window.oldData.cfg.poll.crbsts, window.configData.cfg.poll.crbsts) :
                                                            checkDataExistsOrNot(window.configData.cfg.poll.crbsts) : checkDataExistsOrNot(window.configData.cfg.poll.crbsts)) ||
            (checkDataExistsOrNot(window.configData.cfg.auth.surl) ?
                checkDataExistsOrNot(window.oldData.cfg.auth.surl) ? checkWithOldValue(window.oldData.cfg.auth.surl, window.configData.cfg.auth.surl) :
                                                            checkDataExistsOrNot(window.configData.cfg.auth.surl) : checkDataExistsOrNot(window.configData.cfg.auth.surl)) ||
            (checkDataExistsOrNot(window.configData.cfg.auth.eml) ?
                checkDataExistsOrNot(window.oldData.cfg.auth.eml) ? checkWithOldValue(window.oldData.cfg.auth.eml, window.configData.cfg.auth.eml) :
                                                            checkDataExistsOrNot(window.configData.cfg.auth.eml) : checkDataExistsOrNot(window.configData.cfg.auth.eml)) ||
            (checkDataExistsOrNot(window.configData.cfg.auth.pwd) ?
                checkDataExistsOrNot(window.oldData.cfg.auth.pwd) ? checkWithOldValue(window.oldData.cfg.auth.pwd, window.configData.cfg.auth.pwd) :
                                                            checkDataExistsOrNot(window.configData.cfg.auth.pwd) : checkDataExistsOrNot(window.configData.cfg.auth.pwd)) ||
            (checkDataExistsOrNot(window.configData.cfg.scope.surl) ?
                checkDataExistsOrNot(window.oldData.cfg.scope.surl) ? checkWithOldValue(window.oldData.cfg.scope.surl, window.configData.cfg.scope.surl) :
                                                            checkDataExistsOrNot(window.configData.cfg.scope.surl) : checkDataExistsOrNot(window.configData.cfg.scope.surl)) ||
            (checkDataExistsOrNot(window.configData.cfv) ?
                checkDataExistsOrNot(window.oldData.cfv) ? checkWithOldValue(window.oldData.cfv, window.configData.cfv) :
                                                            checkDataExistsOrNot(window.configData.cfv) : checkDataExistsOrNot(window.configData.cfv)) ) : false ;
  }

  const showUpdateConfigDataDialog = (content) => {
    setUpdateDialogContent(content);
    setShowUpdateDialog(true);
  }

  const updateConfigData = () => {

    setShowUpdateDialog(false);
    setIsValid(false);
    let payload = {
        SID : sessionStorage.tenantId,
        cfg:{
            mqtt : {
                burl : mqttBaseUrl,
                mat : mqttAliveTime,
                mprt : mqttPort,
                mcpwd : mqttPassword,
                cacrtpth : mqttCertificatePath,
                mate0 : mqttWaitTime,
                makto : mqttMakto
            },
            gateway : {
                gwpwd : gatewayPassword,
                wspwd : webServerPassword,
                pylorder : PYOrder,
                wdtinvl : watchDogTimer,
                ccf : CCF
            },
            apiData : {
                apito : APITimeOut,
            },
            logging : {
                lvl : logLevel,
                lsze : logSize
            },
            s3BKT : {
                nme : s3BucketName,
                pth : s3BucketPath
            },
            elk : {
                hst : elkHostUrl,
                prt : elkPort,
                logsts : elkLogSts,
                unme : elkUsername,
                upswd : elkPassword
            },
            poll : {
                pti : pollFrequency,
                dpf : dataRecordFrequency,
                ppf : dataPostFrequency,
                crbsts : crbDataSts
            },
            auth : {
                surl : loginUrl,
                eml : userEmail,
                pwd : userPassword,
            },
            scope : {
                surl : scopeUrl
            }
        },
        cfv : configVersion
    }
    IotServiceClient.updateAgentConfigData(payload).then((res) => {
        if(res.status >= 200 && res.status <= 300){
            enqueueSnackbar("Successfully updated agent config data.");
        }else{
            enqueueSnackbar("Failed to updated agent config data.");
        }
        fetchAgentConfigData();
    })
  }

  const resetToDefault = () => {
    setShowUpdateDialog(false)
    IotServiceClient.resetConfigDataToDefault(sessionStorage.tenantId).then((res) => {
        if(res.status >= 200 && res.status <= 300){
            enqueueSnackbar("Successfully updated agent config data to default.");
        }else{
            enqueueSnackbar("Failed to update agent config data to default.");
        }
        fetchAgentConfigData();
    })
  }

  const setValuesForPollAndPost = (value, type) => {
    if(type === "Poll frequency"){
        setDataRecordFrequency(value);
        window.configData.cfg.poll.dpf = value;
        setDataRecordFrequencyHelper(checkDataExistsOrNot(value) ? value < window.configData.cfg.poll.pti || value > 900 : true);
    }
    setDataPostFrequency(value);
    window.configData.cfg.poll.ppf = value;
    setDataPostFrequencyHelper(checkDataExistsOrNot(value) ? value < window.configData.cfg.poll.dpf || value > 900 : true);
    setCCF(value);
    window.configData.cfg.gateway.ccf = value;
    setCcfHelper(checkDataExistsOrNot(value) ? value < window.configData.cfg.poll.dpf || value > 900 : true)
  }

  React.useEffect(() => {
    enqueueSnackbar("Fetching agent config details.")
    fetchAgentConfigData();
    setTimeout(() => {
      setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
      setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
    }, 1000);
  },[])

  return(
    <>
      <Box sx={{display:"flex",flexDirection:"column", padding:"20px 30px 0px 20px"}}>
        <Box sx={{fontSize:"20px", display:"flex", justifyContent:"space-between"}}>Agent Configuration
            <Button variant='contained' sx={{backgroundColor: (!disableResetBtn ? primaryThemeColor + " !important" : "grey") }} disabled={disableResetBtn} onClick={()=> showUpdateConfigDataDialog("reset")}>
                Reset to default <CloudSync fontSize = "small" sx={{marginLeft:"6px"}}></CloudSync>
            </Button>
        </Box>
        <Box sx={{display:"flex",flexDirection:"column", padding:"20px 30px 0px 20px"}}>
           <Box className="configContent" sx={{ justifyContent:"space-around", padding: "20px 0px 30px 0px"}}>
              <Box sx={{ display:"flex", flexDirection:"column" }}>
                  <Box sx={{paddingBottom:"10px"}}> MQTT info </Box>
                  <TextField
                    label="Base URL"
                    type="text"
                    spellcheck="false"
                    variant="standard"
                    value={mqttBaseUrl}
                    InputProps={{
                        autoComplete : 'new-password',
                        endAdornment: <InputAdornment position="end">
                            <div>
                               <Tooltip title={getTooltipText('b_URL')} placement='top' arrow>
                                  <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                               </Tooltip>
                            </div>
                         </InputAdornment>
                    }}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.mqtt.burl = value;
                      setMqttBaseUrl(value);
                      setIsValid(checkForValidity());
                    }}
                    sx={{width:"20dvw"}}
                  />
                  <TextField
                    className="numberTextFieldSpinners"
                    label="LTE Keep Alive time"
                    type="number"
                    variant="standard"
                    value={mqttAliveTime}
                    InputProps={{
                      autoComplete : 'new-password',
                      inputProps: {
                        min: 30, max: 600, // Enforces maximum value
                        onInput: (e) => {
                            setAliveTimeHelper(e.target.value < 30 || e.target.value > 600 ? true : false)
                        },
                      },
                      endAdornment: <InputAdornment position="end">
                          <div>
                             <Tooltip title={getTooltipText('Keep_Ati')} placement='top' arrow>
                                <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                             </Tooltip>
                          </div>
                       </InputAdornment>
                    }}
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.mqtt.mat = value;
                      setMqttAliveTime(value);
                      setIsValid(checkForValidity());
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <FormHelperText sx={{marginBottom:'5px'}} variant="standard" error={aliveTimeHelper} >Only inputs in the range 30-600 secs are allowed.</FormHelperText>
                  <TextField
                    className="numberTextFieldSpinners"
                    label="Port Number"
                    type="number"
                    variant="standard"
                    value={mqttPort}
                    InputProps={{
                      autoComplete :'new-password',
                      inputProps: {
                        min: 2000, max: 9999, // Enforces maximum value
                        onInput: (e) => {
                          setMqttPortHelper(e.target.value < 2000 || e.target.value > 9999 ? true : false)
                        },
                      },
                      endAdornment: <InputAdornment position="end">
                          <div>
                             <Tooltip title={getTooltipText('Mq_Pno.')} placement='top' arrow>
                                <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                             </Tooltip>
                          </div>
                       </InputAdornment>
                    }}
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.mqtt.mprt = value;
                      setMqttPort(value);
                      setIsValid(checkForValidity());
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <FormHelperText sx={{marginBottom:'5px'}} variant="standard" error={mqttPortHelper} >Only inputs in the range 2000-9999 are allowed.</FormHelperText>
                  <TextField
                    label="Password"
                    type={mqttPasswordType}
                    variant="standard"
                    value={mqttPassword}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.mqtt.mcpwd = value;
                      setMqttPassword(value);
                      setIsValid(checkForValidity())
                    }}
                    InputProps={{
                       autoComplete : 'new-password',
                       endAdornment: <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onMouseDown={()=>togglePassword("mqttPassword")}
                              edge="end"
                            >
                              {mqttPasswordType==="password" ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            <div style={{marginLeft: '9px'}}>
                               <Tooltip title={getTooltipText('Mq_pwd')} placement='top' arrow>
                                  <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                               </Tooltip>
                            </div>
                        </InputAdornment>
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <TextField
                    label="Certificate Path"
                    type="text"
                    spellcheck="false"
                    variant="standard"
                    value={mqttCertificatePath}
                    InputProps={{
                        autoComplete : 'new-password',
                        endAdornment: <InputAdornment position="end">
                          <div>
                             <Tooltip title={getTooltipText('Mq_CrtPth')} placement='top' arrow>
                                <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                             </Tooltip>
                          </div>
                         </InputAdornment>
                    }}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.mqtt.cacrtpth = value;
                      setMqttCertificatePath(value);
                      setIsValid(checkForValidity());
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <TextField
                    className="numberTextFieldSpinners"
                    label="Non LTE Keep Alive time"
                    type="number"
                    variant="standard"
                    value={mqttWaitTime}
                    InputProps={{
                      autoComplete : 'new-password',
                      inputProps: {
                        min: 30, max: 60, // Enforces maximum value
                        onInput: (e) => {
                            setRandomTimeHelper(e.target.value < 30 || e.target.value > 60 ? true : false)
                        },
                      },
                      endAdornment: <InputAdornment position="end">
                         <div>
                            <Tooltip title={getTooltipText('Rnd_wt')} placement='top' arrow>
                               <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                            </Tooltip>
                         </div>
                       </InputAdornment>
                    }}
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.mqtt.mate0 = value;
                      setMqttWaitTime(value);
                      setIsValid(checkForValidity());
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <FormHelperText sx={{marginBottom:'5px'}} variant="standard" error={randomTimeHelper} >Only inputs in the range 30-60 secs are allowed.</FormHelperText>
                  <TextField
                    className="numberTextFieldSpinners"
                    label="Mqtt ack time out"
                    type="number"
                    variant="standard"
                    value={mqttMakto}
                    InputProps={{
                      autoComplete : 'new-password',
                      inputProps: {
                        min: 30, max: 120, // Enforces maximum value
                        onInput: (e) => {
                            setAckTimeHelper(e.target.value < 30 || e.target.value > 120)
                        },
                      },
                      endAdornment: <InputAdornment position="end">
                         <div>
                            <Tooltip title={getTooltipText('Mq_Ack_to')} placement='top' arrow>
                               <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                            </Tooltip>
                         </div>
                       </InputAdornment>
                    }}
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.mqtt.makto = value;
                      setMqttMakto(value);
                      setIsValid(checkForValidity());
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <FormHelperText variant="standard" error={ackTimeHelper} >Only inputs in the range 30-120 secs are allowed.</FormHelperText>
              </Box>
              <Divider orientation="vertical" variant="middle" flexItem sx={{borderRightWidth: '4px'}}/>
              <Box sx={{display:"flex", flexDirection:"column" }}>
                  <Box sx={{paddingBottom:"10px"}}>Gateway info</Box>
                  <TextField
                    label="Gateway Password"
                    type={gWPasswordType}
                    variant="standard"
                    value={gatewayPassword}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.gateway.gwpwd = value;
                      setGatewayPassword(value);
                      setIsValid(checkForValidity())
                    }}
                    InputProps={{
                       autoComplete : 'new-password',
                       endAdornment: <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onMouseDown={()=>togglePassword("gateWayPassword")}
                              edge="end"
                            >
                              {gWPasswordType==="password" ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            <div style={{marginLeft: '9px'}}>
                               <Tooltip title={getTooltipText('Gw_Pwd')} placement='top' arrow>
                                  <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                               </Tooltip>
                            </div>
                        </InputAdornment>
                    }}
                    sx={{width:"20dvw"}}
                  />
                  <TextField
                    label="Web Server Password"
                    type={webServerPasswordType}
                    variant="standard"
                    value={webServerPassword}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.gateway.wspwd = value;
                      setWebServerPassword(value);
                      setIsValid(checkForValidity());
                    }}
                    InputProps={{
                       autoComplete : 'new-password',
                       endAdornment: <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onMouseDown={()=>togglePassword("webServerPassword")}
                              edge="end"
                            >
                              {webServerPasswordType==="password" ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            <div style={{marginLeft: '9px'}}>
                               <Tooltip title={getTooltipText('Ws_Pwd')} placement='top' arrow>
                                  <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                               </Tooltip>
                            </div>
                        </InputAdornment>
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <TextField
                    label="Customer App exec order"
                    type="text"
                    variant="standard"
                    value={PYOrder}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.gateway.pylorder = value;
                      setPYOrder(value);
                      setIsValid(checkForValidity())
                    }}
                    InputProps={{
                        autoComplete : 'new-password',
                        endAdornment: <InputAdornment position="end">
                           <div>
                              <Tooltip title={getTooltipText('cus_app_exec_order')} placement='top' arrow>
                                 <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                              </Tooltip>
                           </div>
                         </InputAdornment>
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <TextField
                    className="numberTextFieldSpinners"
                    label="Watch dog timer"
                    type="number"
                    variant="standard"
                    value={watchDogTimer}
                    InputProps={{
                      autoComplete : 'new-password',
                      inputProps: {
                        min: 30, max: 60, // Enforces maximum value
                        onInput: (e) => {
                            setWatchDogHelper(e.target.value < 30 || e.target.value > 60 ? true : false)
                        },
                      },
                      endAdornment: <InputAdornment position="end">
                          <div>
                             <Tooltip title={getTooltipText('Wtch_dog_timr')} placement='top' arrow>
                                <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                             </Tooltip>
                          </div>
                        </InputAdornment>
                    }}
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.gateway.wdtinvl = value;
                      setWatchDogTimer(value);
                      setIsValid(checkForValidity());
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <FormHelperText sx={{marginBottom:'5px'}} variant="standard" error={watchDogHelper} >Only inputs in the range 30-60 secs are allowed.</FormHelperText>
                  <TextField
                    className="numberTextFieldSpinners"
                    label="API Time out"
                    type="number"
                    variant="standard"
                    value={APITimeOut}
                    InputProps={{
                      autoComplete : 'new-password',
                      inputProps: {
                        min: 1, max: 60, // Enforces maximum value
                        onInput: (e) => {
                          setApiTOutHelper(e.target.value < 1 || e.target.value > 60 ? true : false)
                        },
                      },
                      endAdornment: <InputAdornment position="end">
                         <div>
                            <Tooltip title={getTooltipText('api_to')} placement='top' arrow>
                               <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                            </Tooltip>
                         </div>
                        </InputAdornment>
                    }}
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value, "apitoError");
                      window.configData.cfg.apiData.apito = value;
                      setAPITimeOut(value);
                      setIsValid(checkForValidity());
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <FormHelperText sx={{marginBottom:'5px'}} variant="standard" error={apiTOutHelper} >Only inputs in the range 1-60 secs are allowed.</FormHelperText>
                  <TextField
                    className="numberTextFieldSpinners"
                    label="API wait time"
                    type="number"
                    variant="standard"
                    value={APIWaitTime}
                    InputProps={{
                      autoComplete : 'new-password',
                      inputProps: {
                        min: 1, max: 60, // Enforces maximum value
                        onInput: (e) => {
                          setApiWaitTHelper(e.target.value < 1 || e.target.value > 60 ? true : false)
                        },
                      },
                      endAdornment: <InputAdornment position="end">
                            <div>
                               <Tooltip title={getTooltipText('api_wt')} placement='top' arrow>
                                  <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                               </Tooltip>
                            </div>
                         </InputAdornment>
                    }}
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.apiData.apiwti = value;
                      setAPIWaitTime(value);
                      setIsValid(checkForValidity());
                    }}
                    sx={{width:"20dvw", margin:"5px 0px", visibility: "hidden"}}
                  />
                  <FormHelperText sx={{marginBottom:'5px', visibility: "hidden"}} variant="standard" error={apiWaitTHelper} >Only inputs in the range 1-60 are allowed.</FormHelperText>
              </Box>
              <Divider orientation="vertical" variant="middle" flexItem sx={{borderRightWidth: '4px'}}/>
              <Box className="debugLogLevelDiv" sx={{display:"flex", flexDirection:"column", position: 'relative'}}>
                  <Box sx={{paddingBottom:"10px"}}>Log info</Box>
                  <TextField
                    select
                    label="Debug Log Level"
                    type="text"
                    variant="standard"
                    value={logLevel}
                    onChange={(event) => {
                      let value = event.target.value;
                      //value = checkSpace(value);
                      window.configData.cfg.logging.lvl = value;
                      setLogLevel(value);
                      setIsValid(checkForValidity());
                    }}
                    InputProps={{
                        autoComplete : 'new-password',
                        endAdornment: <InputAdornment position="end">
                           <div>
                              <Tooltip title={getTooltipText('Dbg_Loglv')} placement='top' arrow>
                                 <InfoOutlined />
                              </Tooltip>
                           </div>
                         </InputAdornment>
                    }}
                    sx={{width:"20dvw"}}
                  >
                    {debugOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                   ))}
                  </TextField>
                  <TextField
                    className="numberTextFieldSpinners"
                    label="Debug Log Size"
                    type="number"
                    variant="standard"
                    value={logSize}
                    InputProps={{
                      autoComplete : 'new-password',
                      inputProps: {
                        min: 5000000, max: 10000000, // 5mb-10mb in bytes
                        onInput: (e) => {
                            setLogSizeHelper(e.target.value < 1000000 || e.target.value > 10000000 ? true : false)
                        },
                      },
                      endAdornment: <InputAdornment position="end">
                         <div>
                            <Tooltip title={getTooltipText('Dbg_Logsz')} placement='top' arrow>
                               <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                            </Tooltip>
                         </div>
                       </InputAdornment>
                    }}
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.logging.lsze = value;
                      setLogSize(value);
                      setIsValid(checkForValidity());
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <FormHelperText sx={{marginBottom:'5px'}} variant="standard" error={logSizeHelper} >Only inputs in the range 1mb-10mb(in bytes) are allowed.</FormHelperText>
                  <TextField
                    label="S3 Bucket Name"
                    type="text"
                    spellcheck="false"
                    variant="standard"
                    value={s3BucketName}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.s3BKT.nme = value;
                      setS3BucketName(value);
                      setIsValid(checkForValidity());
                    }}
                    InputProps={{
                        autoComplete : 'new-password',
                        endAdornment: <InputAdornment position="end">
                           <div>
                              <Tooltip title={getTooltipText('S3_bktnm')} placement='top' arrow>
                                 <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                              </Tooltip>
                           </div>
                         </InputAdornment>
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <TextField
                    label="S3 Bucket Path"
                    type="text"
                    spellcheck="false"
                    variant="standard"
                    value={s3BucketPath}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.s3BKT.pth = value;
                      setS3BucketPath(value);
                      setIsValid(checkForValidity());
                    }}
                    InputProps={{
                        autoComplete : 'new-password',
                        endAdornment: <InputAdornment position="end">
                           <div>
                              <Tooltip title={getTooltipText('S3_bktpth')} placement='top' arrow>
                                 <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                              </Tooltip>
                           </div>
                         </InputAdornment>
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <TextField
                    label="ELK Host URL"
                    type="text"
                    spellcheck="false"
                    variant="standard"
                    value={elkHostUrl}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.elk.hst = value;
                      setElkHostUrl(value);
                      setIsValid(checkForValidity());
                    }}
                    InputProps={{
                       autoComplete : 'new-password',
                       endAdornment: <InputAdornment position="end">
                          <div>
                             <Tooltip title={getTooltipText('elk_hst_url')} placement='top' arrow>
                                <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                             </Tooltip>
                          </div>
                        </InputAdornment>
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <TextField
                    className="numberTextFieldSpinners"
                    label="ELK Port"
                    type="number"
                    variant="standard"
                    value={elkPort}
                    InputProps={{
                      autoComplete : 'new-password',
                      inputProps: {
                        min: 2000, max: 9999, // Enforces maximum value
                        onInput: (e) => {
                          setElkPortHelper(e.target.value < 2000 || e.target.value > 9999 ? true : false)
                        },
                      },
                      endAdornment: <InputAdornment position="end">
                         <div>
                            <Tooltip title={getTooltipText('elk_prt')} placement='top' arrow>
                               <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                            </Tooltip>
                         </div>
                       </InputAdornment>
                    }}
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.elk.prt = value;
                      setElkPort(value);
                      setIsValid(checkForValidity());
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <FormHelperText sx={{marginBottom:'5px'}} variant="standard" error={elkPortHelper} >Only inputs in the range 2000-9999 are allowed.</FormHelperText>
                  <TextField
                    label="ELK log status"
                    select
                    type="text"
                    variant="standard"
                    value={elkLogSts == false ? "false" : "true"}
                    InputProps={{
                       endAdornment: <InputAdornment position="end">
                          <div>
                             <Tooltip title={getTooltipText('elk_logst')} placement='top' arrow>
                                <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                             </Tooltip>
                          </div>
                        </InputAdornment>
                    }}
                    onChange={(event) => {
                      let value = event.target.value;
                      window.configData.cfg.elk.logsts = value;
                      setElkLogSts(value);
                      setIsValid(checkForValidity());
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="ELK Username"
                    type="text"
                    spellcheck="false"
                    variant="standard"
                    value={elkUsername}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.elk.unme = value;
                      setElkUsername(value);
                      setIsValid(checkForValidity());
                    }}
                    InputProps={{
                        autoComplete : 'new-password',
                        endAdornment: <InputAdornment position="end">
                           <div>
                              <Tooltip title={getTooltipText('ELK_Unm')} placement='top' arrow>
                                 <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                              </Tooltip>
                           </div>
                         </InputAdornment>
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
                  <TextField
                    label="ELK Password"
                    type={elkPasswordType}
                    variant="standard"
                    value={elkPassword}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.elk.upswd = value;
                      setElkPassword(value);
                      setIsValid(checkForValidity());
                    }}
                    InputProps={{
                       autoComplete : 'new-password',
                       endAdornment: <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onMouseDown={()=>togglePassword("elkPassword")}
                              edge="end"
                            >
                              {elkPasswordType==="password" ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            <div style={{marginLeft: '9px'}}>
                               <Tooltip title={getTooltipText('ELK_pwd')} placement='top' arrow>
                                  <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                               </Tooltip>
                            </div>
                        </InputAdornment>
                    }}
                    sx={{width:"20dvw", marginTop:"5px"}}
                  />
              </Box>
           </Box>
           <Box className="configContent" sx={{ justifyContent:"space-around", padding: "30px 0px 20px 0px"}}>
             <Box className="debugLogLevelDiv" sx={{ display:"flex", flexDirection:"column", position: 'relative'}}>
                 <Box sx={{paddingBottom:"10px"}}> Gateway data </Box>
                 <TextField
                   className="numberTextFieldSpinners"
                   label="Poll frequency"
                   type="number"
                   variant="standard"
                   value={pollFrequency}
                   InputProps={{
                     autoComplete : 'new-password',
                     inputProps: {
                       min: 0, max: 30, // Enforces maximum value
                       onInput: (e) => {
                         setPollFrequencyHelper(e.target.value < 1 || e.target.value > 30 ? true : false)
                       },
                     },
                     endAdornment: <InputAdornment position="end">
                         <div>
                            <Tooltip title={getTooltipText('Poll_fq')} placement='top' arrow>
                              <InfoOutlined />
                            </Tooltip>
                         </div>
                       </InputAdornment>
                   }}
                   onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                   onChange={(event) => {
                     let value = event.target.value;
                     value = checkSpace(value);
                     window.configData.cfg.poll.pti = value;
                     setPollFrequency(value);
                     setValuesForPollAndPost(value, "Poll frequency");
                     setIsValid(checkForValidity());
                   }}
                   sx={{width:"20dvw"}}
                 />
                 <FormHelperText sx={{marginBottom:'5px'}} variant="standard" error={pollFrequencyHelper} >Only inputs in the range 1-30 secs are allowed.</FormHelperText>
                 <TextField
                   select
                   className="numberTextFieldSpinners"
                   label="Data record frequency"
                   type="number"
                   variant="standard"
                   value={dataRecordFrequency}
                   onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                   InputProps={{
                     autoComplete : 'new-password',
                     inputProps: {
                       onInput: (e) => {
                         setDataRecordFrequencyHelper(e.target.value < 1 || e.target.value > 900 ? true : false)
                       },
                     },
                     endAdornment: <InputAdornment position="end">
                        <div>
                           <Tooltip title={getTooltipText('Data_rec_fq')} placement='top' arrow>
                              <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                           </Tooltip>
                        </div>
                       </InputAdornment>
                   }}
                   onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                   onChange={(event) => {
                     let value = event.target.value;
                     window.configData.cfg.poll.dpf = value;
                     setDataRecordFrequency(value);
                     setValuesForPollAndPost(value, "Data record frequency");
                     setIsValid(checkForValidity());
                   }}
                   sx={{width:"20dvw", marginTop:"5px"}}
                   SelectProps={{
                     MenuProps: {
                       PaperProps: {
                         sx: { maxHeight: 200, /* Maximum height of the dropdown menu */ },
                       },
                     },
                   }}
                 >
                   {dropDownOptions.map((option) => (
                     option.value % dataRecordFrequency === 0 && (
                         <MenuItem key={option.value} value={option.value}>
                           {option.label}
                         </MenuItem>
                     )
                   ))}
                 </TextField>
                 <FormHelperText sx={{marginBottom:'5px'}} variant="standard" error={dataRecordFrequencyHelper} >Only inputs in the range {checkDataExistsOrNot(dataRecordFrequency) &&  dataRecordFrequency <= 900 ? dataRecordFrequency : 1}-900 secs are allowed.</FormHelperText>
                 <TextField
                   select
                   className="numberTextFieldSpinners"
                   label="Data post frequency"
                   type="number"
                   variant="standard"
                   value={dataPostFrequency}
                   onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                   InputProps={{
                     autoComplete : 'new-password',
                     inputProps: {
                       onInput: (e) => {
                         setDataPostFrequencyHelper(e.target.value < 1 || e.target.value > 900 ? true : false)
                       },
                     },
                     endAdornment: <InputAdornment position="end">
                         <div>
                            <Tooltip title={getTooltipText('Data_post_fq')} placement='top' arrow>
                              <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                            </Tooltip>
                         </div>
                      </InputAdornment>
                   }}
                   onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                   onChange={(event) => {
                     let value = event.target.value;
                     window.configData.cfg.poll.ppf = value;
                     setDataPostFrequency(value);
                     setIsValid(checkForValidity());
                   }}
                   sx={{width:"20dvw", marginTop:"5px"}}
                   SelectProps={{
                     MenuProps: {
                       PaperProps: {
                         sx: { maxHeight: 200, /* Maximum height of the dropdown menu */ },
                       },
                     },
                   }}
                 >
                   {dropDownOptions.map((option) => (
                     option.value % dataRecordFrequency === 0 && (
                         <MenuItem key={option.value} value={option.value}>
                           {option.label}
                         </MenuItem>
                     )
                   ))}
                 </TextField>
                 <FormHelperText sx={{marginBottom:'5px'}} variant="standard" error={dataPostFrequencyHelper} >Only inputs in the range <span style={{width:'2px'}}>{checkDataExistsOrNot(dataRecordFrequency) && dataRecordFrequency <= 900? dataRecordFrequency : 1}</span>-900 secs are allowed.</FormHelperText>
                 <TextField
                   label="CRB data status"
                   select
                   type="text"
                   variant="standard"
                   value={crbDataSts == false ? "false" : "true"}
                   InputProps={{
                      endAdornment: <InputAdornment position="end">
                          <div>
                             <Tooltip title={getTooltipText('CRB_data_st')} placement='top' arrow>
                               <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                             </Tooltip>
                          </div>
                       </InputAdornment>
                   }}
                   onChange={(event) => {
                     let value = event.target.value;
                     window.configData.cfg.poll.crbsts = value;
                     setCrbDataSts(value);
                     setIsValid(checkForValidity());
                   }}
                   sx={{width:"20dvw", marginTop:"5px"}}
                 >
                   {options.map((option) => (
                     <MenuItem key={option.value} value={option.value}>
                       {option.label}
                     </MenuItem>
                   ))}
                 </TextField>
                 <Box sx={{paddingBottom:"10px",marginTop:"24px"}}>Scope info</Box>
                 <TextField
                    label="Scope URL"
                    type="text"
                    variant="standard"
                    spellcheck="false"
                    value={scopeUrl}
                    onChange={(event) => {
                      let value = event.target.value;
                      value = checkSpace(value);
                      window.configData.cfg.scope.surl = value;
                      setScopeUrl(value);
                      setIsValid(checkForValidity());
                    }}
                    InputProps={{
                        autoComplete : 'new-password',
                        endAdornment: <InputAdornment position="end">
                           <div>
                              <Tooltip title={getTooltipText('scope_info')} placement='top' arrow>
                                 <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                              </Tooltip>
                           </div>
                         </InputAdornment>
                    }}
                    sx={{width:"20dvw", paddingBottom:"10px"}}
                 />
             </Box>
             <Divider orientation="vertical" variant="middle" flexItem sx={{borderRightWidth: '4px'}}/>
             <Box className="debugLogLevelDiv" sx={{display:"flex", flexDirection:"column", position: 'relative' }}>
                 <Box sx={{paddingBottom:"10px"}}>CCF</Box>
                 <TextField
                   select
                   className="numberTextFieldSpinners"
                   label="CCF"
                   type="number"
                   variant="standard"
                   value={CCF}
                   InputProps={{
                     autoComplete : 'new-password',
                     inputProps: {
                       min: 0, max: 900, // Enforces maximum value
                       onInput: (e) => {
                           setCcfHelper(e.target.value < 30 || e.target.value > 900 ? true : false);
                       },
                     },
                     endAdornment: <InputAdornment position="end">
                         <div>
                            <Tooltip title={getTooltipText('CCF')} placement='top' arrow>
                               <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                            </Tooltip>
                         </div>
                       </InputAdornment>
                   }}
                   onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                   onChange={(event) => {
                     let value = event.target.value;
                     window.configData.cfg.gateway.ccf = value;
                     setCCF(value);
                     setIsValid(checkForValidity());
                   }}
                   sx={{width:"20dvw", marginTop:"5px"}}
                   SelectProps={{
                     MenuProps: {
                       PaperProps: {
                         sx: { maxHeight: 200, /* Maximum height of the dropdown menu */ },
                       },
                     },
                   }}
                 >
                   {dropDownOptions.map((option) => (
                     option.value % dataRecordFrequency === 0 && (
                         <MenuItem key={option.value} value={option.value}>
                           {option.label}
                         </MenuItem>
                     )
                   ))}
                 </TextField>
                 <FormHelperText sx={{marginBottom:'5px'}} variant="standard" error={ccfHelper} >Only inputs in the range {checkDataExistsOrNot(dataRecordFrequency) && dataRecordFrequency <= 900 ? dataRecordFrequency : 1}-900 secs are allowed.</FormHelperText>
                 <Box sx={{marginTop:"20px",paddingBottom:"10px"}}>Login info</Box>
                 <TextField
                   label="Auth URL"
                   type="text"
                   spellcheck="false"
                   variant="standard"
                   value={loginUrl}
                   onChange={(event) => {
                     let value = event.target.value;
                     value = checkSpace(value);
                     window.configData.cfg.auth.surl = value;
                     setLoginUrl(value);
                     setIsValid(checkForValidity());
                   }}
                   InputProps={{
                       autoComplete : 'new-password',
                       endAdornment: <InputAdornment position="end">
                          <div>
                             <Tooltip title={getTooltipText('auth_url')} placement='top' arrow>
                                <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                             </Tooltip>
                          </div>
                        </InputAdornment>
                   }}
                   sx={{width:"20dvw"}}
                 />
                 <TextField
                   label="User email"
                   type="text"
                   spellcheck="false"
                   variant="standard"
                   value={userEmail}
                   onChange={(event) => {
                     let value = event.target.value;
                     value = checkSpace(value);
                     window.configData.cfg.auth.eml = value;
                     setUserEmail(value);
                     const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                     pattern.test(value) ? setIsValid(checkForValidity()) : setIsValid(false);
                   }}
                   InputProps={{
                       autoComplete : 'new-password',
                       endAdornment: <InputAdornment position="end">
                          <div>
                             <Tooltip title={getTooltipText('login_mail')} placement='top' arrow>
                                <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                             </Tooltip>
                          </div>
                        </InputAdornment>
                   }}
                   sx={{width:"20dvw", marginTop:"5px"}}
                 />
                 <TextField
                   label="Password"
                   type={loginPasswordType}
                   variant="standard"
                   value={userPassword}
                   onChange={(event) => {
                     let value = event.target.value;
                     value = checkSpace(value);
                     window.configData.cfg.auth.pwd = value;
                     setUserPassword(value);
                     setIsValid(checkForValidity());
                   }}
                   InputProps={{
                      autoComplete : 'new-password',
                      endAdornment: <InputAdornment position="end">
                           <IconButton
                             aria-label="toggle password visibility"
                             onMouseDown={()=>togglePassword("loginPassword")}
                             edge="end"
                           >
                             {loginPasswordType==="password" ? <VisibilityOff /> : <Visibility />}
                           </IconButton>
                           <div style={{marginLeft: '9px'}}>
                              <Tooltip title={getTooltipText('login_pwd')} placement='top' arrow>
                                 <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px'}} />
                              </Tooltip>
                           </div>
                       </InputAdornment>
                   }}
                   sx={{width:"20dvw", marginTop:"5px"}}
                 />
             </Box>
             <Divider orientation="vertical" variant="middle" flexItem sx={{borderRightWidth: '4px'}}/>
             <Box sx={{display:"flex", flexDirection:"column" }}>
                 <Box sx={{padding:"10px 0px", visibility:"hidden"}}>Config info</Box>
                 <TextField
                   className="numberTextFieldSpinners"
                   label="Config version"
                   type="number"
                   variant="standard"
                   value={configVersion}
                   helperText="Only numerical inputs are allowed."
                   InputProps={{
                     autoComplete : 'new-password',
                     inputProps: {
                       min: 0, max: 99999, // Enforces maximum value
                       onInput: (e) => {
                         if (e.target.value.length > 5) {
                           e.target.value = e.target.value.slice(0, 5);
                         }
                       },
                     },
                     endAdornment: <InputAdornment position="end">
                        <div>
                           <Tooltip title={getTooltipText('')} placement='top' arrow>
                              <InfoOutlined sx={{cursor: 'pointer', fontSize:'18px', marginBottom: '-3px', marginLeft: '5px'}} />
                           </Tooltip>
                        </div>
                      </InputAdornment>
                   }}
                   onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                   onChange={(event) => {
                     let value = event.target.value;
                     value = checkSpace(value);
                     window.configData.cfv = value;
                     setConfigVersion(value);
                     setIsValid(checkForValidity());
                   }}
                   sx={{width:"20dvw", visibility: "hidden"}}
                 />
             </Box>
          </Box>
          <Box sx={{display:"flex", justifyContent:"center", padding:"20px 0px"}}>
            <Button variant='contained' sx={{width:"160px",backgroundColor: (isValid ? primaryThemeColor + " !important" : "grey") }} disabled={!isValid} onClick={() => showUpdateConfigDataDialog("update")}>
              Apply
            </Button>
            <br/>
          </Box>
        </Box>
      </Box>
      <DialogActions>
        {
            showUpdateDialog ?
                <ConfigDataUpdateAlert
                    openDialog = {showUpdateDialog}
                    updateCase={updateDialogContent}
                    update={()=> updateConfigData()}
                    resetToDefault={() => resetToDefault()}
                    closeDialog={() => setShowUpdateDialog(false)}
                />
            :
                null
        }
      </DialogActions>
    </>
  );
}