import * as React from 'react';
import {styled} from '@mui/material/styles';
import { Box,Paper,Grid,Typography,List,ListItem,ListItemText,ListItemAvatar,Button,TextField,CircularProgress, IconButton, InputAdornment, Checkbox,FormHelperText } from '@mui/material';
import { LockOutlined,PersonAdd,BrightnessHigh,Email,Done,Lock,VisibilityOff,Visibility,ArrowBack,KeyboardAlt } from '@mui/icons-material';
import lockImage from '../.././wesko_lock.png';
import { useNavigate, useLocation } from "react-router-dom";
import 'stylesheet/Login.css';
import {fontSize, padding, style} from '@mui/system';
import AuthClient from "clients/AuthClient";
import UserSessionStorage from 'userSession/UserSessionStorage';
import _ from 'lodash';
import md5 from 'md5';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import fingerprintGenerator from 'helper-components/fingerPrintGenerator';


const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Login() {
  const [password, setPassword] = React.useState('');
  const [name, setName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [showForgotPwdVw, setShowForgotPwdVw] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(true);
  const [resetLinkSent, setResetLinkSent] = React.useState(false);
  const [orangeColor, setOrangeColor] = React.useState(false);
  const [orangeColorPassword ,setOrangeColorPassword] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [showPasswordResetErrorMessage, setShowPasswordResetErrorMessage] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState("password");
  const [showSecondStep, setShowSecondStep] = React.useState(false);
  const [securityCode, setSecurityCode] = React.useState("");
  const [trustDevice, setTrustDevice] = React.useState(false);
  const [fingerPrintKey, setFingerPrintKey] = React.useState("");
  const [showAuthoriseError, setShowAuthoriseError] = React.useState(false);
  const location = useLocation();



  React.useEffect(()=>{
    sessionStorage.clear();
  },[])

  let user_details = {};

  const login = () => {
      user_details.email = name.toLowerCase();
      user_details.password = password;
      setIsLoading(true);
      fingerprintGenerator().then((res) => {
          AuthClient.login(user_details,res).then((response) => {
             if (!response.hasOwnProperty("message")){
                 if (response.trustedDevice === true) {
                     setShowSecondStep(false);
                     AuthClient.fetchUserDetails().then(response =>{
                          window.userDetails = response;
                          sessionStorage.showMqttClass = true;
                          sessionStorage.userLoggedIn = true;
                          sessionStorage.userPasscode = md5(user_details.password);
                          sessionStorage.setItem("userDetails",JSON.stringify(response));
                          if(response.isRootUser){
                              sessionStorage.isRootUser = true;
                              setIsLoading(false);
                              sessionStorage.tenantPrimaryThemeColor = "#4190c7";
                              sessionStorage.tenantSecondaryThemeColor = "#ffffff";
                              window.location.hash = "#/admin/tenants";
                              location.hash = "#/admin/tenants";
                          } else {
                              setIsLoading(false);
                              sessionStorage.isRootUser = false;
                              sessionStorage.tenantId = response.user.scopeId;
                              AuthClient.getHirarchy().then((data) => {
                                if (data.scope.id === response.user.scopeId){
                                  UserSessionStorage.setTenantName(data.scope.name);
                                  sessionStorage.tenantName = data.scope.name;
                                  sessionStorage.tenantPrimaryThemeColor = checkDataExistsOrNot(data.scope.primaryThemeColor) ? data.scope.primaryThemeColor.indexOf("#") !== -1 ? data.scope.primaryThemeColor : "#" + data.scope.primaryThemeColor : "#4190c7";
                                  sessionStorage.tenantSecondaryThemeColor = checkDataExistsOrNot(data.scope.secondaryThemeColor) ? data.scope.secondaryThemeColor.indexOf("#") !== -1 ? data.scope.secondaryThemeColor : "#"+ data.scope.secondaryThemeColor : "#ffffff";
                                  sessionStorage.logoUrl = checkDataExistsOrNot(data.scope.logoUrl) ? data.scope.logoUrl : "";
                                  window.location.hash = "#/cli/dashboard"
                                  location.hash = "#/cli/dashboard";
                                }
                              });
                          }
                     });
                 } else {
                     if (response.secureAuthentication === true) {
                        setShowSecondStep(true);
                        setIsLoading(false);
                     } else {
                        setShowSecondStep(false);
                        AuthClient.fetchUserDetails().then(response =>{
                          window.userDetails = response;
                          sessionStorage.showMqttClass = true;
                          sessionStorage.userLoggedIn = true;
                          sessionStorage.userPasscode = md5(user_details.password);
                          sessionStorage.setItem("userDetails",JSON.stringify(response));
                          if(response.isRootUser){
                              sessionStorage.isRootUser = true;
                              setIsLoading(false);
                              sessionStorage.tenantPrimaryThemeColor = "#4190c7";
                              sessionStorage.tenantSecondaryThemeColor = "#ffffff";
                              window.location.hash = "#/admin/tenants";
                              location.hash = "#/admin/tenants";
                          }else{
                              setIsLoading(false);
                              sessionStorage.isRootUser = false;
                              sessionStorage.tenantId = response.user.scopeId;
                              AuthClient.getHirarchy().then((data) => {
                                if (data.scope.id === response.user.scopeId){
                                  UserSessionStorage.setTenantName(data.scope.name);
                                  sessionStorage.tenantName = data.scope.name;
                                  sessionStorage.tenantPrimaryThemeColor = checkDataExistsOrNot(data.scope.primaryThemeColor) ? data.scope.primaryThemeColor.indexOf("#") !== -1 ? data.scope.primaryThemeColor : "#" + data.scope.primaryThemeColor : "#4190c7";
                                  sessionStorage.tenantSecondaryThemeColor = checkDataExistsOrNot(data.scope.secondaryThemeColor) ? data.scope.secondaryThemeColor.indexOf("#") !== -1 ? data.scope.secondaryThemeColor : "#"+ data.scope.secondaryThemeColor : "#ffffff";
                                  sessionStorage.logoUrl = checkDataExistsOrNot(data.scope.logoUrl) ? data.scope.logoUrl : "";
                                  window.location.hash = "#/cli/dashboard"
                                  location.hash = "#/cli/dashboard";
                                }
                              });
                          }
                        });
                     }
                 }
             } else {
                  setShowErrorMessage(true);
                  setTimeout(() => {
                    setShowErrorMessage(false);
                  }, 3000);
                  setIsLoading(false);
                  sessionStorage.userLoggedIn = false;
             }
          });
      });
  };

   const authoriseUser = () => {
      setIsLoading(true);
      AuthClient.TwoStepVerification(name,securityCode,fingerPrintKey).then((response) => {
         if(response.status >= 200 && response.status< 300){
            AuthClient.fetchUserDetails().then(response =>{
               window.userDetails = response;
               sessionStorage.showMqttClass = true;
               sessionStorage.userLoggedIn = true;
               sessionStorage.userPasscode = md5(password);
               sessionStorage.setItem("userDetails",JSON.stringify(response));
               if(response.isRootUser){
                   sessionStorage.isRootUser = true;
                   setIsLoading(false);
                   sessionStorage.tenantPrimaryThemeColor = "#4190c7";
                   sessionStorage.tenantSecondaryThemeColor = "#ffffff";
                   window.location.hash = "#/admin/tenants";
                   location.hash = "#/admin/tenants";
               } else {
                   setIsLoading(false);
                   sessionStorage.isRootUser = false;
                   sessionStorage.tenantId = response.user.scopeId;
                   AuthClient.getHirarchy().then((data) => {
                      if (data.scope.id === response.user.scopeId){
                        UserSessionStorage.setTenantName(data.scope.name);
                        sessionStorage.tenantName = data.scope.name;
                        sessionStorage.tenantPrimaryThemeColor = checkDataExistsOrNot(data.scope.primaryThemeColor) ? data.scope.primaryThemeColor.indexOf("#") !== -1 ? data.scope.primaryThemeColor : "#" + data.scope.primaryThemeColor : "#4190c7";
                        sessionStorage.tenantSecondaryThemeColor = checkDataExistsOrNot(data.scope.secondaryThemeColor) ? data.scope.secondaryThemeColor.indexOf("#") !== -1 ? data.scope.secondaryThemeColor : "#"+ data.scope.secondaryThemeColor : "#ffffff";
                        sessionStorage.logoUrl = checkDataExistsOrNot(data.scope.logoUrl) ? data.scope.logoUrl : "";
                        window.location.hash = "#/cli/dashboard"
                        location.hash = "#/cli/dashboard";
                      }
                   });
               }
            });
         } else {
             setShowAuthoriseError(true);
             setTimeout(() => {
               setShowAuthoriseError(false);
             }, 3000);
             setIsLoading(false);
             sessionStorage.userLoggedIn = false;
         }
      });
   }

    const forgotPassword = () => {
        user_details.email = name;
        setIsLoading(true);
        sessionStorage.clear();
        AuthClient.forgotPassword(response => {
            if (response.status === 200) {
              setIsLoading(false);
              sessionStorage.userLoggedIn = true;
              setResetLinkSent(true);
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } else {
              setIsLoading(false);
              setShowPasswordResetErrorMessage(true);
              setTimeout(() => {
                setShowPasswordResetErrorMessage(false);
              }, 1500);

            }

        }, user_details);
    };

    const showForgotPwd = () => {
    setShowForgotPwdVw(true);
  };
  const hideForgotPwd = () => {
    setShowForgotPwdVw(false);
  };

    const togglePassword =()=>{
        if(passwordType==="password")
        {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

  return (
  <>

    <Box
      sx={{
        background: '#fff !important',
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        color:'#000000DE !important',
        flexDirection: 'column',
      }}
      className="loginComponent"
    >
          <Box
            sx={{
              background: '#fff',
              paddingBottom: '1px',
              marginTop: '-6px',
              height: 'calc(98% - 2px)'
            }}
          >
            <Box sx={{flexGrow: 1, display:"flex",flexDirection:"column",margin: '8px 4px 8px 4px', padding: '8px 3px 0px 3px', height: '100%'}}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  margin: '0px 0px 8px 0px',
                  padding: '8px 0px 8px 0px',
                  width:"100%"
                }}
              >
                <Typography
                    style={{
                      fontWeight: '600',
                      fontSize: '1.5em',
                      width: '100%',
                      color:"#4190c7",
                      margin:"8px 0px 8px 0px",
                      padding:"8px 0px 8px 0px",
                    }}
                >
                    Welcome to Gateway Management Portal
                </Typography>
              </Box>
              <Box className="middleComponent" sx={{ display:"flex",margin:"8px 0px 8px 0px"}}>
                <Box className="gatewayImage midThreeComponents">
                  <Box>
                    <img src="/device_image.webp" className="imageSize"/>
                  </Box>
                </Box>
                <Box className="midThreeComponents listComponent" sx={{display:"flex", justifyContent:'center',margin:"0px 0px 0px 19px"}} >
                  <Box className="listItems" sx={{margin: "8px", padding:"20px 0px 20px 0px"}}>
                    <List sx={{width: '100%', background: 'transparent'}}>
                      <ListItem sx={{margin:"8px",padding:"8px"}}>
                        <ListItemAvatar>
                          <img src="/register.png" className="listItemAvatar"/>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="h6"
                              style={{fontSize: '24px', fontWeight: '600',color:"#4190c7",margin:"0px 0px 0px 12px"}}
                              className= "listItemText"
                            >
                              Register your Gateway
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem sx={{margin:"8px",padding:"8px"}}>
                        <ListItemAvatar sx={{margin:"0px 0px 0px -5px"}}>
                          <img src="/gateway_router_icon.webp" className="listItemAvatar"/>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="h6"
                              style={{fontSize: '24px', fontWeight: '600',color:"#4190c7",margin:"0px 0px 0px 12px"}}
                              className= "listItemText"
                            >
                              Remotely monitor your Gateway
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem sx={{margin:"8px",padding:"8px"}}>
                        <ListItemAvatar>
                          <img src="/status.png" className="listItemAvatar"/>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="h6"
                              style={{fontSize: '24px', fontWeight: '600',color:"#4190c7",margin:"0px 0px 0px 12px"}}
                              className= "listItemText"
                            >
                              Display Real Time Gateway information and status
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem sx={{margin:"8px",padding:"8px"}}>
                        <ListItemAvatar sx={{position:'relative'}}>
                          <img src="/alerts_icon.png" className="listItemAvatar"/>
                          <Box sx={{position:'absolute',left:'35px', top:"0px"}}>
                            <img src="/upgradeIcons.png" height='18px' width="18px" />
                          </Box>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="h6"
                              style={{fontSize: '24px', fontWeight: '600',color:"#4190c7",margin:"0px 0px 0px 12px"}}
                              className= "listItemText"
                            >
                              Get eMails/SMS Alerts and OTA upgrades
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Box>
                <Box className="logInBox midThreeComponents" sx={{display:"flex",marginLeft:"auto"}}>
                    <Box
                      className="logInWidth"
                      sx={{
                        backgroundColor: !isLoading ? '#f5f5f5' : '#fff',
                        position: 'relative',
                        margin: '8px',
                        padding: '20px 16px 20px 16px',
                        opacity: isLoading && !resetLinkSent ? 0.5 : null,
                        height:"fit-content"
                      }}
                    >
                      <Typography
                        mt={2}
                        style={{
                          display: !resetLinkSent ? 'block' : 'none',
                          fontSize: '1.5em',
                          marginBlockStart: '1em',
                          marginBlockEnd: '1em',
                          marginInlineStart: '0px',
                          marginInlineEnd: '0px',
                          fontWeight: '400',
                          margin:"20px 16px 20px 16px",
                          display:"flex",
                        }}
                      >
                        {showForgotPwdVw ? "Enter your email address" : "Sign in with existing account"}
                      </Typography>
                      <Box sx={{margin:"0px 16px 0px 16px"}}>
                          <Box
                            sx={{
                              display: !resetLinkSent ? 'flex' : 'none',
                              alignItems: 'flex-end',
                              margin: '18px 0',
                              padding:'2px 0px 2px 0px',
                            }}
                          >
                            <Email sx={{color: showSecondStep ? 'rgba(0,0,0,0.54)' : orangeColor ? 'rgba(0,0,0,0.54)' : null, mr: 1, my: 0.5}} />
                            <TextField
                              disabled={showSecondStep}
                              fullWidth
                              id="input-with-sx"
                              type="email"
                              label={showForgotPwdVw ? "Email Address" : "Username"}
                              variant="standard"
                              value={name}
                              error={name ? (name.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ? false : true) : false}
                              onFocus={()=>setOrangeColor(true)}
                              onBlur={()=>setOrangeColor(false)}
                              onKeyPress={(event)=>{
                                if (event.key === 'Enter'){
                                  login();
                                }
                              }}
                              InputLabelProps={{
                                style: { color: showSecondStep ? null : orangeColor? '#4190c7' : null },
                              }}
                              onChange={(event) => {
                                setName(event.target.value);
                              }}
                              sx={{padding:"2px"}}
                            />
                          </Box>
                          <Box sx={{height:'1px'}}>
                          </Box>
                          {
                            showPasswordResetErrorMessage ?
                              <Box sx={{textAlign:"center",fontSize:"14px",color:"red"}}>
                                Error: Please try again
                              </Box>
                            :
                              null
                          }
                          { showSecondStep ?
                             <Box sx={{margin:"-29px 0px 20px 1px"}}>
                                 <Box
                                     sx={{
                                       display: !resetLinkSent ? 'flex' : 'none',
                                       alignItems: 'flex-end',
                                       margin: '18px 0',
                                       padding:'2px 0px 2px 0px',
                                       position: 'relative',
                                       top: '29px',
                                     }}
                                 >
                                    <KeyboardAlt sx={{color: orangeColor ? 'rgba(0,0,0,0.54)' : null, mr: 1, my: 0.5, position: 'relative', left: '-1px'}} />
                                    <TextField
                                       fullWidth
                                       id="input-with-sx"
                                       label="Enter authenticator code"
                                       variant="standard"
                                       value={securityCode}
                                       //error={securityCode ? (securityCode.match("^[0-9]{6}$") ? false : true) : false}
                                       onFocus={()=>setOrangeColor(true)}
                                       onBlur={()=>setOrangeColor(false)}
                                       InputLabelProps={{
                                         style: { color: orangeColor? '#4190c7' : null },
                                         shrink:true
                                       }}
                                       onChange={(event) => {
                                         setSecurityCode(event.target.value);
                                       }}
                                       sx={{padding:"2px",textIndent:'1px'}}
                                    />
                                 </Box>
                                 <Box sx={{marginTop:'5px', visibility: showAuthoriseError ? 'visible' : 'hidden',position: 'relative',top: '25px',left: '33px'}}>
                                    <FormHelperText variant="standard" error={true} >You have entered an Incorrect code.</FormHelperText>
                                 </Box>
                                 <Box sx={{marginTop: "36px",marginLeft: '0px'}}>
                                    <Typography sx={{display: "flex",flexDirection: "row"}}>
                                       <Checkbox
                                         size="small"
                                         checked={trustDevice}
                                         onChange={(event) => {
                                            if (event.target.checked === true) {
                                               fingerprintGenerator().then((res) => {
                                                  setFingerPrintKey(res);
                                               })
                                               setTrustDevice(event.target.checked);
                                            } else {
                                               setTrustDevice(event.target.checked);
                                               setFingerPrintKey("");
                                            }
                                         }}
                                         color="primary"
                                         inputProps={{
                                             color:"#4190c7"
                                         }}
                                         sx={{margin: '0px', padding: '0px', position:"relative", top:"3px"}}
                                       />
                                       &nbsp;&nbsp;&nbsp;
                                       <Box sx={{display: 'flex',flexDirection: 'column',position: 'relative',top: '3px',fontSize: '11px'}}>
                                         Trust this device
                                         <span style={{fontSize: '9px',color: 'rgba(0,0,0,0.54)',marginLeft: '3px'}}>We won't ask for a code next time</span>
                                       </Box>
                                    </Typography>
                                 </Box>
                             </Box>
                          :
                             <Box
                                sx={{
                                  display: showSecondStep ? 'none' : showForgotPwdVw ? 'none' : 'flex',
                                  alignItems: 'flex-end',
                                  margin: '18px 0',
                                  padding:'2px 0px 2px 0px',
                                }}
                             >
                                <Lock sx={{color: orangeColorPassword ? 'rgba(0,0,0,0.54)' : null, mr: 1, my: 0.5}} />
                                <TextField
                                  fullWidth
                                  id="input-with-sx"
                                  label="Password"
                                  type={passwordType}
                                  variant="standard"
                                  value={password}
                                  onChange={(event) => {
                                    setPassword(event.target.value);
                                  }}
                                  onFocus={()=>setOrangeColorPassword(true)}
                                  onBlur={()=>setOrangeColorPassword(false)}
                                  InputLabelProps={{
                                    style: { color: orangeColorPassword ? '#4190c7' : null },
                                  }}
                                  InputProps={{
                                     endAdornment: <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePassword}
                                            onMouseDown={togglePassword}
                                            edge="end"
                                          >
                                            {passwordType==="password" ? <VisibilityOff /> : <Visibility />}
                                          </IconButton>
                                      </InputAdornment>
                                  }}
                                  onKeyPress={(event)=>{
                                    if (event.key === 'Enter'){
                                      login();
                                    }
                                  }}
                                  sx={{padding:"2px"}}
                                />
                             </Box>
                          }
                      </Box>
                      <Box sx={{height:'1px',marginBottom:"20px"}}>
                      </Box>
                      { showSecondStep ?
                          <Box
                            sx={{
                              alignItems: 'center',
                              margin: '8px',
                              textAlign: 'right',
                              flexDirection: 'row',
                              position: 'relative',
                              top: '-23px'
                            }}
                          >
                            <Button
                              variant="text"
                              style={{
                                minWidth: '88px',
                                margin: '8px 6px',
                                color: 'rgba(0,0,0,0.87)',
                              }}
                              onClick={() => {
                                setShowSecondStep(false);
                                setSecurityCode("");
                                setTrustDevice(false);
                              }}
                            >
                              BACK
                            </Button>
                            <Button
                               variant="contained"
                               disabled={ securityCode.length === 6 ? false : true }
                               style={{
                                 minWidth: '88px', margin: '8px 6px',
                                 color: securityCode.length === 6 ? 'rgba(255,255,255,0.87)' : 'rgba(0,0,0,0.12)',
                                 backgroundColor: securityCode.length === 6 ? '#4190c7' : 'rgba(0,0,0,0.12)',
                                 width: '65%'
                               }}
                               onClick={() => authoriseUser()}
                            >
                                AUTHORISE
                            </Button>
                            {
                                isLoading ?
                                    <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100%',position: 'absolute',top: '-139px',right:'8px'}}>
                                        <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                    </Box>
                                :
                                 null
                            }
                          </Box>
                      :
                          <Box component="span"
                              sx={{
                                  display: showSecondStep ? 'none' : showForgotPwdVw ? 'none' : 'block',
                              }}>
                            {
                              showErrorMessage ?
                                <Box sx={{color: 'red',fontSize: '12px',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                                  Invalid Username or Password
                                </Box>
                              :
                                null
                            }
                            <Button
                              variant="contained"
                              style={{
                                width: '92%',
                                margin: '20px 20px 0px 20px',
                                color: 'rgba(255,255,255,0.87)',
                                backgroundColor: '#4190c7',
                              }}
                              onClick={() => login()}
                            >
                              LOGIN
                            </Button>
                            {
                                isLoading ?
                                    <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100%',position: 'absolute',top: '-19px',right:'8px'}}>
                                        <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                    </Box>
                                :
                                    null
                            }
                          </Box>
                      }
                      <Box
                          sx={{
                            display: showForgotPwdVw && !resetLinkSent ? 'flex' : 'none',
                            alignItems: 'center',
                            margin: '8px',
                            textAlign: 'center',
                            flexDirection: 'row-reverse',
                          }}
                      >
                          <Button
                            variant="contained"
                            style={{minWidth: '88px', margin: '8px 6px',
                            color: 'rgba(255,255,255,0.87)',
                            backgroundColor: '#4190c7',}}
                            onClick={() => forgotPassword()}
                          >
                            RESET PASSWORD
                          </Button>
                          <Button
                            variant="text"
                            style={{
                              minWidth: '88px',
                              margin: '8px 6px',
                              color: 'rgba(0,0,0,0.87)',
                            }}
                            onClick={() => hideForgotPwd()}
                          >
                            CANCEL
                          </Button>
                          {
                            isLoading ?
                                <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100%',position: 'absolute',top: '-19px',right:'8px'}}>
                                    <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                </Box>
                            :
                                null
                          }
                      </Box>
                      <Box
                            sx={{
                                display: resetLinkSent ? 'block' : 'none',
                                textAlign: 'center',
                                cursor: 'pointer',
                            }}
                      >
                        <Typography mt={2}
                            style={{
                                color: "#008000",
                            }}
                        >
                            <Done sx={{position:"relative",top:"6px",right:"4px"}}/>
                            Success
                        </Typography>
                        <Typography mt={2}
                            style={{
                            }}
                        >
                            An email with reset password link has been sent to your Email Id.
                        </Typography>
                      </Box>
                      <Typography
                        mt={2}
                        style={{
                          display: showSecondStep ? 'none' : showForgotPwdVw ? 'none' : 'block',
                          fontWeight: '350',
                          fontSize: '1em',
                          textAlign: 'center',
                        }}
                      >
                        <span style={{cursor: 'pointer'}} onClick={() => showForgotPwd()}>
                          Forgot Password?
                        </span>
                      </Typography>
                    </Box>
                </Box>
              </Box>
              <Box>
                  <Typography
                     style={{
                       fontWeight: '350',
                       fontSize: '1em',
                       textAlign: 'center',
                       background: '#fff',
                       display: 'flex',
                       justifyContent: 'center',
                       alignItems: 'end',
                       height: '64px',
                       padding: '8px',
                       margin:'12px 10px 40px 10px',
                     }}
                  >
                     Powered by Caribou Technologies
                  </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
    </>
  );
}
//                <Grid container spacing={2}>
//                    <Grid item xs={12} md={8}>
//                      <Box
//                        sx={{
//                          display: 'flex',
//                          alignItems: 'center',
//                          textAlign: 'center',
//                          margin: '8px',
//                          padding: '8px',
//                        }}
//                      >
//                        <Typography
//                          mt={2}
//                          style={{
//                            fontWeight: '600',
//                            fontSize: '1.5em',
//                            width: '100%',
//                          }}
//                        >
//                          Welcome to Wesko eLocks Cloud Management Portal
//                        </Typography>
//                      </Box>
//
//                      <List sx={{width: '100%', background: 'transparent'}}>
//                        <ListItem>
//                          <ListItemAvatar>
//                            <LockOutlined sx={{fontSize: '36px', margin: '0 12px'}} />
//                          </ListItemAvatar>
//                          <ListItemText
//                            primary={
//                              <Typography
//                                variant="h6"
//                                style={{fontSize: '20px', fontWeight: '600'}}
//                              >
//                                Manage Lock Users
//                              </Typography>
//                            }
//                            secondary={
//                              <Typography
//                                variant="h6"
//                                style={{
//                                  fontSize: '16px',
//                                  fontWeight: '400',
//                                  letterSpacing: '.010em',
//                                  lineHeight: '24px',
//                                }}
//                              >
//                                and remotely setup on locks
//                              </Typography>
//                            }
//                          />
//                        </ListItem>
//                        <ListItem>
//                          <ListItemAvatar>
//                            <PersonAdd sx={{fontSize: '36px', margin: '0 12px'}} />
//                          </ListItemAvatar>
//                          <ListItemText
//                            primary={
//                              <Typography
//                                variant="h6"
//                                style={{fontSize: '20px', fontWeight: '600'}}
//                              >
//                                Remotely Control Locks
//                              </Typography>
//                            }
//                            secondary={
//                              <Typography
//                                variant="h6"
//                                style={{
//                                  fontSize: '16px',
//                                  fontWeight: '400',
//                                  letterSpacing: '.010em',
//                                  lineHeight: '24px',
//                                }}
//                              >
//                                if users forget their password
//                              </Typography>
//                            }
//                          />
//                        </ListItem>
//                        <ListItem>
//                          <ListItemAvatar>
//                            <BrightnessHigh sx={{fontSize: '36px', margin: '0 12px'}} />
//                          </ListItemAvatar>
//                          <ListItemText
//                            primary={
//                              <Typography
//                                variant="h6"
//                                style={{fontSize: '20px', fontWeight: '600'}}
//                              >
//                                Display Real Time Lock Status
//                              </Typography>
//                            }
//                            secondary={
//                              <Typography
//                                variant="h6"
//                                style={{
//                                  fontSize: '16px',
//                                  fontWeight: '400',
//                                  letterSpacing: '.010em',
//                                  lineHeight: '24px',
//                                }}
//                              >
//                                and change lock settings
//                              </Typography>
//                            }
//                          />
//                        </ListItem>
//                      </List>
//                      <img className="wesko_lock_image" src={lockImage} alt="logo" />
//                    </Grid>
//                    <Grid item xs={12} md={4}>
//                      <Box
//                        sx={{
//                          backgroundColor: !isLoading ? '#f5f5f5' : '#fff',
//                          position: 'relative',
//                          margin: '24px 12px',
//                          padding: '32px',
//                          opacity: isLoading && !resetLinkSent ? 0.5 : null
//                        }}
//                      >
//                        <Typography
//                          mt={2}
//                          style={{
//                            display: !resetLinkSent ? 'block' : 'none',
//                            fontSize: '1.17em',
//                            marginBlockStart: '1em',
//                            marginBlockEnd: '1em',
//                            marginInlineStart: '0px',
//                            marginInlineEnd: '0px',
//                            fontWeight: 'bold',
//                          }}
//                        >
//                          {showForgotPwdVw ? "Enter your email address" : "Sign in with existing account"}
//                        </Typography>
//
//                        <Box
//                          sx={{
//                            display: !resetLinkSent ? 'flex' : 'none',
//                            alignItems: 'flex-end',
//                            margin: '18px 0',
//                          }}
//                        >
//                          <Email sx={{color: orangeColor ? 'rgb(237,87,83)' : null, mr: 1, my: 0.5}} />
//                          <TextField
//                            fullWidth
//                            id="input-with-sx"
//                            type="email"
//                            label={showForgotPwdVw ? "Email Address" : "Username"}
//                            variant="standard"
//                            value={name}
//                            error={name ? (name.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ? false : true) : false}
//                            onFocus={()=>setOrangeColor(true)}
//                            onBlur={()=>setOrangeColor(false)}
//                            onKeyPress={(event)=>{
//                              if (event.key === 'Enter'){
//                                login();
//                              }
//                            }}
//                            InputLabelProps={{
//                              style: { color: orangeColor? 'rgb(237,87,83)' : null },
//                            }}
//                            onChange={(event) => {
//                              setName(event.target.value);
//                            }}
//                          />
//                        </Box>
//                        {
//                          showPasswordResetErrorMessage ?
//                            <Box sx={{textAlign:"center",fontSize:"14px",color:"red"}}>
//                              Error: Please try again
//                            </Box>
//                          :
//                            null
//                        }
//                        <Box
//                          sx={{
//                            display: showForgotPwdVw ? 'none' : 'flex',
//                            alignItems: 'flex-end',
//                            margin: '18px 0',
//                          }}
//                        >
//                          <Lock sx={{color: orangeColorPassword ? 'rgb(237,87,83)' : null, mr: 1, my: 0.5}} />
//                          <TextField
//                            fullWidth
//                            id="input-with-sx"
//                            label="Password"
//                            type={passwordType}
//                            variant="standard"
//                            value={password}
//                            onChange={(event) => {
//                              setPassword(event.target.value);
//                            }}
//                            onFocus={()=>setOrangeColorPassword(true)}
//                            onBlur={()=>setOrangeColorPassword(false)}
//                            InputLabelProps={{
//                              style: { color: orangeColorPassword ? 'rgb(237,87,83)' : null },
//                            }}
//                            onKeyPress={(event)=>{
//                              if (event.key === 'Enter'){
//                                login();
//                              }
//                            }}
//                          />
//                            <button onClick={togglePassword} style={{width: '36px', height: '32px', border: "none",}}>
//                                { passwordType==="password"? <VisibilityOff></VisibilityOff> :<Visibility></Visibility>}
//                            </button>
//                        </Box>
//
//                        <Box component="span"
//                            sx={{
//                                display: showForgotPwdVw ? 'none' : 'block',
//                            }}>
//                          {
//                            showErrorMessage ?
//                              <Box sx={{color: 'red',fontSize: '12px',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
//                                Invalid Username or Password
//                              </Box>
//                            :
//                              null
//                          }
//                          <Button
//                            variant="contained"
//                            style={{
//                              width: '100%',
//                              margin: '20px 0',
//                              color: 'rgba(255,255,255,0.87)',
//                              backgroundColor: 'rgb(20,19,139)',
//                            }}
//                            onClick={() => login()}
//                          >
//                            LOGIN
//                          </Button>
//                          {
//                              isLoading ?
//                                  <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100%',position: 'absolute',top: '-19px',right:'8px'}}>
//                                      <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
//                                  </Box>
//                              :
//                                  null
//                          }
//                        </Box>
//
//                        <Box
//                            sx={{
//                              display: showForgotPwdVw && !resetLinkSent ? 'flex' : 'none',
//                              alignItems: 'center',
//                              margin: '8px',
//                              textAlign: 'center',
//                              flexDirection: 'row-reverse',
//                            }}
//                          >
//                            <Button
//                              variant="contained"
//                              style={{minWidth: '88px', margin: '8px 6px',
//                              color: 'rgba(255,255,255,0.87)',
//                              backgroundColor: 'rgb(20,19,139)',}}
//                              onClick={() => forgotPassword()}
//                            >
//                              RESET PASSWORD
//                            </Button>
//                            <Button
//                              variant="text"
//                              style={{
//                                minWidth: '88px',
//                                margin: '8px 6px',
//                                color: 'rgba(0,0,0,0.87)',
//                              }}
//                              onClick={() => hideForgotPwd()}
//                            >
//                              CANCEL
//                            </Button>
//                            {
//                              isLoading ?
//                                  <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100%',position: 'absolute',top: '-19px',right:'8px'}}>
//                                      <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
//                                  </Box>
//                              :
//                                  null
//                            }
//                          </Box>
//
//
//                          <Box
//                              sx={{
//                                  display: resetLinkSent ? 'block' : 'none',
//                                  textAlign: 'center',
//                                  cursor: 'pointer',
//                              }}
//                          >
//                          <Typography mt={2}
//                              style={{
//                                  color: "#008000",
//                              }}
//                          >
//                              <Done sx={{position:"relative",top:"6px",right:"4px"}}/>
//                              Success
//                          </Typography>
//                              <Typography mt={2}
//                                  style={{
//                                  }}
//                              >
//                                  An email with reset password link has been sent to your Email Id.
//                              </Typography>
//                          </Box>
//                        <Typography
//                          mt={2}
//                          style={{
//                            display: showForgotPwdVw ? 'none' : 'block',
//                            fontWeight: '350',
//                            fontSize: '1em',
//                            textAlign: 'center',
//                          }}
//                        >
//                          <span style={{cursor: 'pointer'}} onClick={() => showForgotPwd()}>
//                            Forgot Password?
//                          </span>
//                        </Typography>
//                      </Box>
//                    </Grid>
//                </Grid>
//                <Grid item sx={{margin: '8px 8px 0px 8px',width: '100%'}}>
//                    <Typography
//                      style={{
//                        fontWeight: '350',
//                        fontSize: '1em',
//                        textAlign: 'center',
//                        background: '#fff',
//                        display: 'flex',
//                        justifyContent: 'center',
//                        alignItems: 'end',
//                        height: '100%',
//                        padding: '8px',
//                        paddingBottom: '0px'
//                      }}
//                    >
//                      Powered by Caribou Technologies
//                   </Typography>
//                </Grid>
//              //</Grid>
//            </Box>
//            </Box>
//        </Box>
//    </>
//  );
//}
