import React from 'react';
import Paho from "paho-mqtt"
import AppConfigService from '../../services/AppConfigService';
import UserDetailService from '../../services/UserDetailService';
import UserSessionStorage from '../../userSession/UserSessionStorage';
  
(function () {  
    var client;
    var _topic = ["testMqtt"];
    var _options;
    var host;
    var protocol;
    var mqttUrl;
    const fetchConfigDetails = () => {
        AppConfigService.getMqttConfiguration().then((config) => {
            host = config.mqttHost;
            protocol =config.mqttProtocol;
            mqttUrl = protocol+"://"+host;
            _options = {
                clientId: "frontend-" + Math.random().toString(16).substr(2, 8),
                port:config.mqttPort,
                username: UserDetailService.getMqttAuthToken(),
                password: UserDetailService.getMqttUserName(),
                reconnectPeriod : 4000,
                clean:true,
                resubscribe: false
            };
            if (!client){
                sessionStorage.mqttUrl = mqttUrl;
                sessionStorage.mqttClientId = _options.clientId;
                sessionStorage.mqttPort = _options.port;
                sessionStorage.mqttUserName = _options.username;
                sessionStorage.mqttPassword = _options.password;
                sessionStorage.mqttClean = _options.clean;
                sessionStorage.reconnectPeriod = _options.reconnectPeriod;
                sessionStorage.resubscribe = _options.resubscribe;
                init(config.mqttHost.replace("/pubsub",""),443,"/pubsub", _options.clientId);
            }        
        });    
    }

    if (client !== null){
        const interval = setInterval(() => {
            if(UserDetailService.getMqttAuthToken()){
                fetchConfigDetails();
                clearInterval(interval);
            }
        }, 1000);
    }

    const init = (hostName, port, path, id) => {
        // Create a client instance
        if (client !== null){
            client = new Paho.Client(hostName,port,path,id);
        }

        const success = () => {
            window.client = client;
            // client.subscribe(props.subscribeChannel,{
            //     onSuccess: ()=>{
            //     },
            //     onFailure: ()=> {
            //     },
            // });
            // client.onMessageArrived = (message) => {
                // props.messageArrived(message.destinationName, message.payloadString)
            // }
        }

        // connect the client
        client.connect({
            userName:_options.username,
            password: _options.password,
            keepAliveInterval: 2000,
            cleanSession:false,
            useSSL:true,
            onFailure: () => {
                UserSessionStorage.setClient(client);
            },
            onSuccess: () => {
                UserSessionStorage.setClient(client);
                window.client = client;
            },
            hosts:[hostName],
            ports:[port],
            reconnect:true,
            mqttVersion:4,
            mqttVersionExplicit:true,              
        });
    }    
})();