import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export default function FallbackUi () {

  return (
      <>
        <Box sx={{height:"90%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <CircularProgress thickness="3.8" size="40px" />
        </Box>
      </>
  );

}
