import React from 'react';
import appLogo from 'caribou_logo.png';
import 'stylesheet/header.css';

export default function LogoHeader(props) {  
  return (
    <nav style={{width: props.hasOwnProperty('parent') ? '100%' : null, color: '#0000008A'}}>
      <div className="div-header" sx={{marginLeft:"20px"}}>
        <img
          className="imgSize logo-img"
          style={{
            padding: props.hasOwnProperty('parent') ? '0px' : '4px',
            marginLeft: props.hasOwnProperty('parent') ? null : '4px',
            marginRight:"auto",
            cursor:"auto"
          }}
          src={appLogo}
          alt="logo"            
        />          
      </div>
    </nav>
  );
};