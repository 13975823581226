import * as React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import './Dashboard1.css';
import Chart from 'react-google-charts';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
  useCircularInputContext
} from "react-circular-input";
import SemiCircleSlider from './SemiCircleSlider';
import { FormControlLabel, Slider } from '@mui/material';
import LocationMap from 'helper-components/LocationMap';



export default function Dashboard1() {
  const options = {
    legend: {
      position: 'top',
      maxLines: 3,
      textStyle: {fontSize: 9},
    },
    chart: {
      title: '',
      subtitle: '',
    },
  };

  const [switchChecked, setSwitchChecked] = React.useState(true);

  const CustomSwitch = styled(Switch)({
    width: 150,
    height: 60,
    padding: 10, // Adjust padding as needed
    '& .MuiSwitch-thumb': {
      width: 70,
      height: 35,
    },
    '& .MuiSwitch-track': {
      borderRadius: 60 / 2, // Make the track round
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(60px)', // Move the thumb to the right when the switch is checked
    },
    '& .MuiSwitch-switchBase.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#52d869', // Thumb color when focused
    },
  });
    

  const [value, setValue] = React.useState(0.24);

  const handleChange = (sliderValue) => {
      if (sliderValue >= 0.74 && sliderValue <=100 && value<1){
        // setValue(value+0.01);
      }
      
  }

  const guage = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1023,
      label: '1023',
    },
  ];
  
  function valuetext(value) {
    return `${value}°C`;
  }

  return (
    <Box sx={{flexGrow: 1, margin: '8px', padding: '8px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper elevation={1}>
            <Box sx={{backgroundColor: '#fff',borderBottom:"2px solid rgb(224,224,224)", padding: '10px'}}>
              <Typography
                mt={2}
                style={{
                  fontWeight: '350',
                  fontSize: '1em',
                  margin: '10px',
                }}
              >
                Pump Switch
              </Typography>
            </Box>
            <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
              <Box sx={{position:"relative",height:"100%",display:"flex", justifyContent:"center",alignContent:"center"}}>
                <CustomSwitch
                    className="customSwitchButton"
                    checked={switchChecked}
                    onChange={() => setSwitchChecked(!switchChecked)}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    sx={{display:"flex",justifyContent:"center",alignItems:"center",position:"relative", top:"75px"}}
                  />
                  {
                    switchChecked ?
                      <Box sx={{position:"absolute",top:"93px",left:"130px",color:"#fff"}}>
                          ON
                      </Box>
                    :
                      <Box sx={{position:"absolute",top:"93px",left:"160px",color:"#fff"}}>
                          OFF
                      </Box>
                  }
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={1}>
            <Box sx={{backgroundColor: '#fff',borderBottom:"2px solid rgb(224,224,224)", padding: '10px'}}>
              <Typography
                mt={2}
                style={{
                  fontWeight: '350',
                  fontSize: '1em',
                  margin: '10px',
                }}
              >
                Pump Seconds
              </Typography>
            </Box>
            <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
            <Box sx={{ width: "80%", position:"relative", top:"95px",left:"31px" }}>
                  <Slider
                    defaultValue={20}
                    valueLabelDisplay="auto"
                    marks={[{"value": 1,"label": "1 Sec"},{"value": 60,"label": "60 Sec"}]}
                    step={1}
                    min={1}
                    max={60} 
                  />
                </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={1}>
            <Box sx={{backgroundColor: '#fff',borderBottom:"2px solid rgb(224,224,224)", padding: '10px'}}>
              <Typography
                mt={2}
                style={{
                  fontWeight: '350',
                  fontSize: '1em',
                  margin: '10px',
                }}
              >
                Soil
              </Typography>
            </Box>
            <Box id="humidity" sx={{margin: 'auto', height: '240px', width: '320px'}}>
            <CircularInput radius={90} value={0.25} style={{position:"relative",top:"30px",left:"57px"}}>
                <CircularTrack strokeWidth={4} stroke="rgb(224,224,224)"/>
                <CircularProgress strokeWidth={12} stroke="#7b7af5f7"/>
                <text x={89} y={90} textAnchor="middle" dy="0.3em" >
                    {0.25*100}%
                </text>
              </CircularInput>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid sx={{marginTop: '15px'}} container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper elevation={1}>
            <Box sx={{backgroundColor: '#fff',borderBottom:"2px solid rgb(224,224,224)", padding: '10px'}}>
              <Typography
                mt={2}
                style={{
                  fontWeight: '350',
                  fontSize: '1em',
                  margin: '10px',
                }}
              >
                Gauge
              </Typography>
            </Box>
            <Box sx={{margin: 'auto', height: '240px', width: '350px'}}>
                {/* <Box sx={{height: "148px" , width: "100%", overflowY:'hidden'}}>
                  <CircularInput id="circulatTextGauge" value={value} onChange={handleChange} style={{transform: "rotate(180deg)", position:"relative", top:"43px", left:"56px"}}>
                    <CircularTrack strokeWidth={10} stroke="#eee"/>
                    <CircularProgress strokeWidth={12} stroke="#7b7af5f7"/>
                    <CircularThumb r={10}/>
                  </CircularInput>         
                </Box>                 */}
                <Box sx={{ width: "80%", position:"relative", top:"95px",left:"31px" }}>
                  <Slider
                    aria-label="Custom marks"
                    defaultValue={20}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    marks={guage}
                    step={1}
                    min={0}
                    max={1023} 
                  />
                </Box>
            </Box>                        
          </Paper>          
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={1}>
            <Box sx={{backgroundColor: '#fff',borderBottom:"2px solid rgb(224,224,224)", padding: '10px'}}>
              <Typography
                mt={2}
                style={{
                  fontWeight: '350',
                  fontSize: '1em',
                  margin: '10px',
                }}
              >
                Humidity
              </Typography>
            </Box>
            <Box id="humidity" sx={{margin: 'auto', height: '240px', width: '320px'}}>
              <CircularInput radius={90} value={0.45} style={{position:"relative",top:"30px",left:"57px"}}>
                <CircularTrack strokeWidth={4} stroke="rgb(224,224,224)"/>
                <CircularProgress strokeWidth={12} stroke="#7b7af5f7"/>
                <text x={89} y={90} textAnchor="middle" dy="0.3em" >
                    {0.45*100}%
                </text>
              </CircularInput>
            </Box>            
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={1}>
            <Box sx={{backgroundColor: '#fff',borderBottom:"2px solid rgb(224,224,224)", padding: '10px'}}>
              <Typography
                mt={2}
                style={{
                  fontWeight: '350',
                  fontSize: '1em',
                  margin: '10px',
                }}
              >
                Location
              </Typography>
            </Box>
            <Box sx={{margin: 'auto', height: '240px', width: '100%'}}>
              <Box className="mapClass" sx={{height: '240px', width: '100%'}}>
                  <LocationMap divHeight="240px" markers={[{"lat": "43.8947122","lng":"-79.4391796"}]}  />
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
