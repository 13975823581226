import React from 'react';
import { Box,Dialog,Button,DialogActions,DialogContent,DialogTitle,DialogContentText,Paper,useMediaQuery,useTheme, CircularProgress, Snackbar } from '@mui/material';
import { Close } from '@mui/icons-material';
import Draggable from 'react-draggable';
import AuthClient from 'clients/AuthClient';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import PaperComponent from 'helper-components/PaperComponent';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';


export default function DisableTwoFactorAuthentication(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };
   const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
   const [confirmedDisabling,setConfirmedDisabling] = React.useState(false);
   const { enqueueSnackbar } = useSnackbar();


    const disableTwoFa = () => {
        if (!confirmedDisabling) {
           setConfirmedDisabling(true);
           AuthClient.DisableTwoFA(props.userDetails ? props.userDetails.email : JSON.parse(sessionStorage.userDetails).user.email).then((response) => {
              props.closeDialog();
              if (!response.hasOwnProperty("message")){
                 enqueueSnackbar("Successfully Disabled 2 factor-authentication");
              } else {
                 enqueueSnackbar("Failed to Disable 2 factor-authentication");
              }
           });
        }
    }

return (
   <>
     <Dialog
        fullWidth={true}
        fullScreen={fullScreen}
        open={props.openDialog}
        onClose={() => {props.closeDialog();props.setToggleToPreviousState()}}
        PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
        aria-labelledby="draggable-dialog-title"
     >
       <DialogTitle style={{color: 'white', display:"flex", flexDirection:"row", background:"#4190c7"}}>
           <Box sx={{flex:"95"}}>Disable Two-Factor Authentication</Box>
           <Box sx={{flex: '5', marginTop: '6px'}}>
               <Close onClick={()=>{props.closeDialog();props.setToggleToPreviousState()}} sx={{cursor:"pointer"}}/>
           </Box>
       </DialogTitle>
       <DialogContent style={{padding:"20px"}}>
           <DialogContentText style={{color: 'black'}}>
               Are you sure, you want to disable Two-Factor Authentication ?
           </DialogContentText>
       </DialogContent>
       <DialogActions>
           <>
               <Button style={{color: 'red'}} onClick={() => {props.closeDialog();props.setToggleToPreviousState()}}>
                 Cancel
               </Button>
               <Button onClick={() => disableTwoFa()}>
                 Yes
               </Button>
           </>
       </DialogActions>
     </Dialog>
   </>
);
}