import React, {Component} from "react";

export default class Permissions extends Component {
    static get userPermissions() {
        return {
            permissions : {
                IOT_DEVICE_READ: {
                    label: "View",
                    category: "devices",
                    actions: ["IOT_DEVICE_READ"]
                },
                IOT_DEVICE_WRITE: {
                    label: "Edit",
                    category: "devices",
                    actions: ["IOT_DEVICE_WRITE", "IOT_DEVICE_BULK_WRITE"]
                },
                IOT_USER_READ: {
                    label: "View",
                    category: "iot_users",
                    actions: ["IOT_USER_READ"]
                },
                IOT_USER_WRITE: {
                    label: "Edit",
                    category: "iot_users",
                    actions: ["IOT_USER_WRITE"]
                },
                PORTAL_USER_READ: {
                    label: "View",
                    category: "users",
                    actions: ["PORTAL_USER_READ"]
                },
                PORTAL_USER_WRITE: {
                    label: "Edit",
                    category: "users",
                    actions: ["PORTAL_USER_WRITE"]
                },
                PORTAL_USER_DELETE: {
                    label: "Delete",
                    category: "users",
                    actions: ["PORTAL_USER_DELETE"]
                },
                CAN_BE_SCOPE_OWNER: {
                    label: "Eligible to be an owner",
                    category: "ownership",
                    actions: ["CAN_BE_SCOPE_OWNER"]
                },
                ALERTS_READ: {
                    label: "View",
                    category: "alerts",
                    actions: ["ALERTS_READ"]
                },
                ALERTS_WRITE: {
                    label: "Edit",
                    category: "alerts",
                    actions: ["ALERTS_WRITE"]
                },
                SCOPE_READ: {
                    label: "View",
                    category: "hierarchy",
                    actions: ["SCOPE_READ"]
                },
                SCOPE_WRITE: {
                    label: "Edit",
                    category: "hierarchy",
                    actions: ["SCOPE_WRITE", "SCOPE_UPDATE"] //"ASSIGN_SCOPE" is not used yet
                },
                SCOPE_DELETE: {
                    label: "Delete",
                    category: "hierarchy",
                    actions: ["SCOPE_DELETE"]
                },
                FIRMWARE_READ: {
                    label: "View",
                    category: "firmware",
                    actions: ["FIRMWARE_READ"]
                },
                FIRMWARE_WRITE: {
                    label: "Upload",
                    category: "firmware",
                    actions: ["FIRMWARE_WRITE"]
                },
                FIRMWARE_DOWNLOAD: {
                    label: "Download",
                    category: "firmware",
                    actions: ["FIRMWARE_DOWNLOAD"]
                },
                GATEWAY_READ: {
                    label: "View",
                    category: "gateways",
                    actions: ["GATEWAY_READ"]
                },
                GATEWAY_WRITE: {
                    label: "Edit",
                    category: "gateways",
                    actions: ["GATEWAY_WRITE", "GENERATE_GATEWAY_TOKEN"]
                },
                GATEWAY_INSTRUCTIONS_READ: {
                    label: "View",
                    category: "instructions",
                    actions: ["GATEWAY_INSTRUCTIONS_READ"]
                },
                GATEWAY_INSTRUCTIONS_WRITE: {
                    label: "Edit",
                    category: "instructions",
                    actions: ["GATEWAY_INSTRUCTIONS_WRITE"]
                },
                TOP_LEVEL_SCOPE_READ: {
                    label: "Manage Tenants",
                    category: "tenants",
                    actions: ["TOP_LEVEL_SCOPE_READ"]
                }
            },

            defaultPermissions : {
                APP_DETAILS: {
                    label: "View app details",
                    category: "others",
                    actions: ["APP_DETAILS"]
                },
                MQTT_AUTH: {
                    label: "View devices",
                    category: "others",
                    actions: ["MQTT_AUTH"]
                },
                PORTAL_USER_DETAILS: {
                    label: "View self details",
                    category: "others",
                    actions: ["PORTAL_USER_DETAILS"]
                },
                PORTAL_USER_PASSWORD_CHANGE: {
                    label: "Change password",
                    category: "others",
                    actions: ["PORTAL_USER_PASSWORD_CHANGE"]
                },
                HIERARCHY_READ: {
                    label: "Hierarchy Read",
                    category: "others",
                    actions: ["HIERARCHY_READ"]
                }
            },

            topLevelSuperAdminPermissions : {
                FIRMWARE_WRITE: "FIRMWARE_WRITE",
                TOP_LEVEL_SCOPE_READ: "TOP_LEVEL_SCOPE_READ"
            },

            superAdminPermissions : {
                IOT_DEVICE_READ: "IOT_DEVICE_READ",
                IOT_DEVICE_WRITE: "IOT_DEVICE_WRITE",
                IOT_DEVICE_BULK_WRITE: "IOT_DEVICE_BULK_WRITE",
                IOT_USER_READ: "IOT_USER_READ",
                IOT_USER_WRITE: "IOT_USER_WRITE",
                PORTAL_USER_READ: "PORTAL_USER_READ",
                PORTAL_USER_WRITE: "PORTAL_USER_WRITE",
                PORTAL_USER_DELETE: "PORTAL_USER_DELETE",
                CAN_BE_SCOPE_OWNER: "CAN_BE_SCOPE_OWNER",
                ALERTS_READ: "ALERTS_READ",
                ALERTS_WRITE: "ALERTS_WRITE",
                SCOPE_READ: "SCOPE_READ",
                SCOPE_WRITE: "SCOPE_WRITE",
                SCOPE_UPDATE: "SCOPE_UPDATE",
                SCOPE_DELETE: "SCOPE_DELETE",
                FIRMWARE_READ: "FIRMWARE_READ",
                FIRMWARE_DOWNLOAD: "FIRMWARE_DOWNLOAD",
                GATEWAY_READ: "GATEWAY_READ",
                GATEWAY_WRITE: "GATEWAY_WRITE",
                GENERATE_GATEWAY_TOKEN: "GENERATE_GATEWAY_TOKEN",
                GATEWAY_INSTRUCTIONS_READ: "GATEWAY_INSTRUCTIONS_READ",
                GATEWAY_INSTRUCTIONS_WRITE: "GATEWAY_INSTRUCTIONS_WRITE",
                APP_DETAILS: "APP_DETAILS",
                MQTT_AUTH: "MQTT_AUTH",
                PORTAL_USER_DETAILS: "PORTAL_USER_DETAILS",
                PORTAL_USER_PASSWORD_CHANGE: "PORTAL_USER_PASSWORD_CHANGE",
                HIERARCHY_READ: "HIERARCHY_READ"
            },

            adminPermissions : {
                IOT_USER_READ: "IOT_USER_READ",
                IOT_DEVICE_READ: "IOT_DEVICE_READ",
                PORTAL_USER_READ: "PORTAL_USER_READ",
                ALERTS_READ: "ALERTS_READ",
                SCOPE_READ: "SCOPE_READ",
                GATEWAY_READ: "GATEWAY_READ",
                FIRMWARE_READ: "FIRMWARE_READ",
                GATEWAY_INSTRUCTIONS_READ: "GATEWAY_INSTRUCTIONS_READ"
            }

        }
    }
}
