// LeafletClusterMapComponent.js
import React from 'react';
import { Box, Dialog, DialogActions, FormControlLabel, DialogContent, DialogTitle, Table, TableBody, useMediaQuery, useTheme, TableCell, Checkbox, LinearProgress, Select, MenuItem, TableContainer, Tooltip, TableHead, TableRow, TablePagination, TableSortLabel, Radio, RadioGroup, FormLabel } from '@mui/material';
import LocationMap from 'helper-components/LocationMap';
import { People,Lock,LockOpen,Check,Close,CheckCircle,HelpOutline, AlarmAdd,ArrowDropDown,ReportProblem,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Search,Refresh,Download } from '@mui/icons-material';
import { Button, CircularProgress, DialogContentText, FormControl, Paper, Popover, requirePropFactory, TextField } from '@mui/material';
import Draggable from 'react-draggable';
import "stylesheet/common.css"
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';



function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


export default function ShowLocationDetails(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));    
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7");
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff");

    React.useEffect(() => {
      setTimeout(() => {
        setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
        setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
      }, 1000);
    }, []);

    return (
        props.markers.length > 0 ?
            <Box className="locationDialogRoot" sx={{overflowY:"hidden !important"}}>
                <Dialog
                    fullScreen
                    open={true}
                    className="locationDialog"
                    onClose={()=> props.closeDialog()}
                    PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                >
                    <DialogTitle style={{
                        background: primaryThemeColor,
                        display: 'flex',
                        flexDirection: 'row',
                        color: 'white',
                        overflow:"hidden"
                    }}>
                        <Box sx={{flex: '95'}}>Gateway Location</Box>
                        <Box sx={{flex: '5', marginTop: '6px',justifyContent:'right', display:"flex"}}>
                            <Close sx={{cursor:"pointer"}} onClick={() => props.closeDialog()}/>
                        </Box>
                    </DialogTitle>
                    <DialogContent sx={{padding:"0px"}}>
                        <LocationMap markers={props.markers} fromDevicePage="true"/>
                    </DialogContent>
                </Dialog>
            </Box>
        :
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                open={true}
                onClose={()=> props.closeDialog()}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                maxWidth="sm"
            >
                <DialogTitle>
                <Box id="draggable-dialog-locationTitle" style={{marginRight: 'auto'}}>
                    Gateway Location
                </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        No Location Data for this Gateway.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> props.closeDialog()} sx={{color:primaryThemeColor}}>Ok</Button>
                </DialogActions>
            </Dialog>
    )
}
