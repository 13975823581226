import React from 'react'

export default function checkDataExistsOrNot(data) {
    try {
        if (Array.isArray(data)) {
            return data.length > 0;
        }else if (data === null || typeof data === 'undefined' || data === "") {
            return false;
        }else if (typeof data === "number" || typeof data === "string" || typeof data === "boolean") {
           return true;
        }else if (typeof data === "object"){
            return data.hasOwnProperty("fromComponent") ? data['valueToCheck'] !== null && typeof data['valueToCheck'] !== 'undefined' : Object.keys(data).length > 0;
        } 
    } catch (error) {
        return false;
    }
}
