import React from 'react';
import { Box,Dialog,Button,DialogActions,DialogContent,DialogTitle,DialogContentText,Paper,useMediaQuery,useTheme, CircularProgress, Snackbar } from '@mui/material';
import { Close} from '@mui/icons-material';
import Draggable from 'react-draggable';
import AuthClient from 'clients/AuthClient';
import IotServiceClient from 'clients/IotServiceClient'
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import UserDetailService from 'services/UserDetailService';
import PaperComponent from 'helper-components/PaperComponent';


export default function DeleteGatewaysDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };
   const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
   const [performedDelete,setPerformedDelete] = React.useState(false);
   const { enqueueSnackbar } = useSnackbar();


  const handleCloseDeleteDialog =() => {
      props.closeDialog();
  }

  const deleteGateways = () => {
     if (!performedDelete) {
        setPerformedDelete(true);
        let json = {};
        let macIds = [];
        _.forEach(props.devices, (val) => {
             macIds.push(val.MAC);
        });
        json.offlineGateways = macIds;
        json.scopeId = props.devices[0].SID;
        IotServiceClient.deleteOfflineGateways(json).then((response) => {
           props.closeDialog();
           if (response.status >= 200 && response.status < 300){
               if (props.devices.length === 1) {
                   enqueueSnackbar("Successfully Deleted Gateway");
               } else {
                   enqueueSnackbar("Successfully Deleted Gateways");
               }
           } else {
               if (props.devices.length === 1) {
                    enqueueSnackbar("Failed to Delete Gateway");
               } else {
                   enqueueSnackbar("Failed to Delete Gateways");
               }
           }
        });
     }
  }

return (
   <>
     <Dialog
        fullWidth={true}
        fullScreen={fullScreen}
        open={props.openDialog}
        onClose={() => handleCloseDeleteDialog()}
        PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
        aria-labelledby="draggable-dialog-title"
     >
       <DialogTitle style={{color: 'white', display:"flex", flexDirection:"row", background:"#4190c7"}}>
           <Box sx={{flex:"95"}}>Delete Gateway</Box>
           <Box sx={{flex: '5', marginTop: '6px'}}>
               <Close onClick={()=>props.closeDialog()} sx={{cursor:"pointer"}}/>
           </Box>
       </DialogTitle>
       <DialogContent style={{padding:"20px"}}>
         { props.devices.length === 1 ?
               <DialogContentText style={{color: 'black'}}>
                   You will not be able to undo this operation. Do you still want to delete <b>{props.devices[0].NAME}</b>.
               </DialogContentText>
            :
               <DialogContentText style={{color: 'black'}}>
                   You will not be able to undo this operation. Do you still want to delete Selected Gateways.
               </DialogContentText>
         }
       </DialogContent>
       <DialogActions>
           <>
               <Button style={{color: 'red'}} onClick={() => props.closeDialog()}>
                 Cancel
               </Button>
               <Button onClick={() => {deleteGateways()}}>
                 Yes
               </Button>
           </>
       </DialogActions>
     </Dialog>
   </>
);
}