import React, {Component} from "react";
import Mqtt_Service from "./Mqtt_Service";


export default class MqttEventHandler extends Component {
    static subscribe = function(topic, ctrl, callback) {

        var handler = {};

        var onSuccessfulSubscription = function(err) {
            if (err === null) {
                console.log("Successfully subscribed to " + topic);
                //MqttClient.mapCallbackToEvent(topic, ctrl, callback);
            } else {
                //As mapping event callbacks before subscription, remove callbacks if it failed to subscribe
                Mqtt_Service.removeCallbackToEvent(topic, ctrl);
                console.log("failed to subscribe topic : " + topic + ", with error : " + err);
            }
        };

        var onSuccessfulUnSubscription = function() {
            console.log("Unsubscribed to " + topic);
        };

        handler.subscribe = function() {
            console.log("subscribing to : " + topic);
            // Mapping callbacks to the topic even before subscribing, as retained messages are received even before call of onSuccessfulSubscription()
            // So remove callbacks if subscription fails
            Mqtt_Service.mapCallbackToEvent(topic, ctrl, callback);
            Mqtt_Service.connect().then(function(mqttClient) {
                mqttClient.subscribe(topic, onSuccessfulSubscription);
            });

        };

        handler.unsubscribe = function() {
            Mqtt_Service.removeCallbackToEvent(topic, ctrl);
            var callbacks = Mqtt_Service.getCallbacksForEvent(topic);
            // Unsubscribing permanently as we dont have any registered callbacks
            if (callbacks.length === 0) {
                //MgttService.unsubscribe(topic);
                Mqtt_Service.connect().then(function(mqttClient) {
                    mqttClient.unsubscribe(topic, onSuccessfulUnSubscription);
                });
            }
        };

        handler.subscribe();
        return handler;
    };

    static publish = function(topic, message, options, callback) {
        console.log("Publishing to topic " + topic + " with message - " + message + " with options -" + JSON.stringify(options));
        Mqtt_Service.connect().then(function(mqttClient) {
            mqttClient.publish(topic, message, options, callback);
        });
    };



    render() {

        return(<></>);
    }
}