import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { AppBar,CssBaseline,Divider,Box,Drawer,IconButton,List,ListItem,ListItemText,Typography,Toolbar,Fab,Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';
import LanguageIcon from '@mui/icons-material/Language';
import { Menu,Lock,Group,Notifications,ArrowBack,Cloud,LocationCity,ArrowLeft,ArrowRight, CropPortrait, PersonalVideo} from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import 'stylesheet/SideNav.css';
import {createTheme} from '@material-ui/core/styles';
import DashBoardGatewayPage from '../DashBoardGatewayPage';
import Appheader from 'components/header/Appheader.js';
import { useNavigate } from "react-router-dom";
import CaribouLogo from 'cariboutechnologieslogo.png';
import {ProgressBar} from "react-fetch-progressbar";
import UserSessionStorage from 'userSession/UserSessionStorage';
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import PubSubChannels from 'pubsub/PubSubChannels';
import IotServiceClient from 'clients/IotServiceClient';
import AuthClient from 'clients/AuthClient';
import GatewayServiceClient from 'clients/GatewayServiceClient';
import frontendServiceClient from 'clients/FrontendClient';
import AgentConfig from 'components/agentConfig/AgentConfig';
import { indexOf } from 'lodash';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot.js';
import Dashboard2 from "../dashboard2/Dashboard2.js"
import _ from 'lodash';
import FallbackUi from 'helper-components/FallbackUi.js';
import { useLocation } from 'react-router-dom';
import Dashboard from 'components/dashboard/Dashboard.js'
import Devices from 'components/devices/devices.js'
import GlobalDashboard from 'components/dashboard/GlobalDashboard.js'
import Dashboard1 from 'components/dashboard1/Dashboard1.js'
import PortalUsers from "components/DashboardPortalUsers/PortalUsers.js"
import Alerts from 'components/userAlerts/Alerts.js'

function SideNav(props) {

  let adminViews = [];
  if (JSON.parse(sessionStorage.userDetails).permissions.includes("PORTAL_USER_READ")) {
    adminViews.push("Users");
  }
  if (JSON.parse(sessionStorage.userDetails).permissions.includes("ALERTS_READ")) {
     adminViews.push("Alerts");
     adminViews.push("Agent Config");
  }

  const checkIsFreeTier = () => {
    if (!sessionStorage.sidenavAlreadySelectedItem){
      if (sessionStorage.tenantName === "FreeTier"){
          return "Gateways";
      }else {
          return "Dashboard";
      }
    }else {
      return "windowSelectedItem";
    }
  };

  const {windows} = props;
  const location = useLocation();
  const [selectedListItem, setSelectedListIem] = React.useState(!sessionStorage.sidenavAlreadySelectedItem ? checkIsFreeTier() : "Dashboard");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [drawerWidth, setDrawerWidth] = React.useState(240);
  const [mqttFrontend, setMqttFrontend] = React.useState(false);
  const [mqttIOT, setMqttIOT] = React.useState(false);
  const [mqttGateway, setMqttGateway] = React.useState(false);
  const [mqttAuth, setMqttAuth] = React.useState(false);
  const [tenantName, setTenantName] = React.useState(sessionStorage.tenantName);
  const [menuItemIconColor, setMenuItemIconColor] = React.useState(
    checkIsFreeTier() === "Dashboard" ? 'DashboardSideNavItem' : (!sessionStorage.sidenavAlreadySelectedItem ? 'GatewaysSideNavItem' : (sessionStorage.sidenavAlreadySelectedItem === "Users" || sessionStorage.sidenavAlreadySelectedItem === "Alerts" || sessionStorage.sidenavAlreadySelectedItem === "Agent Config" ?  sessionStorage.sidenavAlreadySelectedItem + "AdminSideNavItem" : sessionStorage.sidenavAlreadySelectedItem  + "SideNavItem"))
  );
  const [menuItemTextColor, setMenuItemTextColor] = React.useState(
    checkIsFreeTier() === "Dashboard" ? 'DashboardSideNavItemText' : (!sessionStorage.sidenavAlreadySelectedItem ? 'GatewaysSideNavItemText' : (sessionStorage.sidenavAlreadySelectedItem === "Users" || sessionStorage.sidenavAlreadySelectedItem === "Alerts" || sessionStorage.sidenavAlreadySelectedItem === "Agent Config" ?  sessionStorage.sidenavAlreadySelectedItem + "AdminSideNavItemText" : sessionStorage.sidenavAlreadySelectedItem  + "SideNavItemText"))
  );
  const [menuItemLeftBorder, setMenuItemLeftBorder] = React.useState(
    checkIsFreeTier() === "Dashboard" ? 'DashboardSideNavItemDiv' : (!sessionStorage.sidenavAlreadySelectedItem ? "GatewaysSideNavItemDiv" : (sessionStorage.sidenavAlreadySelectedItem === "Users" || sessionStorage.sidenavAlreadySelectedItem === "Alerts" || sessionStorage.sidenavAlreadySelectedItem === "Agent Config" ?  sessionStorage.sidenavAlreadySelectedItem + "AdminSideNavItemDiv" : sessionStorage.sidenavAlreadySelectedItem  + "SideNavItemDiv"))
  );
  if (window.location.hash.indexOf("dashboard") !== -1 && selectedListItem !== "Dashboard"){
    setSelectedListIem("Dashboard");
    setMenuItemIconColor("DashboardSideNavItem");
    setMenuItemTextColor("DashboardSideNavItemText");
    setMenuItemLeftBorder("DashboardSideNavItemDiv");
  }else if(window.location.hash.indexOf("gateways") !== -1 && selectedListItem !== "Gateways"){
      setSelectedListIem("Gateways");
      setMenuItemIconColor("GatewaysSideNavItem");
      setMenuItemTextColor("GatewaysSideNavItemText");
      setMenuItemLeftBorder("GatewaysSideNavItemDiv")
  }else if(window.location.hash.indexOf("globalDashboard") !== -1 && selectedListItem !== "Global Dashboard"){
     setSelectedListIem("Global Dashboard")
     setMenuItemIconColor("Global DashboardSideNavItem");
     setMenuItemTextColor("Global DashboardSideNavItemText");
     setMenuItemLeftBorder("Global DashboardSideNavItemDiv")
  }else if(window.location.hash.indexOf("portal_users") !== -1 && selectedListItem !== "Users"){
     setSelectedListIem("Users")
     setMenuItemIconColor("UsersAdminSideNavItem");
     setMenuItemTextColor("UsersAdminSideNavItemText");
     setMenuItemLeftBorder("UsersAdminSideNavItemDiv")
  }else if(window.location.hash.indexOf("alerts") !== -1 && selectedListItem !== "Alerts"){
     setSelectedListIem("Alerts")
     setMenuItemIconColor("AlertsAdminSideNavItem");
     setMenuItemTextColor("AlertsAdminSideNavItemText");
     setMenuItemLeftBorder("AlertsAdminSideNavItemDiv")
  }else if(window.location.hash.indexOf("agent-config") !== -1 && selectedListItem !== "Agent Config"){
     setSelectedListIem("Agent Config")
     setMenuItemIconColor("Agent ConfigAdminSideNavItem");
     setMenuItemTextColor("Agent ConfigAdminSideNavItemText");
     setMenuItemLeftBorder("Agent ConfigAdminSideNavItemDiv")
  }
  const [firstListBlock, setFirstListBlock] = React.useState([]);

  const [frontEndVersion, setFrontEndVersion] = React.useState("");

  const [iotVersion, setIotVersion] = React.useState("");

  const [gatewayVersion, setGatewayVersion] = React.useState("");

  const [authVersion, setAuthVersion] = React.useState("");

  const[themePrimaryColor, setThemePrimaryColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")

  const[themeSecondaryColor, setThemeSecondaryColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")

  const [logoUrl, setLogoUrl] = React.useState("");

  const isConnected = (status) => {
    return status.toString() === "connected" ? true : false;
  }

  const updateMqttSTatus = () => {
    let status = UserSessionStorage.getMqttStatus();
    setMqttFrontend(status)
    if (!status){
      setMqttAuth(status);
      setMqttGateway(status);
      setMqttIOT(status);
    }
  }

  let checkMqttInterval = setInterval(() => {
     updateMqttSTatus();
  }, 2000);

  let gatewayServiceStatusEventChannel, iotServiceStatusEventChannel, authServiceStatusEventChannel = null;


  const gatewayServiceStatusEventHandler = (message) => {
    if(isConnected(message)){
      setMqttGateway(true)
    }else {
      setMqttGateway(false)
    }
  }

  const iotServiceStatusEventHandler = (message) => {
    if(isConnected(message)){
      setMqttIOT(true)
    }else {
      setMqttIOT(false)
    }
  }

  const authServiceStatusEventHandler = (message) => {
    if(isConnected(message)){
      setMqttAuth(true)
    }else {
      setMqttAuth(false)
    }
  }

  const themeAndLogoChangeEventHandler = (message) => {
     if (message.toString() === "created") {
        AuthClient.getHirarchyOnlineDeviceCount("page").then((response) => {
            _.forEach(response.children,function(value){
                if (value.scope.id === sessionStorage.tenantId) {
                    setThemePrimaryColor(value.scope.primaryThemeColor);
                    setThemeSecondaryColor(value.scope.secondaryThemeColor);
                    setLogoUrl(value.scope.logoUrl);
                }
            });
        })
     }
  }

  const checkMqqttSTatusForFIGA = () => {
    gatewayServiceStatusEventChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_SERVICE_STATUS, "sideNav", gatewayServiceStatusEventHandler);

    iotServiceStatusEventChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.IOT_SERVICE_STATUS, "sideNav" , iotServiceStatusEventHandler);

    authServiceStatusEventChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.AUTH_SERVICE_STATUS, "sideNav", authServiceStatusEventHandler);

    window.themeAndLogoChangeEvent = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.SCOPES.replace("scopeId",sessionStorage.tenantId), "Appheader", themeAndLogoChangeEventHandler);

  }

  const getFigaVersions = () => {
    frontendServiceClient.getFrontendAppDetails().then((response)=>{
      setFrontEndVersion(response.version);
    }).catch((error)=>{})

    IotServiceClient.getIotAppDetails("sideNav").then((response)=>{
      setIotVersion(response.version);
    }).catch((error)=>{})

    AuthClient.getAuthAppDetails("sideNav").then((response)=>{
      setAuthVersion(response.version);
    }).catch((error)=>{})
  }


  const handleHashChange = () => {
    window.addEventListener('popstate', (event) => {
      let hash = window.location.hash;
      if (sessionStorage.sidenavAlreadySelectedItem && hash !== "" && hash){
        let locationHash = "";

        if (hash.indexOf("list_view") !== -1 || hash.indexOf("grid_view") !== -1){
          locationHash = "Gateways"
        }else if (hash.indexOf("/globalDashboard") !== -1){
          locationHash = "Global Dashboard";
        }else if (hash.indexOf("agent-config") !== -1){
          locationHash = "Agent Config";
        }else {
          locationHash = hash;
        }

        if (locationHash.toLowerCase().indexOf(sessionStorage.sidenavAlreadySelectedItem.toLowerCase()) === -1){
          setTimeout(() => {
            if (window.location.hash.toLowerCase().indexOf("dashboard") !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Dashboard";
            }else if (window.location.hash.toLowerCase().indexOf("globalDashboard") !== -1){
              sessionStorage.sidenavAlreadySelectedItem = "Global Dashboard";
            }else if (window.location.hash.toLowerCase().indexOf("list_view") !== -1) {
              sessionStorage.lockView = "listView"
              sessionStorage.sidenavAlreadySelectedItem = "Gateways";

            }else if (window.location.hash.toLowerCase().indexOf("grid_view") !== -1) {
              sessionStorage.lockView = "gridView"
              sessionStorage.sidenavAlreadySelectedItem = "Gateways";

            }else if (window.location.hash.toLowerCase().indexOf("/portal_users") !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Users";

            }else if (window.location.hash.toLowerCase().indexOf("alerts") !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Alerts";

            }else if (window.location.hash.toLowerCase().indexOf('agent-config') !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Agent Config";

            }
          }, 1500);
        }
      }
    });
  }

  React.useEffect(() => {
    if (sessionStorage.tenantName === 'Caribou Technologies') {
      setFirstListBlock([
        'Dashboard',
        // 'Dashboard1',
        'Gateways',
        // 'LocksGrid',
        'Global Dashboard',
      ]);
    }else {
      setFirstListBlock(['Dashboard', 'Gateways']);
    }

    handleHashChange();
    getFigaVersions();
    checkMqqttSTatusForFIGA();
    setThemePrimaryColor(sessionStorage.tenantPrimaryThemeColor);
    setThemeSecondaryColor(sessionStorage.tenantSecondaryThemeColor);
    return () => {
      if (gatewayServiceStatusEventChannel){
        gatewayServiceStatusEventChannel.unsubscribe();
      }
      if (iotServiceStatusEventChannel) {
        iotServiceStatusEventChannel.unsubscribe();
      }
      if (authServiceStatusEventChannel) {
        authServiceStatusEventChannel.unsubscribe();
      }
      if (window.themeAndLogoChangeEvent) {
         window.themeAndLogoChangeEvent.unsubscribe();
      }
      clearInterval(checkMqttInterval);
      IotServiceClient.abortSignal("sideNav");
      delete sessionStorage.sidenavAlreadySelectedItem;
    }
  }, []);

  const changeUrlHash = (listItem) => {

    if (listItem === "Dashboard"){
      window.location.hash = "/cli/dashboard" + "?scopeId="+ sessionStorage.tenantId;
      location.hash = "#/cli/dashboard" + "?scopeId="+ sessionStorage.tenantId;
    }else if (listItem === "Global Dashboard"){
      window.location.hash = "/cli/globalDashboard" + "?scopeId="+ sessionStorage.tenantId;
      location.hash = "#/cli/globalDashboard" + "?scopeId="+ sessionStorage.tenantId;
    }else if (listItem === "Gateways") {
      let urlHash = "/cli/gateways/list_view" + "?scopeId="+ sessionStorage.tenantId;
      if (!sessionStorage.lockView || sessionStorage.lockView === "listView"){
        window.location.hash = urlHash.replace("grid_view","list_view");
        location.hash = "#" + urlHash.replace("grid_view","list_view");
      }else {
        window.location.hash = urlHash.replace("list_view","grid_view");
        location.hash = "#" + urlHash.replace("list_view","grid_view");
      }
    }else if (listItem === "Users") {
      window.location.hash = "/cli/portal_users" + "?scopeId="+ sessionStorage.tenantId;
      location.hash = "#/cli/portal_users" + "?scopeId="+ sessionStorage.tenantId;
    }else if (listItem === "Alerts") {
      window.location.hash = "/cli/alerts" + "?scopeId="+ sessionStorage.tenantId;
      location.hash = "#/cli/alerts" + "?scopeId="+ sessionStorage.tenantId;
    }
    else if (listItem === "Agent Config") {
      window.location.hash = "/cli/agent-config" + "?scopeId="+ sessionStorage.tenantId;
      location.hash = "#/cli/agent-config" + "?scopeId="+ sessionStorage.tenantId;
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const changeDrawerWidth = (presentWidth) => {
    presentWidth === 240 ? setDrawerWidth(80) : setDrawerWidth(240);
  };

  const checkDrawerWidth = () => {
    let checkCondition;
    drawerWidth !== 240 ? (checkCondition = true) : (checkCondition = false);
    return checkCondition;
  };

  const changeSideNavTextColor = (id, listItem) => {
    setMenuItemIconColor(id);
    setMenuItemTextColor(id + 'Text');
    setMenuItemLeftBorder(id + 'Div');
    sessionStorage.sidenavAlreadySelectedItem = listItem;
    setSelectedListIem(listItem);
    changeUrlHash(listItem);
    handleDrawerToggle();
    if (listItem !== "Gateways"){
      delete sessionStorage.navigatedFromDashboard
      delete sessionStorage.lockView;
    }
  };

  const drawer = (
    <div
      style={{
        height:
          checkDrawerWidth() === true
            ? `calc(100% - ${240}px)`
            : `calc(100% - ${84}px)`,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Toolbar />
      <Toolbar />
      <Toolbar className="LockManagerHeader">
        {checkDrawerWidth() === false ? (
          <Typography>GATEWAYS MANAGER</Typography>
        ) : (
          <Typography>IOT</Typography>
        )}
      </Toolbar>
      <List>
        {firstListBlock.map((text, index) => (
          <ListItem
            selected={text === selectedListItem}
            button
            key={text}
            className={text + 'SideNavItemDiv'}
            sx={{
              borderLeft:
                menuItemLeftBorder === text + 'SideNavItemDiv'
                  ? '6px solid '+ themeSecondaryColor
                  : '6px solid white',
              height: '48px',
              background: menuItemLeftBorder === text + 'SideNavItemDiv' ? themePrimaryColor + " !important" : null
            }}
            onClick={() => changeSideNavTextColor(text + 'SideNavItem', text)}
          >
            <ListItem
              className={text + 'SideNavItem'}
              sx={{
                color:
                  menuItemIconColor === text + 'SideNavItem'
                    ? themeSecondaryColor
                    : '#000000DE',
                    width:"56px",paddingLeft:"0px"
              }}
            >
              {/*{text === 'Dashboard' || text === 'Dashboard1' ? (*/}
                {text === 'Dashboard' ? (
                <DashboardIcon />
              ) : text === 'Gateways' ? (
                <CropPortrait />
              ) : text === 'Global Dashboard' ? (
                  <LanguageIcon/>
              ) : null}
            </ListItem>
            <ListItemText
              sx={{
                visibility: checkDrawerWidth() === true ? 'hidden' : 'visible',
                color:
                  menuItemTextColor === text + 'SideNavItemText'
                    ? themeSecondaryColor
                    : '#000000DE'
              }}
              primary={text}
              className={text + 'SideNavItemText'}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      {sessionStorage.tenantName !== 'FreeTier' && adminViews.length > 0 ? (
        <>
          {checkDrawerWidth() === false ? (
            <Typography
              sx={{
                margin: 'auto',
                paddingLeft: '12px',
                marginTop: '6px',
                color: 'gray',
              }}
            >
              ADMINISTRATOR
            </Typography>
          ) : (
            <Typography
              sx={{
                margin: 'auto',
                paddingLeft: '10px',
                marginTop: '6px',
                color: 'gray',
              }}
            >
              ADMIN
            </Typography>
          )}
          <List>
            {
            adminViews.map((text, index) => (
              <ListItem
                selected={text === selectedListItem}
                button
                key={text}
                className={text + 'AdminSideNavItemDiv'}
                sx={{
                  borderLeft:
                    menuItemLeftBorder === text + 'AdminSideNavItemDiv'
                      ? '6px solid '+ themeSecondaryColor
                      : '6px solid white',
                  background: menuItemLeftBorder === text + 'AdminSideNavItemDiv' ? themePrimaryColor + " !important" : null
                }}
                onClick={() =>
                  changeSideNavTextColor(text + 'AdminSideNavItem', text)
                }
              >
                <ListItem
                  className={text + 'AdminSideNavItem'}
                  sx={{
                    color:
                      menuItemIconColor === text + 'AdminSideNavItem'
                        ? themeSecondaryColor
                        : '#000000DE',
                        width:"56px",paddingLeft:"0px",height:"10px"
                  }}
                >
                  {text === 'Users' ? (
                    <Group />
                  ) : text === 'Alerts' ? (
                    <Notifications />
                  ) : text === 'Agent Config' ? (
                    <PersonalVideo />
                  ) : null}
                </ListItem>
                <ListItemText
                  sx={{
                    visibility:
                      checkDrawerWidth() === true ? 'hidden' : 'visible',
                    color:
                      menuItemTextColor === text + 'AdminSideNavItemText'
                        ? themeSecondaryColor
                        : 'black',
                  }}
                  primary={text}
                  className={text + 'AdminSideNavItemText'}
                />
              </ListItem>
            ))}
          </List>
        </>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: checkDrawerWidth() === false ? 'row' : 'column',
          marginTop: '4px',
          alignItems: 'center',
          marginLeft: '4px',
          padding: '2px 14px 2px 14px',
          position: 'fixed',
          bottom: checkDrawerWidth() === true ? '40px' : '40px',
          background: '#fff',
          width: checkDrawerWidth() !== true ? '234px' : '74px',
          borderTop: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
        }}
      >
        <Tooltip
          title={mqttFrontend ? 'Online' : 'Offline'}
          placement={checkDrawerWidth() === true ? 'right' : 'top'}
          arrow
        >
          <Box
            sx={{
              flex: '25',
              textAlign: 'center',
              borderRight:
                checkDrawerWidth() === false ? '1px solid #ccc' : null,
            }}
          >
            F<span className="versionNumber">{frontEndVersion}</span>
            <br />
            {mqttFrontend ? (
              <span className="greenDot"></span>
            ) : (
              <span className="redDot"></span>
            )}
          </Box>
        </Tooltip>
        <Tooltip
          title={mqttIOT ? 'Online' : 'Offline'}
          placement={checkDrawerWidth() === true ? 'right' : 'top'}
          arrow
        >
          <Box
            sx={{
              flex: '25',
              textAlign: 'center',
              borderRight:
                checkDrawerWidth() === false ? '1px solid #ccc' : null,
            }}
          >
            I<span className="versionNumber">{iotVersion}</span>
            <br />
            {mqttIOT ? (
              <span className="greenDot"></span>
            ) : (
              <span className="redDot"></span>
            )}
          </Box>
        </Tooltip>
        <Tooltip
          title={mqttAuth ? 'Online' : 'Offline'}
          placement={checkDrawerWidth() === true ? 'right' : 'top'}
          arrow
        >
          <Box sx={{flex: '25', textAlign: 'center'}}>
            A<span className="versionNumber">{authVersion}</span>
            <br />
            {mqttAuth ? (
              <span className="greenDot"></span>
            ) : (
              <span className="redDot"></span>
            )}
          </Box>
        </Tooltip>
      </Box>
      <Box
        className="cursorPointer"
        sx={{background: '#fff', position: 'fixed', bottom: '0px'}}
        onClick={() => changeDrawerWidth(drawerWidth)}
      >
        {checkDrawerWidth() === false ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: checkDrawerWidth() === true ? '80px' : '240px',
              fontSize: '12px',
              color: 'gray',
            }}
          >
            <img
              src={CaribouLogo}
              alt=""
              style={{height: '26px', width: '26px', margin: '5px'}}
            />
            <div
              style={{display: 'flex', flexDirection: 'column', width: `176px`}}
            >
              <div>Powered by</div>
              <div>
                <b> Caribou Technologies </b>
              </div>
            </div>
            <div></div>
            <ArrowLeft
              className="sideNavMiniIcon"
              sx={{width: '40px', margin: 'auto'}}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: checkDrawerWidth() === true ? '80px' : '240px',
              fontSize: '12px',
              color: 'gray',
            }}
          >
            <img
              src={CaribouLogo}
              alt=""
              style={{height: '26px', width: '26px', margin: '5px 10px'}}
            />
            <ArrowRight
              className="sideNavMiniIcon"
              sx={{
                width: checkDrawerWidth() === true ? '28px' : '240px',
                paddingLeft: checkDrawerWidth() === false ? '200px' : '',
                marginTop: checkDrawerWidth() === true ? "3px" : "",

              }}
            />
            </Box>
        )}
      </Box>
    </div>
  );

  const container =
    windows !== undefined ? () => windows().document.body : undefined;

  const fabRedStyle = {
    color: themeSecondaryColor,
    bgcolor: themePrimaryColor,
    '&:hover': {
      bgcolor: themePrimaryColor,
      color: themeSecondaryColor
    },
  };

  const fabSecondaryStyle = {
    color: themeSecondaryColor,
    '&:hover' : {
      color: themeSecondaryColor
    }
  }

  const customBreakPointtheme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        lg: 1179,
        desktop: 1280,
      },
    },
  });

  const customHiddenBreakPointtheme = createTheme({
      breakpoints: {
        values: {
          mobile: 0,
          lg: 1179,
          desktop: 1280,
        },
      },
   });

    const nav = useNavigate();
  return (
    <>
      <Box sx={{display: 'flex', overflowX: 'hidden',overflowY: selectedListItem === 'Gateways' ? 'hidden' : 'auto',height: selectedListItem === 'Gateways' ? 'calc(100% - 21px)' : 'auto'}}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Toolbar className="header_nav" sx={{ paddingRight:"16px !important"}}>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              theme={customBreakPointtheme}
              sx={{
                mr: 2,
                display: {
                  xs: 'block',
                  desktop: 'none',
                  width: '35px',
                  marginRight: '-1px',
                },
                color:"grey"
              }}
            >
              <Tooltip title="Top Navbar" placement="right" arrow>
                <Menu />
              </Tooltip>
            </IconButton>

            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              theme={customBreakPointtheme}
              sx={{
                mr: 2,
                display: {
                  lg: 'block',
                  mobile: 'none',
                  desktop: 'block',
                  width: '35px',
                  marginRight: '-1px',
                  visibility:"hidden"
                },
                color:"grey"
              }}
            >
              <Tooltip title="Top Navbar" placement="right" arrow>
                <Menu />
              </Tooltip>
            </IconButton>

            <Appheader parent="SideNav" primaryColor={themePrimaryColor} secondaryColor={themeSecondaryColor} logo={logoUrl}/>
          </Toolbar>
          <Box sx={{height:"2px", background:"#d3d3d3"}}>
            &nbsp;
          </Box>
          <Box
            sx={{
              height: '56px',
              background: 'white',
              color: 'black',
              display: 'flex',
              flex: '100',
            }}
          >
              {
                     sessionStorage.isRootUser == 'true' ?
                  <span
                      style={{margin: '10px', marginLeft: '18px', marginRight: '12px'}}
                  >

              <a onClick={() => {
                delete sessionStorage.sidenavAlreadySelectedItem
                delete sessionStorage.tenantPrimaryThemeColor
                delete sessionStorage.tenantSecondaryThemeColor
                delete sessionStorage.logoUrl
                window.location.hash= "/admin/tenants"
              }}>
                <Tooltip title="Back to Admin" arrow>
                  <Fab
                      className="fabArrowIcon"
                      size="small"
                      aria-label="add"
                      sx={{...fabRedStyle, cursor: 'pointer', background: themePrimaryColor}}
                  >
                    <ArrowBack sx={{color: '#fff'}}/>
                  </Fab>
                </Tooltip>
              </a>
            </span>
             : <span
                          style={{margin: '10px', marginLeft: '18px', marginRight: '12px'}}
                      ></span> }
            <span style={{margin: '10px', fontSize: '28px'}}>
              <LocationCity />
            </span>
            <span
              style={{
                fontSIze: '18px',
                fontWeight: '500',
                margin: '14px',
                marginTop: '16px',
                marginLeft: '4px'
              }}
            >
              {tenantName}
            </span>
          </Box>
        </AppBar>
        <Box
          component="nav"
          sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
          aria-label="mailbox folders"
          className="sideNavigation"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              [`& .MuiDrawer-paper`]: {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            className="smallDevicesDrawer"
          >
            {mobileOpen ? drawer : null}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              [`& .MuiDrawer-paper`]: {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            className="largeDevicesDrawer"
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          theme={customBreakPointtheme}
          sx={{
            flexGrow: 1,
            p: 3,
            width: {
              mobile: '100%',
              desktop: `calc(100% - ${drawerWidth}px)`,
              padding: '0px',
            },
          }}
        >
          <Toolbar />

          {selectedListItem === 'Gateways' ? (
            <div style={{background: '#fff', margin: '10px'}}>
                 <Devices />
               <Box sx={{height:"50px"}}> &nbsp;</Box>
            </div>
          ) : selectedListItem === 'Users' ? (
             <div style={{background: '#fff', margin: '10px'}}>
                   <PortalUsers />
                <Box sx={{height:"50px"}}> &nbsp;</Box>
             </div>
          ) : selectedListItem === 'Alerts' ? (
            <div style={{background: '#fff', margin: '10px'}}>
                  <Alerts />
               <Box sx={{height:"50px"}}> &nbsp;</Box>
            </div>
          ) : selectedListItem === 'Agent Config' ? (
            <div style={{background: '#fff', margin: '10px'}}>
                  <AgentConfig />
               <Box sx={{height:"50px"}}> &nbsp;</Box>
            </div>
          ) : selectedListItem === 'Dashboard' ? (
            <div style={{margin: '10px'}}>
              { tenantName === "New Earth Solutions" ?
                    <Dashboard1 changePage={()=> {
                       window.clickEvent=0
                       changeSideNavTextColor("GatewaysSideNavItem", "Gateways")
                    }}
                    />
                 :
                     <Dashboard primaryColor={themePrimaryColor} secondaryColor={themeSecondaryColor} changePage={()=> {
                        window.clickEvent=0
                        changeSideNavTextColor("GatewaysSideNavItem", "Gateways")
                     }} />
              }
              <Box sx={{height:"50px"}}> &nbsp;</Box>
            </div>
          ): selectedListItem === 'Global Dashboard' ? (
             <div style={{margin: '10px'}}>
                   <GlobalDashboard changePage={()=> {
                       window.clickEvent=0
                       changeSideNavTextColor("LocksSideNavItem", "Locks")
                     }}
                   />
                <Box sx={{height:"50px"}}> &nbsp;</Box>
             </div>
          ): null}
        </Box>
      </Box>
    </>
  );
}

SideNav.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows: PropTypes.func,
};

export default SideNav;
