import React from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';

const styles = {
  root: {
    width: 200,
    position: 'relative',
  },
  slider: {
    padding: 0,
    position: 'absolute',
    bottom: 0,
    transform: 'rotate(-90deg)',
  },
};

const SemiCircleSlider = withStyles(styles)(({ classes }) => {
  const [value, setValue] = React.useState(50);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider" gutterBottom>
        Semi Circle Slider
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Slider
          className={classes.slider}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          step={1}
          min={0}
          max={100}
        />
      </Box>
    </div>
  );
});

export default SemiCircleSlider;
