import React from 'react';
import 'stylesheet/UpdateFirmware.css';
import { ArrowBack,HelpOutline,AddCircleOutline,RemoveCircle, CheckCircle, Delete, CloudUpload, InfoOutlined } from '@mui/icons-material';
import { Box,Button,Snackbar,Chip,FormControl,FormControlLabel,Radio,RadioGroup,Tooltip,Select,MenuItem,Avatar,CircularProgress,Checkbox, TextField, InputLabel, ListItemText } from '@mui/material';
import AuthClient from "clients/AuthClient";
import GatewayServiceClient from "clients/GatewayServiceClient.js";
import moment from 'moment';
import _ from 'lodash';
import {useSnackbar} from "notistack";
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import ApplyFirmwareDialog from './ApplyFirmwareDialog';
import ControlFirmwareUpdateDialog from "./ControlFirmwareUpdateDialog.js";
import PubSubChannels from 'pubsub/PubSubChannels';
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import UserDetailService from 'services/UserDetailService';
import { useLocation } from 'react-router-dom'

const UpgradeFirmware = (props) => {
    let OSPattern = window.location.href.indexOf("staging") !== -1 ? "PartialUpdate\\_(\\d{4}-\\d{2}-\\d{2})_STAGING_V(\\d{3})\\.zip" : "PartialUpdate\\_(\\d{4}-\\d{2}-\\d{2})_PROD_V(\\d{3})\\.zip";
    const [selectedTenant, setSelectedTenant] = React.useState([]);
    const [selectedRadioAction, setSelectedRadioAction] = React.useState('Apply');
    const [allGatewayVersions, setAllGatewayVersions] = React.useState([]);
    const [selectedGateway, setSelectedGateway] = React.useState('');
    const [tenants, setTenants] = React.useState([]);
    const [addCustomer, setAddCustomer] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [controlFirmwareUpdateDialog, setControlFirmwareUpdateDialog] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [validToSubmit, setValidToSubmit] = React.useState(false);
    const [allFirmwareVersions, setAllFirmwareVersions] = React.useState([]);
    const [selectedFirmwareVersion, setSelectedFirmwareVersion] = React.useState([]);
    const [disableLoadMore, setDisableLoadMore] = React.useState(false);
    const [applyFirmwareDialog, setApplyFirmwareDialog] = React.useState(false);
    const [applyFirmwareData, setApplyFirmwareData] = React.useState([]);
    const [defaultFwVersion, setDefaultFwVersion] = React.useState([]);
    const [deleteFirmwareDialog, setDeleteFirmwareDialog] = React.useState(false);
    const [uploadFirmwareDialog, setUploadFirmwareDialog] = React.useState(false);
    const [fileHasUploaded, setFileHasUploaded] = React.useState(false);
    const [descriptionText, setDescriptionText] = React.useState("");
    const gatewayFileNamePattern = new RegExp('^CRB_mdm-agent_Generic_iot\\.(\\d{4}-\\d{2}-\\d{2})_.*\\.cpython-.*\\.pyc$');
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    let fileRef = React.useRef(null);
    let fileUploaded = null;
    window.alreadyExistedVersion = false;
    window.fileFormatAccepted = false;
    const location = useLocation();


    React.useEffect(() => {
       window.firmwareMqttChannel = MqttEventHandler.subscribe("auth-service/+/firmware-upgraded", "upgradeFirmware",firmwareChannelEvent);
       window.firmwareApplyVersionChannel = MqttEventHandler.subscribe("auth-service/+/firmware-upgraded-details", "upgradeFirmware",firmwareApplyEvent);
       window.firmwareDeleteVersionChannel = MqttEventHandler.subscribe("auth-service/+/firmware-upgraded", "upgradeFirmware",firmwareDeleteEvent);

       fetchData();
       return() => {
         if (window.firmwareMqttChannel){
           window.firmwareMqttChannel.unsubscribe();
           delete window.firmwareMqttChannel;
         }
         if (window.uploadFirmwareResponseSuccess) {
           delete window.uploadFirmwareResponseSuccess;
         }
         if (window.firmwareApplyVersionChannel){
          window.firmwareApplyVersionChannel.unsubscribe();          
         }
         GatewayServiceClient.abortSignal("updateFirmware")
         AuthClient.abortSignal("configureEnail");
       }
    }, []);

    const firmwareChannelEvent = () => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    const firmwareDeleteEvent = () => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    const firmwareApplyEvent = () => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    const handleUploadedStatus = (value) => {
      window.uploadFirmwareResponseSuccess = value;
    }

    const handleUploadDialog = () => {
       setUploadFirmwareDialog(true);
    }

     const handleChangeDescription = (event) => {
       setDescriptionText(event.target.value);
     }

     const handleCloseUploadDialog = () => {
        setUploadFirmwareDialog(false);
     }

     const handleCloseDeleteDialog = () => {
       setDeleteFirmwareDialog(false);
     }

     const handleCloseApplyDialog = () => {
       setApplyFirmwareDialog(false);
     }

   const handleApplyDialog = () => {
     setApplyFirmwareDialog(true);
   }

    const handleDisplayText = (versiion) => {
      let version = versiion.version;
      let date = moment(versiion.date).format("MMM Do, YYYY h:mm:ss a")
      let string = " (" + date + ")";
      return version + string;
    }

    const handleRender = (selectedValue) => {
      let selected = selectedValue.map(value => value + " (" + moment(value).format("MMM Do, YYYY h:mm:ss a") + ")");
      return selected.join(", ");
    }

    const handleSelectMenuItem = (value) => {
       setSelectedFirmwareVersion(value);
    }

    const MenuProps = {
         PaperProps: {
           style: {
             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
             maxWidth: 250,
           },
         },
    };

    const fetchData = () => {
       let filteredTenants = [];
       let filteredTenantsWithGateways = [];
       let allVersions = [];
       let defaultValue = [];
       let tenants = [];
       setIsLoading(true);
       if (!sessionStorage.userLoggedIn){
         window.location.hash = "/";
         window.location.reload();
       }else {
           AuthClient.getHirarchy().then((data) => {
              // GatewayServiceClient.getAllTenantsWithGateways('updataFirmware').then((response) => {
                filteredTenants.push(data.scope);
                _.forEach(data.children,(value)=>{
                    filteredTenants.push(value.scope);
                })
                _.forEach(filteredTenants,(tenant) => {
                  // _.forEach(response,(value) => {
                  //    if(tenant.id === value.scopeId){
                        tenant.enabledCheckbox=false;
                        filteredTenantsWithGateways.push(tenant);
                    //  }
                  // })
                });
                AuthClient.getScopesLatestVersion('updateFirmware').then((versions) => {
                  if (versions.fileStores){
//                    _.forEach(filteredTenants,(tenant) => {
//                      tenant.versions = versions.fileStores[tenant.id] ? versions.fileStores[tenant.id] : [] ;
//                    })
                    _.forEach(filteredTenantsWithGateways, (tenant) => {
                      tenant.versions = versions.fileStores[tenant.id] ? versions.fileStores[tenant.id] : [] ;
                    })
                  }
                     AuthClient.getAllFirmwareVersions().then((allFirmwareVersions) => {
                       allVersions = _.orderBy(_.uniqBy(allFirmwareVersions, (versn) => versn.version),["date"],["desc"]);
                       if (checkDataExistsOrNot(allVersions)) {
                         defaultValue.push(allVersions[0].version);
                       }
                       setSelectedFirmwareVersion(defaultValue);
                       setDefaultFwVersion(defaultValue);
                       setAllFirmwareVersions(allVersions);
                       setAllGatewayVersions(allVersions);
                       setSelectedGateway(allVersions[0] ? allVersions[0].version : null);
                       setTenants(filteredTenantsWithGateways);
                       setIsLoading(false);
                     });
//                  _.forEach(filteredTenantsWithGateways,(data)=>{
//                      _.forEach(data.versions,(version)=>{
//                        if(version.type === 'RASPI'){
//                          if(JSON.stringify(allVersions).indexOf(version.date) === -1){
//                             allVersions.push(version);
//                          }
//                          data.gatewayVersion = version.version;
//                        }
//                      })
//                  })


                });
              // });
           });
      }
    }

    const handleSuccessForApply = () => {
       setSelectedFirmwareVersion(defaultFwVersion);
       setSelectedTenant([]);
    }

   const handleSuccessForUpload = () => {


   }

    const handleActionRadioChange = (event) => {
      setSelectedRadioAction(event.target.value);
      if (event.target.value === 'Upload') {
        setSelectedFirmwareVersion(defaultFwVersion);
        setSelectedTenant([]);
      } else if (event.target.value === 'Apply') {
         setSelectedTenant([]);
         setDescriptionText("");
         fileUploaded = null;
         let file = document.getElementById("fileInput");
         file.value = "";
         checkIsValidToSubmitOrNot();
      }
    }

    const handleSelectApplyOptionChange = (value,name) => {
      if(name === 'gateway'){
         setSelectedGateway(value);
      }
    }

    const handleDelete = (name) => {
      changeIcon(name);
    };


    const handleChange = (event) => {
      setSearchText(event.target.value);
    }

    const handleSelectAll = () => {
      var selectedTenents = [];
      tenants.map((element) => {
        selectedTenents.push(element.name);
        setSelectedTenant(selectedTenents);
      })
    };

    const handleUnSelectAll = () => {
      var selectedTenents = [];
      setSelectedTenant(selectedTenents);
    };

    const showToastMessage = (message) => {
      setShowSnackBar(true);
      setSnackBarMessage(message);
      setTimeout(() => {
        setShowSnackBar(false);
        setSnackBarMessage("")
      }, 2500);
    }

    const checkIsValidToSubmitOrNot = () => {
       if (fileUploaded) {
           if (window.alreadyExistedVersion === false && window.fileFormatAccepted === true) {
               const firmwareFileReader = new FileReader();
               firmwareFileReader.addEventListener('load', function () {
                  setFileHasUploaded(true);
                  setValidToSubmit(true);
               });
              firmwareFileReader.readAsDataURL(fileUploaded);
           } else {
               setValidToSubmit(false);
               document.getElementById("fileInput").value = "";
               setFileHasUploaded(false);
               if (window.alreadyExistedVersion === true) {
                  setShowSnackBar(true);
                  setSnackBarMessage("Uploaded Gateway version already exists in the tenant.Please choose APPLY instead of UPLOAD");
                  setTimeout(() => {
                     setShowSnackBar(false);
                     setSnackBarMessage("");
                  }, 2000);
               } else if (window.fileFormatAccepted === false) {
                    setShowSnackBar(true);
                    setSnackBarMessage("Invalid version number or file name");
                    setTimeout(() => {
                      setShowSnackBar(false);
                      setSnackBarMessage("");
                    }, 2000);
               }
           }
       } else {
         setValidToSubmit(false);
         setFileHasUploaded(false);
       }
    }

    const handleUploadFile = (event) => {
        if (typeof event.target.files[0] !== 'undefined') {
            fileUploaded = event.target.files[0];
            window.fileUploaded = event.target.files[0];
            let uploadedFileName = fileUploaded !==  null && typeof fileUploaded !== 'undefined' ? fileUploaded.name : null;
            let fileFormateAccepted;
            if (checkDataExistsOrNot(uploadedFileName)) {
               let versionNum = JSON.stringify(uploadedFileName.substring(uploadedFileName.search("Generic_iot.")+12,uploadedFileName.search("Generic_iot.")+22));
               let versionInput = new Date(versionNum).getTime();
               fileFormateAccepted = versionInput > 0 ? uploadedFileName.substring(uploadedFileName.search(".pyc")).length === 4 && gatewayFileNamePattern.test(uploadedFileName) : false;
               window.fileFormatAccepted = fileFormateAccepted;
               window.fileName = uploadedFileName.substring(uploadedFileName.search("Generic_iot.")+12,uploadedFileName.search("Generic_iot.")+22).replaceAll('-',"");
            } else {
               window.fileName = "";
            }

            let alreadyExistedVersion = false;

           if (checkDataExistsOrNot(uploadedFileName)) {
              _.forEach(allGatewayVersions, (versions) => {
                 if (versions.version === Number(uploadedFileName.substring(uploadedFileName.search("Generic_iot.")+12,uploadedFileName.search("Generic_iot.")+22).replaceAll('-',""))) {
                     alreadyExistedVersion = true;
                 }
              })
           }
            window.alreadyExistedVersion = alreadyExistedVersion;
            checkIsValidToSubmitOrNot();
        }
    }

    const getBackgroundColor = (tenant) => {
      let name = tenant + 's';
      var hash = 0;
      for (var i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      var color = (hash & 0x00ffffff).toString(16).toUpperCase();
      return '00000'.substring(0, 6 - color.length) + color;
    };

    const backToTenantPage = () => {
       if(sessionStorage.isRootUser === "true"){
         window.location.hash= "/admin/tenants";
         location.hash= "#/admin/tenants";
       }else {
         window.location.hash= "/cli/dashboard";
         location.hash= "#/cli/dashboard";
       }
    }

    const changeIcon = (name) => {
        name = name.replaceAll('RemoveCircleOutlineIcon', '');
        let selectedTenents =_.cloneDeep(Object.assign([],selectedTenant));
        if (!selectedTenents.includes(name)) {
          selectedTenents.push(name);
          setSelectedTenant(selectedTenents);
        } else {
          var lastIndx = selectedTenents.lastIndexOf(name);
          if (lastIndx > -1) {
            selectedTenents.splice(lastIndx, 1);
          }
          setSelectedTenant(selectedTenents);
        }
    };

    const handleCloseDialog = (value) => {
        _.forEach(tenants,(user) =>{
            _.forEach(data,(val)=>{
                if(user.name===val.name){
                    user.enabledCheckbox=value;
                }
            })
        })
        setControlFirmwareUpdateDialog(value);
        setSelectedTenant([]);
    }

    const controlFirmwareUpdate = (checked,element) => {
        let users=[];
        _.forEach(tenants,(user) => {
            if(user.name === element.name){
                user.enabledCheckbox=checked;
                users.push(user);
            }
        })
        setData(users);

    }

    return (
      <>
        {
          showSnackBar ?
          <Snackbar
            anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
            open={showSnackBar}
            message={snackBarMessage}
          />
        :
         null
        }
        <div style={{height: '90%'}}>
          <div style={{height: '56px'}}>
            <ArrowBack
              sx={{color:"#4190c7"}}
              onClick={() => {
                if(sessionStorage.isRootUser === "true"){
                  window.location.hash= "/admin/tenants";
                  location.hash = "#/admin/tenants"
                }else {
                  window.location.hash= "/cli/dashboard";
                  location.hash= "#/cli/dashboard";
                }
              }}
              className="backArrow"
            />
          </div>
          <div className="container" style={{maxHeight: '95%'}}>
            <div
              className="subContainer"
              style={{
                minHeight: '100%',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <div
                style={{
                  flex: '20',
                  padding: '0 12px',
                  borderBottom: '1px solid rgba(0,0,0,0.12)',
                }}
              >
                <h5 style={{fontSize: '16px'}}>Update Gateway Firmware</h5>
              </div>
              <div className="subBlock" style={{flex: '60'}}>
                <div className="portions">
                  {selectedTenant.length > 0 ? (
                    <div className="selectedBlockExist">
                      {selectedTenant.map((index) => {
                        return(
                          <Chip
                            sx={{marginTop: '8px', marginLeft: '8px'}}
                            label={index}
                            onDelete={() => {handleDelete(index)}}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <Box className="selectedBlock" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                      <span>Select a customer to update the firmware</span>
                    </Box>
                  )}
                  <div className="radioGroup">
                    <FormControl component="fieldset" variant="standard">
                        <RadioGroup
                          defaultValue="Apply"
                          row
                          aria-label="emailType"
                          name="row-radio-buttons-group"
                          onChange={handleActionRadioChange}
                        >
                          <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
                            <div style={{flex:'50%'}}>
                              <FormControlLabel
                                value="Apply"
                                control={
                                  <Radio
                                    sx={{
                                      color: 'rgba(0,0,0,0.54)',
                                      '&.Mui-checked': {
                                        color: '#4190c7',
                                      },
                                    }}
                                  />
                                }
                                label="Apply"
                              />
                              <Tooltip title='Apply existing firmware' placement='top'>
                                <HelpOutline sx={{fontSize:'18px',color:'#0000008a',position:'relative',marginBottom: '-4px',marginLeft:'-12px'}}/>
                              </Tooltip>
                            </div>
                            <div style={{flex:'50%'}}>
                              <FormControlLabel
                                value="Upload"
                                control={
                                  <Radio
                                    sx={{
                                      color: 'rgba(0,0,0,0.54)',
                                      '&.Mui-checked': {
                                        color: '#4190c7',
                                      },
                                    }}
                                  />
                                }
                                label="Upload"
                              />
                             <Tooltip placement="top" title='Upload Firmware' arrow>
                                <HelpOutline sx={{fontSize:'18px',color:'#0000008a',position:'relative',marginBottom: '-4px',marginLeft:'-12px'}}/>
                             </Tooltip>
                            </div>
                          </div>
                        </RadioGroup>
                        <Box
                        className="versionBlock"
                          sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           margin: '8px',
                          }}
                        >
                            <div style={{ display:
                                selectedRadioAction === 'Apply' ? 'block' : 'none', }}
                            >
                               <Box sx={{display: "flex", flexDirection: "column"}}>
                                 <Box className="firmwareUploadColumn" sx={{ display: 'flex', alignItems: 'center', margin: '8px', textAlign: 'center',width:"100%",overflowX:'hidden'}} >
                                     <Box sx={{display:"flex",flexDirection:'row'}}>
                                        <b>Version:</b>
                                     </Box>
                                     <Box sx={{margin: "8px"}} className="firmwareSelect">
                                         <FormControl sx={{ m: 1, width: "100%"}}>
                                            <Select
                                               multiple
                                               variant="standard"
                                               value={selectedFirmwareVersion}
                                               onChange={(event) => handleSelectMenuItem(event.target.value)}
                                               renderValue={(selected) => selected.join(", ")}
                                               MenuProps={MenuProps}
                                            >
                                              {allGatewayVersions.map((version) => (
                                                <MenuItem key={version.id} value={version.version}>
                                                  <Checkbox checked={selectedFirmwareVersion.indexOf(version.version) > -1} />
                                                  <ListItemText primary={handleDisplayText(version)} />
                                                   <div><Tooltip title={version.description} placement='top' arrow>
                                                     <InfoOutlined sx={{display: checkDataExistsOrNot(version.description) ? 'block' : 'none', fontSize:'18px',color:'#0000008a',position:'relative',marginBottom: '-4px',marginLeft:'-12px'}}/>
                                                   </Tooltip></div>
                                                </MenuItem>
                                              ))}
                                            </Select>
                                         </FormControl>
                                     </Box>
                                 </Box>
                                 <Box className="applyAndUploadDiv" sx={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", padding: "5px", margin: "20px 10px 10px 10px"}}>
                                     <Button variant="outlined" disabled={selectedTenant.length !== 0 ? selectedFirmwareVersion.length === 1 ? false : true : true} onClick={() => {handleApplyDialog()}}
                                        style={{color: selectedFirmwareVersion.length === 1 && selectedTenant.length !== 0 ? "#fff" : 'rgba(0,0,0,0.12)', margin: '2px', padding: "5px 20px", fontWeight: "bold", background: selectedFirmwareVersion.length === 1 && selectedTenant.length !== 0 ? '#4190c7' : 'rgba(0,0,0,0.12)'}}>
                                         <CheckCircle style={{marginRight: "5px",fontSize: "22px"}}/> APPLY
                                     </Button>
                                     <Button variant="outlined" disabled={selectedFirmwareVersion.length === 0} onClick={() => setDeleteFirmwareDialog(true)}
                                        style={{color: selectedFirmwareVersion.length !== 0 ? "#fff" : 'rgba(0,0,0,0.12)', margin: '2px', padding: "5px 15px", fontWeight:'600', background: selectedFirmwareVersion.length !== 0 ? '#4190c7' : 'rgba(0,0,0,0.12)'}}>
                                         <Delete style={{marginRight: "5px",fontSize: "22px"}}/> DELETE
                                     </Button>
                                 </Box>
                               </Box>
                            </div>
                            <div
                              style={{
                                display:
                                  selectedRadioAction === 'Upload' ? 'block' : 'none',
                              }}
                            >
                               <Box sx={{display: 'flex',flexDirection:"column",margin: "8px",alignItems: "center"}}>
                                  <Box
                                     sx={{
                                       display: 'flex',
                                       alignItems: 'center',
                                       margin: '8px',
                                       textAlign: 'center',
                                       padding: "10px",
                                       width: "100%"
                                     }}
                                  >
                                  <Tooltip placement="top" title={
                                                    <div style={{ whiteSpace: 'pre-line',fontSize:"10px"}}>
                                                      {"Expected File Name Format: \n CRB_mdm-agent_Generic_iot.yyyy-mm-dd_ENV.cpython-XX.pyc"}
                                                    </div>
                                               } arrow>
                                     <Button
                                         component="label"
                                         variant="outlined"
                                         sx={{
                                           '&.MuiButtonBase-root:hover': {
                                             background: '#4190c7',
                                         },
                                           width: '100%',color: "#fff", margin: '6px',background: '#4190c7'
                                         }}
                                     >
                                          {  fileHasUploaded ? "FILE UPLOADED" : "UPLOAD FILE" }
                                         <input id="fileInput" type="file" accept=".pyc" key={fileUploaded} value={fileUploaded} hidden onChange={(event)=>handleUploadFile(event)} />
                                     </Button>
                                  </Tooltip>
                                  </Box>
                                  <Box sx={{alignItems: 'center', margin: '8px', padding: "10px", textAlign: 'center',width: "100%"}}>
                                       <TextField
                                          inputProps={{ maxLength: 20 }}
                                          label="Description"
                                          variant="standard"
                                          value={descriptionText}
                                          onChange={handleChangeDescription}
                                          sx={{width: '95%'}}
                                       />
                                  </Box>
                                  <Box sx={{margin: '8px', textAlign: 'center'}}>
                                     <Button variant="outlined" disabled={!validToSubmit} onClick={handleUploadDialog}
                                         style={{color: validToSubmit ? "#fff" : 'rgba(0,0,0,0.12)', margin: '2px', padding: "5px 10px", fontWeight:'600', background: validToSubmit ? '#4190c7' : 'rgba(0,0,0,0.12)'}}>
                                          <CloudUpload style={{marginRight: "5px",fontSize: "22px"}}/> UPLOAD
                                     </Button>
                                  </Box>
                               </Box>
                            </div>
                        </Box>
                    </FormControl>
                  </div>
                </div>
                <div
                  className="portions tenantsBlock"
                  style={{
                    margin: 'auto',
                    height: '100%',
                    width: '95%',
                  }}
                >
                  <div className="searchContainer" style={{padding:'7px'}}>
                    <div
                      className="searchBlock"
                      style={{padding: '7px', display: 'initial'}}
                    >
                        <input
                          type="text"
                          onChange={handleChange}
                          placeholder="Search"
                          autoFocus
                          style={{
                            textIndent: '4px',
                            height: '34px',
                            fontSize: '100%',
                            width: '100%',
                          }}
                        />
                    </div>
                    <div style={{display:'flex',flex:'30%'}}>
                        <div style={{display:selectedTenant.length > 0 ? 'block' : 'none', color:'#4190c7', fontSize:'14px',fontWeight:'600',marginTop:'53px',marginLeft:'5px'}}>SELECTIVE UPDATE</div>
                    </div>
                    <div style={{flex:'30%',flexDirection:'column'}}>
                      <div>
                        <Button
                          style={{
                            color: '#4190c7',
                            margin: '2px',
                            fontWeight: '600',
                          }}
                          onClick={() => {handleSelectAll()}}
                        >
                          SELECT ALL
                        </Button>
                      </div>
                      <div>
                        <Button
                          style={{
                            color: '#4190c7',
                            margin: '2px',
                            fontWeight: '600',
                          }}
                          onClick={() => {handleUnSelectAll()}}
                        >
                          UNSELECT ALL
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="allTenants" style={{height:"100%"}}>
                    {
                     !isLoading ?
                       tenants ? tenants
                       .sort((first, next) =>
                         first.name.localeCompare(next.name)
                       )
                       .map((element,index) => {
                         if (index >= 0) {
                            if (
                              element.name
                                .toLowerCase()
                                .lastIndexOf(
                                  searchText.toLowerCase()
                                ) !== -1
                            ) {
                              return(
                                <div
                                  key={element.name}
                                  className="tenantItems"
                                  style={{minHeight: '72px', height: 'auto',
                                  marginBottom: '10px', marginTop: '5px'}}
                                >
                                    <div
                                      style={{
                                        width: '96px',
                                        height: '100%',
                                        alignItems: 'center',
                                        display: 'flex',
                                      }}
                                    >
                                      <Avatar
                                        id="avatar"
                                        style={{
                                          margin: ' 10px 4px 10px 24px',
                                          padding: '4px',
                                          background:
                                            '#' +
                                            getBackgroundColor(
                                              element.name
                                            ),
                                        }}
                                      >
                                        {element.name[0].toUpperCase()}
                                      </Avatar>
                                    </div>
                                    <div
                                      style={{
                                        marginTop: 'auto',
                                        marginBottom: 'auto',
                                        width: '100%',
                                      }}
                                    >
                                      {element.name}
                                      {
                                        <div style={{
                                            marginTop: '5px',
                                            color: 'gray',
                                            fontSize: '12px',
                                        }}>
                                          {
                                            element.versions ?
                                              element.versions.map((value)=>{
                                                return(
                                                  value.type === "RASPI" ?
                                                    <>
                                                      {value.version + " ("+ moment(value.date).format("MMM Do, YYYY h:mm:ss a") + ")"}
                                                    </>
                                                  :
                                                    null
                                                );
                                              })
                                            : null
                                          }
                                        </div>
                                      }
                                    </div>
                                    <div style={{width:'100%',marginTop:'14px',display:selectedTenant.includes(element.name) ? 'block' : 'none'}}>
                                       <Checkbox checked={element.enabledCheckbox}
                                          onClick={(event) => {
                                            controlFirmwareUpdate(event.target.checked,element)
                                            setControlFirmwareUpdateDialog(true)
                                          }}
                                       />
                                    </div>
                                    <div style={{margin: 'auto 30px auto 0px'}}>
                                      {
                                        <>
                                          <Tooltip title='Add Customer' placement='top'>
                                            <AddCircleOutline
                                              id={element.name}
                                              style={{
                                                display:
                                                  selectedTenant.includes(
                                                    element.name
                                                  )
                                                   ? 'none'
                                                   : 'block',
                                                color: 'rgba(0,0,0,0.54)',
                                                cursor:'pointer',
                                              }}
                                              onClick={() =>
                                                changeIcon(element.name)
                                              }
                                            />
                                          </Tooltip>
                                          <Tooltip title='Remove Customer' placement='top'>
                                            <RemoveCircle
                                              className="removeCircle"
                                              style={{
                                                display:
                                                  selectedTenant.includes(
                                                    element.name
                                                  )
                                                    ? 'block'
                                                    : 'none',
                                                color: 'rgba(0,0,0,0.54)',
                                                cursor: 'pointer',
                                              }}
                                              id={
                                                element.name +
                                                'RemoveCircleOutlineIcon'
                                              }
                                              onClick={() =>
                                                changeIcon(
                                                  element.name +
                                                  'RemoveCircleOutlineIcon'
                                                )
                                              }
                                            />
                                          </Tooltip>
                                        </>
                                      }
                                    </div>
                                </div>
                              );
                            }
                         }
                       })
                       :
                         null
                     :
                        <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',minHeight: '72px', height: '100%'}}>
                          <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                        </Box>
                    }
                  </div>
                </div>
              </div>

              <div className="bottomDiv" style={{}}>
                <Button style={{color: '#4190c7', margin: '2px',fontWeight:'600'}} onClick={()=>backToTenantPage()}>
                  BACK TO CUSTOMERS
                </Button>
              </div>
            </div>
          </div>


          {controlFirmwareUpdateDialog ? (
            <>
              <ControlFirmwareUpdateDialog
                show_control_firmware_dialog={controlFirmwareUpdateDialog}
                data = {data}
                closeDialog={(value) => handleCloseDialog(value)}
                dialog = "controlFirmwareUpdateDialog"
              />
            </>
          ) : null}

          {applyFirmwareDialog ? (
              <>
                <ApplyFirmwareDialog
                  show_apply_firmware_dialog={applyFirmwareDialog}
                  handleSuccess = {() => handleSuccessForApply()}
                  allVersions={allFirmwareVersions}
                  allTenants={tenants}
                  selectedTenants = {selectedTenant}
                  firmwareSelected= {selectedFirmwareVersion}
                  closeDialog={() => handleCloseApplyDialog()}
                  dialog = "applyFirmwareDialog"
                />
              </>
          ) : null}

          {deleteFirmwareDialog ? (
              <>
                <ApplyFirmwareDialog
                  show_delete_firmware_dialog={deleteFirmwareDialog}
                  firmwareSelected = {selectedFirmwareVersion}
                  deletedStatus={(val) => window.deletedSuccessfully=val}
                  closeDialog={() => handleCloseDeleteDialog()}
                  dialog = "deleteFirmwareDialog"
                />
              </>
          ) : null}

          {uploadFirmwareDialog ? (
                <>
                  <ApplyFirmwareDialog
                    show_upload_firmware_dialog={uploadFirmwareDialog}
                    allTenants={tenants}
                    selectedTenants={selectedTenant}
                    description={descriptionText}
                    file={window.fileUploaded}
                    fileName={window.fileName}
                    uploadedStatus={(val) => handleUploadedStatus(val)}
                    closeDialog={() => handleCloseUploadDialog()}
                    dialog = "uploadFirmwareDialog"
                  />
                </>
          ) : null}
        </div>
      </>
    );
};

export default UpgradeFirmware;
