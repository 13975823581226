import * as React from 'react';
import { Paper,TextField,Popover,Dialog,Button,DialogActions,DialogContent,DialogTitle,Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,Box,Select,MenuItem,Tooltip,LinearProgress,Icon, Snackbar } from '@mui/material';
import { PersonAddRounded,CheckCircle,Done,Lock,LockOpen,SyncLock,PermIdentity,Download,Edit,Close,Search,Refresh,Settings,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Person,PersonAdd,VerifiedUser,Delete,MailOutline,Wifi, Visibility } from '@mui/icons-material';
import Papa from 'papaparse';
import { IconButton,FormControl,Checkbox,Stack,Autocomplete,FormGroup,FormControlLabel,DialogContentText,Chip,useTheme,useMediaQuery,InputLabel,List,ListItem,ListItemText,ListSubheader,CircularProgress } from '@mui/material';
import { SettingsBackupRestore,AddCircleOutline,RemoveCircle,AssignmentLate,AssignmentTurnedIn,PanTool,FilterList,PresentToAll,Description,PeopleOutline,PersonOutline,ArrowDropDown,ArrowBack,InfoOutlined } from '@mui/icons-material';
import {List as VirtualList} from "react-virtualized/dist/es/List";
import { AutoSizer } from 'react-virtualized';
import CsvDownload from 'react-json-to-csv';
import Draggable from 'react-draggable';
import MqttEventHandler from "./Mqtt/MqttEventHandler";
import '../stylesheet/HomePage.css';
import Permissions from '../components/portalusers/Permissions'
import paginationStyle from "../stylesheet/pagination.module.css";
import "../stylesheet/common.css";
import styles from "../stylesheet/gatewayStatusDialog.module.css";
import Enums from '../constants/Enums';
import _, { first, isError } from 'lodash';
import AuthClient from "../clients/AuthClient";
import IotServiceClient from "../clients/IotServiceClient";
import GatewayServiceClient from "../clients/GatewayServiceClient";
import Moment from 'react-moment';
import moment from 'moment';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import JobSchedulerClient from "../clients/JobSchedulerClient";
import PubSubChannels from '../pubsub/PubSubChannels';
import { useSnackbar } from 'notistack';
import PubSubUtils from '../pubsub/PubSubUtils';

function PaperComponent(props) {
  return (
      <Draggable
          handle="#draggable-dialog-title"
          cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
  );
}

function TableInfoPaginationActions(props) {
  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={paginationStyle.paginationActionIcons}>
          <FirstPage
            onClick={() => {
              if (page > 0) handleFirstPageButtonClick();
            }}
            sx={{
              color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
              cursor: page === 0 ? 'auto' : 'pointer',
              marginRight: '8px'
            }}
          />
          <KeyboardArrowLeft
            onClick={() => {
              if (page > 0) handleBackButtonClick();
            }}
            sx={{
              color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
              cursor: page === 0 ? 'auto' : 'pointer',
              marginRight: '8px'
            }}
          />
          <KeyboardArrowRight
            onClick={() => {
              if (page !== Math.ceil(count / rowsPerPage) - 1)
                handleNextButtonClick();
            }}
            sx={{
              color:
                page === Math.ceil(count / rowsPerPage) - 1
                  ? 'rgba(0,0,0,0.26)'
                  : 'black',
              cursor:
                page === Math.ceil(count / rowsPerPage) - 1 ? 'auto' : 'pointer',
                marginRight: '8px'
            }}
          />
          <LastPage
            onClick={() => {
              if (page !== Math.ceil(count / rowsPerPage) - 1)
                handleLastPageButtonClick();
            }}
            sx={{
              color:
                page === Math.ceil(count / rowsPerPage) - 1
                  ? 'rgba(0,0,0,0.26)'
                  : 'black',
              cursor:
                page === Math.ceil(count / rowsPerPage) - 1 ? 'auto' : 'pointer',
            }}
          />
        </div>
  );
}

export default function DraggableComponent(props) {
  
  const theme = useTheme();
  let mqttOnboardingEventHandler = null;


  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [close, setClose] = React.useState(true);
  
  const [scanCount, setScanCount] = React.useState(5);

  const [isLoading,setLoading] = React.useState(false);

  const [whiteListedLockName, setWhiteListedLockName] = React.useState([]);

  const [scanThresholdCount,setScanThresholdCount] = React.useState(props.data ? props.data.threshold : null);
  
  const [gatewayName, setGatewayName] = React.useState('');
  
  const [gatewayCustomer, setGatewayCustomer] = React.useState('');
  
  const [allCustomers, setAllCustomers] = React.useState([]);
  
  const [allCustomersObj, setAllCustomersObj] = React.useState({});
  
  const [userNetworkLoadingGW, setUserNetworkLoadingGW] = React.useState(false);
  
  const [showSnackBarMessage, setShowSnackBarMessage] = React.useState("");
  
  const [showSnackbar, setShowSnackBar] = React.useState(false);

  const [deviceEdit, setDeviceEdit] = React.useState(false);
  
  const [deviceView, setDeviceView] = React.useState(false);
  
  const [lockUsersEdit, setLockUsersEdit] = React.useState(false);
  
  const [lockUsersView, setLockUsersView] = React.useState(false);
  
  const [gatewaysEdit, setGatewaysEdit] = React.useState(false);
  
  const [gatewaysView, setGatewaysView] = React.useState(false);
  
  const [portalUsersEdit, setPortalUsersEdit] = React.useState(false);
  
  const [portalUsersView, setPortalUsersView] = React.useState(false);
  
  const [portalUsersOwner, setPortalUsersOwner] = React.useState(false);
  
  const [portalUsersDelete, setPortalUsersDelete] = React.useState(false);
  
  const [alertEdit, setAlertsEdit] = React.useState(false);
  
  const [alertsView, setAlertsView] = React.useState(false);
  
  const [firmwareView, setFirmwareView] = React.useState(false);
  
  const [firmwareDelete, setFirmwareDelete] = React.useState(false);
  
  const [hierarchyEdit, setHierarchyEdit] = React.useState(false);
  
  const [hierarchyView, setHierarchyView] = React.useState(false);
  
  const [hierarchyDelete, setHierarchyDelete] = React.useState(false);
  
  const [instructionsView, setInstructionsView] = React.useState(false);
  
  const [instructionsEdit, setInstructionsEdit] = React.useState(false);

  const [userRole, setUserRole] = React.useState(Enums.globals.SUPER_ADMIN_ROLE);

  const [userFirstName, setUserFirstName] = React.useState(props.data ? props.data.firstName : "");
  
  const [userLastName, setUserLastNAme] = React.useState(props.data ? props.data.lastName : "");
  
  const [userEmail, setUserEmail] = React.useState(props.data ? props.data.email : "");
  
  const [userPermission, setuserPermission] = React.useState([]);
  
  const [userNetworkLoading, setUserNetworkLoading] = React.useState(false);
  
  const [permissionData, setPermissionData] = React.useState({});

  const [customPermissionData, setCustomPermissionData] = React.useState({});

  const [justPermissions, setJustPermissions] = React.useState([]);

  const [searchTenantsByNameOrEmail, setSearchTenantsByNameOrEmail] = React.useState('');

  const [modifiedSelection, setModifiedSelection] = React.useState([]);

  const [propsPortalUsersData, setPropsPortalUsersData] = React.useState([]);

  const [alertSelection, setAlertSelection] = React.useState('BATTERY_LOW');

  const [alertTimeInterval, setAlertTimeInterval] = React.useState(3600000);

  const [gatewayLogInterval, setGatewayLogInterval] = React.useState(false);

  const [searchValue, setSearchValue] = React.useState("");

  const [gatewayActivityLogsData, setGatewayActivityLogsData] = React.useState([]);

  const [allActivityLogDates, setAllActivityLogDates] = React.useState([]);

  const [segregatedData , setSegregatedData] = React.useState([]);

  const [ActivityLogsIntervalRange , setActivityLogsIntervalRange] = React.useState("1 hour");

  const [alertsCount, setAlertsCount] = React.useState(0);

  const [eventsCount, setEventsCount] = React.useState(0);

  const [activityLogsLoading, setActivityLogsLoading] = React.useState(false);

  const [showActivityLogPane, setShowActivityLogPane] = React.useState(false);

  const [searchOptions , setSearchOptions] = React.useState([]);

  const [pendingInstructionData, setPendingInstructionData] = React.useState([]);

  const [order, setOrder] = React.useState('asc');  

  const [orderBy, setOrderBy] = React.useState('calories');

  const [selected, setSelected] = React.useState([]);

  const [dense, setDense] = React.useState(false);

  const [checkAll, setCheckAll] = React.useState(false);

  const [page, setPage] = React.useState(0);

  const [pageNumber, setPageNumber] = React.useState(0);

  const [rssiError, setRssiError] = React.useState(false);

  const [rssiHelperText, setRssiHelperText] = React.useState(null);  
  
  const [gatewayStatusMessage, setGatewayStatusMessage] = React.useState(null);

  const [pollIntervalError, setPollIntervalError] = React.useState(false);

  const [pollIntervalHelperText, setPollInetvalHelperText] = React.useState(null);

  const [rssi, setRssi] = React.useState(null);

  const [pollInterval, setPollInterval] = React.useState(null);

  const [showPollTimerApplyButton, setShowPollTimerApplyButton] = React.useState(false);

  const [whiteListMessage, setWhiteListMessage] = React.useState(null);

  const [updateDialogLockUsersData, setUpdateDialogLockUsersData] = React.useState([]);

  const [lockUserEmail , setLockUserEmail] = React.useState("");

  const [lockUserfirstName, setLockUserfirstName] = React.useState("");

  const [lockUserLastName, setLockUserLastName] = React.useState("");

  const [lockUserDivision, setLockUserDivision] = React.useState("");

  const [lockUserMobile, setLockUserMobile] = React.useState("");

  const [lockUserEmployeeId, setLockUserEmployeeId] = React.useState("");

  const [lockUserCardCSN, setLockUserCardCSN] = React.useState("");

  const [enableLockUserUpdateButton, setEnableLockUserUpdateButton] = React.useState(false);

  const [showUserAssignedLocksDialogLoading , setShowUserAssignedLocksDialogLoading] = React.useState(true);

  const [userAssignedLocksData, setUserAssignedLocksData] = React.useState([]);

  const [selectedFile, setSelectedFile] =  React.useState(null);

  const [showStatus, setShowStatus] = React.useState(false);

  const [uploadedUsers, setUploadedUsers] = React.useState(0);

  const [ignoredUsers, setIgnoredUsers] = React.useState(0);

  const [bulkUsersLessThanFiveHundred, setBulkUsersLessThanFiveHundred] = React.useState(true);

  const [callCount , setCallCount] = React.useState(0);

  const [csvAddingUsers,setCSVAddingusers] = React.useState(false);

  const [csvIotUsersUploadedData, setCSVIotUsersUploadedData] = React.useState([]);

  const [showSnackBar, setShowSnackbar] = React.useState("");

  const [showErrorMsg, setShowErrorMsg] = React.useState(false);

  const [errorMsg, setErrorMsg] = React.useState("");

  const [responseMsg, setResponseMsg] = React.useState([]);

  const allSuperAdminPermissions = ["IOT_DEVICE_READ","IOT_DEVICE_WRITE","IOT_DEVICE_BULK_WRITE","PORTAL_USER_READ","PORTAL_USER_WRITE","PORTAL_USER_DELETE","IOT_USER_READ","IOT_USER_WRITE","ALERTS_READ","ALERTS_WRITE","SCOPE_READ","SCOPE_WRITE","SCOPE_UPDATE","SCOPE_DELETE","FIRMWARE_READ","FIRMWARE_WRITE","FIRMWARE_DOWNLOAD","CAN_BE_SCOPE_OWNER","GATEWAY_INSTRUCTIONS_READ","GATEWAY_INSTRUCTIONS_WRITE","GATEWAY_READ","GATEWAY_WRITE","GENERATE_GATEWAY_TOKEN","TOP_LEVEL_SCOPE_READ","APP_DETAILS","MQTT_AUTH","PORTAL_USER_DETAILS","PORTAL_USER_PASSWORD_CHANGE","HIERARCHY_READ"];

  const allTenantSuperAdminPermissions = ["IOT_DEVICE_READ","IOT_DEVICE_WRITE","IOT_DEVICE_BULK_WRITE","PORTAL_USER_READ","PORTAL_USER_WRITE","PORTAL_USER_DELETE","IOT_USER_READ","IOT_USER_WRITE","ALERTS_READ","ALERTS_WRITE","SCOPE_READ","SCOPE_WRITE","SCOPE_UPDATE","SCOPE_DELETE","FIRMWARE_READ","FIRMWARE_DOWNLOAD","CAN_BE_SCOPE_OWNER","GATEWAY_INSTRUCTIONS_READ","GATEWAY_INSTRUCTIONS_WRITE","GATEWAY_READ","GATEWAY_WRITE","GENERATE_GATEWAY_TOKEN","APP_DETAILS","MQTT_AUTH","PORTAL_USER_DETAILS","PORTAL_USER_PASSWORD_CHANGE","HIERARCHY_READ"];

  const allAdminPermissions = ["IOT_DEVICE_READ","PORTAL_USER_READ","IOT_USER_READ","ALERTS_READ","SCOPE_READ","FIRMWARE_READ","GATEWAY_INSTRUCTIONS_READ","GATEWAY_READ","APP_DETAILS","MQTT_AUTH","PORTAL_USER_DETAILS","PORTAL_USER_PASSWORD_CHANGE","HIERARCHY_READ"]

  const [pcToolData, setPcToolData] = React.useState([]);
  
  const [pcToolDataLoading, setPcToolDataLoading] = React.useState(true);

  const [pendingInstructionDataSelectedCount, setPendingInstructionDataSelectedCount] = React.useState(0);

  const [showAbortPendingDialog, setShowAbortPendingDialog] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  
  const [showSpinner, setShowSpinner] = React.useState(false);

  const [showDownloadLink, setShowDownloadLink] = React.useState(false);

  const [downloadLink, setDownloadLink] = React.useState(null);

  const [spinnerTimeoutCount, setSpinnerTimeoutCount] = React.useState(null);

  const [showUnlockAlreadyExistMessage, setShowUnlockAlreadyExistMessage] = React.useState(false);

  const [showRFIDAlreadyExistMessage, setShowRFIDAlreadyExistMessage] = React.useState(false);

  var  gateways = {};

  var timeout = null;

  var debugLogTimeout = null;

  let debugLogAckChannel, debugLogResponseChannel, debugLogAwsUrlChannel = null;

  let whiteListEventChannel , gatewayEventChannel, gatewayActivityLogTopicHandler = null;

  const [valueUpdated, setValueUpdated] = React.useState(true);

  const [batteryLevel, setBatteryLevel] = React.useState(40);

  const [showRssiApplyButton, setShowRssiApplyButton] = React.useState(false);  

  const [showScanFrequencyApplyingLoader, setShowScanFrequencyApplyingLoader] = React.useState(false);

  const [showRssiApplyLoader, setShowRssiApplyLoader] = React.useState(false);  

  const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
      width,
      height,
    };
  };

  const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
  
  const [onBoardingComplted, setOnBoardingCompleted] = React.useState(false);
  
  const [downloadCSVData , setDownloadCsvData] = React.useState([]);
  
  const [scanPassCount, setScanPassCount] = React.useState(0);
  
  const [rowSelectedCount, setRowSelectedCount] = React.useState(0)
  
  const [onboardData, setOnboardData] = React.useState([]);
  
  const [allOnBoardingComplted, setAllOnBoardingComplted] = React.useState(false);  
  
  const [showWhitListUploadCssvDialog, setShowWhitelistUploadCsvDialog] = React.useState(false);

  const [logs, setLogs] = React.useState([]);

  let allSearchOptions = [];

  const [selectedWhitelistCsvFile, setSelectedWhitelistCsvFile] = React.useState(null);
  
  const [rfidAlertDayValue, setRfidAlertDayValue] = React.useState(-1);
  
  const [rfidAlert, setRfidAlert] = React.useState(3600000);
  
  const [rfidAlertText, setRfidAlertText] = React.useState("an hour");
  
  const [rfidCustomValue, setRfidCustomValue] = React.useState(1);

  const [rfidAlertError, setRfidAlertError] = React.useState(false);

  const [enableSaveWhiteListButton, setEnableWhiteListButton] = React.useState(false);

  const [locksAndSerialNumbersCsvData, setLocksAndSerialNumbersCsvData] = React.useState([]);

  const [showDownloadCsvIcon, setShowDownloadCsvIcon] = React.useState(false);

  const [allScannedLocksCSVData, setAllScannedLocksCSVData] = React.useState([]);


  React.useEffect(() => {
    window.locksAndSerialNumbersData = [];
    if (props.button_name !== 'Create') {
      if (props.hasOwnProperty('portal_users_data')) {
        let selectedTenantsForAlert = [];
        _.forEach(props.portal_users_data,(allReceivers)=>{
          _.forEach(props.data.receivers,(selectedUser)=>{
              if (props.title === "Update Alert"){
                if (allReceivers.email === selectedUser){
                  selectedTenantsForAlert.push(allReceivers);
                }
              }else {
                if (allReceivers.id === selectedUser){
                  selectedTenantsForAlert.push(allReceivers);
                }
              }
          })
        })
        setPropsPortalUsersData(selectedTenantsForAlert);
      }      
      if (props.data ? props.data.alertType === "RFID" : false) {
        let rfidAlertData = props.data;
        let scheduleTime = Number(rfidAlertData.rfidScheduleTime) 
        let alertText = scheduleTime / 3600000 === 1 ? 'an hour' : (scheduleTime / 3600000 )% 24 === 0 ?  (scheduleTime / 3600000 ) / 24 === 1 ? 'in a day' : "in " + (scheduleTime / 3600000 ) / 24 +" days": "in "+ Number(scheduleTime) / 3600000 + " hours"
        let customValue = 1;
        setRfidAlertText(alertText);
        setRfidAlert(scheduleTime);
        
        if (scheduleTime % 86400000 === 0){
          customValue = scheduleTime / 8640000;
        }
        setRfidCustomValue(customValue);        
      }      
    } else {
      setPropsPortalUsersData([]);
    }    
    if (props.data ? props.data.hasOwnProperty("alertType") : false){
        props.button_name === 'Create'
          ? setAlertSelection('BATTERY_LOW')
        : (
            props.data.alertType === "UNLOCK" ?
              setAlertSelection('UNLOCK')
            :
              setAlertSelection('RFID')
          );          
    }
    return () => {
      IotServiceClient.abortSignal("lockUsersPageAssignedLocks");
      IotServiceClient.abortSignal("draggableComponentPcToolHistory");
      IotServiceClient.abortSignal("draggableComponentDeviceLogUrl");
    }
  }, []);

  React.useEffect(() => {
    changeRole(userRole);
  }, [userRole]);

  React.useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize);
    if (props.dialog === "onBoardDialog"){
      handleOnBoardData()
    }

    if (props.hasOwnProperty('data') && props.dialog === "infoDialog") {
      setGatewayName(props.data.name);
      var tenantDetails = {};
      AuthClient.getHirarchy().then((data) => {
        var root_temp = data['scope'];
        var child_temp = data['children'];
        var key = root_temp.name;
        var val = root_temp.id;
        tenantDetails[key] = val;

        setGatewayCustomer(sessionStorage.tenantName);
        child_temp.forEach((ele) => {
          var key = ele['scope'].name;
          var val = ele['scope'].id;
          tenantDetails[key] = val;
          if(key === sessionStorage.tenantName) {
            setGatewayCustomer(key);
          }
        });
        setAllCustomersObj(tenantDetails);
      });
    }

    if (props.title !== 'Create Portal User') {
      if (
          props.hasOwnProperty('firstName') &&
          props.hasOwnProperty('lastName') &&
          props.hasOwnProperty('email')
      ) {
        setUserFirstName(props.data.firstName);
        setUserLastNAme(props.data.lastName);
        setUserEmail(props.data.email);
      }
    }
    
    if (props.dialog === "downloadLockCSVDialog"){
      fetchAllLockDataForCsv()
    }
    if (props.dialog === "downloadLockUsersCSVDialog"){
      fetchAllLockUsersDataForCsv()
    }

    if (props.dialog === "permissionDialog"){
      processPermissionsData();
    }


    if (props.dialog){
      if(props.dialog === "gatewayLogDialog"){
          //GET GATEWAY ACTIVITY LOG DATA
          let toTime = new Date().getTime();
          let fromTime = toTime - 3600000;
          window.activityLogInterval = "1 hour";
          fetchActivityLogs(fromTime, toTime);
      }

      if (props.dialog === "pendingInstructionDialog") {
        enqueueSnackbar("Fetching Pending instructions");
        fetchPendingInstructions();
        subscribeToPendingInstructionChannels();
      }
      if (props.dialog === "pcToolHistory"){          
        IotServiceClient.getPcToolHistory("draggableComponentPcToolHistory").then((response)=>{
          setPcToolData(response);      
          setPcToolDataLoading(false);
        })
      }
    }

    checkIsMqttConnected();
    if (props.dialog === "updateOrCreateDialog") {
      let data = Object.assign([], props.data);
      setUpdateDialogLockUsersData(data);
      setLockUserEmail(data.email);
      setLockUserfirstName(data.firstName);
      setLockUserLastName(data.lastName);
      setLockUserDivision(data.division);
      setLockUserMobile(data.mobileNo);
      setLockUserEmployeeId(data.employeeId);
      setLockUserCardCSN(data.cardCSN);
    }else if (props.dialog === "showUserAssignedLocksDialog"){
      setShowUserAssignedLocksDialogLoading(true);
      let userId = props.data.id;
      IotServiceClient.getUserAssignedLocks(userId, sessionStorage.tenantId, "lockUsersPageAssignedLocks").then((response)=>{
        setUserAssignedLocksData(response);
        setShowUserAssignedLocksDialogLoading(false);
      });
    }else if (props.dialog === "cannotDeleteLockUser"){
      let userId = props.data.id;
      IotServiceClient.getUserAssignedLocks(userId, sessionStorage.tenantId, "lockUsersPageAssignedLocks").then((response)=>{
        setUserAssignedLocksData(response);
      });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (mqttOnboardingEventHandler){
        mqttOnboardingEventHandler.unsubscribe()
      }
      if (window.mqttDeviceInstructionsEventHandler) {
        window.mqttDeviceInstructionsEventHandler.unsubscribe();
      }
      if (window.mqttUsersInstructionEventHandler){
        window.mqttUsersInstructionEventHandler.unsubscribe();
      }
      if (window.mqttInstructionCreatedEventHandler){
        window.mqttInstructionCreatedEventHandler.unsubscribe()
      }
      if(props.dialog === "infoDialog"){
        if (debugLogAckChannel){
          debugLogAckChannel.unsubscribe();
        }
        if (debugLogResponseChannel){
          debugLogResponseChannel.unsubscribe();
        }
        if(debugLogAwsUrlChannel){
          debugLogAwsUrlChannel.unsubscribe();
        }
      }
      if (props.dialog === "whoteListDialog") {
          if (whiteListEventChannel) {
            whiteListEventChannel.unsubscribe();
          }
      }
      if(props.dialog === "gatewayLogDialog") {
        if (gatewayEventChannel) {
          gatewayEventChannel.unsubscribe();
        }
        if (gatewayActivityLogTopicHandler) {
          gatewayActivityLogTopicHandler.unsubscribe();
        }
      }
      if (window.activityLogs){
        delete window.activityLogs;
      }
      if (window.activityLogsEventsCount) {
        delete window.activityLogsEventsCount;
      }
      if (window.activityLogsAlertsCount) {
        delete window.activityLogsAlertsCount;
      }

      if (window.activityFeedfromTime) {
        delete window.activityFeedfromTime;
      }
      if (window.activityLogInterval) {
        delete window.activityLogInterval;
      }
    }
  }, []);

  React.useEffect(()=>{
    if (props.dialog === "whiteListDialog" && props.data ? (!_.isEqual(props.data.listOfWhiteListedLocks, window.whiteListedLockName) || (!_.isEqual(props.data.threshold, window.scanThresholdCount)) ) : false) {
        setWhiteListedLockName(props.data.listOfWhiteListedLocks);
        setScanThresholdCount(props.data.threshold);
        window.whiteListedLockName = props.data.listOfWhiteListedLocks;
        window.scanThresholdCount = props.data.threshold;
    }
  },[props])

  const [rowsPerPage, setRowsPerPage] = React.useState( props.hasOwnProperty("dialog") ? props.dialog === "pcToolHistory" ? (sessionStorage.pcToolHistoryRPP ? Number(sessionStorage.pcToolHistoryRPP) : 25) : (props.dialog === "pendingInstructionDialog" ? (sessionStorage.pendingInstructionsRPP ? Number(sessionStorage.pendingInstructionsRPP) : 5) : (props.dialog === "showUserAssignedLocksDialog" ? (sessionStorage.assignedUSersRPP ? Number(sessionStorage.assignedUSersRPP) : 5) : 5) ) : (sessionStorage.noDialogRPP ? Number(sessionStorage.noDIalogRPP) : 5));

  let onBoardCsvData =  [];



  const accessKeySecret = {
    "key" : "AKIAIQMQZTXRD32TQH3Q",
    "secret": "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"
  };  

  const unsubscribeChannel = () => {
    setShowSpinner(false);
    if (debugLogAckChannel){
      debugLogAckChannel.unsubscribe();
    }
    if (debugLogResponseChannel){
      debugLogResponseChannel.unsubscribe();
    }
    if(debugLogAwsUrlChannel){
      debugLogAwsUrlChannel.unsubscribe();
    }
  }

  const startTimeoutProcess = (initial) => {
    if (!initial){
      clearTimeout(debugLogTimeout);
    }
    let setTimeoutTime = 1000 * 60;
    if (props.data.appVersion > 158){
      setTimeoutTime = 1000 * 90;
    }

    debugLogTimeout = setTimeout(()=>{
      unsubscribeChannel();
      enqueueSnackbar("Requesting device logs timed out");
    },setTimeoutTime);
  }


  const timeoutCounter = (message) => {
    let timeoutMessage = message.toString();
    let timeoutPayload = JSON.parse(timeoutMessage);
    let timeoutPercentage = (100 / timeoutPayload.totalChunkCount)*timeoutPayload.currentChunkCount;
    let count = timeoutPercentage > 0 && timeoutPercentage <=100 ? (timeoutPercentage === 100 ? (timeoutPercentage - (timeoutPercentage*5/100)) + " %" :   timeoutPercentage + " %") : "";
    setSpinnerTimeoutCount(count);
    startTimeoutProcess(null);
  }

  const handleDeviceLogResponse = (message) => {
    let deviceResponse = message.toString().trim();
    let deviceLogUploadResponse = JSON.parse(deviceResponse);
    if(deviceLogUploadResponse.status === 'success'){
        var info = {
            "macId": props.data.macId,
            "uploaded_file_key": deviceLogUploadResponse.filename,
            "elk_status": "OFF"
        };
        IotServiceClient.saveDeviceLogs(info).then(()=>{
          enqueueSnackbar("Device logs saved successfully");
          IotServiceClient.fetchDeviceLogsUrl(info.uploaded_file_key, "draggableComponentDeviceLogUrl").then((response)=>{
            if (response.status === 200){
              let data = response.data;
              setShowSpinner(false);
              let devicesLogsPayload = data;
              let lenDevicesLogsPayload = data.length;
              let latestPayload = data[lenDevicesLogsPayload - 1];
              setShowDownloadLink(true);
              setDownloadLink(latestPayload.url)
              enqueueSnackbar("Download link is ready, please click on icon");
            }else {
              setShowSpinner(false);
              enqueueSnackbar("Failed to fetch logs URL");
            }
          })
        }).catch(()=>{
          setShowSpinner(false);
          enqueueSnackbar("Failed to fetch logs URL");
        })
    }
  }

  const handleDebugLogUrl = (message) => {
    let debugLogsEventPayload = null;
    setShowSpinner(false);
    if (message === ""){
        enqueueSnackbar("Failed to fetch logs URL");
    }else {
        debugLogsEventPayload = JSON.parse(message);
        setShowDownloadLink(true);
        enqueueSnackbar("Download link is ready, please click on icon");
        setDownloadLink(debugLogsEventPayload.url);
    }
  }

  const pullDeviceLogs = () => {
    let msg = JSON.stringify(accessKeySecret);
    setShowSpinner(true);
    setShowDownloadLink(false);
    if (props.data.appVersion > 158 ){
      setSpinnerTimeoutCount(0 + " %");
    }
    startTimeoutProcess(true);
    enqueueSnackbar("Requesting device ...")
    MqttEventHandler.publish(PubSubChannels.pubsub_channels.PULL_DEVICE_LOGS.replace("macId",props.data.macId), msg , {qos: 2, retain: false}, function() {});
    debugLogAckChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_DEBUG_LOGS_UPLOADED_PERCENTAGE.replace("macId",props.data.macId), "info", timeoutCounter);
    debugLogResponseChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.PULL_DEVICE_LOGS_RESPONSE.replace("macId",props.data.macId), "info", handleDeviceLogResponse);
    debugLogAwsUrlChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GET_DEBUG_LOG_AWS_URL.replace("macId",props.data.macId), "info", handleDebugLogUrl);
  }

  const selectAllPendingInstructions = (event) => {
    let checked = event.target.checked;
    let modifiedData = [];
    let checkedCount = 0;
    _.forEach(pendingInstructionData,(data)=>{
      data.isChecked = checked;
      if (data.isChecked){
        checkedCount+=1;
      }
      modifiedData.push(data);
    })
    setPendingInstructionDataSelectedCount(checkedCount);
    setPendingInstructionData(modifiedData);
  }

  const unselectPendingInstruction  = (event, row) => {
    let checked = event.target.checked;
    let modifiedData = [];
    let checkedCount = 0;
    _.forEach(pendingInstructionData,(data)=>{
      if(data.instructionId === row.instructionId){
        data.isChecked = checked;
      }
      if (data.isChecked){
        checkedCount+=1;
      }
      modifiedData.push(data);
    })
    setPendingInstructionDataSelectedCount(checkedCount);
    setPendingInstructionData(modifiedData);
  }

  const abortInstruction = () => {
    setShowAbortPendingDialog(true);
  }

  const handleAbortPendingInstruction = () => {
    props.abortInstructions(pendingInstructionData);
    setTimeout(() => {
      handlePendingInstructionsDialog();
    }, 1000);
  } 
  
  const handleResize = () => {
    setDimensions(getWindowDimensions());
  }  

  //height and width logic ends

  const handle_close_dialog = () => {
    props.handleCloseDialogBox(false);
    setClose(false);
  };

  let showDialog = function () {
    if (close === false) {
      setClose(true);
    }
    props.iconClicked(true);
  };

  const processPermissionsData = () => {
    const data = { ...props.data };
      if (props.role && !props.disable){
        if (sessionStorage.tenantId === Enums.globals.ADMIN_TENANT_ID){
          data.permissions = allSuperAdminPermissions;
        } else {
            if (props.role === Enums.globals.SUPER_ADMIN_ROLE) {
                data.permissions = allTenantSuperAdminPermissions;
            } else if (props.role === Enums.globals.ADMIN_ROLE) {
                data.permissions = allAdminPermissions;
            }
        }
      } else {
        if (sessionStorage.tenantId === Enums.globals.ADMIN_TENANT_ID){
          data.permissions = allSuperAdminPermissions;
        } else {
            data.permissions = allTenantSuperAdminPermissions;
        }
      }
      if(props.data.permissions) {
        if (props.data.permissions.length >= 27) {
            setUserRole(Enums.globals.SUPER_ADMIN_ROLE);
        } else if (props.data.permissions.length == 13) {
            setUserRole(Enums.globals.ADMIN_ROLE);
        } else {
            setUserRole(Enums.globals.CUSTOM_ROLE);
        }
      }
      setPermissionData({ ...data });
      let originalPropsData = { ...props.data };
      setCustomPermissionData({ ...originalPropsData });      
      if (originalPropsData.hasOwnProperty("permissions")){
        setJustPermissions([ ...originalPropsData.permissions ]);
      }else {
        setJustPermissions([]);
      }
  }

  //onBoard logic

  const handleShowOnBoardDialogClose = () => {
    props.close_on_board_dialog(false);
  };

  const handleScanCount = (event) => {
    setScanCount(event.target.value);
  };  

  const handleOnBoardData = () => {
    let filteredData = [];
    let data = Object.assign([],props.rowsSelected);
    _.forEach(data, (val)=> {
      val.isLoading = false;
      val.onBoardingComplted = false;
      val.successScanCount = 0;
      val.scanPassCount = 0;
      filteredData.push(val);
    })
    setOnboardData(filteredData);
  }
  
  const generateCSV = (data) =>{
    let csvData = {};
    
    _.forEach(data.locksDetails,(value)=>{
      if (data.pass === "1"){  
        csvData["Gateway Id"] = data.macId;      
        csvData["Lock Name"] = value.lockName;
        csvData["Lock MacId"] = value.macId;
        csvData["Scan"+data.pass+" Rssi"] = value.rssiValue;      
        onBoardCsvData.push(csvData);
        csvData = {};
      }      

      if (data.pass > 1){
        _.forEach(onBoardCsvData,(object)=>{
          if(object["Lock MacId"] === value.macId && object["Gateway Id"] === data.macId){
            object["Scan"+data.pass+" Rssi"] = value.rssiValue;
          }
        })

        if(JSON.stringify(onBoardCsvData).indexOf(value.macId) === -1){
          csvData["Gateway id"] = data.macId;      
          csvData["Lock Name"] = value.lockName;
          csvData["Lock MacId"] = value.macId;
          csvData["Scan"+data.pass+" Rssi"] = value.rssiValue;      
          onBoardCsvData.push(csvData);
          csvData = {};
        }
      }
    })

    if (data.pass === ""+scanCount){          
      let filterData = [];  
      let allCompletedCount = 0;
      _.forEach(onboardData, (val)=>{
        if (val.macId === data.macId){
          val.onBoardingComplted = true;
        }
        if (val.onBoardingComplted){
          allCompletedCount++;
        }
        filterData.push(val);
      })
      setOnboardData(filterData);
      if (allCompletedCount === onboardData.length){
        setDownloadCsvData(onBoardCsvData);
        setAllOnBoardingComplted(true);
      }      
    }
  }

  var onGatewayOnboadingEventReceived = function (data) {
    var gatewayScanDetails = JSON.parse(data.toString());
    var gatewayId = gatewayScanDetails.macId;
    var scanCount1 = gatewayScanDetails.pass;
    setScanPassCount(scanCount1);
    var scannedLocks = gatewayScanDetails.scannedLocks;    
    generateCSV(gatewayScanDetails);

    if(scanCount1 === "1"){
      if(document.getElementById("pass1"+gatewayId))
        document.getElementById("pass1"+gatewayId).innerHTML = scannedLocks;
    }else if(scanCount1 === "2"){
      if(document.getElementById("pass2"+gatewayId))
        document.getElementById("pass2"+gatewayId).innerHTML = scannedLocks;
    }else if(scanCount1 === "3"){
      if(document.getElementById("pass3"+gatewayId))
        document.getElementById("pass3"+gatewayId).innerHTML = scannedLocks;
    }else if(scanCount1 === "4"){
      if(document.getElementById("pass4"+gatewayId))
        document.getElementById("pass4"+gatewayId).innerHTML = scannedLocks;
    }else if(scanCount1 === "5"){
      if(document.getElementById("pass5"+gatewayId))
        document.getElementById("pass5"+gatewayId).innerHTML = scannedLocks;
    }

    let filtereDevicePassCount = [];
    _.forEach(onboardData, (val)=> {
      if (val.macId === gatewayId){
        val.scanPassCount = gatewayScanDetails.pass;
      }
      filtereDevicePassCount.push(val);
    })
    setOnboardData(filtereDevicePassCount);

    if(scanCount1 === ""+scanCount){
      setLoading(false);
      let filteredData = [];
    _.forEach(onboardData, (val)=> {
        if (val.macId === gatewayId){
          val.isLoading = false;
          val.onBoardingComplted = true;
        }
        filteredData.push(val);
      })
      setOnboardData(filteredData);
      setOnBoardingCompleted(true);
    }

    gateways[gatewayId].scanCount = scanCount1;
    if (gateways[gatewayId].scannedData === undefined) {
      gateways[gatewayId].scannedData = [];
    }
    _.forEach(gateways, function (gateway) {
      if (gateway.macId === gatewayId) {
        gateway.scanCount = scanCount1;
        if (gateways[gatewayId].scannedData[scanCount1 - 1] === undefined) {
          gateway.scannedData.push(gatewayScanDetails);
        }
      }
    });

  };

  const startonBoarding = (selectedGateways) => {
    setAllOnBoardingComplted(false);
    let filteredData = [];
    _.forEach(onboardData, (val)=> {
      val.isLoading = true;      
      filteredData.push(val);
    })
    setOnboardData(filteredData);

    window.onBordScanCompletedCount =  window.onBordScanCompletedCount ? window.onBordScanCompletedCount+1 : 1;
    setOnBoardingCompleted(false);
    const commandObj = {
      'command': 'SCAN',
      "totalScanCycles": scanCount
    };        

    setLoading(true);
    _.forEach(selectedGateways,function(gateway){
      gateways[gateway.macId] = gateway;      
      window.onboard = gateways;
      window.onboard[gateway.macId] = gateway;
      MqttEventHandler.publish("gateways/"+gateway.scopeId+"/onboarding/command/"+gateway.macId,JSON.stringify(commandObj), {qos: 2, retain: false}, function() {});
      let activityLogPayload = {
        "macId" : gateway.macId,
        "message" : "Onboarding initiated from portal",
        "date": new Date().getTime(),
        "type": "event",      
      }
      GatewayServiceClient.createLockUnlockEntry(activityLogPayload).then((response) => {})

      mqttOnboardingEventHandler = MqttEventHandler.subscribe("gateways/"+gateway.scopeId+"/onboarding/status/"+gateway.macId, "jj", onGatewayOnboadingEventReceived);
    });
  };


  const stoponBoarding = (selectedGateways) => {    
    setAllOnBoardingComplted(false);
    _.forEach(selectedGateways,function(gateway){
      gateways[gateway.macId] = gateway;
      window.onboard = gateways;
      let activityLogPayload = {
        "macId" : gateway.macId,
        "message" : "Onboarding stopped from portal",
        "date": new Date().getTime(),
        "type": "event",      
      }
      GatewayServiceClient.createLockUnlockEntry(activityLogPayload).then((response) => {})
      window.onboard[gateway.macId] = gateway;
      MqttEventHandler.publish("gateways/"+gateway.scopeId+"/onboarding/command/stop"+"", {qos: 2, retain: false}, function() {});
    });
    handleShowOnBoardDialogClose();
  };

  // gateway log dialog

  const handleShowGatewayLogDialog = () => {
    props.close_gateway_log_dialog(false);
  };

  const handleWhiteListDialog = (value) => {
    props.open_whiteList_dialog(true);
  };

  const handleForceProbeDialog = () => {
    props.open_force_probe_dialog(true);
  }

  const handleRestartDialog = (value) => {
    props.open_restart_dialog(value);
  };

  const handleClearBlackListDialog = (value) => {
    props.open_clear_whiteList_dialog(value);
  };
  const handleWhiteListNames = (event,lockNames) => {
    let allLocks = [];    
    let containsStar = false;
    lockNames.forEach((element) => {
      if (element === "*"){
        containsStar = true;
      }
      allLocks.push(element);
    });
    setWhiteListedLockName(containsStar ? ["*"] : allLocks);
  };

  const saveWhiteList = () => {
    let gateway = {};
    gateway.macId = props.data.macId;
    gateway.noOfWhiteListedDevices = whiteListedLockName.length;
    gateway.listOfWhiteListedLocks = whiteListedLockName;
    gateway.threshold = (whiteListedLockName.length > 0 && whiteListedLockName[0] !== "*") ? scanThresholdCount : 10;
    gateway.editing = false;
    gateway.requestFromFrontend = true;
    gateway.status = props.data.status;
    if ( gateway.noOfWhiteListedDevices === 1 && whiteListedLockName[0] === "*") {
        gateway.noOfWhiteListedDevices = -1;
    }
    props.updateGateway(gateway);
    publishMessage("gateways/"+props.data.macId+"/receive/devices/whitelist",props.data.macId,"sendwhitelist");
    let payload = {
      "macId" : props.data.macId,
      "message" : "Whitelist modified",
      "date": new Date().getTime(),
      "type": "event",      
    }              
    GatewayServiceClient.createLockUnlockEntry(payload).then((response) => {})
    props.close_whiteList_dialog(false);
  };

  const readCsvFile = () => {
    setShowErrorMsg(false);
    setErrorMsg("");
    let fileReader = new FileReader();
    let uploadedFile = document.getElementById('uploadCSV');
    let filteredLockNames = [];
    fileReader.readAsText(uploadedFile.files[0]);
    whiteListedLockName.forEach((element) => {
      filteredLockNames.push(element);
    });

    fileReader.onload = () => {
      let lockNames = fileReader.result.split('\n');
      let csvHasStart = false;
      if(lockNames[0] !== 'Lock Name' && lockNames[0] !== ''){
        setShowErrorMsg(true);
        setErrorMsg("Headers of selected csv file are not valid, please check and retry");
        return;
      } else if(lockNames[0] === ''){
        setShowErrorMsg(true);
        setErrorMsg("Uploaded file is empty, please check and retry.");
      } else if(lockNames[1] === ''){
        setShowErrorMsg(true);
        setErrorMsg("Uploaded file is empty, please check and retry.");
      }
      else {
        lockNames.forEach((element, index) => {
          if (index === 0) {
            if (element === 'Lock Name') {
              return;
            }
          }
          if (whiteListedLockName.toString().indexOf(element) === -1) {
            if (element  === "*"){
              csvHasStart = true;
            }
            filteredLockNames.push(element);
          }
        });
        if (csvHasStart){
          filteredLockNames = ["*"];
        }
        filteredLockNames = _.uniq(filteredLockNames.sort());
        setWhiteListedLockName(filteredLockNames);
        setShowWhitelistUploadCsvDialog(false);
      }
    };
    setSelectedWhitelistCsvFile("");
  };

  const fetchAllLockDataForCsv = () => {
    let allLockCsvData = [];
    IotServiceClient.getLockInfo(sessionStorage.tenantId).then((locks) => {
      let getCsvArray = [];      
      let singleEntry = [];
      let propertiesForCsv = [
        "macId", "name", "batteryLevel", "firmwareVersion", "status", "serialNumber", "type",
        "lastAccessedTime", "time", "firstName", "lastName", "email", "emailHash", "passcode",
        "employeeId", "division", "role", "userStatus", "city", "country", "ipAddress", "timeZone"
      ];   

      let locksArray = [];
      _.forEach(locks, function(data) {
          _.forEach(data.iotUsers, function(userData) {
              let lock = {};
              lock.device = Object.assign([],data.iotDevice);
              lock.user = Object.assign([],userData);
              locksArray.push(lock);
          });
      });

      _.forEach(locksArray, function(data) {                 

          if (data.device) {
              data.macId = data.device.macId;
              data.name = data.device.name;
              data.batteryLevel = data.device.batteryLevel;
              data.firmwareVersion = data.device.firmwareVersion;
              data.status = data.device.status;
              data.serialNumber = data.device.serialNumber;
              data.type = data.device.type;
              data.lastAccessedTime = data.device.lastAccessedTime;
              data.userStatus = data.device.status;
              if (data.device.lastAccessedTime) {
                  data.time = moment(data.device.lastAccessedTime).format('YYYY MMM DD, h:mm:ss A');
              }
              if (data.device.location) {
                  data.city = data.device.location.city;
                  data.country = data.device.location.country;
                  data.ipAddress = data.device.location.ipAddress;
                  data.timeZone = data.device.location.timeZone;
              }
          }
          if (data.user) {
              if (data.user.iotUser) {
                  data.firstName = data.user.iotUser.firstName;
                  data.lastName = data.user.iotUser.lastName;
                  data.email = data.user.iotUser.email;
                  data.emailHash = data.user.iotUser.emailHash;
                  data.employeeId = data.user.iotUser.employeeId;
                  data.division = data.user.iotUser.division;
                  data.userStatus = data.user.iotUser.status;
              }
              data.passcode = data.user.passcode;
              data.role = data.user.role;
          }

          let allData = {};
          _.forEach(propertiesForCsv, function(property) {              
              if (data[property]) {                
                allData[property] = data[property];  
              } else {
                allData[property] = "-";
              }              
          });
          allLockCsvData.push(allData);
      });          
      props.closeCsvDialog(allLockCsvData);        
    });    
  }



  const fetchAllLockUsersDataForCsv = () => {
    let allLockUsersCsvData = [];
    IotServiceClient.getLocksUsersInfo(sessionStorage.tenantId).then((users) => {

      let usersArray = [];
      let getCsvArray = [];
      let propertiesForCsv = [
          "firstName", "lastName", "email", "emailHash", "passcode", "employeeId", "division", "role",
          "status", "macId", "name", "batteryLevel", "firmwareVersion", "lockStatus", "serialNumber",
          "type", "lastAccessedTime", "time", "city", "country", "ipAddress", "timeZone"
      ];
      let singleEntry = [];

      _.forEach(users, function(data) {
          _.forEach(data.iotDevices, function(deviceData) {
              var user = {};
              user.userInfo = Object.assign([],data.iotUser);
              user.device = Object.assign([],deviceData);
              user.location = Object.assign([],deviceData.iotDevice.location);
              usersArray.push(user);
          });
      });

      
      _.forEach(usersArray, function(data) {          

          if (data.userInfo) {
              data.firstName = data.userInfo.firstName;
              data.lastName = data.userInfo.lastName;
              data.email = data.userInfo.email;
              data.emailHash = data.userInfo.emailHash;
              data.employeeId = data.userInfo.employeeId;
              data.division = data.userInfo.division;
              data.status = data.userInfo.status;
          }
          if (data.device) {
              if (data.device.iotDevice) {
                  data.macId = data.device.iotDevice.macId;
                  data.name = data.device.iotDevice.name;
                  data.batteryLevel = data.device.iotDevice.batteryLevel;
                  data.firmwareVersion = data.device.iotDevice.firmwareVersion;
                  data.lockStatus = data.device.iotDevice.status;
                  data.serialNumber = data.device.iotDevice.serialNumber;
                  data.type = data.device.iotDevice.type;
                  data.lastAccessedTime = data.device.iotDevice.lastAccessedTime;
                  if (data.device.iotDevice.lastAccessedTime) {
                      data.time = moment(data.device.iotDevice.lastAccessedTime).format('YYYY MMM DD, h:mm:ss A');
                  }
              }
              data.role = data.device.role;
              data.passcode = data.device.passcode;
          }
          if (data.location) {
              data.city = data.location.city;
              data.country = data.location.country;
              data.ipAddress = data.location.ipAddress;
              data.timeZone = data.location.timeZone;
          }

          let allData = {};
          _.forEach(propertiesForCsv, (property) =>{
              if (data[property]) {
                allData[property] = data[property];
              } else {
                allData[property] = "-"; 
              }
          });
          allLockUsersCsvData.push(allData);
      });          
      props.closeCsvDialog(allLockUsersCsvData);        
    });

  }

  const handleInfoDialog = () => {
    props.close_info_dialog(false);
  };

  const handleUpdateGateway = (gateway) => {
    setUserNetworkLoadingGW(true);
    let registerPayload = {
      "registerMe":0,
      "name": gatewayName,
      "macId": gateway.macId,
      "status": gateway.status ? gateway.status === "CONNECTED" ? "connected" : "disconnected" : "disconnected"
    }
    gateway.name = gatewayName;
    // user should not register until gateway is online
    if (gateway.registerMe === 1 && gateway.status ? gateway.status === "CONNECTED" : false) {
      GatewayServiceClient.registerGateway(res => {
        if (allCustomersObj[gatewayCustomer] !== gateway.scopeId) {
          var data = {
            "id": gateway.id,
            "scopeId": allCustomersObj[gatewayCustomer]
          }
          assignScope(data);
          
        } else {
          if (res.status === 200){
            setUserNetworkLoadingGW(false);
            props.close_info_dialog(false);
          } else {
            setUserNetworkLoadingGW(false);
          }      
        }var data = {
          "id": gateway.id,
          "scopeId": allCustomersObj[gatewayCustomer]
        }
        assignScope(data);
      }, registerPayload)
    }

    if (gateway.registerMe === 0 || (gateway.registerMe === 1 && gateway.status ? gateway.status !== "CONNECTED" : false)) {
      let filteredGateway = _.cloneDeep(gateway);
      filteredGateway.noOfWhiteListedDevices = filteredGateway.noOfWhiteListedDevices === "*" ? -1 : filteredGateway.noOfWhiteListedDevices;
      GatewayServiceClient.updateGateway(response => {
        if (allCustomersObj[gatewayCustomer] !== gateway.scopeId) {
          var data = {
            "id": gateway.id,
            "scopeId": allCustomersObj[gatewayCustomer]
          }

          assignScope(data);

        } else {
          if (response.status === 200){
            setUserNetworkLoadingGW(false);
            enqueueSnackbar("The device details have been successfully updated");
            props.close_info_dialog(false);            
          } else {
            enqueueSnackbar("Error in updating gateway details");
            setUserNetworkLoadingGW(false);
          }          
        }
      }, filteredGateway);
    }

  };

  const assignScope = (data) => {
    GatewayServiceClient.assignScope(res => {
      if (res.status === 200){
        if (data.scopeId !== sessionStorage.tenantId){
          let msg = {
            "message": "deviceMoved",
            "payload" : {
              "id": data.id
            }
          }
          MqttEventHandler.publish("gateway-service/"+sessionStorage.tenantId+"/gateways",JSON.stringify(msg) , {qos: 2, retain: false}, function() {});
        }
        enqueueSnackbar("The device details have been successfully moved");
        setUserNetworkLoadingGW(false);
        props.close_info_dialog(false);
      } else {
        setUserNetworkLoadingGW(false);
      }
    }, data)
  }

  // pending instructions dialog

  const handlePendingInstructionsDialog = () => {
    props.close_pending_instruction_dialog(false);
  };

  const handlePermissionDialog = () => {
    props.close_permission_dialog(false);
  };

  const changePermission = (event,permissionValue) => {
    let data = { ...permissionData };
    if (event.target.checked){                                      
      data.permissions.push(permissionValue);
    }else {
      data.permissions.splice(data.permissions.indexOf(permissionValue),1);
    }
    setPermissionData({ ...data });
  }


  const changeRole = (role) => {
    if (role === 'superadmin') {
      setDeviceEdit(true);
      setDeviceView(true);
      setLockUsersEdit(true);
      setLockUsersView(true);
      setGatewaysEdit(true);
      setGatewaysView(true);
      setPortalUsersEdit(true);
      setPortalUsersOwner(true);
      setPortalUsersView(true);
      setPortalUsersDelete(true);
      setAlertsEdit(true);
      setAlertsView(true);
      setFirmwareView(true);
      setFirmwareDelete(true);
      setInstructionsEdit(true);
      setInstructionsView(true);
      setHierarchyView(true);
      setHierarchyEdit(true);
      setHierarchyDelete(true);
    } else if (role === 'admin') {
      setDeviceEdit(false);
      setDeviceView(true);
      setLockUsersEdit(false);
      setLockUsersView(true);
      setGatewaysEdit(false);
      setGatewaysView(true);
      setPortalUsersEdit(false);
      setPortalUsersOwner(false);
      setPortalUsersView(true);
      setPortalUsersDelete(false);
      setAlertsEdit(false);
      setAlertsView(true);
      setFirmwareView(true);
      setFirmwareDelete(false);
      setInstructionsEdit(false);
      setInstructionsView(true);
      setHierarchyView(true);
      setHierarchyEdit(false);
      setHierarchyDelete(false);
    }

    if (role === 'admin') {
      var data = [];
      var itr = Permissions.userPermissions.adminPermissions;
      Object.values(itr).forEach(value => {
        data.push(value);
      })
      setuserPermission(data);
    } else if (role === 'superadmin') {
      var data = [];
      var itr = Permissions.userPermissions.superAdminPermissions;
      Object.values(itr).forEach(value => {
        data.push(value);
      })
      setuserPermission(data);
    }
  };


  const handleCreatePortalUser = () => {
    setUserNetworkLoading(true);      
    permissionData.scopeId = sessionStorage.tenantId;
    AuthClient.createPortalUser(response => {
        if (response.status === 200 || response.status === 201){
          setUserNetworkLoading(false);
          setShowSnackbar(true);
          enqueueSnackbar("User successfully added");
          setTimeout(() => {
            setShowSnackbar(false)
          }, 2001);
          props.close_permission_dialog(false);
        }else {
          setShowSnackbar(true);
          setShowSnackBarMessage("Failed to create user");
          setTimeout(() => {
            setShowSnackbar(false)
          }, 3001);
          setUserNetworkLoading(false);          
        }        
    }, permissionData);
  }

  const handleUpdatePortalUser = () => {
    setUserNetworkLoading(true);    
    let payload = {
      "id": permissionData.id,
      "email": permissionData.email,
      "firstName": permissionData.firstName,
      "lastName": permissionData.lastName,
      "scopeId": sessionStorage.tenantId,
      "owner": permissionData.owner,
      "status": permissionData.status,
      "permissions": permissionData.permissions
    }
    setShowSnackbar(true);
    setShowSnackBarMessage("Updating ...");
    setTimeout(() => {
      setShowSnackbar(false)
    }, 2001); 
    permissionData.scopeId = sessionStorage.tenantId;
    AuthClient.updatePortalUser(response => {
        if (response.status === 200 || response.status === 201){
          enqueueSnackbar("Details updated successfully");
          setUserNetworkLoading(false);
          props.close_permission_dialog(false);
        }else {
          enqueueSnackbar("Failed to update details");
          setUserNetworkLoading(false);
        }
    }, payload);
  }

  const changeUserRole = (event) => {
    changeRole(event.target.value);
    setUserRole(event.target.value);
  };

  // DELETE PORTAL USER DIALOD

  const handleDeletePortalUserDialog = () => {
    props.close_delete_permission_dialog(false);
  };

  const deletePortalUser = () => {
    AuthClient.deletePortalUser(props.data.id,sessionStorage.tenantId).then((response)=>{
      if (response.status === 200 || response.status ===201 || response.status === 204){
        setShowSnackbar(true);
        setShowSnackBarMessage("User successfully removed");
        setTimeout(() => {
          setShowSnackbar(false)
        }, 3001);
        props.close_delete_permission_dialog(false);
      }
    });
  }

  const handleActivityFeedSearch = (searchText) => {
    let matchedData = {};
    let alertsCount = 0;
    let eventsCount = 0;
    if (searchText && Object.keys(window.activityLogs)){
      if (searchText !== "" && searchText !== null && typeof searchValue !== 'undefined' && Object.keys(window.activityLogs).length > 0){
          Object.keys(window.activityLogs).map((value)=>{
            _.forEach(window.activityLogs[value], (val) => {
              if (val.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || val.value.toLowerCase().indexOf(searchText.toLowerCase()) !== -1){                  
                matchedData[value] = matchedData[value] ? _.concat(matchedData[value], val) : [val];
                if (val.hasOwnProperty("isAlert")){
                  alertsCount++;
                }else {
                  eventsCount++;
                }
              }              
            })
          })
          setAlertsCount(alertsCount);
          setEventsCount(eventsCount);
          setLogs(matchedData);
      }else {
        setLogs(window.activityLogs);
        setAlertsCount(window.activityLogsAlertsCount);
        setEventsCount(window.activityLogsEventsCount);
      }
    }else {
      setLogs(window.activityLogs);
      setAlertsCount(window.activityLogsAlertsCount);
      setEventsCount(window.activityLogsEventsCount)
    }
  }

  const handleEditUnlockAlert = () => {
    props.close_edit_unlock_alert_dialog(false);
  };

  const handleUpdateAlert = () => {
    // JobSchedulerClient.updateAlert()
    let updatedReceivers = [];
    _.forEach(propsPortalUsersData,(value)=>{
      updatedReceivers.push(value.email);
    })

    if (alertSelection === "RFID") {
      let alertPayload = {
        alertType: alertSelection,
        scopeId: sessionStorage.tenantId,
        alertId: props.data.alertId,
        rfidAlertId: props.data.rfidAlertId
      }

      if (alertSelection === "RFID") {
        alertPayload["nextRfidScheduleTime"] = new Date().getTime() + "";
        alertPayload["rfidScheduleTime"] = rfidAlert + "";
        alertPayload["rfidThreshold"] = "2";
      }
      
      alertPayload.receivers = updatedReceivers;
      JobSchedulerClient.updateRfidAlert(alertPayload).then((res)=>{
        if (res.status === 200 || res.status === 204 || res.status === 415){
          enqueueSnackbar("Alert successfully Updated")
        }else {
          enqueueSnackbar("Failed to update alert");
        }
      }).catch((err)=>{
        enqueueSnackbar("Failed to update alert");
      })
    }else {
      let updatedData = Object.assign({},props.data)
      updatedData.receivers = updatedReceivers;
      JobSchedulerClient.updateAlert(updatedData).then((res)=>{
        if (res.status === 200 || res.status === 204 || res.status === 415){
          enqueueSnackbar("Alert successfully Updated")
        }else {
          enqueueSnackbar("Failed to update alert");
        }
      }).catch((err)=>{
        enqueueSnackbar("Failed to update alert");
      })
    }
    props.close_edit_unlock_alert_dialog(false)
  }

  const handleDeleteAlert = () => {
    if(props.dialog === "editUnlockAlertDialog" && props.title === "Delete Alert") {
      handleEditUnlockAlert();
      if (props.data.alertType === "RFID"){
        JobSchedulerClient.deleteRfidAlert(sessionStorage.tenantId).then((data)=>{
          if(data.status === 204 || data.status === 200 || data.status === 200){
            enqueueSnackbar("Alert successfully deleted")
          }else {
            enqueueSnackbar("Error: Please try again")
          }
        })
      }else{     
        JobSchedulerClient.deleteAlert(props.data.alertId).then((data) => {        
          if(data.status === 204 || data.status === 200 || data.status === 200){
            enqueueSnackbar("Alert successfully deleted")
          }else {
            enqueueSnackbar("Error: Please try again")
          }
        });
      }
    }else{

      let allReceivers = [];      
      _.forEach(propsPortalUsersData,(user)=>{
        if(alertSelection === "BATTERY_LOW") {  
          allReceivers.push(user.id);
        }else {
          allReceivers.push(user.email);
        }
      })

      if(alertSelection === "BATTERY_LOW") {
        let alertPayload = {
          alertType: alertSelection,
          batteryLevel: batteryLevel,
          intervalTime: alertTimeInterval,
          receivers: allReceivers,
          scopeId: sessionStorage.tenantId
        }
        JobSchedulerClient.createAlert(response => {
          if (response.status === 201 || response.status === 204){
            enqueueSnackbar("Alert successfully created")
          }else {
            enqueueSnackbar("Failed to create alert");
          }
          handleEditUnlockAlert();
        }, alertPayload);
      }else {
        let alertPayload = {
          alertType: alertSelection,
          receivers: allReceivers,
          scopeId: sessionStorage.tenantId
        }

        if (alertSelection === "RFID") {
          alertPayload["nextRfidScheduleTime"] = new Date().getTime() + rfidAlert + "";
          alertPayload["rfidScheduleTime"] = rfidAlert + "";
        }

        JobSchedulerClient.createAlert(response => {
          if (response.status === 201 || response.status === 204){
            enqueueSnackbar("Alert successfully created")
          }else {
            enqueueSnackbar("Failed to create alert");
          }
          handleEditUnlockAlert();
        }, alertPayload);      
    }
  }
  };

  const searchTenantsInAddOrEditAlerts = (event) => {
    setSearchTenantsByNameOrEmail(event.target.value);
  };

  const checkIfTenantIsSelected = (email) => {
    let alreadyExist = false;
    // eslint-disable-next-line array-callback-return
    propsPortalUsersData.map((element, index) => {
      if (element.email === email) {
        alreadyExist = true;
        return alreadyExist;
      }
    });
    return alreadyExist;
  };

  const unSelectTenant = (data) => {
    let removedUnselectedTeant = [];
    setValueUpdated(false);
    propsPortalUsersData.forEach((element) => {
      if (element.email !== data.email) {
        removedUnselectedTeant.push(element);
      }
    });
    setPropsPortalUsersData(removedUnselectedTeant);
  };

  const selectAllTenants = () => {
    setValueUpdated(false);
    setPropsPortalUsersData(props.portal_users_data);
  };

  const unSelectAllTenants = () => {
    setValueUpdated(false);
    setPropsPortalUsersData([]);
  };

  const changeLogInterval = (value) => {
    let toTime = new Date().getTime();
    let fromTime ;
    setActivityLogsIntervalRange(value);
    window.activityLogInterval = value;
    if(value === "1 hour"){
        fromTime = toTime - 3600000;
        window.activityFeedfromTime = fromTime;
        fetchActivityLogs(fromTime, toTime);
    }else if (value === "6 hours"){
        fromTime = toTime - 21600000;
        window.activityFeedfromTime = fromTime;
        fetchActivityLogs(fromTime, toTime);
    }else if (value === "12 hours"){
        fromTime = toTime - 43200000;
        window.activityFeedfromTime = fromTime;
        fetchActivityLogs(fromTime, toTime);
    }else if (value === "24 hours"){
        fromTime = toTime - 86400000;
        window.activityFeedfromTime = fromTime;
        fetchActivityLogs(fromTime, toTime);
    }else if (value === "1 week"){
        fromTime = toTime - 604800000;
        window.activityFeedfromTime = fromTime;
        fetchActivityLogs(fromTime, toTime);
    }else if (value === "2 weeks"){
         fromTime = toTime - 1209600000;
         window.activityFeedfromTime = fromTime;
         fetchActivityLogs(fromTime, toTime);
    }else if (value === "1 month"){
         fromTime = toTime - 2628000000;
         window.activityFeedfromTime = fromTime;
         fetchActivityLogs(fromTime, toTime);
    }else if (value === "3 months"){
          fromTime = toTime - 7884000000;
          window.activityFeedfromTime = fromTime;
          fetchActivityLogs(fromTime, toTime);
    }    
  };

  const formateValue = (value, oldValue) => {
    let eventDataResult = [];
    let allAlertsCount = 0;
    let allEventsCount = 0;

    if(value.hasOwnProperty("errorText")){
      if(value.errorText !== null && value.errorText !== ''){
        var errorText = {
          "name" : "Gateway Error",
          "value" : value.errorText,
          "time" : value.date,          
          "color": "#c53211"              
        };
        eventDataResult.push(errorText);
        allSearchOptions.push(errorText.name);
        allEventsCount++;
      }
    }

    if(value.hasOwnProperty("gatewayConnectionStatus")){
      if(value.gatewayConnectionStatus !== null && value.gatewayConnectionStatus !== ''){
        let connectionStatus = {
          "name" : "Gateway Connection Status",
          "value" : value.gatewayConnectionStatus,
          "time" :  value.date,
          "color" : value.gatewayConnectionStatus === "Online" ? "#008000" : "#c53211"
        };
        eventDataResult.push(connectionStatus);
        allSearchOptions.push(connectionStatus.name);
        allEventsCount++;
      }
    }

    if(value.hasOwnProperty("gatewayReboot")){
      if(value.gatewayReboot !== null && value.gatewayReboot !== ''){
        let gatewayReboot = {
          "name" : "Gateway Reboot by Gateway",
          "value" : value.gatewayReboot,
          "time" : value.date,
        };
        eventDataResult.push(gatewayReboot);
        allSearchOptions.push(gatewayReboot.name);
        allEventsCount++;
      }
    }

    if(value.hasOwnProperty("message")){
      if(value.message !== null && value.message !== ''){
        var genericMessage = {
          "name" : "Gateway Message",
          "value" : value.message,
          "time" : value.date,              
        };
        eventDataResult.push(genericMessage);
        allSearchOptions.push(genericMessage.name);
        allEventsCount++;
      }
    }
    if(value.hasOwnProperty("scanMadeBy")) {
      if(value.scanMadeBy !== null && value.scanMadeBy !== ""){
        var scanMadeBy = {
            "name" : "Gateway Scan Is Triggered",
            "value" : value.scanMadeBy,
            "time"  : value.date,
        
        };
        eventDataResult.push(scanMadeBy);
        allSearchOptions.push(scanMadeBy.name);
        allEventsCount++;
      }
    }
    if(value.hasOwnProperty("scannedLocks") && value.hasOwnProperty("whiteListedLocks")){
      if(value.scannedLocks !== null && value.whiteListedLocks !== null){
        value.whiteListedLocks = value.whiteListedLocks === -1 ? "*" : value.whiteListedLocks;
        var scannedLocksAndWhiteListedLocks = {
            "name"  :   "Gateway Scan Status",
            "value" :   value.scannedLocks + "/" + value.whiteListedLocks,
            "time"  :    value.date,
          
        };
        eventDataResult.push(scannedLocksAndWhiteListedLocks);
        allSearchOptions.push(scannedLocksAndWhiteListedLocks.name);
        allEventsCount++;
      }
    }
    if(value.hasOwnProperty("scannedLocks") && value.hasOwnProperty("locksProbe")){
      if(value.scannedLocks !== null && value.locksProbe !== null){
        var scannedLocksAndProbedLocks = {
            "name"  :   "Gateway Probe Status",
            "value" :   value.locksProbe + "/" + value.scannedLocks,
            "time"  :    value.date,
          
        };
        eventDataResult.push(scannedLocksAndProbedLocks);
        allSearchOptions.push(scannedLocksAndProbedLocks.name);
        allEventsCount++;
      }
    }
    if(value.hasOwnProperty("scannedLocks") && value.hasOwnProperty("historyFetch")){
      if(value.scannedLocks !== null && value.historyFetch !== null){
        var scannedLocksAndHistoryFetch = {
            "name"  :   "Gateway History Fetch Status",
            "value" :   value.historyFetch + "/" + value.scannedLocks,
            "time"  :    value.date,
            
        };
        eventDataResult.push(scannedLocksAndHistoryFetch);
        allSearchOptions.push(scannedLocksAndHistoryFetch.name);
        allEventsCount++;
      }
    }
      if (value.hasOwnProperty("deviceScopeChange")) {
        if(value.status !== null && value.status !== ""){
          var gatewayStatus = {
            "name" : "Gateway Connection Status",
            "value": value.status.toUpperCase(),
            "time" : value.date,            
            "isAlert" : true
          };
          if (value.status.toUpperCase() === "OFFLINE"){
            gatewayStatus.isOnline = false;
          }else {
            gatewayStatus.isOnline = true;
          }          
          eventDataResult.push(gatewayStatus);
          allSearchOptions.push(gatewayStatus.name);
          allAlertsCount++;
        }
      }
      if (value.hasOwnProperty("deviceScopeChange")) {
        if(value.deviceScopeChange !== null && value.deviceScopeChange !== ""){
          var deviceScopeChange = {
            "name" : "Gateway Moved From",
            "value": value.deviceScopeChange[0] + " -> " + value.deviceScopeChange[1],
            "time" : value.date,            
          };
          eventDataResult.push(deviceScopeChange);
          allSearchOptions.push(deviceScopeChange.name);
          allEventsCount++;
        }
      }

      window.activityLogsEventsCount += allEventsCount; 
      window.activityLogsAlertsCount += allAlertsCount;       
      return oldValue ? _.concat(oldValue, eventDataResult) : eventDataResult;
  }  

  const fetchActivityLogs = function(fromTime, toTime){
    setActivityLogsLoading(true);
    window.activityLogsEventsCount = 0;
    window.activityLogsAlertsCount = 0;    
    if (window.activityFeedfromTime) {
      fromTime = window.activityFeedfromTime;
    }
    GatewayServiceClient.getGatewayActivityLogs(fromTime ,toTime , props.data.macId).then((data)=>{
      let allDates = [];
      let formattedData = {};
      let sortedData = _.orderBy(data,["date"],['desc']);
      _.forEach(sortedData,function(value){
        let date = moment(value.date).format("YYYY-MM-DD");
        allDates.push(date);
        if (formattedData[date]){
          if (formattedData[date].length > 0){
            formattedData[date] = formateValue(value, formattedData[date]);
          }else {
            formattedData[date] = formateValue(value);
          }
        }else {
          formattedData[date] = formateValue(value);
        }        
      });      
      window.activityLogs = formattedData;
      let searchValue = document.getElementById("activityFeedSearchField") ? document.getElementById("activityFeedSearchField").value : null;
      if (!searchValue || searchValue === "" || typeof searchValue === 'undefined'){
        setAlertsCount(window.activityLogsAlertsCount);
        setEventsCount(window.activityLogsEventsCount);
        setSearchOptions(_.uniq(allSearchOptions));
        setLogs(formattedData);
      }else {
        handleActivityFeedSearch(searchValue);
      }
      setAllActivityLogDates(allDates);
      setActivityLogsLoading(false);
    });
  }

  const isCaribouTechDomain = () => {
    return JSON.stringify(sessionStorage.userDetails).indexOf("cariboutech.com") !== -1;
  }

  const calculateHeight = (date, data) => {
    let count = 0;
    _.forEach(data,function(value){
        if (date === value.formattedDate){
            count++;
        }
    });
    return allActivityLogDates.length > 1 ? (count > 4 ? 4*84 +"px" : count*84 +"px") : "100%";
  };

  const fetchPendingInstructions = () => {
    GatewayServiceClient.getPendingInstruction(props.data.macId).then((data) => {
      let modifiedData = [];
      _.forEach(data,(value)=>{
        value.isChecked = false;
        modifiedData.push(value);
      })
      setPendingInstructionData(modifiedData);
    });
  }

  const pendingInstructionEvents = () => {
    enqueueSnackbar("Refreshing Pending instructions");
    fetchPendingInstructions();
  }

  const subscribeToPendingInstructionChannels = () => {
    window.mqttDeviceInstructionsEventHandler = MqttEventHandler.subscribe(PubSubUtils.getDevicesEvent(sessionStorage.tenantId), "pendingInstructoin", pendingInstructionEvents);
    window.mqttUsersInstructionEventHandler = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.DEVICE_USER_MAPPINGS.replace("scopeId",sessionStorage.tenantId), "pendingInstructions", pendingInstructionEvents);
    window.mqttInstructionCreatedEventHandler = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.INSTRUCTIONS.replace("scopeId",sessionStorage.tenantId), "pendingInstructions", pendingInstructionEvents);
  }

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    setPage(event.target.value);
  };

  const handleRowPerPage = (event) => {
    if (event.target.value > rowsPerPage && pendingInstructionData.length > 0){
      setPageNumber(Math.ceil(pendingInstructionData.length/event.target.value) - 1);
    }

    if (props.hasOwnProperty("dialog")) {
      if(props.dialog === "pcToolHistory") {
       sessionStorage.pcToolHistoryRPP = event.target.value;
      }else if(props.dialog === "showUserAssignedLocksDialog"){
        sessionStorage.assignedUSersRPP = event.target.value;
      }else if(props.dialog === "pendingInstructionDialog"){
        sessionStorage.pendingInstructionsRPP = event.target.value;
      }
    }else {
      sessionStorage.noDialogRPP = 5
    }

    setRowsPerPage(event.target.value);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let emptyRows =
            page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pendingInstructionData.length) : 0;

   function TablePaginationActions(props) {
      let {count, page, rowsPerPage, onPageChange} = props;

      function handleFirstPageButtonClick(event) {
        onPageChange(event, 0);
      }

      function handleBackButtonClick(event) {
        onPageChange(event, page - 1);
      }

      function handleNextButtonClick(event) {
        onPageChange(event, page + 1);
      }

      function handleLastPageButtonClick(event) {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      }

      return (
        <div className={paginationStyle.paginationActionIcons}>
          <FirstPage
            onClick={() => {
              if (page > 0) handleFirstPageButtonClick();
            }}
            sx={{
              color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
              cursor: page === 0 ? 'auto' : 'pointer',
              marginRight: '8px'
            }}
          />
          <KeyboardArrowLeft
            onClick={() => {
              if (page > 0) handleBackButtonClick();
            }}
            sx={{
              color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
              cursor: page === 0 ? 'auto' : 'pointer',
              marginRight: '8px'
            }}
          />
          <KeyboardArrowRight
            onClick={() => {
              if (page !== Math.ceil(count / rowsPerPage) - 1)
                handleNextButtonClick();
            }}
            sx={{
              color:
                page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
                  ? 'black'
                  : 'rgba(0,0,0,0.26)',
              cursor:
                page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
                marginRight: '8px'
            }}
          />
          <LastPage
            onClick={() => {
              if (page !== Math.ceil(count / rowsPerPage) - 1)
                handleLastPageButtonClick();
            }}
            sx={{
              color:
                page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
                  ? 'black'
                  : 'rgba(0,0,0,0.26)',
              cursor:
                page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
            }}
          />
        </div>
      );
    }

    const handlePageChange = (value) => {

    };

  const infoRowPerPageLabel = (
    <div className={paginationStyle.rowPerPageLabelDiv}>
        <Box className={paginationStyle.page}>
          <span style={{marginRight: '32px'}}>Page</span>
        </Box>
        <Box className={paginationStyle.pageSelectBox}>
          <Select value={pageNumber} onChange={handleChange}>
            {
              Math.ceil(pendingInstructionData.length / rowsPerPage) > 0 ?
              // eslint-disable-next-line array-callback-return
              Array(Math.ceil(pendingInstructionData.length / rowsPerPage))
                .fill()
                .map((_, index) => {
                  return (
                    <MenuItem value={index} sx={{minWidth: '64px'}}>
                      {index + 1}
                    </MenuItem>
                  );
                })
              :
                <MenuItem value={0} sx={{minWidth: '64px'}}>
                  1
                </MenuItem>
            }
          </Select>
        </Box>
        <Box className={paginationStyle.rowPerPageLabelDiv}>
          <span style={{marginRight: '32px'}}>Rows Per Page</span>
        </Box>
        <Box className={paginationStyle.rowPerPageSelectBox}>
          <Select value={rowsPerPage} onChange={handleRowPerPage}>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={25}>25</MenuItem>
          </Select>
        </Box>
      </div>
    );

    const rowPerPageLabel = (
      <div className={paginationStyle.rowPerPageLabelDiv}>
        <Box className={paginationStyle.page}>
          <span style={{marginRight: '32px'}}>Page</span>
        </Box>
        <Box className={paginationStyle.pageSelectBox}>
          <Select value={pageNumber} onChange={handleChange}>
            {
              userAssignedLocksData ?
                Math.ceil(userAssignedLocksData.length / rowsPerPage) > 0 ?
                    // eslint-disable-next-line array-callback-return
                    Array(Math.ceil(userAssignedLocksData.length / rowsPerPage))
                      .fill()
                      .map((_, index) => {
                        return (
                          <MenuItem value={index} sx={{minWidth: '64px'}}>
                            {index + 1}
                          </MenuItem>
                        );
                      })
                :
                    <MenuItem value={0} sx={{minWidth: '64px'}}>
                        1
                    </MenuItem>
              :
                <MenuItem value={0} sx={{minWidth: '64px'}}>
                    1
                </MenuItem>
            }
          </Select>
        </Box>
      </div>
    );

    const pcToolRowPerPageLabel = (
      <div className={paginationStyle.rowPerPageLabelDiv}>
        <Box className={paginationStyle.page}>
          <span style={{marginRight: '32px'}}>Page</span>
        </Box>
        <Box className={paginationStyle.pageSelectBox}>
          <Select value={pageNumber} onChange={handleChange}>
            {
              pcToolData ?
                Math.ceil(pcToolData.length / rowsPerPage) > 0 ?
                    // eslint-disable-next-line array-callback-return
                    Array(Math.ceil(pcToolData.length / rowsPerPage))
                      .fill()
                      .map((_, index) => {
                        return (
                          <MenuItem value={index} sx={{minWidth: '64px'}}>
                            {index + 1}
                          </MenuItem>
                        );
                      })
                :
                    <MenuItem value={0} sx={{minWidth: '64px'}}>
                        1
                    </MenuItem>
              :
                <MenuItem value={0} sx={{minWidth: '64px'}}>
                    1
                </MenuItem>
            }
          </Select>
        </Box>
      </div>
    );
    
    const applyScanFrequency = (value) => {
      setShowScanFrequencyApplyingLoader(true);
      setShowPollTimerApplyButton(false);
      enqueueSnackbar("Applying Scan Frequency ...")
      publishMessage(publishTopics.command,props.data.macId,"pollinterval",value);
    }

    const handlePollIntervalChange = (value) => {      
      setPollInterval(value);
      if (value == window.pollIntervalTime){
        setShowPollTimerApplyButton(false)
      }else {
        if (value < 1){        
          setPollIntervalError(true);
          setShowPollTimerApplyButton(false)
          setPollInetvalHelperText("Invalid Scan Frequency")
        }else if (value > 10800) {
          setPollIntervalError(true);
          setShowPollTimerApplyButton(false)
          setPollInetvalHelperText("Value must be below 10800")
        }else if(value > 0 && value <= 10800){
          setPollIntervalError(false);
          setShowPollTimerApplyButton(true);
          setPollInetvalHelperText(null);
        }
      }
    };

    const applyRssiValue = (value) => {
      setShowRssiApplyLoader(true);
      setShowRssiApplyButton(false);
      enqueueSnackbar("Applying Rssi Value ...")
      publishMessage(publishTopics.command,props.data.macId,"rssi",Number(value));
    }

    const handleRssiChange = (value) => {
      setRssi(value);
      if (value == window.rssiValue){
        setShowRssiApplyButton(false);
      }else {
        if (value < -1000){
          setRssiError(true);
          setShowRssiApplyButton(false);
          setRssiHelperText("Invalid Scan Frequency")
        }else if (value > 0) {
          setRssiError(true);
          setShowRssiApplyButton(false);
          setRssiHelperText("Value must be negative")
        }else if (value > -1000 && value < 1 ){
          setRssiError(false);
          setShowRssiApplyButton(true);
          setRssiHelperText(null);           
        }
      }
    };

  const checkSpace = (name) => {
    if(name === " " || name.indexOf(" ") === 0){
        if(name.length <= 1){
            return "";
        }else{
            return name ? name.trim() : "";
        }
    }
  }

    //************* PAHO MQTT LOGIC ************
    
    const subscribeTopics = {
      "whiteList" : props.dialog === "whiteListDialog" ? PubSubChannels.pubsub_channels.GATEWAY_WHITELIST_RECEIVE_FROM_DEVICE.replace("gatewayId",props.data.macId) : "",
      "gatewayStatus" : props.dialog === "gatewayLogDialog" ? PubSubChannels.pubsub_channels.GATEWAY_STATUS.replace("scopeId",sessionStorage.tenantId).replace("gatewayId",props.data.macId) : "",
      "deviceLogPercentage": props.dialog === "infoDialog" ? PubSubChannels.pubsub_channels.GATEWAY_DEBUG_LOGS_UPLOADED_PERCENTAGE.replace("macId",props.data.macId) : "",

    }

    const publishTopics = {
      "whiteList" : props.dialog === "whiteListDialog" ? PubSubChannels.pubsub_channels.REQUEST_WHITELISTED_LOCKS.replace("gatewayId",props.data.macId) : "",      
      "command": props.dialog === "gatewayLogDialog" ? PubSubChannels.pubsub_channels.GATEWAY_COMMAND.replace("scopeId",sessionStorage.tenantId).replace("gatewayId",props.data.macId) : "",
    }  

    const getMessage = (name, value) => {
      let message = {};
      if (name === "scan"){
          message.command = "SCAN";
      }else if (name === "forceIdle"){
          message.command = "FORCE-IDLE";
      }else if (name === "clearBlackList"){
          message.command = "REMOVE-BLACKLISTED-LOCKS";
      }else if (name === "reset"){
          message.command = "RESET";
      }else if (name === "pollinterval"){
          message.command = 'POLLING-INTERVAL';
          message.pollingInterval = value;
      }else if (name === "rssi"){
          message.command = 'RSSI-VALUE';
          message.rssiValue = value;
      }
      return message;
  };

  const publishMessage = (topic , gatewayId, messageType, value) => {
      let message = getMessage(messageType, value);
      let payload = messageType !== "whitelist" ? (messageType === "sendwhitelist" ? JSON.stringify(whiteListedLockName) : JSON.stringify(message)) : "WHITELIST";
      MqttEventHandler.publish(topic, payload , {qos: 2, retain: false}, function() {});
      if (topic === publishTopics.command){
        if (messageType === "scan"){
          enqueueSnackbar("Gateway Scanning started ...")
        }else if (messageType === "forceIdle"){
          enqueueSnackbar("Gateway has been Forced to Idle ...")
        }else if (messageType === "reset"){
          enqueueSnackbar("Gateway Restarted ...")
        }else if (messageType === "forceIdle"){
          enqueueSnackbar("Gateway has been Forced to Idle ...")
        }else if (messageType === "whiteList"){
          enqueueSnackbar("Fecthing Gateway Devices Whitelist")
        }
      }
  };

  const gatewayEventHandler = (message) =>{
    let deviceMessage = JSON.parse(message);
    setGatewayStatusMessage(deviceMessage);
    if (deviceMessage.currentState === "IdleState"){
      if (showPollTimerApplyButton){
        setShowPollTimerApplyButton(false);
      }
      setShowScanFrequencyApplyingLoader(false);
      if (showRssiApplyButton){
        setShowRssiApplyButton(false);
      }      
      setShowRssiApplyLoader(false)
      window.pollIntervalTime = deviceMessage.pollInterval / 60000;    
      setPollInterval(deviceMessage.pollInterval / 60000);
      window.rssiValue = deviceMessage.rssiValue;
      setRssi(deviceMessage.rssiValue); 
    }else {
      setShowPollTimerApplyButton(false);
      setShowRssiApplyButton(false);
    }    
  }

  const whiteListEventHandler = (message) => {
    setEnableWhiteListButton(true);
    // setWhiteListedLockName(JSON.parse(message))
  }

  const activityFeedEventHandler = () => {
    enqueueSnackbar("Refreshing Activity Logs");
    changeLogInterval(window.activityLogInterval);
  }  
  
  const generateScannedLocksEventHandler = (data) => {
    data = data ? JSON.parse(data) : "";
    if (data.hasOwnProperty("payload")){
      setAllScannedLocksCSVData(data.payload);
      setShowDownloadCsvIcon(true);
    }
  }

  const subscribeAndPublishChannels = () => {
    if(props.dialog === "gatewayLogDialog"){
      gatewayEventChannel = MqttEventHandler.subscribe(subscribeTopics.gatewayStatus, "activityLogs", gatewayEventHandler);
      gatewayActivityLogTopicHandler = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_ACTIVITY_LOGS.replace("macId",props.data.macId),"gatewaylogs",activityFeedEventHandler);
      window.generateScannedLocksCsv = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GENERATE_SCANNED_LOCKS_CSV.replace("macId",props.data.macId).replace("scopeId",sessionStorage.tenantId), "gatewayLogs", generateScannedLocksEventHandler)
    }else if (props.dialog === "whiteListDialog"){
      publishMessage(publishTopics.whiteList, props.data.macId, "whitelist");
      let whitlistData = props.data ? props.data.hasOwnProperty("listOfWhiteListedLocks") ? props.data.listOfWhiteListedLocks : [] : [];
      setWhiteListedLockName(props.data.listOfWhiteListedLocks);
      setScanThresholdCount(props.data.threshold);
      window.whiteListedLockName = props.data.listOfWhiteListedLocks;
      window.scanThresholdCount = props.data.threshold;
      whiteListEventChannel = MqttEventHandler.subscribe(subscribeTopics.whiteList, "whiteListData", whiteListEventHandler);
    }
  }

  const checkIsMqttConnected = () => {    
      if (props.dialog === "gatewayLogDialog" || props.dialog === "whiteListDialog"){
        subscribeAndPublishChannels();
      }
  }

      const updateIotDeviceUser = () => {
        let data = {
          "firstName" : lockUserfirstName,
          "lastName" : lockUserLastName,
          "division" : lockUserDivision,
          "employeeId" : lockUserEmployeeId,
          "mobileNo" : lockUserMobile,
          "scopeId" : sessionStorage.tenantId,
          "id": props.data.id          
        }
        if (lockUserCardCSN !== "-" && typeof lockUserCardCSN !== 'undefined'){
          if (lockUserCardCSN !== props.data.preserveCardCSN){
            data["cardCSN"] = lockUserCardCSN === "" ? null : lockUserCardCSN;
          }
        }
        props.updateIotDeviceUser(data);
      }

      const isErrorInCreation = () => {        
        let errorCount = 0;
        if (lockUserMobile){
          if (!lockUserMobile.match(/^\+(?:[0-9] ?){6,14}[0-9]$/)){
            errorCount +=1;
          }
        }
        if (!lockUserfirstName || !lockUserLastName || !lockUserEmail){
          errorCount +=1;
        }
         if (lockUserfirstName === "" || lockUserLastName === "" || lockUserEmail === ""){
          errorCount +=1;            
        }
        if (lockUserEmail){
          if (!(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(lockUserEmail))){
            errorCount +=1;
          }
        }else {
          errorCount +=1
        }

        return errorCount > 0;
      };

      const createIotUser = () => {
        let data = {
          "firstName" : lockUserfirstName,
          "lastName" : lockUserLastName,
          "division" : lockUserDivision,
          "employeeId" : lockUserEmployeeId,
          "email" : lockUserEmail,
          "mobileNo" : lockUserMobile,
          "scopeId" : sessionStorage.tenantId,
          "cardCSN" : lockUserCardCSN === "" ? null : lockUserCardCSN
        }
        props.createIotUser(data);
      }

var validHeaderNames = function (user, fromNormalUsersDialog) {
      var noOfProperties = Object.keys(user).length;
      if(fromNormalUsersDialog && noOfProperties == 6) {
          return (user.hasOwnProperty("firstName") && user.hasOwnProperty("lastName") &&
          user.hasOwnProperty("division") && user.hasOwnProperty("employeeId") &&
          user.hasOwnProperty("email") && user.hasOwnProperty("mobileNo")) ? true : false ;
      } else if ( !fromNormalUsersDialog && noOfProperties == 7) {
          return (user.hasOwnProperty("firstName") && user.hasOwnProperty("lastName") &&
          user.hasOwnProperty("division") && user.hasOwnProperty("employeeId") &&
          user.hasOwnProperty("email") && user.hasOwnProperty("mobileNo") &&
          user.hasOwnProperty("cardCSN")) ? true : false ;
      } else {
          return false;
      }
}
      var prepareResponseMsg = function (response) {
        var message = [];
        var consolidatedResponse = {};
        for (var i=0; i< response.length; i++) {
            if(consolidatedResponse.hasOwnProperty(response[i])) {
                consolidatedResponse[response[i]]++;
            } else {
                consolidatedResponse[response[i]] = 1;
            }
        }
        var keys = Object.keys(consolidatedResponse).sort(function(a, b){
                     // ASC  -> a.length - b.length
                     // DESC -> b.length - a.length
                     return a.length - b.length;
                   });
        for (var i=0; i<keys.length; i++) {
            if (keys[i] == "Added") {
                message.unshift("Successfully added " +consolidatedResponse[keys[i]]+ "/"+ response.length +
                                             " users\n \n");
            } else if (keys[i] == "Updated") {
                    message.unshift("Successfully updated " +consolidatedResponse[keys[i]]+ "/"+ response.length +
                                                 " users\n \n");
            } else {
                message.push("Failed to add " +consolidatedResponse[keys[i]]+ "/"+ response.length +
                                             " users - " +keys[i]+ "\n \n");
            }
        }
        return message;
      }

      const uploadCSVHandler = (event, fromNormalUsersDialog) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (users) {
              let ignored_Users = 0;
              let uploaded_Users = 0;
              let call_count = 0;
              let dataLessThanFiveHundred = true;
              users = users.data;
              if (users.length > 0) {
                var isValid = true;
                if (!validHeaderNames(users[0], fromNormalUsersDialog)) {
                  setShowErrorMsg(true);
                  setErrorMsg("Headers of selected csv file are not valid, please check and retry");
                  return;
                }

                //CONVERTING GIVEN EMAIL TO LOWERCASE BEFORE SENDING INTO BACKEND SERVICE
                _.forEach(users,function(value){
                  if(value.hasOwnProperty("email")){
                    value.email = value.email.toLowerCase();
                  }
                  value.scopeId = sessionStorage.tenantId;
                });
                const createBulkIotUsers = function(Users, totalBulkCsvCount){
                  setCSVAddingusers(true);
                  IotServiceClient.createBulkIotUserForCurrentScope(Users, !fromNormalUsersDialog).then(function(res) {
                    if(dataLessThanFiveHundred){
                      setResponseMsg(prepareResponseMsg(res));                      
                      setIgnoredUsers(Users.length - res.length);
                      // setUploadedUsers(addedLocks);
                      setShowStatus(true);
                      setCSVAddingusers(false);
                      //props.closeUploadBulkUsersDialog();
                    }else {
                      ignored_Users += Users.length - res.length;
                      uploaded_Users += res.length;
                      call_count +=1
                      if (call_count === Math.ceil(totalBulkCsvCount/500)){
                        setTimeout(() => {
                          setCSVAddingusers(false);
                          setIgnoredUsers(ignored_Users);
                          setUploadedUsers(uploaded_Users);
                          //props.closeUploadBulkUsersDialog(false);
                        }, 3000);
                        setResponseMsg(prepareResponseMsg(res));
                        setShowStatus(true);
                      }
                    }
                  }).catch((error)=>{
                    //notificationService.showToast("Error. Please try again");
                    callCount +=1;
                  });
                };

                if(users.length > 500){
                  dataLessThanFiveHundred = false;
                  var initial_slice_endLimit = 501;
                  let totalUserFromCSV = users;
                  setCSVIotUsersUploadedData(totalUserFromCSV);
                  for(var index = 0;index < Math.ceil(totalUserFromCSV.length/500) ; index++){
                    if(initial_slice_endLimit > totalUserFromCSV.length){
                      initial_slice_endLimit = totalUserFromCSV.length+2;
                    }
                    // 501 because last element will not be considered
                    var Lock_Users = totalUserFromCSV.slice(index * 501,initial_slice_endLimit);
                    createBulkIotUsers(Lock_Users,totalUserFromCSV.length);
                    // increase initial_slice_endLimit by 500
                    initial_slice_endLimit += 501;
                  }
                }else{
                  dataLessThanFiveHundred = true;
                  let totalUserFromCSV = users;
                  setCSVIotUsersUploadedData(totalUserFromCSV);
                  createBulkIotUsers(totalUserFromCSV);
                }

              } else {
                setShowErrorMsg(true);
                setErrorMsg("Uploaded file is empty, please check and retry.");


                // setResponseMsg(["Uploaded file is empty, please check and retry"]);
                // setTimeout(() => {
                //   setShowSnackbar(false)
                // }, 3001);
                // setShowStatus(true);
                // setCSVAddingusers(false);
              }
            },
        });
        setSelectedFile("");
    }

    const showWhiteListThreshold = () => {
      return whiteListedLockName ? whiteListedLockName.indexOf("*") === -1 && (whiteListedLockName ? whiteListedLockName.length > 0 : false) : false;
    }

    const handleRemoveGateway = () => {
      GatewayServiceClient.deleteGateway(props.data.macId).then((response) => {
        if (response.status === 200){
          enqueueSnackbar("Successfully deleted the gateway");
          props.fetchAllGateways();
        }else {
          enqueueSnackbar("Error occurred while deleting the gateway");          
          props.closeDialog(false);
        }        
      })
    }

    const fetchLockCSVData = () => {

    }
    
    const handleBulkDialog = () => {
      props.closeAndOpenUploadCsvDialog();
    }

    const checkFieldIsEmpty = (data, field) => {
      return data[field] !== "" && data[field] !== null && typeof data[field] !== 'undefined';
    }

    const checkLockUsersData = (data, field) => {
      let disable = checkFieldIsEmpty(data,"firstName") && checkFieldIsEmpty(data,"lastName") && checkFieldIsEmpty(data,"email");      
      let checkCount = 0;
      if (disable) {        
        // else part will be false if field is a required field
        if (checkFieldIsEmpty(data,"mobileNo") ? !data.mobileNo.match(/^\+(?:[0-9] ?){11,14}$/) : false) {
          checkCount+=1;
        }
        let regex = new RegExp("^[a-zA-Z0-9+_.\-]+@[a-zA-Z0-9_.\-]+[.]{1}[a-z]{2,3}$");
        if (props.title === "Create New User") {
          if(checkFieldIsEmpty(data,"email") ? !data.email.match(regex) : false) {
            checkCount+=1;
          }
        }
        if (checkFieldIsEmpty(data,"cardCSN") ? !data.cardCSN.match(/^[A-Fa-f0-9]{10,10}$/) : false ){
          checkCount+=1;
        }
        disable = checkCount < 1;
      }
      setEnableLockUserUpdateButton(disable);
    }

    const portalUserFirstNameHelperText = () => {
      return permissionData.firstNameTextLength ? permissionData.firstNameTextLength : "0/20";
    }

    const portalUserLastNameHelperText = () => {
      return permissionData.lastNameTextLength ? permissionData.lastNameTextLength : "0/20";
    }
    
    const createRfidAlertText = (value)=> {
      if (props.title === "Update Alert"){
        if (props.data.alertType === "RFID") {
          if (value === null || value === "" || typeof value === 'undefined'){
            setRfidAlertError(true);
          }else {
            if (value > 0 && value <= 30) {
              setRfidAlertError(false);
              setValueUpdated(false);
            }else {
              setRfidAlertError(true);
            }            
          }
        }
      }else {
        setRfidAlertError(false);
      }
      let dayOrDays = value > 1 ? " days" : " day";
      let text = value === 1 ? "in a day" : "in "+ value + dayOrDays; 
      let alertValue = 86400000 * value;
      setRfidAlertText(text);
      setRfidAlert(alertValue);
      setRfidCustomValue(value);
    }

    const downloadLocksData = () => {
      let locksAndSerialNumbersData = [];
      _.forEach(allScannedLocksCSVData, (val)=>{
        let data = {};
        data.serialNumber = val.serialNumber;
        data.names = val.name;
        let clonedData = _.cloneDeep(data);
        locksAndSerialNumbersData.push(clonedData);
      });
      setLocksAndSerialNumbersCsvData(locksAndSerialNumbersData);
      window.locksAndSerialNumbersData = locksAndSerialNumbersData;
      setTimeout(() => {
        document.getElementById("lockSerialNumbersNameCsvData").click();      
      }, 1500);      
    }

    const getLockAndSerialNumbersCSVData = () => {
      
      return window.locksAndSerialNumbersData;
    }

  return (
      <>
        {props.hasOwnProperty('buttonName') ? (
            <div onClick={() => showDialog()} style={{width: '100%'}}>
              {
                props.from !== "createNewTenant" ?
                    props.buttonName.toLowerCase() === 'create' ? (
                        <Button
                            style={{
                              position: 'fixed',
                              bottom: '37px',
                              right: '40px',
                              height: '62px',
                              miWwidth: '55px',
                              maxWidth: '55px',
                              borderRadius: '50%',
                              backgroundColor: 'rgb(20,19,139)',
                            }}
                        >
                          <personAddrounded style={{color: 'white'}} />
                        </Button>
                    ) : (
                        <>
                          <Edit style={{margin: 'auto 16px auto 0',color:'grey !important'}} />
                          <span
                              className="editText"
                              style={{marginTop: '-10px', marginBottom: 'auto'}}
                          >
                      Edit
                      </span>
                    </>
              )
              :
                null
              }

              <Dialog
                  fullWidth={true}
                  fullScreen={fullScreen}
                  open={close === false ? false : props.openDialogBox}
                  onClose={() => props.handleCloseDialogBox(false)}
                  aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle
                    id="responsive-dialog-title"
                    style={{
                      background: 'rgb(20,19,139)',
                      display: 'flex',
                      flexDirection: 'row',
                      color: 'white',
                    }}
                >
                  <Box sx={{flex: '95'}}>{props.title}</Box>
                  <Box sx={{flex: '5', marginTop: '6px'}}>
                    <Close onClick={() => handle_close_dialog()} />
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <Box className="paddingBottom">
                    <TextField
                        label="Tenant Label"
                        variant="standard"
                        sx={{width: '100%'}}
                    />
                  </Box>
                  <Box className="nameTextField ">
                    <Box className="firstName paddingBottom">
                      <TextField
                          label="First Name"
                          variant="standard"
                          sx={{width: '100%'}}
                      />
                    </Box>
                    <Box className="lastName paddingBottom focuspaddingBottom">
                      <TextField
                          label="Last Name"
                          variant="standard"
                          sx={{width: '100%'}}
                      />
                    </Box>
                  </Box>

                  <Box className="nameTextField ">
                    <Box className="firstName paddingBottom">
                      <TextField
                          label="super Admin Email"
                          variant="standard"
                          sx={{width: '100%'}}
                      />
                    </Box>
                    <Box className="lastName paddingBottom">
                      <TextField
                          label="Super Admin Password"
                          variant="standard"
                          sx={{width: '100%'}}
                      />
                    </Box>
                  </Box>

                  <Box className="paddingBottom">
                    <TextField
                        label="Domain Name"
                        variant="standard"
                        sx={{width: '100%'}}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handle_close_dialog()}>Cancel</Button>
                  <Button onClick={() => handle_close_dialog()}>
                    {props.buttonName}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
        ) : props.dialog === 'onBoardDialog' ? (
            <Dialog
                open={true}
                fullWidth={true}
                fullScreen={fullScreen}
                onClose={handleShowOnBoardDialogClose}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
                maxWidth="md"
            >
              <DialogTitle
                  style={{
                    color: 'white',
                    fontSize: '20px',
                    backgroundColor: 'rgb(20,19,139)',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
              >
                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                  Selected Gateways for Onboarding
                </Box>
                <span>No: of scans</span>
                <Select
                    value={scanCount}
                    onChange={handleScanCount}
                    variant="standard"
                    sx={{
                      minWidth: '60px',
                      background: '#fff',
                      color: '#000',
                      textIndent: '12px',
                      margin: '0px 14px 0px 14px'
                    }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
                <span style={{marginTop: '6px'}}>
              <Close onClick={() => handleShowOnBoardDialogClose()} />
            </span>
              </DialogTitle>
              <DialogContent>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{minWidth: '100px'}}>
                          <b>Name</b>
                        </TableCell>
                        <TableCell sx={{minWidth: '100px'}}>
                          <b>Serial Number</b>
                        </TableCell>
                        <TableCell sx={{minWidth: '100px'}}>
                          <b>Scanning Status</b>
                        </TableCell>
                        {
                          isLoading || onBoardingComplted ?
                              <TableCell sx={{minWidth: '40px'}}>
                              </TableCell>
                              :
                              null
                        }
                        {
                          scanCount >= 1 ?
                              <TableCell sx={{minWidth: '100px'}}>
                                <b>Scan 1</b>
                              </TableCell> :
                              null
                        }
                        {
                          scanCount >= 2 ?
                              <TableCell sx={{minWidth: '100px'}}>
                                <b>Scan 2</b>
                              </TableCell> :
                              null
                        }

                        {
                          scanCount >= 3 ?
                              <TableCell sx={{minWidth: '100px'}}>
                                <b>Scan 3</b>
                              </TableCell> :
                              null
                        }
                        {
                          scanCount >= 4 ?
                              <TableCell sx={{minWidth: '100px'}}>
                                <b>Scan 4</b>
                              </TableCell> :
                              null
                        }
                        {
                          scanCount >= 5 ?
                              <TableCell sx={{minWidth: '100px'}}>
                                <b>Scan 5</b>
                              </TableCell> :
                              null
                        }


                      </TableRow>
                    </TableHead>
                    <TableBody sx={{height: 'auto'}}>
                      {onboardData.length > 0
                          ? onboardData.map((row, index) => {
                            return (
                                <TableRow>
                                  <TableCell sx={{minWidth: '100px'}}>
                                    {row.name}
                                  </TableCell>
                                  <TableCell sx={{minWidth: '100px'}}>
                                    {row.macId}
                                  </TableCell>
                                  <TableCell sx={{minWidth: '100px'}}>
                                    {row.scanPassCount}/{scanCount}
                                  </TableCell>

                                  {
                                    row.isLoading ?
                                        <TableCell sx={{minWidth: '40px'}}>
                                          <CircularProgress thickness="3" sx={{height:'20px !important',width: '20px !important',color: 'rgb(20,19,139) !important'}}/>
                                        </TableCell>
                                        :
                                          null

                                  }
                                  {
                                    row.onBoardingComplted ?
                                      <TableCell sx={{minWidth: '40px'}}>
                                        <Done sx={{color: "#008000"}} />
                                      </TableCell>

                                    :
                                      null
                                  }

                                  {
                                    scanCount >= 1 ?
                                        <TableCell id={"pass1" + row.macId} sx={{minWidth: '100px'}}>
                                          -
                                        </TableCell> :
                                        null
                                  }
                                  {
                                    scanCount >= 2 ?
                                        <TableCell  id={"pass2"+row.macId} sx={{minWidth: '100px'}}>
                                          -
                                        </TableCell> :
                                        null
                                  }

                                  {
                                    scanCount >= 3 ?
                                        <TableCell id={"pass3"+row.macId} sx={{minWidth: '100px'}}>
                                          -
                                        </TableCell> :
                                        null
                                  }
                                  {
                                    scanCount >= 4 ?
                                        <TableCell id={"pass4"+row.macId} sx={{minWidth: '100px'}}>
                                          -
                                        </TableCell> :
                                        null
                                  }
                                  {
                                    scanCount >= 5 ?
                                        <TableCell id={"pass5"+ row.macId} sx={{minWidth: '100px'}}>
                                          -
                                        </TableCell> :
                                        null
                                  }
                                </TableRow>
                            );
                          })
                          : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
              <DialogActions>
                <Button
                    onClick={() => handleShowOnBoardDialogClose()}
                    style={{marginRight: allOnBoardingComplted ? '10px' :'auto', color: 'red'}}
                >
                  Cancel
                </Button>
                {
                  allOnBoardingComplted ?
                    <CsvDownload
                        data={downloadCSVData}
                        filename={"On-boarding.csv"}
                        style={{
                          border: 'transparent',
                          background: 'transparent',
                          marginRight:"auto"
                        }}           
                    >
                      <Tooltip title="Download CSV" placement="top" arrow>
                        <Download style={{marginRight: 'auto', cursor: 'pointer',color: '#0000008A'}} />
                      </Tooltip>
                  </CsvDownload>
                :
                    null
                }
                {
                  !isLoading ?
                      <Button onClick={() => startonBoarding(props.rowsSelected)}>Start</Button>:
                      <Button onClick={() => stoponBoarding(props.rowsSelected)}>Stop</Button>
                }

              </DialogActions>
            </Dialog>
        ) : props.dialog === 'gatewayLogDialog' ? (
            <Dialog
                open={props.show_gateway_log_dialog}
                fullWidth={true}
                fullScreen={fullScreen}
                onClose={handleShowGatewayLogDialog}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
                maxWidth="md"
            >
              <DialogTitle
                  style={{
                    color: 'white',
                    fontSize: '20px',
                    backgroundColor: 'rgb(20,19,139)',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
              >
                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                  {props.data.name} 
                </Box>
                <span style={{marginTop: '6px'}}>
                  <>
                {
                  gatewayStatusMessage && props.data.status === "CONNECTED" && showDownloadCsvIcon ?
                    gatewayStatusMessage.currentState !== "ScanningState" && gatewayStatusMessage.currentState !== "LockProbingState" ?
                      <>
                        <Tooltip title="Download Scanned Locks" placement='top' arrow>
                          <Download onClick={()=> downloadLocksData()} sx={{fontSize:"22px", cursor:"pointer"}} />
                        </Tooltip>
                        <CsvDownload
                            data={window.locksAndSerialNumbersData || locksAndSerialNumbersCsvData}
                            filename={"ScannedData.csv"}
                            style={{
                              border: 'transparent',
                              background: 'transparent',
                              visibility: 'hidden',
                              position:'absolute'
                            }}
                            id="lockSerialNumbersNameCsvData"
                        >
                          <Tooltip title="Download CSV" placement="top" arrow>
                            <Download/>
                          </Tooltip>
                        </CsvDownload>
                      </>
                    :
                      null
                  :
                    null
                }
                </>
              <Close sx={{marginLeft:"6px"}} onClick={() => handleShowGatewayLogDialog()} />
            </span>
              </DialogTitle>
              <DialogContent
                  sx={{padding: '0px', height: '100%', overflow: 'hidden'}}
              >
                <Box className={styles.gatewayStatusDialogActivityLogs} sx={{display: 'flex', flexDirection: 'row',}}>
                  <Box className={styles.leftPane} sx={{display: 'flex', flexDirection: 'column', width : !(isCaribouTechDomain()) ? '100%' : 'none'}}>
                    <Box>
                      <Box
                          style={{
                            color: 'white',
                            fontSize: '20px',
                            backgroundColor: 'rgb(20,19,139)',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            alignItems:'center',
                            height: '65px'
                          }}
                      >
                      <Box>
                        <Tooltip
                            title="Scan"
                            placement="top"
                            arrow
                            style={{cursor: 'pointer'}}
                        >
                          <Refresh sx={{margin: '4px auto 4px auto'}} onClick={()=> 
                            {
                              publishMessage('gateways/'+sessionStorage.tenantId+'/command/'+props.data.macId ,props.data.macId,"scan")
                              let payload = {
                                "macId" : props.data.macId,
                                "scanMadeBy" : "Portal",
                                "date": new Date().getTime(),
                                "type": "event",      
                              }              
                              GatewayServiceClient.createLockUnlockEntry(payload).then((response) => {})
                            }} />
                        </Tooltip>
                      </Box>
                      <Box>
                        <Tooltip
                            title="Force Idle"
                            placement="top"
                            arrow
                            style={{cursor: 'pointer'}}
                        >
                          <PanTool sx={{margin: '4px auto 4px auto'}} onClick={()=> publishMessage('gateways/'+sessionStorage.tenantId+'/command/'+props.data.macId ,props.data.macId,"forceIdle")}/>
                        </Tooltip>
                      </Box>
                      <Box>
                        <Tooltip
                            title="Whitelist"
                            placement="top"
                            arrow
                            style={{cursor: 'pointer'}}
                        >
                          <AssignmentTurnedIn
                              onClick={() => handleWhiteListDialog(true)}
                              sx={{margin: '4px auto 4px auto'}}
                          />
                        </Tooltip>
                      </Box>
                        <Box sx={{width: '24px',display:'flex',cursor:'pointer',marginBottom:'6px'}}>
                            <Tooltip
                                title="Clear Blacklist"
                                placement="top"
                                arrow
                                style={{cursor: 'pointer'}}
                            >
                              <AssignmentLate onClick={()=> handleClearBlackListDialog(true)}/>
                            </Tooltip>
                            <Tooltip
                                title="Clear Blacklist"
                                placement="top"
                                arrow
                                style={{cursor: 'pointer'}}
                            >
                                <Close sx={{position: 'relative',top: '5px',left: '-19px',fontSize: '15px',fontWeight: 700,background: '#fff',color: '#20378b'}} onClick={()=> handleClearBlackListDialog(true)} />
                            </Tooltip>
                      </Box>
                      <Box style={{display: JSON.parse(sessionStorage.userDetails).user.permissions.includes("TOP_LEVEL_SCOPE_READ") ? null : 'none'}}>
                        <Tooltip
                            title="Force Probe"
                            placement="top"
                            arrow
                            style={{cursor: 'pointer'}}
                        >
                          <SyncLock
                              onClick={() => handleForceProbeDialog(true)}
                              sx={{margin: '4px auto 4px auto'}}
                          />
                        </Tooltip>
                       </Box>

                      <Box>
                        <Tooltip
                            title="Restart"
                            placement="top"
                            arrow
                            style={{cursor: 'pointer'}}
                        >
                          <SettingsBackupRestore
                              onClick={() => handleRestartDialog(true)}
                              sx={{margin: '4px auto 4px auto'}}
                          />
                        </Tooltip>
                       </Box>
                       {
                            isCaribouTechDomain() && Dimensions.width < 960 ?
                               <Box>
                                    <Tooltip
                                        title="Gateway Log"
                                        placement="top"
                                        arrow
                                        style={{cursor: 'pointer'}}
                                    >
                                        <Description sx={{margin: '4px auto 4px auto'}} onClick={()=> setShowActivityLogPane(true)}/>
                                    </Tooltip>
                               </Box>
                           :
                            null
                        }
                      </Box>
                      <Box
                          sx={{
                            width: '100%',
                            color: 'white',
                            fontSize: '20px',
                            backgroundColor: 'rgb(20,19,139)',
                          }}
                      >
                        &nbsp;
                      </Box>
                    </Box>
                    <Box
                        sx={{
                          height:
                              Dimensions.width >= 600
                                  ? `${Dimensions.height - 230}px`
                                  : `${Dimensions.height - 170}px`,
                          overflowY: 'auto',
                          borderRight:"1px solid #c1a6a6"
                        }}
                    >
                     {
                        props.data.status === "CONNECTED"  ?
                           <>
                              <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '12px',
                                  }}
                              >
                                <Box sx={{width: '50%'}}>Current State</Box>
                                
                                <Box sx={{textAlign: 'right', width: '50%',display: 'flex',flexDirection:"column"}}>
                                    <Box sx={{display:'flex',marginLeft:"auto"}}>
                                      {gatewayStatusMessage ? gatewayStatusMessage.currentState : "(/Rssi ())"}
                                    </Box>
                                  {
                                    gatewayStatusMessage ?

                                      gatewayStatusMessage.currentLock !== '' ?
                                        <Box sx={{display:'flex', marginLeft: "auto"}}>
                                          ({gatewayStatusMessage.currentLock}) / Rssi({gatewayStatusMessage.currentLockRssi})
                                        </Box>
                                      :
                                        null
                                    : 
                                      null
                                  }
                                <Box>
                              </Box>
                              </Box>
                              </Box>
                              {
                                    gatewayStatusMessage ? 


                                      gatewayStatusMessage.whitelistedLocksCount === "*" || typeof gatewayStatusMessage.whitelistedLocksCount === 'number' ?
                                        
                                        typeof gatewayStatusMessage.whitelistedLocksCount === 'number' ?
                                          
                                          gatewayStatusMessage.whitelistedLocksCount > 0 ?
                                          
                                            <Box
                                                sx={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  padding: '12px',
                                                }}
                                            >
                                              <Box sx={{width: '50%'}}>No. of Whitelisted Locks</Box>
                                              <Box sx={{textAlign: 'right', width: '50%'}}>
                                                  {gatewayStatusMessage ? gatewayStatusMessage.whitelistedLocksCount : "-"}                                              
                                              </Box>
                                            </Box>
                                          :
                                            null

                                        :
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              padding: '12px',
                                            }}
                                        >
                                          <Box sx={{width: '50%'}}>No. of Whitelisted Locks</Box>
                                          <Box sx={{textAlign: 'right', width: '50%'}}>
                                              {gatewayStatusMessage ? gatewayStatusMessage.whitelistedLocksCount : "-"}                                              
                                          </Box>
                                        </Box>
                                      :
                                        null



                                    :
                                      null
                              }
                              <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '12px',
                                  }}
                              >
                                <Box sx={{width: '50%'}}>No. of Scanned Locks</Box>
                                <Box sx={{textAlign: 'right', width: '50%'}}>
                                    {gatewayStatusMessage ? (gatewayStatusMessage.whitelistedLocksCount === "*" || typeof gatewayStatusMessage.whitelistedLocksCount === 'number'  ? gatewayStatusMessage.whitelistedLocksCount === 'number' ? (gatewayStatusMessage.totalWhitelistedScannedLocks > 0 ? gatewayStatusMessage.totalWhitelistedScannedLocks : 0) : gatewayStatusMessage.totalWhitelistedScannedLocks !== null ? gatewayStatusMessage.totalWhitelistedScannedLocks : 0 : gatewayStatusMessage.totalScannedLocks !== null ? gatewayStatusMessage.totalScannedLocks : 0) : "-"}
                                    &nbsp;
                                    <span>
                                      <Tooltip title={gatewayStatusMessage ? gatewayStatusMessage.unreacheableLocksList !== null ? Object.keys(gatewayStatusMessage.unreacheableLocksList).length > 0 ? Object.keys(gatewayStatusMessage.unreacheableLocksList).map((item)=>{
                                          return (
                                              <>
                                                {gatewayStatusMessage.unreacheableLocksList[item]}
                                                <br/>
                                              </>
                                            )
                                      }) : "No information available" : "No information available" : "No information available" } placement='top' arrow>
                                        <InfoOutlined sx={{height: '20px',position: 'relative',top: '4px'}}/>
                                      </Tooltip>
                                    </span>
                                </Box>
                              </Box>
                              {
                                gatewayStatusMessage ?
                                  gatewayStatusMessage.totalScannedLocksCount?
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '12px',
                                      }}
                                    >
                                      <Box sx={{width: '50%'}}>Reachable Whitelisted Locks</Box>
                                      <Box sx={{textAlign: 'right', width: '50%'}}>
                                          {gatewayStatusMessage.totalScannedLocksCount !== null ? gatewayStatusMessage.totalScannedLocksCount: 0 }
                                      </Box>
                                    </Box>
                                  :
                                    null
                                :
                                  null
                              }
                              <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '12px',
                                  }}
                              >
                                <Box sx={{width: '50%'}}>
                                  <span>Scanning Frequency:</span>
                                  <br />
                                  <span style={{fontSize: '12px'}}>
                                    Can be edited only in idle state
                                  </span>
                                </Box>
                                <Box sx={{textAlign: 'right', width: '50%'}} className="scanOrRssiApplyDiv">
                                    <Box classname="minsTextField" sx={{width:"100%"}}>
                                      {
                                        showScanFrequencyApplyingLoader === false?
                                          <>
                                            <TextField 
                                                min="1"
                                                max="10800"
                                                type="number"
                                                className='scanTextField'                                          
                                                value={pollInterval}
                                                onChange={(event) => handlePollIntervalChange(event.target.value)}
                                                helperText={pollIntervalHelperText}
                                                error={pollIntervalError}
                                                variant="standard"
                                                disabled={gatewayStatusMessage ? gatewayStatusMessage.currentState !== "IdleState" ? true : false  : true}
                                                sx={{width:'66px'}}
                                            />
                                            <span style={{position:'relative',margin:"4px",top:"5px"}}>
                                              mins
                                            </span>                                                                                                            
                                          </>
                                        :
                                          <Box>
                                            <Box sx={{fontSize:"12px" }}>
                                              applying changes ...                                          
                                              <span sx={{marginLeft:"10px"}}>
                                                <CircularProgress thickness="4" sx={{height:'24px !important',width: '24px !important',color: 'rgb(20,19,139) !important',position:'relative',top:"8px"}}/>
                                              </span>
                                            </Box>
                                          </Box>
                                      }
                                    </Box>   
                                    {
                                      showScanFrequencyApplyingLoader === false?
                                        <Box>
                                          <Tooltip title= {showPollTimerApplyButton ? "Click to apply the changes" : ""} placement='top'  arrow>
                                            <CheckCircle onClick={() => {
                                              if (showPollTimerApplyButton)
                                                applyScanFrequency(pollInterval)
                                            }} sx={{cursor:showPollTimerApplyButton ? "pointer" : "auto",position:"relative",top:"4px",color:showPollTimerApplyButton ? "#008000" : "#b5c8cf",height:"24px",width:"20px"}}/>
                                          </Tooltip>
                                        </Box>                             
                                      :
                                        null
                                    }
                                </Box>
                              </Box>
                              <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '12px',
                                  }}
                              >
                                <Box sx={{width: '50%'}}>
                                  <span>Rssi Value:</span>
                                  <br />
                                  <span style={{fontSize: '12px'}}>
                                    Can be edited only in idle state
                                  </span>
                                </Box>
                                <Box sx={{textAlign: 'right', width: '50%'}}  className="scanOrRssiApplyDiv">
                                  <Box classname="minsTextField" sx={{width:"100%"}}>
                                    {
                                      showRssiApplyLoader === false ?
                                        <>
                                          <TextField 
                                            min="-1000"
                                            max="0"
                                            type="number"    
                                            className='scanTextField'                                      
                                            value={rssi}
                                            onChange={(event) => handleRssiChange(event.target.value)}
                                            helperText={rssiHelperText}
                                            error={rssiError}
                                            variant="standard"
                                            disabled={gatewayStatusMessage ? gatewayStatusMessage.currentState !== "IdleState" ? true : false  : true}
                                            sx={{width:'66px'}}
                                          />
                                          <span style={{position:'relative',margin:"4px",top:"5px",marginRight:"16px"}}>
                                            db
                                          </span>                                                                                                            
                                        </>
                                      :
                                        <Box>
                                          <Box sx={{fontSize:"12px" }}>
                                            applying changes ...                                          
                                            <span sx={{marginLeft:"10px"}}>
                                              <CircularProgress thickness="4" sx={{height:'24px !important',width: '24px !important',color: 'rgb(20,19,139) !important',position:'relative',top:"8px"}}/>
                                            </span>
                                          </Box>
                                        </Box>
                                    }
                                  </Box>
                                  {
                                    !showRssiApplyLoader ?
                                      <Box>
                                        <Tooltip title= {showRssiApplyButton ? "Click to apply the changes" : ""} placement='top'  arrow>
                                          <CheckCircle onClick={() => {
                                            if (showRssiApplyButton){
                                              applyRssiValue(rssi);
                                            }
                                          }} sx={{cursor:showRssiApplyButton ? "pointer" : "auto",position:"relative",top:"4px",color:showRssiApplyButton ? "#008000" : "#b5c8cf",height:"24px",width:"20px"}}/>
                                        </Tooltip>
                                      </Box>                             
                                    :
                                      null
                                  }
                                </Box>  
                              </Box>
                              <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '12px',
                                  }}
                              >
                                <Box sx={{width: '50%'}}>Next Scan at</Box>
                                <Box sx={{textAlign: 'right', width: '50%'}}>
                                  {gatewayStatusMessage ?  gatewayStatusMessage.nextScanAt !== "-" && gatewayStatusMessage.nextScanAt !== null ? moment(gatewayStatusMessage.nextScanAt).format("DD MMM yyyy, HH:mm:ss") : "-" : "-"}
                                </Box>
                              </Box>
                              {
                                gatewayStatusMessage ?
                                  gatewayStatusMessage.locksYetToProbeCount ?
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '12px',
                                      }}
                                    >
                                      <Box sx={{width: '50%'}}>Yet to Probe</Box>
                                      <Box sx={{textAlign: 'right', width: '50%'}}>
                                        {gatewayStatusMessage ?  gatewayStatusMessage.locksYetToProbeCount ? gatewayStatusMessage.locksYetToProbeCount : "-" : "-"}
                                      </Box>
                                    </Box>
                                  :
                                    null
                                :
                                  null
                              }

                              {
                                gatewayStatusMessage ?
                                  gatewayStatusMessage.locksYetToFetchHistoryCount ?
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '12px',
                                      }}
                                    >
                                      <Box sx={{width: '50%'}}>Yet to fetch History</Box>
                                      <Box sx={{textAlign: 'right', width: '50%'}}>
                                        {gatewayStatusMessage ?  gatewayStatusMessage.locksYetToFetchHistoryCount ? gatewayStatusMessage.locksYetToFetchHistoryCount : "-" : "-"}
                                      </Box>
                                    </Box>
                                  :
                                    null
                                :
                                  null
                              }

                              {
                                  gatewayStatusMessage ?
                                    gatewayStatusMessage.lockWithStrongestRssi !== "" ? 
                                    <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          padding: '12px',
                                        }}
                                      >
                                        <Box sx={{width: '50%'}}>Strongest Rssi</Box>
                                        <Box sx={{textAlign: 'right', width: '50%'}}>
                                          {
                                            gatewayStatusMessage ?
                                              gatewayStatusMessage.lockWithStrongestRssi !== "" && gatewayStatusMessage.lockWithStrongestRssi !== null? 
                                                <>
                                                  {gatewayStatusMessage.lockWithStrongestRssi} ({gatewayStatusMessage.strongestRssi})
                                                </>
                                              :
                                                "-"
                                            :
                                              "-"
                                          }
                                        </Box>
                                      </Box>
                                    :
                                      null
                                  :
                                    null
                              }
                              {
                                    gatewayStatusMessage ?
                                      gatewayStatusMessage.lockWithWeakestRssi !== "" ? 
                                      <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: '12px',
                                          }}
                                        >
                                          <Box sx={{width: '50%'}}>Weakest Rssi</Box>
                                          <Box sx={{textAlign: 'right', width: '50%'}}>
                                            {
                                              gatewayStatusMessage ?
                                                gatewayStatusMessage.lockWithWeakestRssi !== "" && gatewayStatusMessage.lockWithWeakestRssi !== null ? 
                                                  <>
                                                    {gatewayStatusMessage.lockWithWeakestRssi} ({gatewayStatusMessage.weakestRssi})
                                                  </>
                                                :
                                                  "-"
                                              :
                                                "-"
                                            }
                                          </Box>
                                        </Box>
                                      :
                                        null
                                    :
                                      null
                              }
                              {
                                    gatewayStatusMessage ?
                                      gatewayStatusMessage.locksToUpdateFwCount !== 0 ? 
                                      <Box
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: '12px',
                                          }}
                                        >
                                          <Box sx={{width: '50%'}}>Total Locks to update Firmware</Box>
                                          <Box sx={{textAlign: 'right', width: '50%'}}>
                                            {
                                              gatewayStatusMessage ?
                                                gatewayStatusMessage.locksToUpdateFwCount !== 0 ? 
                                                  gatewayStatusMessage.locksToUpdateFwCount
                                                :
                                                  "-"
                                              :
                                                "-"
                                            }
                                          </Box>
                                        </Box>
                                      :
                                        null
                                    :
                                      null
                              }
                          </>
                        :
                            <Box sx={{height: "100%",width:"100%",background:"#eff3f2",fontSize:"16px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                (Gateway is offline)
                            </Box>
                        }
                        </Box>
                  </Box>
                  <Box className={!showActivityLogPane ? styles.rightPane : styles.mobileRightPane} sx={{overflowX: 'hidden', display : !(isCaribouTechDomain()) && 'none'}}>
                    <Box
                        style={{
                          color: 'white',
                          fontSize: '20px',
                          backgroundColor: 'rgb(20,19,139)',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                    >
                      <Box sx={{width: '100%',display:'flex'}}>
                        {
                            showActivityLogPane && Dimensions.width < 960 ?
                                <ArrowBack onClick={()=>setShowActivityLogPane(false)} sx={{margin:"4px 6px 0px 6px",cursor:'pointer'}}/>
                            :
                                null
                        }
                        <span style={{marginRight: 'auto',fontSize: '20px'}}>
                          Activity Feed ({ActivityLogsIntervalRange})
                        </span>
                        <PopupState variant="popover" popupId="demo-popup-popover">
                              {(popupState) => (
                                <Box sx={{marginLeft:'28px'}}>
                                  <Tooltip title="Interval" placement="top" arrow>
                                      <FilterList
                                          sx={{float: 'right', marginRight: '22px',cursor:"pointer"}}
                                          {...bindTrigger(popupState)}
                                      />
                                  </Tooltip>
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}

                                  >
                                    <Box sx={{display:'flex',flexDirection:'column',padding: '20px'}}>
                                        <Box sx={{margin:"auto"}}>
                                            Interval
                                            <Box sx={{position: 'absolute',top: '5px',right: '8px'}}>
                                              <Close sx={{cursor:"pointer",fontSize:"20px"}} onClick={()=>popupState.close()}/>
                                            </Box>
                                        </Box>
                                        <Box sx={{width: "150px"}}>
                                            <FormControl variant="standard" sx={{width:"100%"}}>
                                                <Select value={ActivityLogsIntervalRange} onChange={(event)=> {changeLogInterval(event.target.value);popupState.close()}} labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" sx={{width: '100%'}}>
                                                    <MenuItem value="1 hour">1 hour</MenuItem>
                                                    <MenuItem value="6 hours">6 hours</MenuItem>
                                                    <MenuItem value="24 hours">24 hours</MenuItem>
                                                    <MenuItem value="1 week">1 week</MenuItem>
                                                    <MenuItem value="2 weeks">2 weeks</MenuItem>
                                                    <MenuItem value="1 month">1 month</MenuItem>
                                                    <MenuItem value="3 months">3 months</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                  </Popover>
                                </Box>
                                )}
                        </PopupState>
                      </Box>
                      <Box sx={{width: '100%',fontSize: '20px',marginLeft:"6px"}}>
                        Alerts({alertsCount})/Events({eventsCount})
                      </Box>
                      <Box className={`${styles.autocompleteDiv} ${'autocompleteblock'}`}>
                        <Autocomplete
                          freeSolo
                          options={searchOptions.map((option) => option)}
                          renderInput={(params) => <TextField {...params} placeholder="Search ..." sx={{height:'100%'}} />}
                          sx={{height:'100%'}}
                          id="activityFeedSearchField"
                          onChange={(event,value)=> handleActivityFeedSearch(value)}
                        />
                      </Box>
                    </Box>
                    <Box
                        sx={{
                          height:
                              Dimensions.width >= 600
                                  ? 'calc(70vh - 96px)'
                                  : `${Dimensions.height - 170}px`,
                          overflowY: 'auto',
                          width:'100%',
                          display: 'flex',
                          justifyContent:'center'
                        }}

                    >
                      <Box className="listRepeater" sx={{alignItems:'center',justifyContent:'center',display:'flex',height: '98%'}}>
                        {
                            !activityLogsLoading ?
                                allActivityLogDates.length > 0 ?
                                    <List
                                          sx={{
                                            width: '100%',
                                            height: '100%',
                                            bgcolor: 'background.paper',
                                            position: 'relative',
                                            overflow: 'auto',
                                            '& ul': { padding: 0 },
                                          }}
                                          subheader={<li />}
                                        >
                                          {
                                            Object.keys(logs).length > 0 ?
                                              Object.keys(logs).map((item,ind) => (
                                                <li key={`section-${ind}`}>
                                                  <ul>
                                                    <ListSubheader>
                                                      <Moment format="ddd MMMM Do, YYYY" >
                                                        {item} 
                                                      </Moment>
                                                    </ListSubheader>
                                                    <AutoSizer disableHeight disableWidth>
                                                        {({width, height}) => (
                                                          <VirtualList
                                                            className="List"                                                      
                                                            rowHeight={Dimensions.width < 400 ? 104 : 90}                                                      
                                                            rowRenderer={({key, index , style, parent })=>{
                                                              const val = logs[item][index];
                                                              return (
                                                                <Box style={style} className="listSubDiv" sx={{display:'flex',flexDirection: 'row',height: Dimensions.width < 400 ? "104px" :  '90px'}}>
                                                                  <Box className="subDiv" sx={{height: Dimensions.width < 400 ? "104px" :  '90px',width: val.name === "Gateway Error" ? "40%" : "65%",display:'flex',flexDirection:'column'}}>
                                                                      <Box sx={{height:'50%',color: val.hasOwnProperty("color") ? val.color : "black",padding:'8px 0px 0px 10px'}}>
                                                                        {moment(val.time).format("h:mm:ss A")}
                                                                      </Box>
                                                                      <Box sx={{height:'50%',color: val.hasOwnProperty("color") ? val.color : "black",padding:'8px 0px 0px 10px'}}>
                                                                        {val.name}
                                                                      </Box>
                                                                  </Box>
                                                                  <Box className="listSubDiv" sx={{height: Dimensions.width < 400 ? "104px" :  '90px',textAlign:"center",width: val.name === "Gateway Error" ? "60%" :'35%',color: val.hasOwnProperty("color") ? val.color : "black", background: '#fff',display: "flex",alignItems:"center",justifyContent:"center",whiteSpace: val.value ? val.value.indexOf("->") !== -1 ? 'pre-line' : null : null}}>
                                                                      {val.value}
                                                                  </Box>
                                                                </Box>
                                                              );
                                                            }}
                                                            rowCount={logs[item].length}                                                     
                                                            width={1}
                                                            height={Object.keys(logs).length > 1 ? (logs[item].length * 84 < 336 ? logs[item].length * 84 : 336) : logs[item].length * 84}
                                                            containerStyle={{
                                                              width: "100%",  
                                                              maxWidth: "100%"
                                                            }}
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          />  
                                                        )}
                                                    </AutoSizer>        
                                                </ul>
                                              </li>
                                            ))
                                          :
                                            null
                                        }
                                        </List>
                                    :
                                        <Box>
                                            (No Gateway logs)
                                        </Box>
                                :
                                    <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100px'}}>
                                        <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                    </Box>
                            }
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
        ) : props.dialog === 'infoDialog' ? (
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.show_info_dialog}
                onClose={handleInfoDialog}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
                maxWidth="md"
            >
              <DialogTitle
                  style={{
                    color: 'white',
                    fontSize: '20px',
                    backgroundColor: 'rgb(20,19,139)',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
              >
                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                  Gateway Configuration
                </Box>
                <span style={{marginTop: '6px'}}>
              <Close onClick={() => handleInfoDialog()} />
            </span>
              </DialogTitle>
              <DialogContent
                  sx={{padding: '0px', height: '100%', overflow: 'hidden'}}
              >
                <Box
                    sx={{
                      display: 'flex',
                      flexDirection: Dimensions.width <= 600 ? 'column' : 'row',
                      padding: '14px',
                    }}
                >
                  <Box
                      sx={{
                        margin: '10px',
                        width: Dimensions.width <= 600 ? '90%' : '50%',
                      }}
                  >
                    <TextField
                        label="Gateway Name"
                        variant="standard"
                        sx={{width: '100%', margin: '10px'}}
                        value={gatewayName}
                        onChange={(event) => setGatewayName(event.target.value)}
                    />
                    <Box>&nbsp;</Box>
                    <TextField
                        disabled
                        label="Gateway MacId"
                        variant="standard"
                        sx={{width: '100%', margin: '10px'}}
                        value={props.data.macId}
                    />
                  </Box>
                  <Box
                      sx={{
                        margin: '10px',
                        width: Dimensions.width <= 600 ? '90%' : '50%',
                      }}
                  >
                    <TextField
                        disabled
                        label="Manufacturer"
                        variant="standard"
                        sx={{width: '100%', margin: '10px'}}
                        value={props.data.manufacturer}
                    />
                    <Box>&nbsp;</Box>
                    <FormControl
                        variant="standard"
                        sx={{width: '100%', margin: '10px', marginTop: '10px'}}
                    >
                      <InputLabel id="selectCustomer" sx={{margin: '0px'}}>
                        Select Customer
                      </InputLabel>
                      <Select
                          id="selectCustomer"
                          value={gatewayCustomer}
                          MenuProps={{PaperProps: {style: {maxHeight: '150px'}}}}
                          onChange={(event) => setGatewayCustomer(event.target.value)}
                          style={{
                            width: '100%',
                            marginTop: '16px',
                            textAlign: 'left',
                          }}
                      >

                        {
                         Object.keys(allCustomersObj).length > 0 ?
                            (Object.keys(allCustomersObj).sort((first, next) =>
                              first.localeCompare(next)
                            )).map((element, index) => {
                              if (element.toLocaleLowerCase() !== "freetier"){
                                return <MenuItem value={element}>{element}</MenuItem>;
                              }
                            })
                        :
                          null
                        }

                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions className="downloadLogsDiv">
                {
                  (JSON.parse(sessionStorage.userDetails).user.email.indexOf("wesko-elocks.com") !== -1 || JSON.parse(sessionStorage.userDetails).user.email.indexOf("cariboutech.com")) && props.data.status === "CONNECTED" ?
                    <Box sx={{marginLeft:'16px',display:"flex",flexDirection:"row"}}>
                      <Box>
                        <Tooltip placement='top' title="Request for Device logs" arrow>
                          <PresentToAll sx={{color:'rgb(20,19,139)',cursor: 'pointer'}} onClick={()=> pullDeviceLogs()}/>
                        </Tooltip>
                      </Box>
                      {
                        showSpinner && !showDownloadLink ?
                          <div style={{position: 'relative',marginLeft:"17px"}}>
                            <CircularProgress thickness="4" sx={{height:'26px !important',width: '26px !important',color: 'rgb(20,19,139) !important'}}/>
                            <span style={{position: 'absolute',top: "8px",marginLeft:'-29px',width: "100%",fontSize:' 7px',textAlign: "center"}}>{spinnerTimeoutCount}</span>
                            <Close  sx={{color: 'rgb(20,19,139)',position: 'relative',top: '-19px',fontSize: '14px',width: '9px',zIndex: '10',left: '-8px',height: '11px',background: '#fff',cursor:"pointer"}} onClick={()=>unsubscribeChannel()} />
                          </div>
                        :
                          showDownloadLink ?
                            <Tooltip placement='top' title="Download Device Logs" arrow>
                              <Box sx={{marginLeft:"17px"}}>
                                  <a href={downloadLink} download>
                                    <Download sx={{color:'rgb(20,19,139)',cursor: 'pointer'}} />
                                  </a>
                              </Box>
                            </Tooltip>
                          :
                            null
                      }
                    </Box>
                  :
                    null
                }
                <Box className="cancelButton">
                  <Button onClick={() => handleInfoDialog()} style={{color: 'red'}}>
                    Cancel
                  </Button>
                  <Button
                   style={{
                      display: JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_WRITE") ? null : 'none',
                      flexDirection:"row",
                    }}
                    disabled={gatewayName === props.data.name && gatewayCustomer === sessionStorage.tenantName}
                  onClick={() => handleUpdateGateway(props.data)}>Submit</Button>
                </Box>
              </DialogActions>
              {
                userNetworkLoadingGW ?
                    <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '70%',position: 'absolute',top: '70px',right:'8px'}}>
                      <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                    </Box>
                    :
                    null
              }

              {
                showSnackbar ?
                    <Snackbar
                        anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                        open={showSnackbar}
                        autoHideDuration={3000}
                        message={showSnackBarMessage}
                    />
                    :
                    null
              }
            </Dialog>
        ) : props.dialog === 'pendingInstructionDialog' ? (
            <Dialog
                open={props.show_pending_instruction_dialog}
                fullWidth={true}
                fullScreen={fullScreen}
                onClose={handlePendingInstructionsDialog}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
                maxWidth="md"
            >
              {
                !showAbortPendingDialog ?
                  <>
                      <DialogTitle
                          style={{
                            color: 'white',
                            fontSize: '20px',
                            backgroundColor: 'rgb(20,19,139)',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                      >
                        <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                          List of Pending Instructions for {props.data.name}
                        </Box>
                        <span style={{marginTop: '6px'}}>
                      <Close onClick={() => handlePendingInstructionsDialog()} />
                    </span>
                      </DialogTitle>
                      <DialogContent>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                              <TableCell
                                sx={{
                                  padding: '16px',
                                  fontSize: '13px',
                                  width: "10px !important"
                                }}
                              >
                                <Checkbox
                                  color="error"
                                  onClick={(event) => selectAllPendingInstructions(event)}
                                  sx={{margin: '0px', padding: '0px',left: "-3px"}}
                                />
                              </TableCell>
                                <TableCell sx={{minWidth: '100px'}}>
                                  <b>Pending Instructions</b>
                                </TableCell>
                                <TableCell sx={{minWidth: '100px'}}>
                                  <b>Status</b>
                                </TableCell>
                                <TableCell sx={{minWidth: '100px'}}>
                                  <b>Lock Name</b>
                                </TableCell>
                                <TableCell>Date And Time</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody sx={{background: '#fff'}}>
                              {stableSort(pendingInstructionData, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                // eslint-disable-next-line array-callback-return
                                .map((row, index) => {
                                    {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                        className={paginationStyle.tableBodyRow}
                                        sx={{borderBottom:'1px solid #c1a6a6'}}
                                      >
                                            <TableCell
                                            sx={{
                                              padding: '16px',
                                              fontSize: '13px',
                                              width: "10px !important"
                                            }}
                                          >
                                            <Checkbox
                                              color="error"
                                              checked={row.isChecked}
                                              onClick={(event) => unselectPendingInstruction(event,row)}
                                              sx={{margin: '0px', padding: '0px',left: "-3px"}}
                                            />
                                          </TableCell>
                                            <TableCell sx={{minWidth: '100px'}}>{row.type}</TableCell>
                                            <TableCell sx={{minWidth: '100px'}}>{row.status}</TableCell>
                                            <TableCell sx={{minWidth: '100px'}}>
                                              {row.payload.name ? row.payload.name : "-"}
                                            </TableCell>
                                            <TableCell sx={{minWidth: '100px'}}>{row.payload.time ? row.payload.time : "-"}</TableCell>
                                      </TableRow>
                                    );
                                  }
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        </DialogContent>
                        <DialogActions sx={{display:"flex",flexDirection:"column"}}>
                          <Box sx={{marginLeft:"auto"}}>
                            <Button sx={{color:"red"}} disabled={pendingInstructionDataSelectedCount === 0} onClick={()=> abortInstruction()}>
                              Abort
                            </Button>
                          </Box>
                          <TablePagination
                              sx={{width: '100%', overflowX: 'hidden'}}
                              labelRowsPerPage={infoRowPerPageLabel}
                              rowsPerPageOptions={[5, 10, 15, 20, 25]}
                              component="div"
                              count={pendingInstructionData.length}
                              className="pagination"
                              rowsPerPage={rowsPerPage}
                              page={pageNumber}
                              SelectProps={{
                                inputProps: {'aria-label': 'rows per page'},
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                          />
                      </DialogActions>
                  </>
                :
                  <>
                    <DialogTitle>
                      <Box id="draggable-dialog-pending0title" style={{marginRight: 'auto'}}>
                        Abort Instructions
                      </Box>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure, you want to abort {pendingInstructionDataSelectedCount} instruction ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                          onClick={() => handlePendingInstructionsDialog()}
                          style={{color: 'red'}}
                      >
                        Cancel
                      </Button>
                      <Button onClick={()=> handleAbortPendingInstruction()}>Yes</Button>
                    </DialogActions>
                  </>
              }
            </Dialog>

            //    Create Portal User

        ) : props.dialog === 'permissionDialog' ? (
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.show_permission_dialog}
                onClose={handlePermissionDialog}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
                maxWidth="md"
            >
              <DialogTitle
                  style={{
                    color: 'white',
                    fontSize: '20px',
                    backgroundColor: 'rgb(20,19,139)',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
              >
                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                  {props.title}
                </Box>
                <span style={{marginTop: '6px',cursor:"pointer"}}>
              <Close onClick={() => handlePermissionDialog()} />
            </span>
              </DialogTitle>
              <DialogContent
                  sx={{
                    padding: '0px',
                    height: '100%',
                    overflow: Dimensions.width > 600 ? 'hidden' : 'auto',
                    overflowX: 'hidden',
                    opacity: userNetworkLoading ? 0.5 : null
                  }}
              >
                <Box
                    sx={{
                      display: Dimensions.width > 600 ? 'flex' : 'none',
                      flexDirection: Dimensions.width <= 600 ? 'column' : 'row',
                      margin: '14px 14px 0px 14px',
                    }}
                >
                  <Box sx={{width: '46%'}}>
                    <p style={{fontSize: '16px', fontWeight: 'bold'}}>
                      {' '}
                      {props.disabled ? 'Enter User Details' : 'User Details'}
                    </p>
                  </Box>
                  <Box sx={{margin: '14px 24px'}}>&nbsp;</Box>
                  <Box sx={{width: '50%'}}>
                    <p
                        style={{fontSize: '16px', fontWeight: 'bold', width: '100%'}}
                    >
                      {props.disabled ? 'Select Permissions' : 'Permissions'}
                    </p>
                  </Box>
                </Box>
                <Box
                    sx={{
                      display: 'flex',
                      flexDirection: Dimensions.width <= 600 ? 'column' : 'row',
                      margin:
                          Dimensions.width > 600
                              ? '0px 14px 0px 14px '
                              : '0px 14px 0px',
                      width: '98%',
                    }}
                >
                  <Box sx={{width: '100%'}} className="usersTextBox">
                    <p
                        style={{
                          display: Dimensions.width <= 600 ? 'flex' : 'none',
                          fontSize: '16px',
                          fontWeight: 'bold',
                        }}
                    >
                      {props.disabled ? 'Enter User Details' : 'User Details'}
                    </p>
                    <Box className="UsersDialog">
                        <Box className="nameTextField" sx={{ position:"relative"}}>
                          <TextField
                              disabled={props.disable}
                              label="First Name *"
                              variant="standard"
                              sx={{width: '100%', margin: '0px 0px'}}
                              //error={permissionData.firstName === ""}
                              helperText={(permissionData.firstName === "")? "First name is required.":" "}
                              inputProps={{
                                maxlength: 20
                              }}

                              //helperText={portalUserFirstNameHelperText()}
                              value={permissionData.firstName}
                              onChange={(event) => {
                                let value = event.target.value;
                                value = value === " " || value.indexOf(" ") === 0 ? checkSpace(value) : value;
                                setUserFirstName(value);
                                let data = { ...permissionData };
                                data.firstName = value;
                                data.firstNameTextLength = value !== "" && typeof value !== 'undefined' && value ? value.length + "/20" : "0/20";
                                setPermissionData({ ...data });
                              }
                              }
                          />
                          <Box sx={{position:"absolute",left:"94%",top:"50px",fontSize:"12px",right:"51%",color: permissionData.firstName !== "" && permissionData.firstName && typeof permissionData.firstName !== 'undefined' ? "#000000DE" : "red"}}>
                            {permissionData.firstName !== "" && permissionData.firstName && typeof permissionData.firstName !== 'undefined' ? permissionData.firstName.length + "/20" : "0/20"}
                          </Box>
                        </Box>
                    </Box>
                    <Box className="UsersDialog">
                        <Box className="nameTextField" sx={{position:"relative"}}>
                          <TextField
                              disabled={props.disable}
                              label="Last Name *"
                              variant="standard"
                              sx={{width: '100%', margin: '10px 0px', position: 'relative'}}
                              inputProps={{
                                maxlength: 20
                              }}
                              //helperText={portalUserLastNameHelperText()}
                              value={permissionData.lastName}
                              //error={permissionData.lastName === ""}
                              helperText={(permissionData.lastName === "")? "Last Name is required.": " "}
                              onChange={(event) => {
                                let value = event.target.value;
                                value = value === " " || value.indexOf(" ") === 0 ? checkSpace(value) : value;
                                setUserLastNAme(value);
                                let data = { ...permissionData };
                                data.lastName = value;
                                data.lastNameTextLength =  value !== "" && typeof value !== 'undefined' && value ? value.length + "/20" : "0/20";
                                setPermissionData({ ...data });
                              }}
                          />
                          <Box sx={{position:"absolute",left:"94%",top :"60px",fontSize:"12px",color: permissionData.lastName !== "" && permissionData.lastName && typeof permissionData.lastName !== 'undefined' ? "#000000DE" : "red"}}>
                            {permissionData.lastName !== "" && permissionData.lastName && typeof permissionData.lastName !== 'undefined' ? permissionData.lastName.length + "/20" : "0/20"}
                          </Box>
                        </Box>
                    </Box>
                    <Box className="UsersDialog">
                    <Box className="nameTextField">
                    <TextField
                        disabled={props.disable || props.button_name === 'Update'}
                        label="Email *"
                        type="email"
                        variant="standard"
                        sx={{width: '100%', margin: '10px 0px'}}
                        helperText={props.title === "Create Portal User" ? permissionData.email === "" ||  permissionData.email ? !permissionData.email.match("^[a-zA-Z0-9+_.\-]+@[a-zA-Z0-9_.\-]+[.]{1}[a-z]{2,3}$") ? "Email is required." : null : null : null}
                        value={permissionData.email}
                        onChange={(event) => {
                          let value = event.target.value;
                          setUserEmail(event.target.value);
                          let data = { ...permissionData };
                          data.email = event.target.value;
                          setPermissionData({ ...data });
                        }}
                    />
                    </Box>
                    </Box>
                    <FormControl
                        disabled={props.disable}
                        variant="standard"
                        sx={{width: '100%', margin: '10px 0px'}}
                    >
                      <InputLabel id="role">Role</InputLabel>
                      <Select
                          id="role"
                          value={userRole}
                          onChange={(event) => {
                            let value = event.target.value;
                            setUserRole(event.target.value);
                            let data = Object.assign({},permissionData);
                            if (value === Enums.globals.SUPER_ADMIN_ROLE){
                              if (sessionStorage.tenantId === Enums.globals.ADMIN_TENANT_ID){
                                permissionData.permissions = allSuperAdminPermissions;
                              }else {
                                permissionData.permissions = allTenantSuperAdminPermissions;
                              }
                            }else if (value === Enums.globals.ADMIN_ROLE){
                              permissionData.permissions = allAdminPermissions;
                            }else if (value === Enums.globals.CUSTOM_ROLE){
                              permissionData.permissions = customPermissionData.permissions;
                            }
                          }}
                          sx={{width: '100%', margin: '10px 0px', textAlign: 'left'}}
                      >
                        <MenuItem value={Enums.globals.SUPER_ADMIN_ROLE}>Super Admin</MenuItem>
                        <MenuItem value={Enums.globals.ADMIN_ROLE}>Admin</MenuItem>
                        { (props.title != "Create Portal User" ) ? <MenuItem value={Enums.globals.CUSTOM_ROLE}>Custom</MenuItem> : null }
                      </Select>
                    </FormControl>
                  </Box>
                  {Dimensions.width > 600 ? <hr style={{margin: '14px'}} /> : null}
                  <Box
                      sx={{
                        width: '100%',
                        overflowY: 'auto',
                        height: Dimensions.width <= 600 ? 'auto' : '262px',
                      }}
                  >
                    <Box>
                      <Box>
                        <p
                            style={{
                              display: Dimensions.width <= 600 ? 'flex' : 'none',
                              fontSize: '16px',
                              fontWeight: 'bold',
                              width: '100%',
                            }}
                        >
                          {props.disabled ? 'Select Permissions' : 'Permissions'}
                        </p>
                        <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                          Devices
                        </p>
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("IOT_DEVICE_READ"): false}
                                  onChange={(event) => {
                                    changePermission(event,"IOT_DEVICE_READ");
                                  }}
                                  name="deviceView"
                              />
                            }
                            label="View"
                        />
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("IOT_DEVICE_WRITE"): false}
                                  onChange={(event) => {
                                    changePermission(event,"IOT_DEVICE_WRITE");
                                  }}
                                  name="deviceEdit"
                              />
                            }
                            label="Edit"
                        />
                      </Box>
                      <Box>
                        <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                          Locks Users
                        </p>
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("IOT_USER_READ"): false}
                                  onChange={(event) => {
                                    changePermission(event,"IOT_USER_READ");
                                  }}
                                  name="lockUsersView"
                              />
                            }
                            label="View"
                        />
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("IOT_USER_WRITE"): false}
                                  onChange={(event) => {
                                    changePermission(event,"IOT_USER_WRITE");
                                  }}
                                  name="locksUsersEdit"
                              />
                            }
                            label="Edit"
                        />
                      </Box>
                      <Box>
                        <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                          Gateways
                        </p>
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("GATEWAY_READ"): false}
                                  onChange={(event) => {
                                    changePermission(event,"GATEWAY_READ");
                                  }}
                                  name="GatewaysView"
                              />
                            }
                            label="View"
                        />
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("GATEWAY_WRITE"): false}
                                  onChange={(event) => {
                                    changePermission(event,"GATEWAY_WRITE");
                                    changePermission(event,"GENERATE_GATEWAY_TOKEN");
                                  }}
                                  name="GatewaysEdit"
                              />
                            }
                            label="Edit"
                        />
                      </Box>
                      <Box>
                        <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                          Instructions
                        </p>
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("GATEWAY_INSTRUCTIONS_READ"): false}
                                  onChange={(event) => {
                                    changePermission(event,"GATEWAY_INSTRUCTIONS_READ");
                                  }}
                                  name="InstructionsView"
                              />
                            }
                            label="View"
                        />
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("GATEWAY_INSTRUCTIONS_WRITE"): false}
                                  onChange={(event) => {
                                    changePermission(event,"GATEWAY_INSTRUCTIONS_WRITE");
                                  }}
                                  name="InstructionsEdit"
                              />
                            }
                            label="Edit"
                        />
                      </Box>
                      <Box>
                        <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                          Portal Users
                        </p>
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("PORTAL_USER_READ"): false}
                                  onChange={(event) => {
                                    changePermission(event,"PORTAL_USER_READ");
                                  }}
                                  name="portalUsersView"
                              />
                            }
                            label="View"
                        />
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("PORTAL_USER_WRITE"): false}
                                  onChange={(event) => {
                                    changePermission(event,"PORTAL_USER_WRITE");
                                  }}
                                  name="portalUsersEdit"
                              />
                            }
                            label="Edit"
                        />
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("PORTAL_USER_DELETE"): false}
                                  onChange={(event) => {
                                    changePermission(event,"PORTAL_USER_DELETE");
                                  }}
                                  name="portalUsersDelete"
                              />
                            }
                            label="Delete"
                        />
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("CAN_BE_SCOPE_OWNER"): false}
                                  onChange={(event) => {
                                    changePermission(event,"CAN_BE_SCOPE_OWNER");
                                  }}
                                  name="portalUsersOwner"
                              />
                            }
                            label="Eligible to be an owner"
                        />
                      </Box>
                      <Box>
                        <p style={{fontSize: '14px', fontWeight: 'bold'}}>Alerts</p>
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("ALERTS_READ"): false}
                                  onChange={(event) => {
                                    changePermission(event,"ALERTS_READ");
                                  }}
                                  name="alertsView"
                              />
                            }
                            label="View"
                        />
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("ALERTS_WRITE"): false}
                                  onChange={(event) => {
                                    changePermission(event,"ALERTS_WRITE");
                                  }}
                                  name="alertsEdit"
                              />
                            }
                            label="Edit"
                        />
                      </Box>
                      <Box>
                        <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                          Firmware
                        </p>
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("FIRMWARE_READ"): false}
                                  onChange={(event) => {
                                    changePermission(event,"FIRMWARE_READ");
                                  }}
                                  name="firmwareView"
                              />
                            }
                            label="View"
                        />
                        <FormControlLabel
                            disabled={props.disable}
                            style={{display: sessionStorage.tenantId === Enums.globals.ADMIN_TENANT_ID ? 'initial' : 'none'}}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("FIRMWARE_WRITE"): false}
                                  onChange={(event) => {
                                    changePermission(event,"FIRMWARE_WRITE");
                                  }}
                                  name="firmwareUpload"
                              />
                            }
                            label="Upload"
                        />
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("FIRMWARE_DOWNLOAD"): false}
                                  onChange={(event) => {
                                    changePermission(event,"FIRMWARE_DOWNLOAD");
                                  }}
                                  name="firmwareDownload"
                              />
                            }
                            label="Download"
                        />
                      </Box>
                      <Box>
                        <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                          Hierarchy
                        </p>
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("SCOPE_READ"): false}
                                  onChange={(event) => {
                                    changePermission(event,"SCOPE_READ");
                                  }}
                                  name="hierarchyView"
                              />
                            }
                            label="View"
                        />
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("SCOPE_WRITE"): false}
                                  onChange={(event) => {
                                    changePermission(event,"SCOPE_WRITE");
                                    changePermission(event,"SCOPE_UPDATE");
                                  }}
                                  name="hierarchyEdit"
                              />
                            }
                            label="Edit"
                        />
                        <FormControlLabel
                            disabled={props.disable}
                            control={
                              <Checkbox
                                  color="error"
                                  checked={permissionData.permissions ? permissionData.permissions.includes("SCOPE_DELETE"): false}
                                  onChange={(event) => {
                                    changePermission(event,"SCOPE_DELETE");
                                  }}
                                  name="hierarchyDelete"
                              />
                            }
                            label="Delete"
                        />
                      </Box>
                      {
                        JSON.parse(sessionStorage.userDetails).isRootUser && JSON.parse(sessionStorage.userDetails).user.scopeId === sessionStorage.tenantId ?
                          <Box>
                            <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                              Tenants
                            </p>
                            <FormControlLabel
                                disabled={props.disable}
                                control={
                                  <Checkbox
                                      color="error"
                                      checked={permissionData.permissions ? permissionData.permissions.includes("TOP_LEVEL_SCOPE_READ"): false}
                                      onChange={(event) => {
                                        changePermission(event,"TOP_LEVEL_SCOPE_READ");
                                      }}
                                      name="tenantView"
                                  />
                                }
                                label="Manage Tenants"
                            />
                          </Box>
                        :
                          null
                      }
                    </Box>

                  </Box>
                  {
                    userNetworkLoading ?
                      <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '70%',position: 'absolute',top: '70px',right:'8px'}}>
                          <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                      </Box>
                    :
                      null
                  }
                </Box>
              </DialogContent>
              {!props.disable ? (
                  <DialogActions>
                    {
                      props.dialog === "gatewayLogDialog" ?
                        <Button style={{marginRight: 'auto', cursor: 'pointer'}}>
                          <PresentToAll />
                        </Button>
                      :
                        null
                    }
                    <Button
                        onClick={() => handlePermissionDialog()}
                        sx={{color: 'red',marginLeft: props.dialog !== "gatewayLogDIalog" ? "auto" : "0px"}}
                    >
                      Cancel
                    </Button>
                    {
                      props.title === "Create Portal User" ?
                        <Button disabled={permissionData.email === "" || !permissionData.email || permissionData.firstName === "" || !permissionData.firstName || permissionData.lastName ==="" || !permissionData.lastName || !(/^[a-zA-Z0-9+_.\-]+@[a-zA-Z0-9_.\-]+[.]{1}[a-z]{2,3}$/.test(permissionData.email)) } onClick={() => handleCreatePortalUser()}>Submit</Button>
                      :
                        <Button disabled={ permissionData.firstName === "" || permissionData.lastName ==="" || permissionData.firstName === props.data.firstName && permissionData.lastName === props.data.lastName && permissionData.permissions && props.data.permissions ? JSON.stringify(permissionData.permissions.sort()) === JSON.stringify(justPermissions.sort()) : false } onClick={() => handleUpdatePortalUser()}>Update</Button>
                    }
                  </DialogActions>
              ) : null}
            </Dialog>
        ) : props.dialog === 'deletePermissionDialog' ? (
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.show_delete_permission_dialog}
                onClose={handleDeletePortalUserDialog}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
            >
              <DialogTitle>
                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                  {props.title}
                </Box>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure, you want to delete this portal user?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                    onClick={() => handleDeletePortalUserDialog()}
                    style={{color: 'red'}}
                >
                  Cancel
                </Button>
                <Button onClick={()=> deletePortalUser()}>Yes</Button>
              </DialogActions>
            </Dialog>
        ) : props.dialog === 'editUnlockAlertDialog' ? (
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.show_edit_unlock_alert_dialog}
                onClose={handleEditUnlockAlert}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
                maxWidth="md"
            >
              <DialogTitle
                  style={{
                    color: props.title !== 'Delete Alert' ? 'white' : 'black',
                    fontSize: '20px',
                    backgroundColor:
                        props.title === 'Delete Alert' ? '#fff' : 'rgb(20,19,139)',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
              >
                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                  {props.title}
                </Box>
                {props.title !== 'Delete Alert' ? (
                    <span style={{marginTop: '6px'}}>
                <Close onClick={() => handleEditUnlockAlert()} />
              </span>
                ) : null}
              </DialogTitle>
              {props.title !== 'Delete Alert' ? (
                  <DialogContent
                      sx={{
                        padding: '0px',
                        height: '100%',
                        overflow: Dimensions.width > 600 ? 'hidden' : 'auto',
                        overflowX: 'hidden',
                      }}
                  >
                    <Box
                        sx={{
                          display: 'flex',
                          flexDirection: Dimensions.width <= 600 ? 'column' : 'row',
                          margin:
                              Dimensions.width > 600
                                  ? '0px 14px 0px 14px '
                                  : '0px 14px 0px',
                          width: '97%',
                        }}
                    >
                      <Box sx={{width: '90%', margin: '14px'}}>
                        <Box sx={{margin: '14px'}}>
                          when
                          <Select
                              variant="standard"
                              value={alertSelection}
                              sx={{marginLeft: '10px', textAlign: 'left'}}
                              onChange={(event) =>
                                {
                                  setShowUnlockAlreadyExistMessage(false);
                                  if (event.target.value === "UNLOCK"){
                                    let allData = props.allData ? props.allData : [] ;
                                    let alreadyUnlockAlertExist = false;
                                    _.forEach(allData,(value)=>{
                                      if (value.alertType === "UNLOCK"){
                                        alreadyUnlockAlertExist = true;
                                      }
                                    })                                    
                                    if (!alreadyUnlockAlertExist){
                                      setShowUnlockAlreadyExistMessage(false);
                                    }else {
                                      setShowUnlockAlreadyExistMessage(true);
                                    }
                                  }else if (event.target.value === "RFID"){
                                    let allData = props.allData ? props.allData : [] ;                                    
                                    let alreadyRfidAlertExist = false;
                                    _.forEach(allData,(value)=>{
                                      if (value.alertType === "RFID") {
                                        alreadyRfidAlertExist = true;
                                      }
                                    })
                                    if (!alreadyRfidAlertExist) {
                                      setShowRFIDAlreadyExistMessage(false);
                                    }else {
                                      setShowRFIDAlreadyExistMessage(true);
                                    }
                                  }
                                  setAlertSelection(event.target.value);
                                }
                              }
                          >
                            {props.button_name === 'Create' ? (
                                <MenuItem value="BATTERY_LOW">Battery Low</MenuItem>
                            ) : null}
                            {
                              props.data.alertType === "UNLOCK" || props.button_name === 'Create' ?
                                <MenuItem value="UNLOCK">Unlock</MenuItem>
                              :
                                null
                            }
                            {
                              props.data.alertType === "RFID" || props.button_name === 'Create' ?
                                <MenuItem value="RFID">Multiple Shared Lockers Access</MenuItem>
                              :
                                null
                            }
                          </Select>
                          {alertSelection === "BATTERY_LOW" ? (
                              <span>
                        is &lt;&#x3D;{' '}
                                <input
                                  type="number"
                                  value={batteryLevel}
                                  min="0"
                                  max="100"
                                  onChange={(event)=> {
                                    let value = event.target.value;
                                    if (value >= 0 && value <= 100){
                                      setBatteryLevel(event.target.value)
                                    }
                                  }}
                                  style={{width: '47px', height: '24px'}}
                                />{' '}
                                %
                      </span>
                          ) : null}
                          {
                            showUnlockAlreadyExistMessage && alertSelection === "UNLOCK" ? 

                                <DialogContent sx={{textAlign:"center",marginTop:"-50px"}}>
                                  <DialogContentText>
                                    <br/>
                                    <br/>
                                      Cannot create duplicate Unlock alert. Please edit the existing one.
                                    </DialogContentText>
                                </DialogContent>
                            :
                              null
                          }
                          {
                            showRFIDAlreadyExistMessage && alertSelection === "RFID" ?
                              <DialogContent sx={{textAlign:"center",marginTop:"-50px"}}>
                                <DialogContentText>
                                  <br/>
                                  <br/>
                                    Cannot create duplicate Multiple Shared Lockers Access alert. Please edit the existing one.
                                  </DialogContentText>
                              </DialogContent>
                            :
                              null
                          }
                        </Box>
                          <Box sx={{margin: '40px 14px 0px'}}>
                            {
                              !showUnlockAlreadyExistMessage ?
                                props.button_name !== 'Create' && alertSelection !== 'BATTERY_LOW' && alertSelection !== 'RFID' ? (
                                    <>
                                      Send alert to {propsPortalUsersData.length} user
                                      {propsPortalUsersData.length > 1 ? 's' : ''}
                                    </>
                                ) : alertSelection === 'BATTERY_LOW' ? (
                                    <>
                                      Alert once
                                      <Select
                                          variant="standard"
                                          value={alertTimeInterval}
                                          onChange={(event) =>
                                              setAlertTimeInterval(event.target.value)
                                          }
                                          sx={{marginLeft: '10px', textAlign: 'left'}}
                                      >
                                        <MenuItem value="3600000">an hour</MenuItem>
                                        <MenuItem value="21600000">in 6 hours</MenuItem>
                                        <MenuItem value="43200000">in 12 hours</MenuItem>
                                        <MenuItem value="86400000">in a day</MenuItem>
                                      </Select>
                                      to {propsPortalUsersData.length} user
                                      {propsPortalUsersData.length > 1 ? 's' : ''}
                                    </>
                                ) : alertSelection === 'RFID' && !showRFIDAlreadyExistMessage ? (
                                    <Box sx={{display:"flex"}}>

                                      Alert once <Box style={{marginLeft:"8px",display:"flex", borderBottom:"1px solid #008000"}}> {rfidAlertText}
                                      <PopupState variant="popover" popupId="demo-popup-popover">
                                              {(popupState) => (
                                                <Box sx={{marginLeft:'28px'}}>                                                  
                                                  <ArrowDropDown
                                                      sx={{float: 'left', marginLeft: '-26px',cursor:"pointer"}}
                                                      {...bindTrigger(popupState)}
                                                  />
                                                  <Popover
                                                    {...bindPopover(popupState)}
                                                    anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'right',
                                                    }}
                                                    sx={{top:"4px !important"}}
                                                  >
                                                    <Box sx={{display:'flex',flexDirection:'column'}}>
                                                        <Box sx={{width: "150px"}}>
                                                            <FormControl variant="standard" sx={{width:"100%"}}>                                                                
                                                              <MenuItem value="3600000" onClick={()=> {
                                                                setRfidAlertText("an hour");
                                                                setValueUpdated(false);
                                                                popupState.close();                                                                
                                                                setRfidAlert(3600000);
                                                              }}>an hour</MenuItem>
                                                              <MenuItem value="21600000" onClick={()=> {
                                                                setRfidAlertText("in 6 hours");
                                                                setValueUpdated(false);
                                                                popupState.close();
                                                                setRfidAlert(21600000);
                                                              }} >in 6 hours</MenuItem>
                                                              <MenuItem value="43200000" onClick={()=> {
                                                                setRfidAlertText("in 12 hours");
                                                                setValueUpdated(false);
                                                                popupState.close();
                                                                setRfidAlert(43200000);                                                                
                                                              }}>in 12 hours</MenuItem>
                                                              <MenuItem > in <input type="number" value={rfidCustomValue} max={60} min={1} defaultValue={1} onChange={(event)=> {                                                                                                                                
                                                                setRfidCustomValue(event.target.value);
                                                              }} style={{width:"43px",margin:"0px 5px"}}/> {rfidCustomValue > 1 ? "days" : "day" }
                                                              <checkcircle onClick={()=>{
                                                                if (rfidCustomValue >= 1 && rfidCustomValue <= 60) {
                                                                  createRfidAlertText(rfidCustomValue);                                                                
                                                                  popupState.close()
                                                                }
                                                              }} sx={{marginLeft:"5px", color: rfidCustomValue >= 1 && rfidCustomValue <= 60 ? "#008000" : "#b5c8cf"}} />
                                                              </MenuItem>
                                                            </FormControl>
                                                        </Box>
                                                    </Box>
                                                  </Popover>
                                                </Box>
                                                )}
                                        </PopupState>
                                        </Box>                                        
                                        to {propsPortalUsersData.length} user
                                        {propsPortalUsersData.length > 1 ? 's' : ''}
                                    </Box>
                                ) : (
                                      showRFIDAlreadyExistMessage && alertSelection === "RFID" ?
                                        null
                                      :
                                        <>
                                          Send alert to {propsPortalUsersData.length} user
                                          {propsPortalUsersData.length > 1 ? 's' : ''}
                                        </>
                                )
                              :
                                null
                            }
                          </Box>

                          {
                           alertSelection === "UNLOCK" && !showUnlockAlreadyExistMessage ?
                            props.button_name !== 'Create' ? (
                                props.data.receivers.length > 0 ? (
                                    <Box
                                        sx={{
                                          marginTop: '40px',
                                          padding: '20px',
                                          border:
                                              propsPortalUsersData.length > 0
                                                  ? '1px solid darkgray'
                                                  : '1px solid transparent',
                                        }}
                                    >
                                      {propsPortalUsersData.map((element, index) => {
                                        return (
                                            <Chip
                                                sx={{marginRight: '8px'}}
                                                key={index}
                                                label={element.firstName + '' + element.lastName}
                                                onDelete={() => unSelectTenant(element)}
                                            />
                                        );
                                      })}
                                    </Box>
                                ) : null
                            ) : (
                                <Box
                                    sx={{
                                      marginTop: '40px',
                                      padding: '20px',
                                      border:
                                          propsPortalUsersData.length > 0
                                              ? '1px solid darkgray'
                                              : '1px solid transparent',
                                    }}
                                >
                                  {propsPortalUsersData.map((element, index) => {
                                    return (
                                        <Chip
                                            sx={{marginRight: '8px'}}
                                            key={index}
                                            label={element.firstName + '' + element.lastName}
                                            onDelete={() => unSelectTenant(element)}
                                        />
                                    );
                                  })}
                                </Box>
                            )
                            :
                              null
                          }
                          {
                           alertSelection === "RFID" && !showRFIDAlreadyExistMessage ?
                            props.button_name !== 'Create' ? (
                                props.data.receivers.length > 0 ? (
                                    <Box
                                        sx={{
                                          marginTop: '40px',
                                          padding: '20px',
                                          border:
                                              propsPortalUsersData.length > 0
                                                  ? '1px solid darkgray'
                                                  : '1px solid transparent',
                                        }}
                                    >
                                      {propsPortalUsersData.map((element, index) => {
                                        return (
                                            <Chip
                                                sx={{marginRight: '8px'}}
                                                key={index}
                                                label={element.firstName + '' + element.lastName}
                                                onDelete={() => unSelectTenant(element)}
                                            />
                                        );
                                      })}
                                    </Box>
                                ) : null
                            ) : (
                                <Box
                                    sx={{
                                      marginTop: '40px',
                                      padding: '20px',
                                      border:
                                          propsPortalUsersData.length > 0
                                              ? '1px solid darkgray'
                                              : '1px solid transparent',
                                    }}
                                >
                                  {propsPortalUsersData.map((element, index) => {
                                    return (
                                        <Chip
                                            sx={{marginRight: '8px'}}
                                            key={index}
                                            label={element.firstName + '' + element.lastName}
                                            onDelete={() => unSelectTenant(element)}
                                        />
                                    );
                                  })}
                                </Box>
                            )
                            :
                              null
                          }
                          </Box>
                          {
                            (alertSelection !== "RFID" && !showUnlockAlreadyExistMessage) || (alertSelection === "RFID" && !showRFIDAlreadyExistMessage) ?
                          <Box
                              sx={{
                                width: '90%',
                                border: '1px solid darkgray',
                                margin: '14px',
                              }}
                          >
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                              <TextField
                                  className="createAlertSearch"
                                  onChange={(event) =>
                                      searchTenantsInAddOrEditAlerts(event)
                                  }
                                  sx={{
                                    flex: Dimensions.width <= 600 ? '50' : '33',
                                    margin: '10px 0px 0px 10px',
                                  }}
                                  placeholder="Search ..."
                              />
                              <Button
                                  color="error"
                                  sx={{flex: Dimensions.width <= 600 ? '25' : '33', marginTop:"4px"}}
                                  onClick={() => selectAllTenants()}
                              >
                                Select All
                              </Button>
                              <Button
                                  color="error"
                                  sx={{flex: Dimensions.width <= 600 ? '25' : '33'}}
                                  onClick={() => unSelectAllTenants()}
                              >
                                Unselect all
                              </Button>
                            </Box>
                            <Box sx={{height: '312px', overflowY: 'auto'}}>
                              {
                                // eslint-disable-next-line array-callback-return
                                props.portal_users_data.map((element, index) => {
                                  if (
                                      (element.hasOwnProperty('email')
                                          ? element.email.toLowerCase().replaceAll(" ","").indexOf(
                                          searchTenantsByNameOrEmail.toLowerCase().replaceAll(" ","")
                                      ) !== -1
                                          : false) ||
                                      ((element.firstName + element.lastName)?
                                      (element.firstName + element.lastName).toLowerCase().replaceAll(" ","")
                                          .indexOf(
                                              searchTenantsByNameOrEmail.toLowerCase().replaceAll(" ","")
                                          ) !== -1
                                      : false)
                                  ) {
                                    return (
                                        <div
                                            style={{
                                              margin: '20px',
                                              display: 'flex',
                                              flexDirection: 'row',
                                            }}
                                        >
                                          <div style={{flex: '80'}}>
                                            <div
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  overflowWrap: 'anywhere',
                                                }}
                                            >
                                              <div>
                                                {element.firstName + element.lastName}
                                              </div>
                                              <div>{element.email}</div>
                                            </div>
                                          </div>
                                          <div style={{flex: '20', textAlign: 'right'}}>
                                            {checkIfTenantIsSelected(element.email) ? (
                                                <RemoveCircle
                                                    onClick={() => unSelectTenant(element)}
                                                />
                                            ) : (
                                                <AddCircleOutline
                                                    onClick={() =>
                                                        {setValueUpdated(false);
                                                        setPropsPortalUsersData(
                                                            (previousData) => [
                                                              ...previousData,
                                                              element,
                                                            ]
                                                        )
                                                    }}
                                                />
                                            )}
                                          </div>
                                        </div>
                                    );
                                  }
                                })
                              }
                            </Box>
                          </Box>
                          :
                            null
                          }
                      </Box>
                    </DialogContent>
                ) : (
                    <DialogContent>
                      <DialogContentText>
                        Are you sure you want to delete this alert?
                      </DialogContentText>
                    </DialogContent>
                )}
              <DialogActions>
                <Button
                    onClick={() => handleEditUnlockAlert()}
                    style={{color: 'red'}}
                >
                  Cancel
                </Button>
                <Button disabled={ 
                  !showUnlockAlreadyExistMessage ?
                    props.title !== "Delete Alert" ?
                      (props.title === "Create New Alert" ? 
                        propsPortalUsersData.length === 0 : ( props.data.alertType === "RFID" ? propsPortalUsersData.length === 0 || rfidAlertError || valueUpdated : propsPortalUsersData.length === 0 || valueUpdated || (batteryLevel < 0 && batteryLevel > 100)))
                    : 
                      false 
                  : 
                    true
                  }
                  onClick={() => props.title === "Update Alert" ? handleUpdateAlert() : handleDeleteAlert()}
                >{props.button_name}</Button>
              </DialogActions>
            </Dialog>
        ) : props.dialog === 'whiteListDialog' ? (
            <>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.showDIalog}
                onClose={() => props.close_whiteList_dialog(false)}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
            >
              <DialogTitle
                  style={{
                    color: 'white',
                    fontSize: '20px',
                    backgroundColor: 'rgb(20,19,139)',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
              >
                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                  Manage Locks Whitelist
                </Box>
                {
                    showWhiteListThreshold() ?
                    <span
                    style={{
                      display: JSON.parse(sessionStorage.userDetails).permissions.includes("TOP_LEVEL_SCOPE_READ") ? 'flex' : 'none',
                    }}
                    >
                      Scan Threshold
                      <Select
                          variant="standard"
                          value={scanThresholdCount}
                          onChange={(event => setScanThresholdCount(event.target.value))}
                          sx={{marginLeft: '10px', textAlign: 'left',background: '#fff',borderRadius: '5px'}}
                      >
                        {
                          [10,20,30,40,50,60,70,80,90,100].map((value) =>
                            <MenuItem value={value}>{value} %</MenuItem>
                          )
                        }
                      </Select>
                    </span>
                  :
                    null
                }
                <span style={{marginTop: '6px'}}>
                  <Close onClick={() => props.close_whiteList_dialog(false)} />
                </span>
              </DialogTitle>
              <DialogContent>
                <Stack sx={{marginTop: '14px', marginBottom: '14px'}}>
                  <Autocomplete
                      id="whiteListLockNames"
                      multiple
                      options={[]}
                      freeSolo
                      value={whiteListedLockName}
                      onChange={(event, value) => handleWhiteListNames(event,value)}
                      renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                              <Chip
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({index})}
                              />
                          ))
                      }
                      renderInput={(params) => (
                          <TextField {...params} placeholder="Enter Lock Name" />
                      )}
                  />
                </Stack>
                <DialogContentText sx={{fontSize: '12px'}}>
                  Current whitelist of Lock names configured in Gateway (), is shown
                  first. After typing each Lock name, press 'return' key. Lock names
                  may contain letters, numbers and the symbols _.- : @
                </DialogContentText>
                <DialogContentText sx={{fontSize: '12px', marginTop: '14px'}}>
                  <b>
                    To whitelist all the locks enter "*" and press 'return' key.
                  </b>
                </DialogContentText>
              </DialogContent>
              <DialogActions
                  sx={{
                    display: 'flex',
                    flexDirection: Dimensions.width <= 400 ? 'column' : 'row',
                  }}
              >
                <Button onClick={()=> setShowWhitelistUploadCsvDialog(true) }>
                  Upload csv
                </Button>
                <Button
                    onClick={() => setWhiteListedLockName([])}
                    style={{color: 'ponk'}}
                >
                  clear whitelist
                </Button>
                <Button
                    variant="contained"
                    onClick={() => saveWhiteList()}
                    disabled={props.data.status !== "CONNECTED" || !enableSaveWhiteListButton}
                    sx={{background: 'rgb(20, 19, 139)'}}
                >
                  {enableSaveWhiteListButton ? "save whitelist" : "Waiting For Gateway..." }
                </Button>
              </DialogActions>
            </Dialog>
            {
              showWhitListUploadCssvDialog ?
                <Dialog
                  fullWidth={true}
                  fullScreen={fullScreen}
                  open={props.showDIalog}
                  onClose={() => setShowWhitelistUploadCsvDialog(false)}
                  PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                  aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle
                    id="responsive-dialog-whitelist-title"
                    style={{
                      background: 'rgb(20,19,139)',
                      display: 'flex',
                      flexDirection: 'row',
                      color: 'white',
                    }}
                  >
                  <Box sx={{flex: '95'}}>
                    Upload Whitelist CSV
                  </Box>
                  <Box sx={{flex: '5', marginTop: '6px'}}>
                    <Close onClick={() => {
                      setShowWhitelistUploadCsvDialog(false)
                      setShowErrorMsg(false);
                      setErrorMsg("");

                    }} />
                  </Box>
                </DialogTitle>
                  <DialogContent>
                    <Box sx={{marginTop:"14px"}}>
                      Select a CSV file (<i>filename.csv</i>) with Lock names.&nbsp;
                      <a href="/whitelist.csv" download>Click here</a>&nbsp;to download sample file.
                    </Box>
                    <center>
                      <input
                          type="file"
                          accept=".csv"
                          style={{display: 'none'}}
                          id="uploadCSV"
                          value={selectedWhitelistCsvFile}
                          onChange={() => readCsvFile()}
                      />
                      <label htmlFor="uploadCSV">
                        <Button variant="standard" 
                          sx={{marginLeft: 'auto',
                          background: 'rgb(20,19,139)',
                          color: '#fff',
                          marginRight: '10px',
                          '&.MuiButtonBase-root:hover': {
                              background: 'rgb(20,19,139)',
                          },
                          width: '167px',
                          margin: '15px'}} component="span">
                          UPLOAD CSV File
                        </Button>
                      </label>
                      <Box sx={{display:showErrorMsg ? "flex" : "none", marginTop : '10px', color : 'red'}}>
                         {errorMsg}
                       </Box>
                    </center>
                  </DialogContent>
                </Dialog>
              :
                null
            }
          </>
        ) : props.dialog === 'restartGatewayDialog' ? (
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.showDIalog}
                onClose={() => props.close_restart_gateway_dialog(false)}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
            >
              <DialogTitle>
                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                  Restart Gateway
                </Box>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure, you want to restart the gateway ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                    onClick={() => props.close_restart_gateway_dialog(false)}
                    style={{color: 'red'}}
                >
                  Cancel
                </Button>
                <Button onClick={()=>{
                  publishMessage('gateways/'+sessionStorage.tenantId+'/command/'+props.data.macId, props.data.macId,"reset");
                  enqueueSnackbar("Gateway Restarted ...");
                  let payload = {
                    "macId" : props.data.macId,
                    "message" : "Reboot initiated from portal",
                    "date": new Date().getTime(),
                    "type": "alert",      
                  }              
                  GatewayServiceClient.createLockUnlockEntry(payload).then((response) => {})

                  props.close_restart_gateway_dialog(false)}                  
                  }>
                    Yes
                </Button>
              </DialogActions>
            </Dialog>
        )
        :
            props.dialog === "clearBlackListDialog" ?
                <Dialog
                    fullWidth={true}
                    fullScreen={fullScreen}
                    open={props.showDIalog}
                    onClose={() => props.close_clear_black_list_dialog(false)}
                    PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                    aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle>
                    <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                      Clear Blacklisted Locks
                    </Box>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      This action will clear blacklisted locks. Are you sure you want to continue?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                        onClick={() => props.close_clear_black_list_dialog(false)}
                        style={{color: 'red'}}
                    >
                      Cancel
                    </Button>
                    <Button onClick={() => {
                        publishMessage('gateways/'+sessionStorage.tenantId+'/command/'+props.data.macId ,props.data.macId,"clearBlackList");
                        let payload = {
                          "macId" : props.data.macId,
                          "message" : "Clear blacklist issued from portal",
                          "date": new Date().getTime(),
                          "type": "event",      
                        }              
                        GatewayServiceClient.createLockUnlockEntry(payload).then((response) => {})
                        setTimeout(() => {
                          props.close_clear_black_list_dialog(false);
                        }, 1000);
                      }}>
                        Yes
                    </Button>
                  </DialogActions>
                </Dialog>
            :
              props.dialog === "lockDeleteDialog" ?
                <Dialog
                    fullWidth={true}
                    fullScreen={fullScreen}
                    open={props.showLockDeleteDialog}
                    onClose={() => props.closeLockDeleteDialog(false)}
                    PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                    aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle>
                    <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                      Delete Lock
                    </Box>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      This action will delete the lock. Are you sure you want to continue ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                        onClick={() => props.closeLockDeleteDialog(false)}
                        style={{color: 'red'}}
                    >
                      Cancel
                    </Button>
                    <Button onClick={() => props.removeLock(true)}>
                        Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              :
                props.dialog === "updateOrCreateDialog" ?
                  <Dialog
                      fullWidth={true}
                      fullScreen={fullScreen}
                      open={props.showUpdateOrCreateLockUser}
                      onClose={() => props.closeDialog(false)}
                      PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                      aria-labelledby="draggable-dialog-title"
                  >
                    <DialogTitle
                      id="responsive-dialog-title"
                      style={{
                        background: 'rgb(20,19,139)',
                        display: 'flex',
                        flexDirection: 'row',
                        color: 'white',
                      }}
                    >
                      <Box sx={{flex: '90'}}>{props.title}</Box>
                      {
                        props.title !== "Update User" ?                      
                          <Tooltip title="Upload users" placement="top" arrow>                    
                              <img src="/upload_users_white.png" height='24px'width='40px' style={{marginTop:"6px",marginRight:"10px",cursor:"pointer"}}  onClick={()=>handleBulkDialog()}/>
                          </Tooltip>
                        :
                          null
                      }
                      <Box sx={{flex: '5', marginTop: '6px'}}>                        
                        <Close sx={{cursor:"pointer"}} onClick={() => props.closeDialog(true)} />
                      </Box>
                    </DialogTitle>
                    <DialogContent>
                      <Box className="nameTextField ">
                        <Box className="firstName paddingBottom" sx={{position:"relative"}}>
                          <TextField
                              label="First Name"
                              variant="standard"
                              required
                              inputProps={{
                                maxLength: 20
                              }}
                              helperText={updateDialogLockUsersData.firstName === "" ? "First Name is required." : null}
                              value={updateDialogLockUsersData.firstName}
                              onChange={(event)=>{
                                let firstName = event.target.value;
                                firstName = firstName === " " || firstName.indexOf(" ") === 0 ? checkSpace(firstName) : firstName;
                                let data = updateDialogLockUsersData;
                                data.firstName = firstName;
                                data.firstNameTextLength = firstName !== "" && typeof firstName !== 'undefined' && firstName ? firstName.length + "/20" : "0/20";
                                setUpdateDialogLockUsersData(data);
                                setLockUserfirstName(firstName);
                                checkLockUsersData(data,"firstName")
                              }}
                              sx={{width: '100%'}}
                          />
                          <Box sx={{position:"absolute",top:'50px',right:"0px",fontSize:"12px",color: updateDialogLockUsersData.firstName ? updateDialogLockUsersData.firstName === "" ? "red" : "#000000DE" : "red"}}>
                            {updateDialogLockUsersData.firstNameTextLength ? updateDialogLockUsersData.firstNameTextLength : "0/20"}
                          </Box>
                        </Box>
                        <Box className="lastName paddingBottom" sx={{position:"relative"}}>
                          <TextField
                              label="Last Name"
                              variant="standard"
                              required
                              inputProps={{
                                maxLength: 20
                              }}
                              helperText={updateDialogLockUsersData.lastName === "" ? "Last Name is required." : null}
                              value={updateDialogLockUsersData.lastName}
                              onChange={(event)=>{
                                let lastName = event.target.value;
                                lastName = lastName === " " || lastName.indexOf(" ") === 0 ? checkSpace(lastName) : lastName;
                                let data = updateDialogLockUsersData;
                                data.lastName = lastName;
                                data.lastNameTextLength = lastName !== "" && typeof lastName !== 'undefined' && lastName ? lastName.length + "/20" : "0/20";
                                setUpdateDialogLockUsersData(data);
                                setLockUserLastName(lastName);
                                checkLockUsersData(data,"lastName")
                              }}
                              sx={{width: '100%'}}
                          />
                          <Box sx={{position:"absolute",top:'50px',right:"0px",fontSize:"12px",color: updateDialogLockUsersData.lastName ? updateDialogLockUsersData.lastName === "" ? "red" : "#000000DE" : "red"}}>
                            {updateDialogLockUsersData.lastNameTextLength ? updateDialogLockUsersData.lastNameTextLength : "0/20"}
                          </Box>
                        </Box>
                      </Box>
                      <Box className="nameTextField ">
                        <Box className="firstName paddingBottom" sx={{position:'relative'}}>
                          <TextField
                              label="Employee Id"
                              variant="standard"
                              inputProps={{
                                maxLength: 20
                              }}
                              value={updateDialogLockUsersData.employeeId}
                              onChange={(event)=>{
                                let employeeId = event.target.value;
                                employeeId = employeeId === " " || employeeId.indexOf(" ") === 0 ? checkSpace(employeeId) : employeeId;
                                let data = updateDialogLockUsersData;
                                data.employeeId = employeeId;
                                data.employeeIdTextLength = employeeId !== "" && typeof employeeId !== 'undefined' && employeeId ? employeeId.length + "/20" : "0/20";
                                setUpdateDialogLockUsersData(data);
                                setLockUserEmployeeId(employeeId);
                                checkLockUsersData(data,"employeeId")
                              }}
                              sx={{width: '100%'}}
                          />
                          <Box sx={{position:"absolute",top:'50px',right:"0px",fontSize:"12px",color: "#000000DE"}}>
                            {updateDialogLockUsersData.employeeIdTextLength ? updateDialogLockUsersData.employeeIdTextLength : "0/20"}
                          </Box>
                        </Box>
                        <Box className="lastName paddingBottom" sx={{position:'relative'}}>
                          <TextField
                              label="Division"
                              variant="standard"
                              inputProps={{
                                maxLength: 20
                              }}
                              value={updateDialogLockUsersData.division}
                              onChange={(event)=>{
                                let division = event.target.value;
                                division = division === " " || division.indexOf(" ") === 0 ? checkSpace(division) : division;
                                let data = updateDialogLockUsersData;
                                data.division = division;
                                data.divisionTextLength = division !== "" && typeof division !== 'undefined' && division ? division.length + "/20" : "0/20";
                                setUpdateDialogLockUsersData(data);
                                setLockUserDivision(division);
                                checkLockUsersData(data,"division")
                              }}
                              sx={{width: '100%'}}
                          />
                          <Box sx={{position:"absolute",top:'50px',right:"0px",fontSize:"12px",color: "#000000DE"}}>
                            {updateDialogLockUsersData.divisionTextLength ? updateDialogLockUsersData.divisionTextLength : "0/20"}
                          </Box>
                        </Box>
                      </Box>

                      <Box className="nameTextField ">
                        <Box className="firstName paddingBottom" sx={{position:'relative'}}>
                          <TextField
                              label="Mobile No"
                              variant="standard"
                              inputProps={{
                                minLength: 12,
                                maxLength: 14,                                
                              }}
                              helperText={updateDialogLockUsersData.mobileNo ? (updateDialogLockUsersData.mobileNo.match(/^\+(?:[0-9] ?){11,14}$/) ? null : "Mobile No accepted format +CountryCodeMobileNo (+15179001907)") : null }
                              value={updateDialogLockUsersData.mobileNo }
                              onChange={(event)=> {
                                let mobile = event.target.value;
                                let data = updateDialogLockUsersData;
                                data.mobileNo = mobile;
                                data.mobileNoTextLength = mobile !== "" && typeof mobile !== 'undefined' && mobile ? mobile.length + "/14" : "0/14";
                                setUpdateDialogLockUsersData(data);
                                setLockUserMobile(mobile)
                                checkLockUsersData(data,"mobileNo")
                              }}
                              sx={{width: '100%'}}
                          />
                          <Box sx={{position:"absolute",top:'50px',right:"0px",fontSize:"12px",color: updateDialogLockUsersData.mobileNo ? updateDialogLockUsersData.mobileNo === "" ? "#000000DE" : !updateDialogLockUsersData.mobileNo.match(/^\+(?:[0-9] ?){11,14}$/) ? "red" :"#000000DE" : "#000000DE"}}>
                            {updateDialogLockUsersData.mobileNoTextLength ? updateDialogLockUsersData.mobileNoTextLength : "0/14"}
                          </Box>
                        </Box>
                        <Box className="lastName paddingBottom">
                          <TextField
                              label="Email *"
                              variant="standard"
                              type={props.title === "Create New User" ? "email" :"text"}
                              helperText={ props.title === "Create New User" ? updateDialogLockUsersData.email === "" ||  updateDialogLockUsersData.email ? !updateDialogLockUsersData.email.match("^[a-zA-Z0-9+_.\-]+@[a-zA-Z0-9_.\-]+[.]{1}[a-z]{2,3}$") ? "Email is required." : null : null : null}
                              value={updateDialogLockUsersData.email}
                              disabled={props.title === "Update User"}
                              onChange={(event)=>{
                                let email = event.target.value;
                                let data = updateDialogLockUsersData;
                                data.email = email;
                                setUpdateDialogLockUsersData(data);
                                setLockUserEmail(email);
                                checkLockUsersData(data,"email")
                              }}
                              sx={{width: '100%'}}
                          />
                        </Box>
                      </Box>
                      <Box className="nameTextField ">
                        <Box className="firstName paddingBottom" sx={{position:'relative'}}>
                          <TextField
                              label="Card ID"
                              variant="standard"
                              inputProps={{
                                minLength: 10,
                                maxLength: 10,                                
                              }}
                              helperText={updateDialogLockUsersData.cardCSN ? (updateDialogLockUsersData.cardCSN.match(/^[A-Fa-f0-9]{10,10}$/) ? null : "Invalid card id") : null }
                              value={updateDialogLockUsersData.cardCSN }
                              onChange={(event)=> {
                                let cardCSN = event.target.value;
                                let data = updateDialogLockUsersData;
                                data.cardCSN = cardCSN;
                                data.cardCSNTextLength = cardCSN !== "" && typeof cardCSN !== 'undefined' && cardCSN ? cardCSN.length + "/10" : "0/10";
                                setUpdateDialogLockUsersData(data);
                                setLockUserCardCSN(cardCSN)
                                checkLockUsersData(data,"cardCSN")
                              }}
                              sx={{width: '100%'}}
                          />
                          <Box sx={{position:"absolute",top:'50px',right:"0px",fontSize:"12px",color: updateDialogLockUsersData.cardCSN ? updateDialogLockUsersData.cardCSN === "" ? "#000000DE" : !updateDialogLockUsersData.cardCSN.match(/^[A-Fa-f0-9]{10,10}$/) ? "red" :"#000000DE" : "#000000DE"}}>
                            {updateDialogLockUsersData.cardCSNTextLength ? updateDialogLockUsersData.cardCSNTextLength : "0/10"}
                          </Box>
                        </Box>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                          onClick={() => props.closeDialog(false)}
                          style={{color: 'red'}}
                      >
                        Cancel
                      </Button>
                      {
                        props.title === "Create New User" ?
                          <Button disabled={!enableLockUserUpdateButton} onClick={() => createIotUser()}>
                              create
                          </Button>
                        :
                          <Button disabled={!enableLockUserUpdateButton} onClick={() => updateIotDeviceUser()}>
                              Update
                          </Button>
                      }
                    </DialogActions>
                  </Dialog>
                :
                props.dialog === "deleteLockUserDialog" ?
                    <Dialog
                        fullWidth={true}
                        fullScreen={fullScreen}
                        open={props.showLockUserDeleteDialog}
                        onClose={() => props.closeDeleteLockUserDialog(false)}
                        PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                        aria-labelledby="draggable-dialog-title"
                    >
                      <DialogTitle>
                        <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                          Delete User
                        </Box>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          User will be removed from all locks. Are you sure, you want to delete this user?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                            onClick={() => props.closeDeleteLockUserDialog(false)}
                            style={{color: 'red'}}
                        >
                          Cancel
                        </Button>
                        <Button onClick={() => props.deleteLockUser(true)}>
                            Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                :
                  props.dialog === "showUserAssignedLocksDialog" ?
                    <Dialog
                        fullWidth={true}
                        fullScreen={fullScreen}
                        open={props.showAssignedLocksDialog}
                        onClose={() => props.closeAssignedLocksDialog(false)}
                        PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                        aria-labelledby="draggable-dialog-title"
                    >
                      <DialogTitle
                        id="responsive-dialog-title"
                        style={{
                          background: 'rgb(20,19,139)',
                          display: 'flex',
                          flexDirection: 'row',
                          color: 'white',
                        }}
                      >
                        <Box sx={{flex: '95'}}>List of Devices for {props.data.mergedName}</Box>
                        <Box sx={{display:"content",flex: '5', marginTop: '6px'}}>
                          <Close sx={{cursor:"pointer"}} onClick={() => props.closeAssignedLocksDialog(true)} />
                        </Box>
                      </DialogTitle>
                      <DialogContent>
                        {
                          !showUserAssignedLocksDialogLoading ?
                           <TableContainer sx={{minHeight:'150px'}}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        Name
                                      </TableCell>
                                      <TableCell>
                                        Lock Mode
                                      </TableCell>
                                      <TableCell>
                                        Password
                                      </TableCell>
                                      <TableCell>
                                        Role
                                      </TableCell>
                                      <TableCell>
                                        Battery Level
                                      </TableCell>
                                      <TableCell>
                                        Status
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody sx={{minHeight:"150px"}}>
                                    {
                                    userAssignedLocksData.length > 0 ?
                                        stableSort(userAssignedLocksData, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                          return (
                                              <TableRow
                                                  hover
                                                  role="checkbox"
                                                  tabIndex={-1}
                                                  key={row.id}
                                                  className={paginationStyle.tableBodyRow}
                                                  sx={{borderBottom:'1px solid #c1a6a6'}}
                                              >
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">
                                                {row.mode === 'shared' ? (
                                                    <Tooltip title="Shared Lock" placement='top' arrow>
                                                      <PeopleOutline sx={{color: '#0000008A'}} />
                                                    </Tooltip>
                                                  ) : (
                                                    <Tooltip title="Residential Lock" placement='top' arrow>
                                                      <PersonOutline sx={{color: '#0000008A'}} />
                                                    </Tooltip>
                                                  )}
                                                </TableCell>
                                                <TableCell align="left">{row.passcode}</TableCell>
                                                <TableCell align="left">{row.role}</TableCell>
                                                <TableCell align="left">{row.batteryLevel}</TableCell>
                                                <TableCell align="left">
                                                {row.status === 'LOCKED' ? (
                                                    <Tooltip title="LOCKED" placement='top' arrow>
                                                      <Lock sx={{color: '#0000008A'}} />
                                                    </Tooltip>
                                                  ) : (
                                                    <Tooltip title="OPEN" placement='top' arrow>
                                                      <LockOpen sx={{color: '#0000008A'}}/>
                                                    </Tooltip>
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                          )
                                        })
                                        :

                                          <TableRow>
                                            <TableCell sx={{fontSize:"16px",border:"transparent"}} colSpan={5}>
                                              <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                No locks assigned to this user
                                              </Box>
                                            </TableCell>
                                          </TableRow>
                                      }
                            </TableBody>
                          </Table>
                          </TableContainer>
                        :
                        <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%',padding:"20px"}}>
                          <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                        </Box>
                      }
                      </DialogContent>
                      <DialogActions>
                          {
                            !showUserAssignedLocksDialogLoading ?
                              <TablePagination
                                sx={{width: '100%', overflowX: 'hidden'}}
                                labelRowsPerPage={rowPerPageLabel}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                className="pagination"
                                count={userAssignedLocksData.length}
                                rowsPerPage={rowsPerPage}
                                page={pageNumber}
                                SelectProps={{
                                  inputProps: {'aria-label': 'rows per page'},
                                  native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                              />
                            :
                              null
                          }
                    </DialogActions>
                    </Dialog>
                  :
                  props.dialog === "createNewTenant" ?
                  <Dialog
                      fullWidth={true}
                      fullScreen={fullScreen}
                      open={props.showCreateNewTenantDialog}
                      onClose={() => props.closeCreateNewTenantDialog(false)}
                      PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                      aria-labelledby="draggable-dialog-title"
                  >
                    <DialogTitle
                        id="responsive-dialog-title"
                        style={{
                          background: 'rgb(20,19,139)',
                          display: 'flex',
                          flexDirection: 'row',
                          color: 'white',
                        }}
                    >
                      <Box sx={{flex: '95'}}>{props.title}</Box>
                      <Box sx={{flex: '5', marginTop: '6px'}}>
                        <Close onClick={() => props.closeCreateNewTenantDialog()} />
                      </Box>
                    </DialogTitle>
                    <DialogContent>
                      <Box className="paddingBottom">
                        <TextField
                            label="Tenant Label"
                            variant="standard"
                            sx={{width: '100%'}}
                        />
                      </Box>
                      <Box className="nameTextField ">
                        <Box className="firstName paddingBottom">
                          <TextField
                              label="First Name"
                              variant="standard"
                              sx={{width: '100%'}}
                          />
                        </Box>
                        <Box className="lastName paddingBottom focuspaddingBottom">
                          <TextField
                              label="Last Name"
                              variant="standard"
                              sx={{width: '100%'}}
                          />
                        </Box>
                      </Box>

                      <Box className="nameTextField ">
                        <Box className="firstName paddingBottom">
                          <TextField
                              label="super Admin Email"
                              variant="standard"
                              sx={{width: '100%'}}
                          />
                        </Box>
                        <Box className="lastName paddingBottom">
                          <TextField
                              label="Super Admin Password"
                              variant="standard"
                              sx={{width: '100%'}}
                          />
                        </Box>
                      </Box>

                      <Box className="paddingBottom">
                        <TextField
                            label="Domain Name"
                            variant="standard"
                            sx={{width: '100%'}}
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                          onClick={() => props.closeCreateNewTenantDialog(false)}
                          style={{color: 'red'}}
                      >
                        Cancel
                      </Button>
                      <Button onClick={() => props.closeCreateNewTenantDialog(false)}>
                          create
                      </Button>
                    </DialogActions>
                  </Dialog>
                :
                  props.dialog === "pcToolHistory" ?
                    <Dialog
                        fullWidth={true}
                        fullScreen={fullScreen}
                        open={props.showDialog}
                        onClose={() => props.handleCloseDialogBox()}
                        aria-labelledby="draggable-dialog-title"
                        className="pcToolhistoryDialog"
                    >
                      <DialogTitle
                        id="responsive-dialog-title"
                        style={{
                          background: 'rgb(20,19,139)',
                          display: 'flex',
                          flexDirection: 'row',
                          color: 'white',
                        }}
                      >
                        <Box sx={{flex: '95'}}>Lock Passcode Change History</Box>
                        <Box sx={{flex: '5', marginTop: '6px'}}>
                          <Close sx={{cursor:"pointer",float:'right'}} onClick={() => props.handleCloseDialogBox()} />
                        </Box>
                      </DialogTitle>
                      <DialogContent>
                        {
                          !pcToolDataLoading ?
                          <TableContainer sx={{minHeight:'150px'}}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        Name
                                      </TableCell>
                                      <TableCell>
                                        Serial Number
                                      </TableCell>
                                      <TableCell>
                                        Time of Change
                                      </TableCell>
                                      <TableCell>
                                        Old Passcode
                                      </TableCell>
                                      <TableCell>
                                        New Passcode
                                      </TableCell>
                                      <TableCell>
                                        Passcode Type
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody sx={{minHeight:"150px"}}>
                                    {
                                      pcToolData.length > 0 ?
                                        stableSort(pcToolData, getComparator(order, orderBy))
                                        .slice(page * page, page * page + rowsPerPage)
                                        .map((row, index) => {
                                          return (
                                              <TableRow
                                                  hover
                                                  role="checkbox"
                                                  tabIndex={-1}
                                                  key={row.id}
                                                  className={paginationStyle.tableBodyRow}
                                                  sx={{borderBottom:'1px solid #c1a6a6'}}
                                              >
                                                <TableCell align="left">{row.lockName}</TableCell>
                                                <TableCell align="left">{row.lockSerialNumber}</TableCell>
                                                <TableCell align="left">{row.lastUpdated}</TableCell>
                                                <TableCell align="left">{row.oldPasscode}</TableCell>
                                                <TableCell align="left">{row.newPasscode}</TableCell>
                                                <TableCell align="left">{row.passcodeType}</TableCell>
                                              </TableRow>
                                          )
                                        })
                                        :

                                          <TableRow>
                                            <TableCell sx={{fontSize:"16px",border:"transparent"}} colSpan={5}>
                                              <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                No lock passcode change history.
                                              </Box>
                                            </TableCell>
                                          </TableRow>
                                      }
                            </TableBody>
                          </Table>
                          </TableContainer>
                        :
                        <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%',padding:"20px 0px 20px 0px"}}>
                          <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                        </Box>
                      }
                      </DialogContent>
                      <DialogActions>
                          {
                            !pcToolDataLoading ?
                              <TablePagination
                                sx={{width: '100%', overflowX: 'hidden'}}
                                labelRowsPerPage={pcToolRowPerPageLabel}
                                rowsPerPageOptions={[25, 50, 100]}
                                component="div"
                                className="pagination"
                                count={pcToolData.length}
                                rowsPerPage={rowsPerPage}
                                page={pageNumber}
                                SelectProps={{
                                  inputProps: {'aria-label': 'rows per page'},
                                  native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                              />
                            :
                              null
                          }
                    </DialogActions>
                    </Dialog>
                :
                    props.dialog === "cannotDeleteLockUser" ?
                      <Dialog
                          fullWidth={true}
                          fullScreen={fullScreen}
                          open={props.cannotDeleteUserDialog}
                          onClose={() => props.closeCannotDeleteUserDialog(false)}
                          PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                          aria-labelledby="draggable-dialog-title"
                      >
                        <DialogTitle
                            id="responsive-dialog-title"
                            style={{
                              background: 'rgb(20,19,139)',
                              display: 'flex',
                              flexDirection: 'row',
                              color: 'white',
                            }}
                        >
                          <Box sx={{flex: '95'}}>
                            Error
                          </Box>
                          <Box sx={{flex: '5', marginTop: '6px'}}>
                            <Close onClick={() => props.closeCannotDeleteUserDialog()} />
                          </Box>
                        </DialogTitle>
                        <DialogContent sx={{margin:"20px"}}>
                          <Box>
                              Unable to delete <b>{props.data.mergedName} ({props.data.email})</b> as the user is setup on the following lock(s) which are not connected to the Management Server.
                          </Box>
                          <Box sx={{fontWeight:'bold'}}>
                            {
                              _.sortBy(userAssignedLocksData,["name"],['asc']).map((lock,index)=>{
                                return (
                                  <Box>
                                    {index + 1} . {lock.name}
                                  </Box>
                                )
                              })
                            }
                          </Box>
                          <Box>
                            Please delete <b>{props.data.mergedName}</b> from all eLocks and retry.
                          </Box>
                          <Box>
                            Locks can be remotely managed by installing the Gateway Server.
                          </Box>
                        </DialogContent>
                      </Dialog>
                  :
                  props.dialog === "uploadRFIDUsersDialog" ?
                    <Dialog
                        fullWidth={true}
                        fullScreen={fullScreen}
                        open={props.RFIDUsersDialog}
                        onClose={() => props.closeUploadRFIDUsersDialog(false)}
                        PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                        aria-labelledby="draggable-dialog-title"
                    >
                      <DialogTitle
                          id="responsive-dialog-title"
                          style={{
                            background: 'rgb(20,19,139)',
                            display: 'flex',
                            flexDirection: 'row',
                            color: 'white',
                          }}
                      >
                        <Box sx={{flex: '95'}}>
                          Upload RFID User CSV
                        </Box>
                        <Box sx={{flex: '5', marginTop: '6px'}}>
                          <Close onClick={() => props.closeUploadRFIDUsersDialog()} />
                        </Box>
                      </DialogTitle>
                      <DialogContent sx={{margin:"20px"}}>
                        {
                          !csvAddingUsers ?
                              !showStatus ?
                                <>
                                  <Box>
                                    Select a CSV file (<i>filename.csv</i>) with RFID users details.&nbsp;
                                    <a href="/rfidUsers.csv" download>Click here</a>&nbsp;to download sample file.
                                  </Box>
                                  <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                    <form>
                                            <Button
                                                component="label"
                                                variant="outlined"
                                                sx={{
                                                    marginLeft: 'auto',
                                                    background: 'rgb(20,19,139)',
                                                    color: '#fff',
                                                    marginRight: '10px',
                                                    '&.MuiButtonBase-root:hover': {
                                                        background: 'rgb(20,19,139)',
                                                    },
                                                    width: '167px',
                                                    margin: '15px'
                                                }}
                                            >
                                                Upload CSV File
                                                <input type="file" accept=".csv" value={selectedFile} hidden onChange={(event)=>uploadCSVHandler(event)} />
                                            </Button>
                                    </form>
                                  </Box>
                                  <Box sx={{display:showErrorMsg ? "flex" : "none", marginTop : '10px', color : 'red'}}>
                                      {errorMsg}
                                  </Box>
                                </>
                              :
                                <Box>
                                  <Box>
                                    <ul style={{listStyleType: 'none', paddingInlineStart: '0px'}}>{responseMsg.map((item, index) =>
                                        <li style={{paddingBottom: '0.75rem'}} key={index}>
                                            {item}
                                        </li>
                                    )}
                                    </ul>
                                   </Box>
                                  <Box sx={{display:"flex",justifyContent:"end",alignItems:"end",float:"right",marginTop:"10px"}}>
                                  <Button variant="contained" style={{color: 'rgba(255,255,255,0.87)',backgroundColor: 'rgb(20,19,139)',}} onClick={()=>props.closeUploadRFIDUsersDialog()}>
                                      ok
                                    </Button>
                                  </Box>
                                </Box>
                          :
                            <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100px'}}>
                              <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                          </Box>
                        }
                      </DialogContent>
                    </Dialog>
                :
                    props.dialog === "uploadBulkUsersDialog" ?
                      <Dialog
                          fullWidth={true}
                          fullScreen={fullScreen}
                          open={props.bulkUsersDialog}
                          onClose={() => props.closeUploadBulkUsersDialog(false)}
                          PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                          aria-labelledby="draggable-dialog-title"
                      >
                        <DialogTitle
                            id="responsive-dialog-title"
                            style={{
                              background: 'rgb(20,19,139)',
                              display: 'flex',
                              flexDirection: 'row',
                              color: 'white',
                            }}
                        >
                          <Box sx={{flex: '95'}}>
                            Upload CSV
                          </Box>
                          <Box sx={{flex: '5', marginTop: '6px'}}>
                            <Close onClick={() => props.closeUploadBulkUsersDialog()} />
                          </Box>
                        </DialogTitle>
                        <DialogContent sx={{margin:"20px"}}>
                          {
                            !csvAddingUsers ?
                                !showStatus ?
                                  <>
                                    <Box>
                                      Select a CSV file (<i>filename.csv</i>) with users details.&nbsp;
                                      <a href="/users.csv" download>Click here</a>&nbsp;to download sample file.
                                    </Box>
                                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                      <form>
                                              <Button
                                                  component="label"
                                                  variant="outlined"
                                                  sx={{
                                                      marginLeft: 'auto',
                                                      background: 'rgb(20,19,139)',
                                                      color: '#fff',
                                                      marginRight: '10px',
                                                      '&.MuiButtonBase-root:hover': {
                                                          background: 'rgb(20,19,139)',
                                                      },
                                                      width: '167px',
                                                      margin: '15px'
                                                  }}
                                              >
                                                  Upload CSV File
                                                  <input type="file" accept=".csv" value={selectedFile} hidden onChange={(event)=>uploadCSVHandler(event,"normalusers")} />
                                              </Button>
                                      </form>
                                    </Box>
                                    <Box sx={{display:showErrorMsg ? "flex" : "none", marginTop : '10px', color : 'red'}}>
                                        {errorMsg}
                                    </Box>
                                  </>
                              :
                                <Box>
                                    {
                                          <Box>
                                            <ul style={{listStyleType: 'none', paddingInlineStart: '0px'}}>{responseMsg.map((item, index) =>
                                                <li style={{paddingBottom: '0.75rem'}} key={index}>
                                                    {item}
                                                </li>
                                            )}
                                            </ul>
                                          </Box>
                                    }
                                  <Box sx={{display:"flex",justifyContent:"end",alignItems:"end",float:"right",marginTop:"10px"}}>
                                    <Button variant="contained" style={{color: 'rgba(255,255,255,0.87)',backgroundColor: 'rgb(20,19,139)',}} onClick={()=>props.closeUploadBulkUsersDialog()}>
                                        ok
                                      </Button>
                                    </Box>
                                </Box>
                            :
                              <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100px'}}>
                                <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                            </Box>
                          }
                        </DialogContent>
                      </Dialog>
                  :

                  props.dialog === "filterUsersDialog" ?
                    <Dialog
                      fullWidth={true}
                      fullScreen={fullScreen}
                      open={props.openFilterUsersDialog}
                      onClose={() => props.closeFilterUsersDialog()}
                      PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                      aria-labelledby="draggable-dialog-title"
                  >
                    <DialogTitle
                        id="responsive-dialog-title"
                        style={{
                          background: 'rgb(20,19,139)',
                          display: 'flex',
                          flexDirection: 'row',
                          color: 'white',
                        }}
                    >
                      <Box sx={{flex: '95'}}>
                        Filter
                      </Box>
                      <Box sx={{flex: '5', marginTop: '6px'}}>
                        <Close onClick={() => props.closeFilterUsersDialog()} />
                      </Box>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <FormGroup sx={{ marginTop: '6px'}}>
                          <FormControlLabel disabled control={<Checkbox color="error" defaultChecked />} label="Multiple Shared Lockers Access" />
                        </FormGroup>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        component="label"
                        variant="outlined"
                        sx={{
                            marginLeft: 'auto',
                            background: 'rgb(20,19,139)',
                            color: '#fff',
                            marginRight: '10px',
                            '&.MuiButtonBase-root:hover': {
                                background: 'rgb(20,19,139)',
                            },
                            width: '167px',
                            margin: '15px'
                        }}
                        onClick={() => props.applyFilter()}
                    >
                        Apply
                    </Button>
                    </DialogActions>
                  </Dialog>
                  :
                    
                    props.dialog === "domainExistAlert" ?
                      <Dialog
                        fullWidth={true}
                        fullScreen={fullScreen}
                        open={props.openDialog}
                        onClose={() => props.closeDialog()}
                        PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                        aria-labelledby="draggable-dialog-title"
                    >
                      <DialogTitle>
                        <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                          Alert
                        </Box>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          The email <b>{props.data.email}</b> is already registered for <b>{props.data.name}</b> tenant and hence cannot be used. Please retry with a different email
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => props.closeDialog()}>
                          ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                    :

                      props.dialog === "deleteeGatwayDialog" ?
                        <Dialog
                            fullWidth={true}
                            fullScreen={fullScreen}
                            open={props.showDIalog}
                            onClose={() => props.closeDialog(false)}
                            PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                            aria-labelledby="draggable-dialog-title"
                        >
                          <DialogTitle>
                            <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                              Delete Gateway
                            </Box>
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                            Are you sure, you want to delete <b>{props.data.macId}</b> gateway
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                                onClick={() => props.closeDialog(false)}
                                style={{color: 'red'}}
                            >
                              Cancel
                            </Button>
                            <Button onClick={() => handleRemoveGateway()}>
                                Yes
                            </Button>
                          </DialogActions>
                        </Dialog>

                      :
                        props.dialog === "downloadLockCSVDialog" || props.dialog === "downloadLockUsersCSVDialog" ?
                          <Dialog
                              fullWidth={true}
                              fullScreen={fullScreen}
                              open={props.showDialog}
                              onClose={() => props.closeProcess()}
                              PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                              aria-labelledby="draggable-dialog-title"
                          >
                            <DialogTitle style={{
                              background: 'rgb(20,19,139)',
                              display: 'flex',
                              flexDirection: 'row',
                              color: 'white',
                            }}>
                              <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                                Preparing the CSV ...
                              </Box>
                              <Close sx={{marginLeft:"auto",marginRight:"10px",cursor:"pointer"}} onClick={()=>props.closeProcess()} />
                            </DialogTitle>
                            <DialogContent>
                              <Box sx={{ display:"flex",width: '100%',justifyContent: 'center',alignItems: 'center',height: '200px'}}>
                                  <CircularProgress thickness="4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                              </Box>
                            </DialogContent>                          
                          </Dialog>

                        :
                          props.dialog === "lockInfoDalog" ?
                            <Dialog
                                fullWidth={true}
                                fullScreen={fullScreen}
                                open={props.showDialog}
                                onClose={() => props.closeDialog(false)}
                                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                                aria-labelledby="draggable-dialog-title"
                            >
                              <DialogTitle style={{
                                background: 'rgb(20,19,139)',
                                display: 'flex',
                                flexDirection: 'row',
                                color: 'white',
                              }}>
                                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                                  Lock information
                                </Box>
                                <Close sx={{marginLeft:"auto",marginRight:"10px",cursor:"pointer"}} onClick={()=>props.closeDialog()} />
                              </DialogTitle>
                              <DialogContent>
                                <TableContainer sx={{minHeight:'150px'}}>
                                  <Table>
                                    <TableHead>
                                      <TableRow sx={{textAlign:"center"}}>
                                        <TableCell sx={{textAlign:"center"}}>
                                          In use
                                        </TableCell>
                                        <TableCell sx={{textAlign:"center"}}>
                                          Rssi
                                        </TableCell>
                                        <TableCell sx={{textAlign:"center"}}>
                                          In Mode
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody sx={{minHeight:"150px"}}>
                                      <TableRow sx={{textAlign:"center"}}>
                                        <TableCell sx={{textAlign:"center"}}>
                                          {props.data.inUse || "-"}
                                        </TableCell>
                                        <TableCell sx={{textAlign:"center"}}>
                                          {props.data.rssi || "0"}
                                        </TableCell>
                                        <TableCell sx={{textAlign:"center"}}>
                                          {props.data.mode || "-"}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                    onClick={() => props.closeDialog(false)}
                                    style={{color: 'red',marginLeft:"auto"}}
                                >
                                  Cancel
                                </Button>
                              </DialogActions>
                            </Dialog>
                          :

                            props.dialog === "changePasswordConfirmationDialog" ?
                              <>
                                <Dialog
                                  fullWidth={true}
                                  fullScreen={fullScreen}
                                  open={props.show_dialog}    
                                  onClose={()=> props.close_confirmation_password_dialog()}                            
                                  PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                                  aria-labelledby="draggable-dialog-title"
                              >
                                <DialogTitle>
                                  <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                                    {props.title}
                                  </Box>
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    On successful password change you will be logged out from the current session. Click YES to confirm.
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                      onClick={()=> props.close_confirmation_password_dialog()}
                                      style={{color: 'red'}}
                                  >
                                    Cancel
                                  </Button>
                                  <Button onClick={()=> props.change_password()}>Yes</Button>
                                </DialogActions>
                              </Dialog>
                            </>
                            :
                              props.dialog === "cardCSNErrorDialog" ?
                                <Dialog
                                  fullWidth={true}
                                  fullScreen={fullScreen}
                                  open={props.openCardCsnErrorDialog}    
                                  onClose={()=> props.closeCardCsnErrorDialog()}                            
                                  PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                                  aria-labelledby="draggable-dialog-title"
                              >
                                <DialogTitle>
                                  <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                                    {props.email !== null ? "Error!" : "Warning!"}
                                  </Box>
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    {
                                      props.email !== null && (props.lockNames === "" || props.lockNames === null) ?
                                        <>
                                          This card is already assigned to <b>{props.email}</b> and cannot be assigned to this user.
                                        </>
                                      :                                          
                                        props.lockNames === "" || props.lockNames === null ?
                                          <>
                                            This user has been configured to lock(s) with a different card. Please re-configure the access with the new card.
                                          </>
                                        :
                                          <>
                                            This user has been configured to lock(s) <b> {props.lockNames}</b> with a different card. Please re-configure the access with the new card.                                          
                                          </>
                                    }                                    
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>                                  
                                  {
                                    props.email !== null && props.lockNames !== "" && props.lockNames !== null?
                                      <>
                                        <Button
                                            onClick={()=> props.updateCardCSNData()}
                                        >
                                          ok
                                        </Button>
                                        <Button onClick={()=> props.closeCardCsnErrorDialog()}>
                                          cancel
                                        </Button>
                                      </>
                                    :
                                      <Button
                                          onClick={()=> props.closeCardCsnErrorDialog()}
                                      >
                                        ok
                                      </Button>
                                  }                               
                                </DialogActions>
                              </Dialog>
                              :
                                props.dialog === "updateCardCSNSuccessDialog" ?
                                  <Dialog
                                    fullWidth={true}
                                    fullScreen={fullScreen}
                                    open={props.openUpdateCardCSNSuccessDialog}    
                                    onClose={()=> props.closeOpenUpdateCardCSNSuccessDialog()}                            
                                    PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                                    aria-labelledby="draggable-dialog-title"
                                  >
                                  <DialogTitle>
                                    <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                                      Warning!
                                    </Box>
                                  </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText>
                                    {
                                        props.newCSN !== ""?
                                        <>
                                            This user has already been assigned card <b> {props.oldCSN}</b>  . It will be replaced with the new card <b> {props.newCSN}</b>. Click OK to proceed.
                                        </>
                                        :
                                        <>
                                            Are you sure you want to remove card <b> {props.oldCSN} </b> for this user?. Click ok to proceed.
                                        </>

                                    }
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button style={{color:'red'}} onClick={() => props.closeOpenUpdateCardCSNSuccessDialog()}>
                                      cancel
                                    </Button>
                                    <Button
                                        onClick={()=> props.updateUserData()}
                                    >
                                      ok
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              :
                                null
            }                                     
            {
              showSnackBar ?
              <Snackbar
                anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                open={showSnackBar}
                autoHideDuration={3000}
                message={showSnackBarMessage}
              />
            :
              null
            }
      </>
  );
}