
import * as React from 'react';
import {styled} from '@mui/material/styles';
import { Box,Paper,Grid,Typography,CircularProgress,Snackbar } from '@mui/material';
import 'stylesheet/Dashboard.css';
import Chart from 'react-google-charts';
import LocationMap from 'helper-components/LocationMap';
import AuthClient from "clients/AuthClient";
import GatewayServiceClient from "clients/GatewayServiceClient";
import IotServiceClient from "clients/IotServiceClient";
import _ from 'lodash';
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import PubSubChannels from 'pubsub/PubSubChannels';
import PubSubUtils from 'pubsub/PubSubUtils';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import { useSnackbar } from "notistack";



export default function Dashboard(props) {
    const [tenantName, setTenantName] = React.useState(null);
    const [iotDevices, setIotDevices] = React.useState([]);
    const [iotGateways, setIotGateways] = React.useState([]);
    const [clickEventCount, setClickEventCount] = React.useState(0);
    const [dashboardGatewaysEventHandlerChannel, setDashboardGatewaysEventHandlerChannel] = React.useState(null);
    const [lockStatusEventHandlerChannel, setLockStatusEventHandlerChannel] = React.useState(null);
    const [onlineCount, setOnlineCount] = React.useState(0);
    const [offlineCount, setOfflineCount] = React.useState(0);
    const [lteCount, setLteCount] = React.useState(0);
    const [ethernetCount, setEthernetCount] = React.useState(0);
    const [ wifiCount, setWifiCount] = React.useState(0);
    const [registeredCount, setRegisteredCount] = React.useState(0);
    const [unregisteredCount, setUnregisteredCount] = React.useState(0);
    const [firmwareData, setFirmwareData] = React.useState([["Version","FM"],["",0]]);
    const [osVersionData, setOsVersionData] = React.useState(["Version","OS"],["", 0]);
    const [scopeId,setScopeId] = React.useState(sessionStorage.tenantId);
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    window.dashboardIotGateways = [];
    const [defaultCenter, setDefaultCenter] = React.useState({lat: -34.397,lng: 150.644})
    const [LCTNs, setLCTNs]= React.useState([])
    const [deviceLCTNs, setDeviceLCTNs] = React.useState([]);
    const mapStyles = {
        height: '240px',
        width: '100%',
    };
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
    const { enqueueSnackbar } = useSnackbar();

    const [showMap , setShowMap] = React.useState(false);
 
     const options = {
         legend: {
            position: 'top',
            maxLines: 3,
            textStyle: {fontSize: 9},
         },
         chart: {
           title: '',
           subtitle: '',
         },
     };

     const getDevicesDataByVersions = (devices) => {
           _.forEach(devices,(device) => {
               if(device.FW !== null && device.FW !== undefined) {
                   let firmwareVersion = device.FW;
                   device.versionNum = firmwareVersion.substring(0, 10);
               }
           });
           var data = _.groupBy(devices, function(device) {
               return device.versionNum;
           });
           return data;
     };
 
    const getDevicesDataByOSVersions = (devices) => {
         var data = _.groupBy(devices, function(device) {
             if(device.OSversion !== null && device.OSversion !== undefined) {
               return device.OSversion;
             }
         });
         return data;
    }
 
     const fetchData = () => { 
        let allLockTypeExist = [];
        let devicesLCTN = [];
        let allOSVersions = {};
        let allFirmwares = {};
        let filteredFirmwares = [["Version","FM"]];
        let filteredOsVersions = [["Version","OS"]]
        let LCTNData = [];

        IotServiceClient.getReachableIotDevices(scopeId, "dashboardPage").then((data) => {
          let [onlineCounter, offlineCounter,lteCounter, ethernetCounter, wifiCounter, registerCount, unRegisterCount] = [0, 0, 0, 0, 0, 0,0];
          if (!data.hasOwnProperty("message")){                               
            _.forEach(data,function(iotDevice, index){
              if (iotDevice.type){
                  allLockTypeExist.push(iotDevice.type);
              }

              if (checkDataExistsOrNot(iotDevice.LCTN) ? checkDataExistsOrNot(iotDevice.LCTN.LAT) && checkDataExistsOrNot(iotDevice.LCTN.LNG) : false){
                  if(devicesLCTN.hasOwnProperty(iotDevice.LCTN.LAT)){
                    devicesLCTN[iotDevice.LCTN.LAT].MAC += ", " + iotDevice.MAC;
                    devicesLCTN[iotDevice.LCTN.LAT].count += 1;
                  }else{
                    devicesLCTN[iotDevice.LCTN.LAT] = {
                      count: 1,
                      NAME: iotDevice.NAME,
                      LAT: iotDevice.LCTN.LAT,
                      LNG: iotDevice.LCTN.LNG,
                      IPADDR: iotDevice.LCTN.IPADDR,
                      MAC: iotDevice.MAC,
                      CS: iotDevice.CS
                    };
                  }
              }

              if (iotDevice.CS === 1) {
                onlineCounter += 1;
              } else {
                offlineCounter += 1;
              }           
              if (iotDevice.REG === 1){
                registerCount++;
              }else{
                unRegisterCount++;
              }
              if(checkDataExistsOrNot(iotDevice.NW)){
                if(iotDevice.NW === 0){
                    lteCounter+=1;
                }else if(iotDevice.NW === 2){
                  ethernetCounter+=1;
                }else{
                  wifiCounter+=1;
                }
              }
              if (checkDataExistsOrNot(iotDevice.FW)){
                if (_.has(allFirmwares, iotDevice.FW)){
                  allFirmwares[iotDevice.FW]+=1;
                }else {
                  allFirmwares[iotDevice.FW] = 1;
                }
              }

              if (checkDataExistsOrNot(iotDevice.OS)){
                if (_.has(allOSVersions, iotDevice.OS)){
                  allOSVersions[iotDevice.OS]+=1;
                }else {
                  allOSVersions[iotDevice.OS] = 1;
                }
              }
            });
              
            if (_.size(allFirmwares) > 0){
              _.forOwn(allFirmwares, (value, key) => {
                filteredFirmwares.push([key, value]);
              }); 
            }else {
              filteredFirmwares.push(["",0]);
            }
            
            if (_.size(allOSVersions) > 0){
              _.forOwn(allOSVersions, (value, key) => {
                filteredOsVersions.push([key, value]);
              }); 
            }else {
              filteredOsVersions.push(["",0]);
            }
            Object.keys(devicesLCTN).map((key) => {
               if(devicesLCTN[key].hasOwnProperty('LAT') && devicesLCTN[key].hasOwnProperty('LNG')){
                LCTNData.push({"lat":devicesLCTN[key].LAT,"lng":devicesLCTN[key].LNG,"macId":devicesLCTN[key].MAC,"connStatus":devicesLCTN[key].CS,"count":devicesLCTN[key].count})
              }
            })

            setRegisteredCount(registerCount);
            setUnregisteredCount(unRegisterCount);
            setDeviceLCTNs(LCTNData);
            setOnlineCount(onlineCounter);
            setOfflineCount(offlineCounter);
            setLteCount(lteCounter);
            setEthernetCount(ethernetCounter);
            setWifiCount(wifiCounter);
            setFirmwareData(filteredFirmwares);
            setOsVersionData(filteredOsVersions);
        }else {
          setRegisteredCount(registerCount);
          setUnregisteredCount(unRegisterCount);
          setDeviceLCTNs(LCTNData);
          setOnlineCount(onlineCounter);
          setOfflineCount(offlineCounter);
          setLteCount(lteCounter);
          setEthernetCount(ethernetCounter);
          setWifiCount(wifiCounter);
          setFirmwareData(filteredFirmwares);
          setOsVersionData(filteredOsVersions);
        }
      });
    }
 
    const handleGatewayEventReceived = () => {
        enqueueSnackbar("Refreshing dashboard page...");
        fetchData();
    }
 
    const handleLockEventReceived = () => {
          setShowSnackBar(true);
          setSnackBarMessage("Refreshing Gateways Status ...");
 
            setTimeout(() => {
                    setShowSnackBar(false);
                    setSnackBarMessage("");
            }, 3000);
         fetchData();
    }
 
     const handleOnlineStatusEventReceived = () => {
           setShowSnackBar(true);
           setSnackBarMessage("Refreshing Online Status ...");
 
           setTimeout(() => {
                     setShowSnackBar(false);
                     setSnackBarMessage("");
           }, 3000);
           fetchData();
     }
 
    const subscribeChannels = () => {
         const fetchOnlineStatus = () => {
             handleOnlineStatusEventReceived();
         }
         window.dashboardGatewaysEventHandlerChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.DEVICES.replace("scopeId", sessionStorage.tenantId), "dashboardController", handleGatewayEventReceived);
         /* window.dashboardGatewaysEventHandlerChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAYS.replace("scopeId",sessionStorage.tenantId),"dashboadrController",handleGatewayEvent);
          window.lockOnlineStatusEventHandlerChannel = MqttEventHandler.subscribe("gateway-service/activityLogs/+", "dashboardlockController", function(message,topic){
              let macId = topic !== "" && topic ? topic.substring(topic.lastIndexOf("activityLogs/")+13,topic.length) : "";
              if (window.dashboardIotGateways ? window.dashboardIotGateways.length > 0 : false){
                  _.forEach(window.dashboardIotGateways,(device)=>{
                      if (macId === device.MAC){
                          fetchOnlineStatus();
                      }
                  })
              }
          }); */
    }
 
    React.useEffect(()=>{
         fetchData();
          subscribeChannels();
          setTimeout(() => {
            setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
            setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
          }, 1000);
         return () => {
                 if (window.dashboardGatewaysEventHandlerChannel){
                     window.dashboardGatewaysEventHandlerChannel.unsubscribe();
                 }
                 if (window.lockStatusEventHandlerChannel){
                     window.lockStatusEventHandlerChannel.unsubscribe();
                 }
                 if (window.lockOnlineStatusEventHandlerChannel){
                     window.lockOnlineStatusEventHandlerChannel.unsubscribe();
                 }
                 // GatewayServiceClient.abortSignal("dashboardPage");
         }
    },[]);
 
   return (
 
     <Box sx={{flexGrow: 1, margin: '8px', padding: '8px'}}>
       <Grid container spacing={4}>
         <Grid item xs={12} md={12} lg={4}>
           <Paper elevation={1}>
             <Box sx={{backgroundColor: checkDataExistsOrNot(props.primaryColor) ? props.primaryColor : primaryThemeColor, color: checkDataExistsOrNot(props.secondaryColor) ? props.secondaryColor : secondaryThemeColor, padding: '10px'}}>
               <Typography
                 mt={2}
                 style={{
                   fontWeight: '350',
                   fontSize: '1em',
                   margin: '10px',
                 }}
               >
                 Connectivity
               </Typography>
             </Box>
             <Box  sx={{margin: 'auto', height: '240px', width: '320px'}}>
               <Chart
                 width={'320px'}
                 height={'240px'}
                 chartType="PieChart"
                 loader={
                    <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                        <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                    </Box>
                 }
                 data={[
                   ['Task', 'Hours per Day'],
                   ['Online', onlineCount],
                   ['Offline',  offlineCount ]
                 ]}
                 options={{
                   title: '',
                   slices: [{color: '#109618'}, {color: '#DC3912'}],
                   pieSliceText: 'value',
                   chartArea: {'width': '90%', 'height': '90%'},
                   margin: 'auto',
                 }}
                 chartEvents={[
                    {
                        eventName: 'ready',
                        callback: ({chartWrapper, google}) => {
                            let handler = function (event) {
                                // var  = e.targetID.split('#');
                                let targetId = event.targetID;
                                window.clickEvent = window.clickEvent ? window.clickEvent : 0;
                                if (targetId && window.clickEvent === 0) {
                                  if (targetId === "legendentry#0" || targetId === "slice#0") {
                                    sessionStorage.navigatedFromDashboard = "online";
                                    //window.clickEvent +=1;
                                    sessionStorage.sidenavAlreadySelectedItem = "Locks";
                                    props.changePage();
                                  } else if (targetId === "legendentry#1" || targetId === "slice#1"){
                                    sessionStorage.navigatedFromDashboard = "offline";
                                    //window.clickEvent +=1;
                                    sessionStorage.sidenavAlreadySelectedItem = "Locks";
                                    props.changePage();
                                  }
                                }
                            };
                            google.visualization.events.addListener(
                                chartWrapper.getChart(),
                                'click',
                                handler
                            );
                        },
                    },
                 ]}
                 rootProps={{'data-testid': '1'}}
               />
             </Box>
           </Paper>
         </Grid>
         <Grid item xs={12} md={12} lg={4}>
           <Paper elevation={1}>
             <Box sx={{backgroundColor: checkDataExistsOrNot(props.primaryColor) ? props.primaryColor : primaryThemeColor, color: checkDataExistsOrNot(props.secondaryColor) ? props.secondaryColor : secondaryThemeColor, padding: '10px'}}>
               <Typography
                 mt={2}
                 style={{
                   fontWeight: '350',
                   fontSize: '1em',
                   margin: '10px',
                 }}
               >
                 Connectivity Types
               </Typography>
             </Box>
             <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
               <Chart
                 width={'320px'}
                 height={'240px'}
                 chartType="ColumnChart"
                 loader={
                     <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                         <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                     </Box>
                 }
                 data={[
                   ['Task', 'Number of Gateways'],
                   ['LTE',  lteCount],
                   ['Ethernet', ethernetCount],
                   ['Wifi', wifiCount]
                 ]}
                 options={{
                       legend: {
                                position: 'top',
                                maxLines: 3,
                                textStyle: {fontSize: 9},
                       },
                       chart: {
                                title: '',
                                subtitle: '',
                       },
                       series: {
                               0: { color: '#DC3912' }
                       },
                       chartArea: {'width': '70%', 'height': '70%'}
                 }}
 
                  // rootProps={{'data-testid': '1'}}
               />
             </Box>
           </Paper>
         </Grid>
         <Grid item xs={12} md={12} lg={4}>
           <Paper elevation={1}>
             <Box sx={{backgroundColor: checkDataExistsOrNot(props.primaryColor) ? props.primaryColor : primaryThemeColor, color: checkDataExistsOrNot(props.secondaryColor) ? props.secondaryColor : secondaryThemeColor, padding: '10px'}}>
               <Typography
                 mt={2}
                 style={{
                   fontWeight: '350',
                   fontSize: '1em',
                   margin: '10px',
                 }}
               >
                 Enrollment Status
               </Typography>
             </Box>
             <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
               <Chart
                 width={'320px'}
                 height={'240px'}
                 chartType="PieChart"
                 loader={
                     <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                         <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                     </Box>
                 }
                 data={[
                   ['Task', 'Hours per Day'],
                   ['Registered', registeredCount],
                   ['Unregistered', unregisteredCount]
                 ]}
                 options={{
                    title: '',
                    slices: [{color: '#109618'}, {color: '#DC3912'}],
                    pieSliceText: 'value',
                    margin: 'auto',
                    chartArea: {'width': '90%', 'height': '90%'}
                 }}
                 chartEvents={[
                    {
                      eventName: 'ready',
                      callback: ({chartWrapper, google}) => {
                          let handler = function (event) {
                              let targetId = event.targetID;
                              window.clickEvent = window.clickEvent ? window.clickEvent : 0;
                              if (targetId && window.clickEvent === 0) {
                                if (targetId === "legendentry#0" || targetId === "slice#0") {
                                  sessionStorage.navigatedFromDashboardEnrollmentStatus = "registered";
                                  sessionStorage.sidenavAlreadySelectedItem = "Locks";
                                  props.changePage();
                                } else if (targetId === "legendentry#1" || targetId === "slice#1"){
                                  sessionStorage.navigatedFromDashboardEnrollmentStatus = "unregistered";
                                  sessionStorage.sidenavAlreadySelectedItem = "Locks";
                                  props.changePage();
                                }
                              }
                          };
                          google.visualization.events.addListener(
                              chartWrapper.getChart(),
                              'click',
                              handler
                          );
                      },
                    },
                ]}
                 rootProps={{'data-testid': '1'}}
               />
             </Box>
           </Paper>
         </Grid>
       </Grid>
       <Grid sx={{marginTop: '25px'}} container spacing={4}>
         <Grid item xs={12} md={12} lg={4}>
           <Paper elevation={1}>
             <Box sx={{backgroundColor: checkDataExistsOrNot(props.primaryColor) ? props.primaryColor : primaryThemeColor, color: checkDataExistsOrNot(props.secondaryColor) ? props.secondaryColor : secondaryThemeColor, padding: '10px'}}>
               <Typography
                 mt={2}
                 style={{
                   fontWeight: '350',
                   fontSize: '1em',
                   margin: '10px',
                 }}
               >
                 Locations
               </Typography>
             </Box>
             <Box className="mapClass" sx={{ margin: 'auto', height: '240px', width: '100%'}}>
                 <LocationMap divHeight="240px" markers={deviceLCTNs}  />
             </Box>
           </Paper>
         </Grid>
         <Grid item xs={12} md={12} lg={4}>
           <Paper elevation={1}>
             <Box sx={{backgroundColor: checkDataExistsOrNot(props.primaryColor) ? props.primaryColor : primaryThemeColor, color: checkDataExistsOrNot(props.secondaryColor) ? props.secondaryColor : secondaryThemeColor, padding: '10px'}}>
               <Typography
                 mt={2}
                 style={{
                   fontWeight: '350',
                   fontSize: '1em',
                   margin: '10px',
                 }}
               >
                 Gateway Firmware
               </Typography>
             </Box>
             <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
               <Chart
                 width={ '320px'}
                 height={'240px'}
                 chartType="ColumnChart"
                 loader={
                     <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                         <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                     </Box>
                 }
                 data={
                   firmwareData
                 }
                 options={{
                       legend: {
                                position: 'top',
                                maxLines: 3,
                                textStyle: {fontSize: 9},
                       },
                       chart: {
                                title: '',
                                subtitle: '',
                       },
                       series: {
                                  0: { color: '#ba55d3'  }
                       },
                       chartArea: {'width': '70%', 'height': '70%'}
                 }}
                 // rootProps={{'data-testid': '1'}}
               />
             </Box>
           </Paper>
         </Grid>
         <Grid item xs={12} md={12} lg={4}>
           <Paper elevation={1}>
             <Box sx={{backgroundColor: checkDataExistsOrNot(props.primaryColor) ? props.primaryColor : primaryThemeColor, color: checkDataExistsOrNot(props.secondaryColor) ? props.secondaryColor : secondaryThemeColor, padding: '10px'}}>
               <Typography
                 mt={2}
                 style={{
                   fontWeight: '350',
                   fontSize: '1em',
                   margin: '10px',
                 }}
               >
                 Gateway OS
               </Typography>
             </Box>
             <Box sx={{margin: 'auto', height: '240px', width: '320px'}}>
               <Chart
                 width={'320px'}
                 height={'240px'}
                 chartType="ColumnChart"
                 loader={
                     <Box sx={{width:'100%',display:'flex',justifyContent: 'center',alignItems:'center',height:'100%'}}>
                          <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                     </Box>
                 }
                 data={
                      osVersionData
                 }
                 options={{
                     legend: {
                                position: 'top',
                                maxLines: 3,
                                textStyle: {fontSize: 9},
                     },
                     chart: {
                               title: '',
                               subtitle: '',
                     },
                     series: {
                              0: { color: '#ba55d3' }
                     },
                     chartArea: {'width': '70%', 'height': '70%'}
                 }}
                 //rootProps={{'data-testid': '1'}}
               />
             </Box>
           </Paper>
         </Grid>
       </Grid>
       {
           showSnackBar ?
                  <Snackbar
                      anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                      open={showSnackBar}
                      message={snackBarMessage}
                  />
                  :
           null
       }
     </Box>
   );
}

