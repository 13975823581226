import { AssignmentOutlined, CheckCircle, Close, CropPortraitOutlined, Delete, Download, FilterList, FirstPage, Info, KeyboardArrowLeft, KeyboardArrowRight, LastPage, LocationOn, Lock, ModeEditOutlineOutlined, PersonAdd, PriorityHighOutlined, Refresh, Search, SettingsOutlined, SettingsPower, SignalCellular0Bar, SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar, SyncLock, Verified, Wifi, WifiTethering } from '@mui/icons-material';
import LanIcon from '@mui/icons-material/Lan';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { Box, Button, Checkbox, MenuItem, Popover, Select, TableCell, TableHead, TableRow, TableSortLabel, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import { visuallyHidden } from '@mui/utils';
import GatewayServiceClient from "clients/GatewayServiceClient";
import IotServiceClient from "clients/IotServiceClient";
import MqttEventHandler from "components/Mqtt/MqttEventHandler";
import Enums from "constants/Enums";
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import DownloadCSVDialog from 'helper-components/DownloadCSVDialog';
import _ from 'lodash';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from "notistack";
import PubSubChannels from 'pubsub/PubSubChannels';
import PubSubUtils from 'pubsub/PubSubUtils';
import * as React from 'react';
import CsvDownload from 'react-json-to-csv';
import { AutoSizer, Grid as VirtualGrid } from 'react-virtualized';
import "stylesheet/common.css";
import paginationStyle from "stylesheet/pagination.module.css";
import AirQualityDataGraph from './AirQualityDataGraph';
import Co2DataGraph from './Co2DataGraph';
import DeleteGatewaysDialog from './DeleteGatewaysDialog';
import DiagnosticInfo from "./diagnosticInfo";
import InformationDialog from './InformationDialog';
import RebootDevice from "./rebootDevice";
import ShowGraphData from './ShowGraphData';
import ShowLocationDetails from './ShowLocationDetails';
import TroubleShootDialog from './troubleShootDialog';
import UnRegisterDialog from './UnRegisterDialog';
function TablePaginationActions(props) {

  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }



  return (
      <div className={paginationStyle.paginationActionIcons}>
        <FirstPage
            onClick={() => {
              if (page > 0) handleFirstPageButtonClick();
            }}
            sx={{
              color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
              cursor: page === 0 ? 'auto' : 'pointer',
              marginRight: '8px'
            }}
        />
        <KeyboardArrowLeft
            onClick={() => {
              if (page > 0) handleBackButtonClick();
            }}
            sx={{
              color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
              cursor: page === 0 ? 'auto' : 'pointer',
              marginRight: '8px'
            }}
        />
        <KeyboardArrowRight
            onClick={() => {
              if (page !== Math.ceil(count / rowsPerPage) - 1)
                handleNextButtonClick();
            }}
            sx={{
              color:
                  page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
                      ? 'black'
                      : 'rgba(0,0,0,0.26)',
              cursor:
                  page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
              marginRight: '8px'
            }}
        />
        <LastPage
            onClick={() => {
              if (page !== Math.ceil(count / rowsPerPage) - 1)
                handleLastPageButtonClick();
            }}
            sx={{
              color:
                  page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
                      ? 'black'
                      : 'rgba(0,0,0,0.26)',
              cursor:
                  page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
            }}
        />
      </div>
  );
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    editMode,
    onRequestSort,
  } = props;

  const [changeTextColor,setChangeTextColor] = React.useState('');
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    setChangeTextColor('');
  };

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      width: '90px'
    },
    {
      id: 'serialNumber',
      numeric: false,
      disablePadding: false,
      label: 'Serial #',
      width: '90px'
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      width: '100px'
    },
    {
      id: 'mode',
      numeric: false,
      disablePadding: false,
      label: 'Mode',
      width: '80px'
    },
    {
      id: 'inUse',
      numeric: false,
      disablePadding: false,
      label: 'In Use',
      width: '90px'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      width: '60px'
    },
    {
      id: 'userCount',
      numeric: false,
      disablePadding: false,
      label: 'Users',
      width: '60px'
    },
    {
      id: 'delete',
      numeric: false,
      disablePadding: false,
      label: null,
      width: '80px'
    },
    {
      id: 'accessedTime',
      numeric: false,
      disablePadding: false,
      label: 'Accessed',
      width: '80px'
    },
    {
      id: 'history',
      numeric: false,
      disablePadding: false,
      label: 'History',
      width: '60px'
    },
    {
      id: 'batteryLevel',
      numeric: false,
      disablePadding: false,
      label: 'Battery',
      width: '90px'
    },
    {
      id: 'rssi',
      numeric: false,
      disablePadding: false,
      label: 'Rssi',
      width: '60px'
    },
    {
      id: 'gatewayName',
      numeric: false,
      disablePadding: false,
      label: 'Gateway',
      width: '100px'
    },
    {
      id: 'firmwareVersion',
      numeric: false,
      disablePadding: false,
      label: 'Firmware',
      width: '100px'
    },
  ];

  const selectAll = (event) => {
    onSelectAllClick(event.target.checked);
  };

  return (
      <TableHead>
        <TableRow>
          {
            editMode ?
                <TableCell
                    sx={{
                      padding: '16px',
                      fontSize: '13px'
                    }}
                >
                  <Checkbox
                      color="error"
                      checked={props.headerCheckBox}
                      onClick={(event) => selectAll(event)}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                      id="tableHeadCheckBox"
                      sx={{margin: '0px', padding: '0px',left: "-3px"}}
                  />
                </TableCell>
                :
                null
          }
          {headCells.map((headCell) => (
              (editMode && headCell.id !== "inUse" && headCell.id !== "userCount" && headCell.id !== "history") || (!editMode)  ?
                  <TableCell key={headCell.id} align="left">
                    {headCell.label !== 'History' && headCell.id !== 'delete' ? (
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            sx={{
                              width: headCell.width,
                              maxWidth: headCell.width,
                              color: orderBy !== headCell.id ? '#0000008A !important' : '#000000DE !important',
                              overflowWrap: 'break-word',
                            }}
                        >

                          {headCell.label}
                          {orderBy === headCell.id ? (
                              <Box className="styles.lockSortIcon" component="span" sx={visuallyHidden}>
                                {order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                              </Box>
                          ) : null}
                        </TableSortLabel>
                    ) : (
                        <Box
                            sx={{
                              width: headCell.width,
                              maxWidth: headCell.width,
                              overflowWrap: 'break-word',
                              color: changeTextColor !== 'history' || changeTextColor !== 'delete' ? '#0000008A !important' : '#000000DE !important',
                            }}
                            onClick={()=>setChangeTextColor('history')}
                        >
                          {headCell.label}
                        </Box>
                    )}
                  </TableCell>
                  :
                  null
          ))}
        </TableRow>
      </TableHead>
  );
}

export default function Devices() {
  const [SearchBar, setSearchBar] = React.useState(true);
  const [refresh,setRefresh] = React.useState(true)
  const [rowsPerPage, setRowsPerPage] = React.useState(sessionStorage.locksRPP ? Number(sessionStorage.locksRPP) : 25);
  const [order, setOrder] = React.useState('asc');
  const theme = useTheme(); 
  const [deleteMode, setDeleteMode] = React.useState(false);
  const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    const gridWidth = width > 1279 ? width - 280 : width - 50;
    const gridHeight = window.deleteMode === true ? height - 300 : height - 270;
    return {
      width,
      height,
      gridWidth,
      gridHeight,
    };
  };
  const [orderBy, setOrderBy] = React.useState('Name');
  const [selected, setSelected] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [devices, setDevices] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [deleteLockData, setDeleteLockData] = React.useState([]);
  const [selectedCount, setSelectedCount] = React.useState(0);  
  const { enqueueSnackbar } = useSnackbar();
  let mqttEventHandler, mqttEventHandlerAddUserMap, mqttEventHandlerFailedOrSuccessUserAddition;
  const [downloadCSVData,setDownloadCsvData] = React.useState([]);
  const [showDownloadCsvDialog, setShowDownloadCsvDialog] = React.useState(false);
  const [createLockUserDialog,setCreateLockUserDialog] = React.useState(false);
  const [uploadBulkUsersDialog, setUploadBulkUsersDialog] = React.useState(false)
  const [pageNumber, setPageNumber] = React.useState(0);
  const [openHistoryDialog, setOpenHistoryDialog] = React.useState(false);
  const [infoData, setInfoData] = React.useState([]);
  const [openUsersDialog, setOpenUsersDialog] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [snackBar, setSnackBar] = React.useState(false);
  const [lockDeleteDialog ,setLockDeleteDialog] = React.useState(false);
  const [addUserDialog, setAddUserDialog] = React.useState(false);
  const [selectedBulkAddUsersData, setSelectedBulkAddUsersData] = React.useState([]);
  const [showLockView, setShowLockView] = React.useState(true);
  const [lockInfoDialog, setLockInfoDialog] = React.useState(false);
  const [lockInfoDialogData, setlockInfoDialogData] = React.useState([]);
  let iotReachableDevices = [];
  let iotReachableDevicesResponseReceived = false;
  let iotReachableDeviceUserCount = [];
  let iotReachableDeviceUserCountResponseReceived = false;
  let iotReachableDeviceUsers = [];
  let iotReachableDeviceUsersResponseReceived = false;
  const [responseReceivedCount, setResponseReceivedCount] = React.useState(0);
  const [headersCheckBox, setHeadersCheckBox] = React.useState(false);
  const [showAddingUserDialog, setShowAddingUserDialog] = React.useState(false);
  const [showAddingUserDialogData, setShowAddingUserDialogData] = React.useState(0);
  const [showAddingUserDialogCompletedData, setShowAddingUserDialogCompletedData] = React.useState(0);
  const [showAddUserFailedData, setShowAddUserFailedData] = React.useState(0);
  const [closeAddingLoader, setCloseAddingLoader] = React.useState(false);
  const [lockInstructionType, setLockInstructionType] = React.useState("");
  const [showLockInstructionDialog, setShowLockInstructionDialog] = React.useState(false);
  const [lockInstructionResponseStatus, setLockInstructionResponseStatus] = React.useState("");
  const [closeLockInstructionDialogLoader, setCloseLockInstructionDialogLoader] = React.useState(false);
  const [switchLockModeData, setSwitchLockModeData] = React.useState(null);
  const [showSwitchLockModeDialog, setShowSwitchLockModeDialog] = React.useState(false);
  const [multiClick, setMultiClick] = React.useState(false);
  const [failedUserAdditionOrDeletionUsers,setFailedUserAdditionOrDeletionUsers] = React.useState(null);
  const [smColumn, setSmallColumn] = React.useState(8);
  const [mdColumn, setMediumColumn] = React.useState(8);
  const [lgColumn, setLargeColumn] = React.useState(15);
  const [smItemColumn, setSmItemColumn] = React.useState(4);
  const [mdItemColumn, setMdItemColumn] = React.useState(2);
  const [lgItemColumn, setLgItemColumn] = React.useState(5);  
  const [showLocationDialog, setShowLocationDialog] = React.useState(false);
  const [showGraphDialog, setShowGraphDialog] = React.useState(false);
  const [graphDialogData, setGraphDialogData] = React.useState([]);
  const [unRegisterDialog, setUnregisterDialog] = React.useState(false);
  const [unRegisterData, setUnregisterData] = React.useState([]);
  const [infoDialog, setInfoDialog] = React.useState(false);
  const [infoDialogData, setInfoDialogData] = React.useState([]);
  const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
  const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
  const [locationsData, setLocationsData] = React.useState([]);
  const [tShootDialog, setTShootDialog] = React.useState(false);
  const [tShootDialogData, setTShootDialogData] = React.useState([]);
  const [troubleShootData, setTroubleShootData] = React.useState([]);
  const [diagnosticInfoDialog, setDiagnosticInfoDialog] = React.useState(false);
  const [diagnosticDialogData, setDiagnosticDialogData] = React.useState([]);
  const [diagnosticInfoData, setDiagnosticInfoData] = React.useState("");
  const [rebootDeviceDialog,setRebootDeviceDialog] = React.useState(false);
  const [rebootData, setRebootData] = React.useState([]);
  const [rebootDeviceData,setRebootDeviceData] = React.useState([]);
  const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
  const [selectedOfflineCount, setSelectedOfflineCount] = React.useState(0);
  const [totalOffline, setTotalOffline] = React.useState(null);
  const [deleteGatewayDialog, setDeleteGatewayDialog] = React.useState(false);
  const [selectedRange, setSelectedRange] = React.useState("");
  const [selectedDevices,setSelectedDevices] = React.useState([]);
  const [selectedGwsMac, setSelectedGwsMac] = React.useState(null);
  const [airQualityDialog,setAirQualityDialog] = React.useState(false);
  const [co2Dialog,setCo2Dialog] = React.useState(false);
  const [airQualityDialogData,setAirQualityDialogData] = React.useState([]);
  const [co2DialogData,setCo2DialogData] = React.useState([]);
  //TODO: Need to implement auto screen

  const handleResize = () => {
    setDimensions(getWindowDimensions());
  }

  // let lockViewInterval = setInterval(() => {
  //   if (sessionStorage.lockView === "listView" || sessionStorage.tenantName === "FreeTier" || !sessionStorage.lockView){
  //     setShowLockView(true);
  //   }else {
  //     setShowLockView(false);
  //   }
  // }, 1000);

  const createNewLockUser = (data) => {
    IotServiceClient.createNewLockUser(data).then((response)=> {
      enqueueSnackbar("User added successfully");
    }).catch(()=>{
      enqueueSnackbar("Failed to add user");
    });
    setCreateLockUserDialog(false);
  }

  const handleDeleteLockDialog = (data) =>{
    setDeleteLockData(data);
    setLockDeleteDialog(true);
  };

  const segregateFilteredData = (data) => {    
    let isRefreshingWithFilter = document.getElementById("searchText") ? document.getElementById("searchText").value.length > 0 : false;
    let searchFilterText = document.getElementById("searchText") ? document.getElementById("searchText").value : null;
    if (sessionStorage.navigatedValueFromGlobalSearch){
      let matchedObject = _.find(data,{"MAC":sessionStorage.gsMacId})
      matchedObject = matchedObject ? matchedObject : _.find(data,{"deviceMacId":sessionStorage.gsMacId});
      if (matchedObject){
        gridViewData([matchedObject])
      }else {
        setDevices([]);
      }
    }else if (sessionStorage.navigatedFromDashboard) {
      let filteredValue = sessionStorage.navigatedFromDashboard === "online" ? 1 : null;
      let filteredData = _.filter(data,(value)=> filteredValue === null ? value.CS === filteredValue || value.CS !== 1 : value.CS === filteredValue);
      if(!isRefreshingWithFilter){
        gridViewData(filteredData)
      } else {
        filterDataBasedOnSearchTerm(searchFilterText);
      }
    }else if (sessionStorage.navigatedFromDashboardEnrollmentStatus) {
      let filteredValue = sessionStorage.navigatedFromDashboardEnrollmentStatus === "registered" ? 1 : 0;
      let filteredData = _.filter(data,(value)=>  value.REG === filteredValue);
      let onlineDevices = _.filter(filteredData,(value)=> value.CS === 1);
      let offlineDevices = _.filter(filteredData,(value)=> value.CS !== 1);
      let  finalFilteredData = onlineDevices.concat(offlineDevices);
      if(!isRefreshingWithFilter){
        gridViewData(finalFilteredData)
      } else {
        filterDataBasedOnSearchTerm(searchFilterText);
      }
    } else {
       let onlineDevices = _.filter(data,(value)=> value.CS === 1);
       let offlineDevices = _.filter(data,(value)=> value.CS !== 1);
       let  filteredData = onlineDevices.concat(offlineDevices);
       if(!isRefreshingWithFilter){
         gridViewData(filteredData)
       } else {
         filterDataBasedOnSearchTerm(searchFilterText);
      }
    }
  }

  const gridViewData = (data) => {
     let devicesData = [];
     let arr = [];
     let deepCopy = [];
     let colCount=0;
     let deviceCount = 0;
     _.forEach(data, (val,index) => {
         arr.push(val);
         colCount+=1;
         deviceCount+=1;
         if (colCount > window.columnCount -1 || (data.length < window.columnCount ?  colCount <= window.columnCount -1 && index === data.length -1 : data.length - deviceCount < window.columnCount && index === data.length -1  )){
           colCount = 0;
           deepCopy = _.cloneDeep(arr);
           devicesData.push(deepCopy);
           arr = []
         }
     })
     setDevices(devicesData);
  }

  const handleSetColumnCount = () => {
      let dimentiionsWidth = getWindowDimensions();
      if (dimentiionsWidth.width >= 1919){
        if (dimentiionsWidth.width >= 1919 && dimentiionsWidth.width < 2100){
          window.columnCount = 5;
          window.columnWidth = 320;
        }else if (dimentiionsWidth.width > 2099 && dimentiionsWidth.width < 2300){
          window.columnCount = 6;
          window.columnWidth = 295;
        }else {
          window.columnCount = 6;
          window.columnWidth = 330;
        }
      }else if (dimentiionsWidth.width < 1919 && dimentiionsWidth.width > 1279) {
        if (dimentiionsWidth.width < 1400 && dimentiionsWidth.width > 1279){
          window.columnCount = 3;
          window.columnWidth = 320;
        }else if (dimentiionsWidth.width < 1500 && dimentiionsWidth.width > 1399){
          window.columnCount = 4;
          window.columnWidth = 270;
        }else if (dimentiionsWidth.width > 1499 && dimentiionsWidth.width < 1600){
          window.columnCount = 4;
          window.columnWidth = 300;
        }else if (dimentiionsWidth.width > 1599 && dimentiionsWidth.width < 1700){
          window.columnCount = 4;
          window.columnWidth = 320;
        }else if(dimentiionsWidth.width > 1699 && dimentiionsWidth.width < 1830){
          window.columnCount = 5;
          window.columnWidth = 275;
        }else {
          window.columnCount = 5;
          window.columnWidth = 300;
        }
      }else if (dimentiionsWidth.width < 1280 && dimentiionsWidth.width > 959){
        if (dimentiionsWidth.width < 1100 && dimentiionsWidth.width > 959){
           window.columnCount = 3;
           window.columnWidth = 295;
        }else if (dimentiionsWidth.width < 1200 && dimentiionsWidth.width > 1099){
           window.columnCount = 4;
           window.columnWidth = 250;
        }else if (dimentiionsWidth.width < 1280 && dimentiionsWidth.width > 1199){
           window.columnCount = 5;
           window.columnWidth = 220;
        }
      }else if (dimentiionsWidth.width < 960 && dimentiionsWidth.width > 599) {
        if (dimentiionsWidth.width > 899 && dimentiionsWidth.width < 960){
          window.columnCount = 3;
          window.columnWidth = 275;
        }else if(dimentiionsWidth.width > 699 && dimentiionsWidth.width < 900){
          window.columnCount = 2;
          window.columnWidth = 315;
        }else {
          window.columnCount = 2;
          window.columnWidth = 260;
        }
      }else if (dimentiionsWidth.width < 600){
        if(dimentiionsWidth.width > 499 && dimentiionsWidth.width < 600){
          window.columnCount = 1;
          window.columnWidth = 430;
        }else if(dimentiionsWidth.width > 399 && dimentiionsWidth.width < 500){
          window.columnCount = 1;
          window.columnWidth = 330;
        }else {
          window.columnCount = 1;
          window.columnWidth = 250;
        }
      }
  }

  const fetchDevices = (isMqttEVent) => {
    sessionStorage.alreadyInSideFetchDevices = true;
    handleSetColumnCount();
    let editModeNewData = [];
    IotServiceClient.getReachableIotDevices(sessionStorage.tenantId, "locksPage").then((data) => {
      if (!data.hasOwnProperty("message")){
        _.forEach(data, (val)=> {
          if (val.hasOwnProperty("MAC")){
              val.macIdData = checkDataExistsOrNot(val.MAC) ? val.MAC.toLowerCase().replaceAll(":","") : "";
              val.OSversion = checkDataExistsOrNot(val.OS) ? val.OS : "";
              val.firmware = checkDataExistsOrNot(val.FW) ? val.FW.replace("v", "FW") : "";
              val.macId = val.MAC;
              val.deviceStatus = val.CS === 1 ? "online" : "offline";
          }
        })
        iotReachableDevices = data;
        window.allLocks = data;
        iotReachableDevicesResponseReceived = true;
        if (!isMqttEVent) {
          if (window.deleteMode === true) {
            let selectedDevicesInEditModeCount = 0;
              _.forEach(data, (val)=> {
                if (val.CS !== 1) {
                    if (window.selectedDevicesInDeleteMode[val.MAC]) {
                        val.selected = true;
                        selectedDevicesInEditModeCount+=1;
                    } else {
                        val.selected = false;
                    }
                  editModeNewData.push(val);
                }
              });
              gridViewData(editModeNewData);
              setSelectedOfflineCount(selectedDevicesInEditModeCount);
              setTotalOffline(editModeNewData.length);
          } else {
             segregateFilteredData(data);
          }
        }
      }
    });
  }

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    fetchDevices(false);
    window.oldPreservedLocksPageNumber = 0;
    window.addingUserCompletedData = 0;
    window.addingUserFailedData = 0;
    window.selectedDevicesInDeleteMode = {};
    setTimeout(() => {
      setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
      setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
    }, 1000);
    enqueueSnackbar("Fetching Gateways ...");
    subscribeGatewayStatusEvent(PubSubUtils.getDevicesEvent(sessionStorage.tenantId));
    mqttEventHandlerAddUserMap = MqttEventHandler.subscribe(PubSubUtils.getDevicesUserMappingsEvent(sessionStorage.tenantId), "lock", onDevicesEventReceivedOnAddUserMapping);    
    return () => {
      window.removeEventListener('resize', handleResize);
      window.addingUserCompletedData = 0;
      window.addingUserDIalogData = 0;
      window.addingUserFailedData = 0;
      if (mqttEventHandler){
        mqttEventHandler.unsubscribe();
      }
      if (window.gatewaysEventHandlerChannel) {
        window.gatewaysEventHandlerChannel.unsubscribe();
      }
      // if (lockViewInterval) {
      //   clearInterval(lockViewInterval);
      // }
      if (mqttEventHandlerAddUserMap) {
        mqttEventHandlerAddUserMap.unsubscribe();
      }
      if (mqttEventHandlerFailedOrSuccessUserAddition) {
        mqttEventHandlerFailedOrSuccessUserAddition.unsubscribe()
      }
      if (window.mqttEventHandlerFailedOrSuccessUserAddition) {
        if (Array.isArray(window.mqttEventHandlerFailedOrSuccessUserAddition)) {
          _.forEach(window.mqttEventHandlerFailedOrSuccessUserAddition,(mqttChannel) => {
            mqttChannel.unsubscribe();
          })
        }
        window.mqttEventHandlerFailedOrSuccessUserAddition = [];
      }
      delete sessionStorage.navigatedValueFromGlobalSearch;
      delete sessionStorage.navigatedFromDashboard;
      delete sessionStorage.alreadyInSideFetchDevices;
      delete sessionStorage.navigatedFromDashboardEnrollmentStatus;
      IotServiceClient.abortSignal("locksPage");
      GatewayServiceClient.abortSignal("locksPage");
      delete window.deleteMode;
      delete window.selectedDevicesInDeleteMode;
    }
  }, []);

  const handleRefresh = () => {
    setRefresh(false);
    setTimeout(() => {
      setRefresh(true);
    }, 1050);
    enqueueSnackbar("Fetching Gateways ...");
    fetchDevices(true);
  };

  function numberCharacterOrganizer(array, orderOfsort, orderByOfSort){
    let numbers = [], characters = [];
    _.forEach(array, (element) => {
        if(typeof(element[orderByOfSort]) === 'string' ? Number(element[orderByOfSort] ? element[orderByOfSort].replaceAll(" ","") : element[orderByOfSort]) : Number(element[orderByOfSort])){
          numbers.push(element);
        }
        else{
          characters.push(element);
        }
    })
    array = orderOfsort === 'asc' ? characters.concat(numbers): numbers.concat(characters);
    return array;
  }

  function stableSort(array, orderOfsort, orderByOfSort) {
    array = numberCharacterOrganizer(array, orderOfsort, orderByOfSort);
    array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? typeof(obj[orderByOfSort]) === 'string' ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","")) ? _.get(obj, orderByOfSort, '').toString().toLowerCase().replaceAll(" ","") : Number(_.get(obj, orderByOfSort).replaceAll(" ","")) : Number(_.get(obj, orderByOfSort,'')) : ""], [orderOfsort]);
    return array;
  }

  function descendingComparator(a, b, orderBy) {
    if (typeof b[orderBy] !== 'undefined' && typeof a[orderBy] !== 'undefined' && b[orderBy] !== null && a[orderBy] !== null){
      if (b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()) {
        return 1;
      }
    }
    return -1;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
    window.oldPreservedLocksPageNumber = newPage;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, row) => {
    let newSelected = [];

    if (event.target.checked) {
      if (selected.length > 0) {
        selected.forEach((element) => {
          newSelected.push(element);
        });
        if (JSON.stringify(newSelected).indexOf(row.id) === -1) {
          newSelected.push(row);
        }
      } else {
        newSelected.push(row);
      }
    } else {
      selected.forEach((element) => {
        if (element.id !== row.id) {
          newSelected.push(element);
        }
      });
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (checked) => {
    let selectedData = [];
    let count = 0;
    _.forEach(window.allLocks, (device) => {
      if (device.authStatus === "AUTH_SUCCESS"){
        device.selected = checked;
      }else {
        device.selected = false;
      }
      if (device.selected){
        count +=1;
      }
      selectedData.push(device);
    })
    setSelectedCount(count);
    setHeadersCheckBox(checked);
    setDevices(selectedData);
    window.allLocks = selectedData;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    setPage(event.target.value);
    window.oldPreservedLocksPageNumber = event.target.value;
  };

  const handleRowPerPage = (event) => {
    let rowPerPageValue = event.target.value;
    if (devices.length > 0) {    
      let pageNumberCount = Math.ceil((devices.length/rowPerPageValue)) - 1;                
      if (window.oldPreservedLocksPageNumber > pageNumberCount) {
        setPage(pageNumberCount);
        setPageNumber(pageNumberCount);        
        window.oldPreservedLocksPageNumber = pageNumberCount;
      }else {
        if (window.oldPreservedLocksPageNumber) {
          setPage(window.oldPreservedLocksPageNumber);
          setPageNumber(window.oldPreservedLocksPageNumber);        
        }else {
          setPage(0);
          setPageNumber(0);
          window.oldPreservedLocksPageNumber = 0;
        }
      } 
    } 
    sessionStorage.locksRPP = rowPerPageValue;
    setRowsPerPage(rowPerPageValue);
  };

  const checkSearchTextExist = (data, key,  text) => {
    let value = data[key];
    return value !== null && typeof value !== 'undefined' && value !== "" ? value.toString().toLowerCase().replaceAll(" ","").indexOf(text) !== -1 : false;
  };

  const filterDataBasedOnSearchTerm = (searchFilterText) => {
    let sortedDevices = [];
    if (searchFilterText !== "" && searchFilterText !== undefined && searchFilterText !==  null){
      searchFilterText = searchFilterText.toLowerCase().replaceAll(" ","");
      _.forEach(window.allLocks,(value)=>{
        if (checkSearchTextExist(value, "NAME", searchFilterText) || checkSearchTextExist(value, "MAC", searchFilterText) || checkSearchTextExist(value, "firmware", searchFilterText) ||
            checkSearchTextExist(value, "macId", searchFilterText) || checkSearchTextExist(value, "OSversion", searchFilterText) || checkSearchTextExist(value, "deviceStatus", searchFilterText)){
          sortedDevices.push(value);
        }
      })
      sortedDevices = _.uniq(sortedDevices);
      let onlineDevices = _.filter(sortedDevices,(value)=> value.CS === 1);
      let offlineDevices = _.filter(sortedDevices,(value)=> value.CS !== 1);
      let  filteredData = onlineDevices.concat(offlineDevices);
      let csvData = [];
      _.forEach(filteredData, (value)=>{
        csvData.push(_.omit(value,['id','scopeId','location','isOneTimeUserpresent','authStatus','scopeDoesNotMatch','selected']));
      })
      setDownloadCsvData(csvData);
      gridViewData(filteredData);
    } else {
      let csvData = [];
      _.forEach(window.allLocks, (value)=>{
          csvData.push(_.omit(value,['id','SID','LCTN','scopeDoesNotMatch','selected']));
      })
      setDownloadCsvData(csvData);
       let onlineDevices = _.filter(window.allLocks,(value)=> value.CS === 1);
       let offlineDevices = _.filter(window.allLocks,(value)=> value.CS !== 1);
       let  filteredData = onlineDevices.concat(offlineDevices);
       gridViewData(filteredData);
    }
  }

  const resetPaginationData = (data) => {
    if (window.oldPreservedLocksPageNumber) {
      if (data.length > 0) {
        let pageNumberCount = Math.ceil((data.length/rowsPerPage)) - 1;                
        if (window.oldPreservedLocksPageNumber > pageNumberCount) {
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedLocksPageNumber = pageNumberCount;
        }
      }else {
        let pageNumberCount = Math.ceil((window.allLocks.length/rowsPerPage)) - 1;                
        if (window.oldPreservedLocksPageNumber > pageNumberCount){
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedLocksPageNumber = pageNumberCount;
        }else if (window.oldPreservedLocksPageNumber <= pageNumberCount && pageNumberCount > 0) {
          setPage(window.oldPreservedLocksPageNumber);
          setPageNumber(window.oldPreservedLocksPageNumber);        
        }else {
          setPage(0);
          setPageNumber(0);
          window.oldPreservedLocksPageNumber = 0;
        }        
      }
    }else {
      setPageNumber(0);
      setPage(0);   
      window.oldPreservedLocksPageNumber = 0;
    }
  }

  const handleTextCange = (event) => {
    setSearchText(event.target.value);
    filterDataBasedOnSearchTerm(event.target.value);
  };

  const rowPerPageLabel = (
      <div className={paginationStyle.rowPerPageLabelDiv}>
        <Box className={paginationStyle.page}>
          <span style={{marginRight: '32px'}}>Page</span>
        </Box>
        <Box className={paginationStyle.pageSelectBox}>
          <Select value={pageNumber} onChange={handleChange}>
            {
              Math.ceil(devices.length / rowsPerPage) > 0 ?
                  // eslint-disable-next-line array-callback-return
                  Array(Math.ceil(devices.length / rowsPerPage))
                      .fill()
                      .map((_, index) => {
                        return (
                            <MenuItem value={index} sx={{minWidth: '64px'}}>
                              {index + 1}
                            </MenuItem>
                        );
                      })
                  :
                  <MenuItem value={0} sx={{minWidth: '64px'}}>
                    1
                  </MenuItem>
            }
          </Select>
        </Box>
        <Box className={paginationStyle.rowPerPageLabelDiv}>
          <span style={{marginRight: '32px'}}>Rows Per Page</span>
        </Box>
        <Box className={paginationStyle.rowPerPageSelectBox}>
          <Select value={rowsPerPage} onChange={handleRowPerPage}>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
      </div>
  );

  const historyDialog = (data) => {
    setInfoData(data);
    setOpenHistoryDialog(true);
  };

  const usersDialog = (data) => {
    setUsersData(data);
    setOpenUsersDialog(true);
    window.openedDialogData = data;    
  };

  const handleClose = () => {
    setOpenHistoryDialog(false);
  };

  const [showRemoveUserDialog, setShowRemoveUserDialog] = React.useState(false);

  const handleUsersClose = (event, data, dialog) => {    
    setOpenUsersDialog(false);
    if (data !== "" && typeof data !== 'undefined' && data !== null) {
      // add instruction done
      if (Array.isArray(data) ? data.length > 0 : false){             
        let totalInstructionsCreated = data.length;                
        if (dialog !== "remove"){
          setShowAddingUserDialog(true);
        }else {
          setShowRemoveUserDialog(true);
        }
        setCloseAddingLoader(false);
        setShowAddingUserDialogData(totalInstructionsCreated);
        window.addingUserDIalogData = totalInstructionsCreated;
      }else {
        window.addingUserDIalogData = 0;
        setShowAddingUserDialog(false);
        setShowRemoveUserDialog(false);
        setShowAddingUserDialogData(0);
      }
    }else {
      window.addingUserDIalogData = 0;
      setShowAddingUserDialog(false);
      setShowRemoveUserDialog(false);
      setShowAddingUserDialogData(0);
    }    
  };

  const getAccessedTime = (pastTimestamp)=> {
    let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    if (pastTimestamp === null) return '-';
    else {
      let currentDate = new Date();
      let days = Math.round(Math.abs((currentDate.getTime() - pastTimestamp) / (oneDay)));

      if (days === 0) return 'Today';
      else if (days === 1) return 'Yesterday';
      else if (days > 1) return days + ' Days';
    }
  };

  const getAccessedbyDays = (pastTimestamp)=> {
    let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    if (pastTimestamp === null) return "-";
    else {
      let currentDate = new Date();
      let days = Math.round(Math.abs((currentDate.getTime() - pastTimestamp) / (oneDay)));
      return days;
    }
  }

  const publishInstructionCHannel = () => {
    MqttEventHandler.publish(PubSubChannels.pubsub_channels.INSTRUCTIONS.replace("scopeId",sessionStorage.tenantId),"created",{qos:2, retain:false},function(){})
  }

  const createEntryInActivityFeed = (instruction, data, deviceData, gatewayId) => {
    let payload = {
      "macId" : gatewayId,
      "message" : instruction === "unlock" ? deviceData.name +" Unlock Initiated"  : deviceData.name + " Lock Initiated",
      "date": new Date().getTime(),
      "type": "event",      
    }

    GatewayServiceClient.createLockUnlockEntry(payload).then((response) => {})
  }

  const toggleLockUnlock = (device, instrcutionIssueType) => {

      let lockUnlockInstructionPayload = {
        "payload": {
          "macId": device.MAC,
          "locked": instrcutionIssueType === "unlock" ? true : false,
          "time": new Date().toUTCString(),
          "name": device.NAME,
          "scopeId": device.scopeId
        },
        "gatewayMacId": device.gateway_MacId,
        "type": Enums.instruction_enums.LOCK_UNLOCK_INSTRUCTION
      }
      
      GatewayServiceClient.createInstruction(response => {
        window.lockUnlockSelectedData = device;
        if (response.status === 201 || response.status === 200) {
          if (instrcutionIssueType === "unlock"){
            setLockInstructionType("unlock")
            setShowLockInstructionDialog(true);
            createEntryInActivityFeed("unlock",lockUnlockInstructionPayload.payload,device, device.gateway_MacId);
            enqueueSnackbar("Successfully Created Unlock Instruction");
          }else if (instrcutionIssueType === "lock"){
            setLockInstructionType("lock")
            setShowLockInstructionDialog(true);
            createEntryInActivityFeed("lock",lockUnlockInstructionPayload.payload, device, device.gateway_MacId);
            enqueueSnackbar("Successfully Created Lock Instruction");
          }
          response.json().then((responseData)=> {
            window.mqttEventHandlerFailedOrSuccessLockUnlock = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_LOCK_UNLOCK_INSTRUCTION_SUCCESS_OR_FAILURE.replace("scopeId",sessionStorage.tenantId).replace("gatewayMacId",device.gateway_MacId).replace("lockMacId",lockUnlockInstructionPayload.payload.macId).replace("instructionId",responseData.instructionId), "lock", handleGatewayLockUnlockInstructionSuccessOrFailure)
          })          
        } else {
          enqueueSnackbar("Failed To Create Instruction");
        }
        publishInstructionCHannel();
      }, lockUnlockInstructionPayload);
  };

  const subscribeGatewayStatusEvent = (topic, dialog) => {
    mqttEventHandler = MqttEventHandler.subscribe(topic, "lock", onDevicesEventReceived);
  };

  const handleEvent = (data) => {
    let lockData = [];
    let message = data.message ? data.message : "";
    let payload = data.payload ? data.payload[0] : "";
    let updateUi = false;
    let flagForNewDevice = true;
    if (message === "gatewayOffline") {
      _.forEach(window.allLocks, (val) => {
          if (val.gateway_MacId === data.macId) {
            val.gatewayMacId = "-";
            val.gatewayName = "-";
            val.gateway_MacId = null;
            val.authStatus = null;
          }
          lockData.push(val);
      })
      updateUi = true;
    }else if(message === "lockStatus"){
      _.forEach(window.allLocks, (val) => {
        if (val.MAC === payload.macId) {
          val.status = payload.status;
        }
        lockData.push(val);
      })
      updateUi = true;
    }else if(message === "userMapping"){
      if (payload !== "" && payload !== null){
        _.forEach(window.allLocks, (val) => {
          if (val.MAC === payload.macId) {
            val.count = payload.userCount;
            val.userCount = payload.userCount;
            val.inUse = payload.inUse !== null && payload.inUse !== "" ? payload.inUse : "no-email";
          }
          lockData.push(val);
        })
        updateUi = true;
      }
    }else if (message === "deleteLock") {
      _.forEach(window.allLocks, (val) => {
        if (val.MAC !== data.macId) {
            lockData.push(val);
        }
      })
      updateUi = true;
    }else if (message === "bulkUpdate") {
      window.handleMqttEventFetchDevices = setInterval(() => {
        let alreadyExistInNetworkCall = sessionStorage.alreadyInSideFetchDevices ? JSON.parse(sessionStorage.alreadyInSideFetchDevices) : null;
        if (!alreadyExistInNetworkCall) {
          fetchDevices(true);
          clearInterval(window.handleMqttEventFetchDevices);
        }
      }, 1000);
    }else if (message === "lockMode") {
      _.forEach(window.allLocks, (val) => {
        if (val.macId === payload.macId) {
          val.mode = payload.mode === "residential" ? "resident" : payload.mode;;
          val.count = payload.userCount;
          val.userCount = payload.userCount;
          val.inUse = payload.inUse !== null && payload.inUse !== "" ? payload.inUse : "no-email";
        }
        lockData.push(val);
      })
      updateUi = true;
    }else if (message === "lockData") {
      _.forEach(window.allLocks, (val) => {
        if (val.macId === payload.macId) {
          val.mode = payload.mode === "residential" ? "resident" : payload.mode;;
          val.count = payload.userCount;
          val.userCount = payload.userCount;
          val.inUse = payload.inUse !== null && payload.inUse !== "" ? payload.inUse : "no-email";
          val.name = payload.name;
          val.mode = payload.mode;
          val.accessed = getAccessedTime(payload.lastAccessedTime);
          val.accessedTime = payload.lastAccessedTime;
        }
        lockData.push(val);
      })
      updateUi = true;
    }else if(message === "history"){
      _.forEach(window.allLocks, (val) => {
        if (val.macId === payload.macId) {
          val.accessed = getAccessedTime(payload.lastAccessedTime);
          val.accessedTime = payload.lastAccessedTime;
        }
        lockData.push(val);
      })
      updateUi = true;
    }else if (message === "forceProbeData") {
      _.forEach(window.allLocks, (val) => {        
          if (val.macId === payload.macId) {

          }
        lockData.push(val);
      })
      updateUi = true
    }else if(checkDataExistsOrNot(data) && data.scopeId === sessionStorage.tenantId){
      _.forEach(window.allLocks, (val) => {
          if (val.macId === data.macId) {
            val.OSversion = checkDataExistsOrNot(data.OSversion) ? data.OSversion : "";
            val.connStatus = data.connStatus;
            val.connectionType = data.connectionType;
            val.firmware = checkDataExistsOrNot(data.firmware) ? data.firmware : "";
            val.hotspotStatus = data.hotspotStatus;
            val.lastOfflineTime = data.lastOfflineTime;
            val.lastOnlineTime = data.lastOnlineTime;
            val.manufacturedDate = data.manufacturedDate;
            val.name = data.name;
            val.nwType = data.nwType;
            val.REG = data.REG;
            val.signalStrength = data.signalStrength;
            val.updatedAt = data.updatedAt;
            flagForNewDevice = false;
          }
          lockData.push(val);
      })
      updateUi = true;
    }else if(message.hasOwnProperty("type") && message.type === "HSIPRFAILED") {
       _.forEach(window.allLocks, (val) => {
          if (val.macId === message.data.MAC) {
            val.HSIPRC = message.data.HSIPRC;
          }
          lockData.push(val);
       })
       enqueueSnackbar("Failed to update hotspot status for " + message.data.MAC);
       updateUi = true;
    }else if (message.hasOwnProperty("type") && message.type === "HSIPRCModified") {
        _.forEach(window.allLocks, (val) => {
              if (val.macId === message.data.MAC) {
                val.HSIPRC = message.data.HSIPRC;
              }
              lockData.push(val);
        })
        enqueueSnackbar("Successfully updated hotspot status for " + message.data.MAC);
        updateUi = true;
    }else{
      enqueueSnackbar("Refreshing Gateways...");
      flagForNewDevice =false;
      fetchDevices(false);
    }

    if(flagForNewDevice){
      enqueueSnackbar("Refreshing Gateways...");
      updateUi = false;
      fetchDevices(false);
    }

    if (updateUi){
      window.allLocks = lockData;
      enqueueSnackbar("Refreshing Gateways...");
      segregateFilteredData(lockData);
    }
  }

  const onDevicesEventReceived = (data) => {
    data = data.toString().indexOf("{") !== -1 ? JSON.parse(data.toString()) : "" ;
      handleEvent(data);
  };

  const onDevicesEventReceivedOnAddUserMapping = (data) => {
    enqueueSnackbar("Refreshing Gateways ...");
    data = data ? JSON.parse(data.toString()) : "" ;
    if (data.hasOwnProperty("message")){
      handleEvent(data);
    }
  }

  const handleGatewayLockUnlockInstructionSuccessOrFailure = (message) => {
    message = message.toString();
    if (message === "failed") {
      setLockInstructionResponseStatus("failed");
      setCloseLockInstructionDialogLoader(true)
    }else if (message === "success") {
      setLockInstructionResponseStatus("success");
      setCloseLockInstructionDialogLoader(true)
    }
  }

  const handleAddOrRemoveUserEvent = (data) => {    
      let userDetails = _.find(window.allUsers, {"emailHash": data.payload.payload.emailHash});
      if (userDetails){
        let name = userDetails.firstName ? userDetails.lastName ? userDetails.firstName + ' ' + userDetails.lastName : userDetails.firstName : "No Name";
        
        if (window.failedUserAdditionOrDeletionUsers) {
          window.failedUserAdditionOrDeletionUsers+= ", " +name;
        }else {
          window.failedUserAdditionOrDeletionUsers = name;
        }
        setFailedUserAdditionOrDeletionUsers(window.failedUserAdditionOrDeletionUsers);        
      }
  }

  const handleGatewayAddInstructionSuccessOrFailure = (data) => {        
    data = data ? data.toString().indexOf("message") !== -1 ? JSON.parse(data.toString()) : "" : "" ;
    let message = data.message;
    if (data.hasOwnProperty("message")){
      if (message === "failed"){
        handleAddOrRemoveUserEvent(data);
      }
    }    
    if (message === "failed") {
      window.addingUserFailedData += 1; 
    }else if (message === "success"){
      window.addingUserCompletedData += 1;
    }

    if (window.addingUserDIalogData){
      if (window.addingUserDIalogData > 0) {
        if ((window.addingUserCompletedData + window.addingUserFailedData) <= window.addingUserDIalogData){
          setShowAddingUserDialogCompletedData(window.addingUserCompletedData);
          setShowAddUserFailedData(window.addingUserFailedData);
          if ((window.addingUserCompletedData + window.addingUserFailedData) === window.addingUserDIalogData) {
            setCloseAddingLoader(true);
          }
        }
      }else {
        setShowAddingUserDialogCompletedData(0);
      }
    }
  }

  const unCheck = (device) => {
    let uncheckDevice = [];
    let count = 0;
    _.forEach(window.allLocks,(value)=>{
      if (device.MAC === value.macId && value.authStatus === "AUTH_SUCCESS"){
        value.selected = !device.selected;
      }
      if (value.selected){
        count+=1;
      }
      uncheckDevice.push(value);
    });
    setSelectedCount(count);
    if (!(searchText.length > 0)) {
        setDevices(uncheckDevice);
    }
    window.allLocks = uncheckDevice;
  }

   const closeDeleteGatewayDialog = () => {
      setDeleteGatewayDialog(false);
      window.selectedDevicesInDeleteMode = {};
   }

  const handleRandomSelection = (device) => {
    let count = 0;
    let offlineGws;
    let selectedGws = [];
    let latestSelectedGatewaysMac = _.cloneDeep(Object.assign({},window.selectedDevicesInDeleteMode));
    _.forEach(window.allLocks,(val) =>{
       if (val.MAC === device.MAC) {
          val.selected = !val.selected;
       }
       if (val.selected) {
          count+=1;
          selectedGws.push(val);
          if (!latestSelectedGatewaysMac[val.MAC]) {
            latestSelectedGatewaysMac[val.MAC] = true;
          }
       } else {
          if (latestSelectedGatewaysMac[val.MAC]) {
             delete latestSelectedGatewaysMac[val.MAC];
          }
       }
    })
    setSelectedGwsMac(latestSelectedGatewaysMac);
    window.selectedDevicesInDeleteMode = latestSelectedGatewaysMac;
    offlineGws = _.filter(window.allLocks, (val) => val.CS !== 1);
    gridViewData(offlineGws);
    setSelectedOfflineCount(count);
    setSelectedDevices(selectedGws);
  }

  const handleSelectGateways = () => {
    let offlineGws = _.filter(window.allLocks, (val) => val.CS !== 1);
    let selectedGws = [];
    let count = 0;
    let latestSelectedGatewaysMac = _.cloneDeep(Object.assign({},window.selectedDevicesInDeleteMode));
    if (selectedRange === '*') {
       selectedGws = offlineGws.slice(0,offlineGws.length);
    } else {
       if (selectedRange >= 0) {
          selectedGws = offlineGws.slice(0,selectedRange);
       }
    }
    _.forEach(window.allLocks,(value)=>{
        if (selectedGws.indexOf(value) !== -1) {
           value.selected = true;
        } else if (!value.selected) {
            value.selected = false;
        }
        if (value.selected) {
          if (!latestSelectedGatewaysMac[value.MAC]) {
             latestSelectedGatewaysMac[value.MAC] = true;
          }
          count+=1;
        }
    })
    setSelectedGwsMac(latestSelectedGatewaysMac);
    window.selectedDevicesInDeleteMode = latestSelectedGatewaysMac;
    let updatedDevices = _.filter(window.allLocks, (val) => val.CS !== 1);
    gridViewData(updatedDevices);
    setSelectedDevices(selectedGws);
    setSelectedOfflineCount(count);
  }

  const handleOptionSelection = (event) => {
    if (checkDataExistsOrNot(event.target.value)) {
       setSelectedRange(event.target.value);
    }
  }

  const handleDeleteMode = () => {
    if (deleteMode === false) {
       let offlineGws = _.filter(window.allLocks, (val) => val.CS !== 1);
       _.forEach(offlineGws, (val) =>{
          val.selected = false;
       })
       setTotalOffline(offlineGws.length);
       gridViewData(offlineGws);
    } else {
       _.forEach(window.allLocks,(val) =>{
          val.selected = false;
       })
       segregateFilteredData(window.allLocks);
       setSelectedOfflineCount(0);
       window.selectedDevicesInDeleteMode = {};
    }
    window.deleteMode = !deleteMode;
    setDeleteMode(!deleteMode);    
    setDimensions(getWindowDimensions());
  }

  const handleEditMode = () => {
    let removeSelectedDevices = [];
    if (editMode) {
      _.forEach(window.allLocks, (value) => {
        value.selected = false;
        removeSelectedDevices.push(value);
      });
      setDevices(removeSelectedDevices);
      setSelectedCount(0);
      window.allLocks = removeSelectedDevices;
    }
    setEditMode(!editMode);
  };

  const handleBulkToggleInstruction = () => {
    let data = [];
    setEditMode(false);
    _.forEach(window.allLocks,(value) => {
      if (value.selected) {
        let instruction = value.status === "LOCKED" ? "unlock" : "lock";
        toggleLockUnlock(value, instruction);
        value.selected = false;
      }
      data.push(value);
    });
    setSelectedCount(0);
    setDevices(data);
    window.allLocks = data;
  };

  const handleBulkAddUsers = () => {
    let selectedLocks = [];
    let selectedLockTypes = [];
    _.forEach(window.allLocks,(device)=>{
      if (device.selected){
        selectedLockTypes.push(device.type);
        selectedLocks.push(device);
      }
    })
    setSelectedBulkAddUsersData(selectedLocks);
    let uniqSelectedLockTypes = _.uniq(selectedLockTypes);
    if (uniqSelectedLockTypes.length !== 1){
      enqueueSnackbar("Please select same type of Locks.");
    }else {
      setAddUserDialog(true);      
    }

  };

  const removeFilter = () => {
    delete sessionStorage.navigatedFromDashboard;
    delete sessionStorage.navigatedValueFromGlobalSearch;
    delete sessionStorage.navigatedGatewayName;
    delete sessionStorage.navigatedFromDashboardEnrollmentStatus;
    fetchDevices(false);
  };

  const handleOpenCsvDialog = () => {
    setShowDownloadCsvDialog(true);
  }

  const handleCloseCsvDialog = (data) => {
    setShowDownloadCsvDialog(false);
    setDownloadCsvData(data);
    document.getElementById("downloadDeviceCsv").click()
  }

  const showLockInfo = (lockData) => {
    setLockInfoDialog(true);
    setlockInfoDialogData(lockData);
  }

  const getAnimationCOndition = (device) => {
    return device.scopeDoesNotMatch &&  (sessionStorage ? JSON.parse(sessionStorage.userDetails).permissions ?  JSON.parse(sessionStorage.userDetails).permissions.length > 0 ? JSON.parse(sessionStorage.userDetails).permissions.indexOf("TOP_LEVEL_SCOPE_READ") !== -1  : false : false : false);
  }

  const onSwitchModeDialog = (data) => {
    if (data.gateway_MacId){
      setShowSwitchLockModeDialog(true);
      setSwitchLockModeData(data);
    }
  }

  const publishForceProbe = (devices) => {
    let allDevicesNameMapping = {};
    let data = [];
    if (devices !== null){
      let deviceMacId = [devices.macId];
      MqttEventHandler.publish("gateways/"+devices.gateway_MacId+"/receive/devices/forceProbe", JSON.stringify(deviceMacId) , {qos: 2, retain: false}, function() {});
      enqueueSnackbar("Successfully published force probe")
    }else {
        let showErrorToastMessage = false;
      _.forEach(window.allLocks, (device) => {
        if (device.selected) {
          device.selected = false;        
          if (!device.scopeDoesNotMatch){            
            let gatewayMacId = device.gateway_MacId;
            if (allDevicesNameMapping.hasOwnProperty(gatewayMacId)) {
              allDevicesNameMapping[gatewayMacId].push(device.MAC);
            }else {
              allDevicesNameMapping[gatewayMacId] = [device.MAC];
            }
          }else {
            showErrorToastMessage = true;
          }
        }
        data.push(device);
      })
      setEditMode(false);
      setSelectedCount(0);
      setDevices(data);
      setHeadersCheckBox(false)
      window.allLocks = data;
      if (!showErrorToastMessage) {
        let allGatewayIds = _.keys(allDevicesNameMapping);
        _.forEach(allGatewayIds,(gatewayId)=> {
          let forceProbeLocksPayload = JSON.stringify(allDevicesNameMapping[gatewayId]);
          MqttEventHandler.publish("gateways/"+gatewayId+"/receive/devices/forceProbe", forceProbeLocksPayload , {qos: 2, retain: false}, function() {});
        })
        if (allGatewayIds.length > 0){        
          enqueueSnackbar("Successfully published force probe")
        }
      }else {
        enqueueSnackbar("Please select valid locks for force probe");
      }
    }        
  }

  const unRegisterDevice = (device) => {
    if (device.REG === 1 && device.CS === 1){
      setUnregisterDialog(true);
      setUnregisterData(device);
    }
  }

  const showInfoDialog = (device) => {
    setInfoDialog(true);
    setInfoDialogData(device);
  }

  const showTShootDialog = (device) => {
    if(device.CS === 1) {
        setTShootDialog(true);
        setTShootDialogData(device);
    }
    else {
        enqueueSnackbar("Gateway is offline...");
    }
  }

  const showDiagnosticInfo = (device) => {
    if(device.CS === 1) {
      setDiagnosticInfoDialog(true);
      setDiagnosticDialogData(device);
    }
    else{
      enqueueSnackbar("Gateway is offline...");
    }
  }

  const showRebootDialog = (device) => {
    if(device.CS === 1) {
      setRebootDeviceDialog(true);
      setRebootData(device);
    }else{
      enqueueSnackbar("Gateway is offline...")
    }
  }

  const changeHotSpotProcessingStatus = () => {
    let devicesData = [];
    let hotSpotToggleData = infoDialogData;
    _.forEach(window.allLocks, (val) => {
        if(val.MAC === hotSpotToggleData.MAC) {
            val.HSIPRC = 1;
        }
        devicesData.push(val);
    })
    window.allLocks = devicesData;
    //enqueueSnackbar("Refreshing Gateways...");
    segregateFilteredData(devicesData);

  }

  return (
      <>
        {
          sessionStorage.navigatedFromDashboard === "online" || sessionStorage.navigatedFromDashboard === "offline"
              || sessionStorage.navigatedValueFromGlobalSearch === "gs" || sessionStorage.navigatedFromDashboardEnrollmentStatus === "registered" || sessionStorage.navigatedFromDashboardEnrollmentStatus === "unregistered" ?
              <Box sx={{display:"flex",flexDirection:"row",padding: '10px',background:"#ffcc80"}}>
                <Box>
                  Filtered by :
                </Box>
                <Box sx={{marginRight: "auto", marginLeft: "6px",height:"22px",background:"#ede8e8",width:"auto"}}>
                  <Box sx={{display:"flex",flexDirection:"row",marginLeft:"9px"}}>                    
                    <Tooltip placement='top' title={sessionStorage.navigatedFromDashboard ? sessionStorage.navigatedFromDashboard : sessionStorage.navigatedGatewayName} arrow>
                      <Box className={sessionStorage.navigatedFromDashboard ? "" : 'filterNameForSmallScreens'}>
                        {sessionStorage.navigatedFromDashboard ? sessionStorage.navigatedFromDashboard : sessionStorage.navigatedFromDashboardEnrollmentStatus ? sessionStorage.navigatedFromDashboardEnrollmentStatus : sessionStorage.navigatedGatewayName}
                      </Box>
                    </Tooltip>                    
                    <Box sx={{padding:"0px 3px 0px 3px"}}>
                      <Close sx={{cursor:"pointer",fontSize:"21px",paddingLeft:"4px",marginTop:"1px"}} onClick={()=> removeFilter()} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              :
              null
        }
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: '20px 0px 20px 24px',
            }}
        >
        <span style={{fontSize: '20px', marginRight: 'auto'}}>Gateways {
          editMode ?
              selectedCount > 0  ?
                  <>
                    selected - {selectedCount}
                  </>
                  :
                  null
              :
              null
        }</span>
          <div style={{display: 'flex'}} id="iconsBlock">
            <input
                id="searchText"
                className= {SearchBar ? "search" : "searchAnimation"}
                type="text"
                onChange={handleTextCange}
                placeholder="Search"
                autoFocus
            />
            <Close
                style={{
                  marginRight: '4px',
                  cursor: 'pointer',
                  display: SearchBar ? 'flex' : 'none',
                  color: '#0000008A'
                }}
                onClick={() => {
                  setSearchBar(false);
                  setSearchText('');
                  document.getElementById('searchText').value = '';
                  let csvData = [];
                  _.forEach(window.allLocks, (value)=>{
                    csvData.push(_.omit(value,['id','SID','LCTN']));
                  })
                  setDownloadCsvData(csvData);
                  gridViewData(window.allLocks);
                }}
            />
            <Tooltip title="Search" placement="top" arrow>
              <Search
                  style={{
                    marginRight: '3px',
                    cursor: 'pointer',
                    display: !SearchBar ? 'flex' : 'none',
                    color: '#0000008A'
                  }}
                  onClick={() => {setSearchBar(true);
                    setTimeout(() => {document.getElementById("searchText").focus();},100);
                  }}
              />
            </Tooltip>
            {
              selectedCount > 0 ?
                  <>
                    <Tooltip title="Add User" placement="top" arrow>
                      <PersonAdd style={{marginRight: '10px', cursor: 'pointer',color: '#0000008A'}} onClick={()=> handleBulkAddUsers()} />
                    </Tooltip>
                    <Tooltip title="Toggle Status" placement="top" arrow>
                      <Lock style={{marginRight: '10px', cursor: 'pointer',color: '#0000008A'}} onClick={()=> handleBulkToggleInstruction()}/>
                    </Tooltip>
                    {
                      JSON.parse(sessionStorage.userDetails).permissions.includes("TOP_LEVEL_SCOPE_READ") ?
                        <Tooltip
                          title="Force Probe"
                          placement="top"
                          arrow
                          style={{cursor: 'pointer'}}
                        >
                          <SyncLock style={{cursor: 'pointer',color: '#0000008A',fontSize:"22px",marginRight: '10px',
                              display: JSON.parse(sessionStorage.userDetails).user.permissions.includes("TOP_LEVEL_SCOPE_READ") ? null : 'none'}}                                          
                            onClick={() => publishForceProbe(null)} />
                        </Tooltip>
                      :
                        null
                    }
                  </>
                  :
                  null
            }
            <Tooltip title="Refresh" placement="top" arrow>
              <Refresh className={refresh?"refreshIcon":"refreshIconAnimation"} sx={{marginRight:"4px",color: '#0000008A'}} onClick={()=> handleRefresh()} />
            </Tooltip>
            <Tooltip title="Download CSV" placement="top" arrow>
              <Download style={{marginRight: '14px', cursor: 'pointer',color: '#0000008A'}} onClick={()=> handleOpenCsvDialog()} />
            </Tooltip>
            <CsvDownload
                data={downloadCSVData}
                filename={sessionStorage.tenantName + '-Devices.csv'}
                style={{
                  border: 'transparent',
                  background: 'transparent',
                  paddingLeft: '0px',
                  visibility: 'hidden',
                  position:"absolute",
                  top:"-50%"
                }}           
                id="downloadDeviceCsv"
            >
              <Tooltip title="Download CSV" placement="top" arrow>
                <Download style={{marginRight: '14px', cursor: 'pointer',color: '#0000008A'}} />
              </Tooltip>
            </CsvDownload>
          </div>
        </Box>
        <Box sx={{
                   display: deleteMode === true ? 'block' : 'none',
                   flexDirection: 'row',
                   paddingLeft: "25px"
                 }}>
          <Box sx={{display: 'flex'}}>
             <Box sx={{flex: '95%'}}>
               <span style={{fontSize: '18px', marginRight: 'auto'}}> Selected Offline Gateways - {selectedOfflineCount} / {totalOffline} </span>
             </Box>
             <Box sx={{flex: '5%'}}>
                <Box sx={{display: 'flex',flexDirection:'row'}}>
                  <Box sx={{marginRight: '20px'}}>
                     <Tooltip title="Delete Gateways" placement="top" arrow>
                        <Delete style={{display: selectedOfflineCount > 0 ? 'block' : 'none', cursor: 'pointer',color: '#0000008A',fontSize: '28px',padding: '2px'}}
                           onClick={() => setDeleteGatewayDialog(true)} />
                     </Tooltip>
                  </Box>
                  <Box>
                     <PopupState variant="popover" popupId="demo-popup-popover">
                         {(popupState) => (
                        <Box>
                           <Tooltip title="Select Gateways" placement="top" arrow>
                              <FilterList style={{marginRight: '20px', cursor: 'pointer',color: '#0000008A'}} {...bindTrigger(popupState)}/>
                           </Tooltip>
                           <Popover
                              {...bindPopover(popupState)}
                              anchorReference={popupState.anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              sx={{top:"14px !important"}}
                           >
                             <MenuItem value="10" onClick={() => setSelectedRange(10)}>
                                10
                             </MenuItem>
                             <MenuItem value="50" onClick={() => setSelectedRange(50)}>
                                50
                             </MenuItem>
                             <MenuItem value="100" onClick={() => setSelectedRange(100)}>100</MenuItem>
                             <MenuItem>
                                <TextField variant="standard" sx={{width: '50px'}} onChange={(event) => handleOptionSelection(event)}/>
                             </MenuItem>
                             <MenuItem value="*" onClick={() => setSelectedRange('*')}>All</MenuItem>
                             <div>
                               <Tooltip title='Apply' placement='top'>
                                  <CheckCircle sx={{color: '#4190c7',marginLeft: '25px',marginTop:'10px',cursor: 'pointer'}}
                                     onClick={() => { popupState.close(); handleSelectGateways();}}/>
                               </Tooltip>
                             </div>
                           </Popover>
                        </Box>
                       )}
                     </PopupState>
                  </Box>
                </Box>
             </Box>
          </Box>
        </Box>
        <Box style={{width: '100%', marginBottom: '86px', padding: '15px'}}>
              {
                devices.length > 0 ?
                  <AutoSizer disableHeight disableWidth>
                  {({width,height}) => (
                    <VirtualGrid
                      width={Dimensions.gridWidth}
                      height={Dimensions.gridHeight}
                      columnCount={window.columnCount}
                      columnWidth={window.columnWidth}
                      cellRenderer={({columnIndex, key, rowIndex, style}) => {
                        const device = devices[rowIndex].length >= columnIndex ? devices[rowIndex][columnIndex] : [];
                        return(
                        checkDataExistsOrNot(device) ?
                         <div style={style} >
                         <Box sx={{position:"absolute",top:"-4px",left:"-4px",zIndex:"400",display: device.CS !== 1 && deleteMode === true ? 'block' : 'none', padding: '2px'}}>
                           &nbsp;
                           <Tooltip title="Select for Deletion" placement="top" arrow>
                              <Verified className='verifiedIcon' sx={{fontSize: '28px', cursor: 'pointer', color: device.selected === true ? primaryThemeColor : '#dfc3df'}}
                                 onClick={() => handleRandomSelection(device)}/>
                           </Tooltip>
                         </Box>
                          <Box className={device.REG === 0 ? 'showAnimation ' : null} style={{background: device.CS === 1 ? primaryThemeColor : "grey", borderRadius:"8px", margin:"8px"}}>
                            <Box sx={{display:"flex",flexDirection:"row",color:"#fff",justifyContent:"space-around",paddingTop:"10px",position: 'relative'}}>
                              <Box sx={{position:"relative"}}>&nbsp;&nbsp;</Box>
                              <Box sx={{fontWeight: 'bold',fontSize: "14px"}}>
                                {
                                  device.NAME.length > 14 ?
                                    <Tooltip placement='top' title={device.NAME}>
                                      <Box>
                                        {device.NAME.substring(0,14)+"..."}
                                      </Box>
                                    </Tooltip>
                                  :
                                    device.NAME
                                }
                              </Box>
                              <Box>
                                {
                                  device.HS === 1 || device.HSIPRC === 1 ?
                                    <span className={ device.HSIPRC === 1 ? 'showBlinking' : null }>
                                        <Tooltip title="Hotspot enabled" placement="top" arrow>
                                          <WifiTethering />
                                        </Tooltip>
                                    </span>
                                  :
                                    <WifiTethering sx={{visibility:'hidden'}}/>
                                }
                                {
                                  device.NW === 1 ?
                                    <Tooltip title="Wi-fi" placement="top" arrow>
                                      <Wifi/>
                                    </Tooltip>
                                  :
                                    device.NW === 0 ?
                                      device.SS === 0 ?
                                        <Tooltip title="LTE" placement="top" arrow>
                                          <SignalCellular0Bar />
                                        </Tooltip>
                                      :
                                        device.SS === 1 ?
                                          <Tooltip title="LTE" placement="top" arrow>
                                            <SignalCellular1Bar />
                                          </Tooltip>
                                        :
                                          device.SS === 2 ?
                                            <Tooltip title="LTE" placement="top" arrow>
                                              <SignalCellular2Bar />
                                            </Tooltip>
                                          :
                                            device.SS === 3 ?
                                              <Tooltip title="LTE" placement="top" arrow>
                                                <SignalCellular3Bar />
                                              </Tooltip>
                                            :
                                              <Tooltip title="LTE" placement="top" arrow>
                                                <SignalCellular4Bar />
                                              </Tooltip>
                                    :
                                      device.NW === 2 ?
                                        <Tooltip title="Ethernet" placement="top" arrow>
                                          <LanIcon />
                                        </Tooltip>
                                      :
                                        <LanIcon sx={{visibility:'hidden'}}/>
                                }
                              </Box>
                            </Box>
                            <Box sx={{height:"24px"}}>
                              <Typography sx={{marginLeft:"10px",color:"#fff",fontSize: "12px",fontWeight: 'bold'}}>
                                Mac-Id # {
                                  checkDataExistsOrNot(device.MAC) ?
                                    device.MAC.length > 14 ?
                                      <Tooltip placement='top' title={device.MAC}>
                                        <span>
                                          {device.MAC.substring(0,14)+"..."}
                                        </span>
                                      </Tooltip>
                                    :
                                      device.MAC
                                  :
                                    device.MAC
                                }
                              </Typography>
                            </Box>
                            <Box sx={{height:"140px",marginLeft:"10px", marginRight:"10px",border:"1px solid #fff",borderRadius:"10px"}}>
                              <Box sx={{display:'flex',flexDirection:"row",justifyContent:"space-between",color: "#fff",margin:"6px"}}>
                                <Box>
                                  <Tooltip title="Reboot Gateway" placement="top" arrow>
                                    <SettingsPower sx={{cursor:"pointer"}} onClick={() => showRebootDialog(device)} />
                                  </Tooltip>
                                </Box>
                                <Box>
                                  <Box sx={{position:"relative"}} onClick={()=> unRegisterDevice(device)}>
                                    <Tooltip title="Unregister Gateway from Cloud Server and Delete Gateway Stored Data" placement="top" arrow>
                                      <AssignmentOutlined sx={{cursor:"pointer"}} />
                                    </Tooltip>
                                        <ModeEditOutlineOutlined sx={{fontSize: "21px",position:"absolute",top:"0px",right:"-9px",cursor:"pointer"}} />
                                  </Box>
                                </Box>
                                <Box>
                                  <Tooltip title="Diagnostic information" placement="top" arrow>
                                    <SettingsOutlined sx={{cursor:"pointer"}} onClick={() => showDiagnosticInfo(device)}/>
                                  </Tooltip>
                                </Box>
                              </Box>

                              <Box sx={{display:'flex',flexDirection:"row",justifyContent:"center",color: "#fff",margin:"6px",height:"44px"}}>
                                <Box>
                                  <Box sx={{position:"relative",cursor:"pointer"}}>
                                    <Box onClick={()=> {setAirQualityDialog(true)
                                        setAirQualityDialogData(device)}}>
                                      <Typography>air</Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box>
                                  <Box sx={{position:"relative",cursor:"pointer"}}>
                                    <Box onClick={()=> {setShowGraphDialog(true)
                                        setGraphDialogData(device)}}>
                                      <CropPortraitOutlined sx={{fontSize:"44px"}} />
                                      <PriorityHighOutlined sx={{fontSize: "22px",position:"absolute",top:"10px",right:"11px",rotate:"180deg"}} />
                                    </Box>
                                  </Box>
                                </Box>
                                <Box>
                                  <Box sx={{position:"relative",cursor:"pointer"}}>
                                    <Box onClick={()=> {setCo2Dialog(true)
                                        setCo2DialogData(device)}}>
                                      <Typography>co2</Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>

                              <Box sx={{display:'flex',flexDirection:"row",justifyContent:"space-between",color: "#fff",margin:"16px 6px 6px 6px"}}>
                                <Box sx={{position: 'relative'}}>
                                  <Tooltip title="Information" placement="top" arrow>
                                    <Info sx={{cursor:"pointer"}} onClick={() => showInfoDialog(device)} />
                                  </Tooltip>
                                </Box>
                                <Box sx={{position: 'relative'}}>
                                  <Tooltip title="Gateway Location" placement="top" arrow>
                                    <LocationOn sx={{cursor:"pointer"}} onClick={()=>{
                                       window.DeviceGraphName = device.MAC
                                       if (checkDataExistsOrNot(device.LCTN) ? checkDataExistsOrNot(device.LCTN.LAT) && checkDataExistsOrNot(device.LCTN.LNG) : false){
                                         setLocationsData([{"lat":device.LCTN.LAT,"lng":device.LCTN.LNG,"macId":device.MAC,"connStatus":device.CS}])
                                       }else {
                                         setLocationsData([])
                                       }
                                       setShowLocationDialog(true);
                                    }}/>
                                  </Tooltip>
                                  <Tooltip title="Delete Gateway" placement="top" arrow>
                                     <Delete style={{display: device.CS !== 1 && JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE") && deleteMode !== true ? 'block' : 'none', position: 'absolute', bottom: '-54px', cursor:"pointer"}}
                                      onClick={() => {setDeleteGatewayDialog(true); setSelectedDevices([device]);}}/>
                                  </Tooltip>
                                </Box>
                                <Box>
                                  <Tooltip title="Troubleshoot" placement="top" arrow>
                                    <TroubleshootIcon sx={{cursor:"pointer"}} onClick={() => showTShootDialog(device)} />
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                            <Box style={{height:"20px"}}>
                              <Box style={{ height: "80%", margin: "5px 10px 5px 10px", textAlign: "center" }}>
                                {device.tags.slice(0, 2).map((tag) => (
                                  <Tooltip title={tag} style={{ height:"15px",cursor:"pointer"}} placement="top">
                                    <Chip id="chip" label={tag.length > 4 ? tag.substring(0, 4) + ".." : tag} variant="outlined"/>
                                  </Tooltip>
                                ))}
                                {device.tags.length>2 ?
                                  <Tooltip title={device.tags.slice(2,device.tags.length).map(tag =><>{tag}<br/></>)} style={{height:"15px",cursor:"pointer"}} placement="top">
                                    <Chip id="chip" label={`+${device.tags.length - 2}`} variant="outlined" />
                                  </Tooltip>
                                  :
                                  null
                                }
                              </Box>
                            </Box>
                            <Box sx={{height:"30px"}}>
                              <Box sx={{display:"flex",flexDirection:"row",justifyContent:'space-between', margin:"0px 10px 0px 10px", color:"#fff"}}>
                                <Box sx={{marginTop:"7px"}}>
                                  <Typography sx={{fontSize:"12px"}}>
                                    {device.OS}
                                  </Typography>
                                </Box>
                                <Box sx={{marginTop:"7px"}}>
                                  <Typography sx={{fontSize:"12px"}}>
                                    {device.FW}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                         </div>
                        :
                         null
                        )
                      }}
                      rowCount={devices.length}
                      rowHeight={290}
                    />
                  )}
                  </AutoSizer>
              :
                null
            }
        </Box>
        <div>
            <Button
                onClick={()=> handleDeleteMode()}
                style={{
                  position: 'fixed',
                  bottom: '37px',
                  right: '25px',
                  height: '62px',
                  miWwidth: '55px',
                  maxWidth: '55px',
                  borderRadius: '50%',
                  backgroundColor: primaryThemeColor,
                  display: JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE") ? 'block' : 'none'
                }}
            >
              {
                  !deleteMode ?
                      <Delete sx={{color: '#fff',position:'relative', top: '3px'}} />
                  :
                      <Close sx={{color: '#fff',position: 'relative', top: '3px'}} />
              }
            </Button>
        </div>

        {
          showLocationDialog ?
            <ShowLocationDetails
              closeDialog = {() => setShowLocationDialog(false)}
              name={window.DeviceGraphName}
              markers={locationsData}
            />
          :
            null
        }
        {
          showGraphDialog ?
            <ShowGraphData
              closeDialog = {() => setShowGraphDialog(false)}
              selectedDevice={graphDialogData}
            />
          :
            null
        }
        {
          unRegisterDialog ?
           <UnRegisterDialog 
            openDialog={unRegisterDialog}
            closeDialog= {()=> {
              setUnregisterDialog(false);
            }}
            unRegistereDeviceData={unRegisterData}
           />
          :
            null
        }
        {
          infoDialog ?
            <InformationDialog
              openDialog={infoDialog}
              infoData={infoDialogData}
              close={() => {changeHotSpotProcessingStatus(); setInfoDialog(false) }}
              closeDialog={()=> setInfoDialog(false)}
            />
          :
            null
        }
        {
          showDownloadCsvDialog ?
            <DownloadCSVDialog
              dialog="downloadDevicesCSVDialog"
              data={[]}
              showDialog={showDownloadCsvDialog}
              closeProcess={() => setShowDownloadCsvDialog(false)}
              closeCsvDialog={(data)=> handleCloseCsvDialog(data)}
            />
          :
            null
        }
        {
          tShootDialog ?
              <TroubleShootDialog
                  openDialog={tShootDialog}
                  troubleShootData={tShootDialogData}
                  closeDialog={() => setTShootDialog(false)}
              />
              :
              null
        }
        {
          diagnosticInfoDialog ?
              <DiagnosticInfo
                openDialog={diagnosticInfoDialog}
                diagnosticInfoData={diagnosticDialogData}
                closeDialog={() => setDiagnosticInfoDialog(false)}
              />
          :
              null

        }
        {
          rebootDeviceDialog ?
              <RebootDevice
                openDialog={rebootDeviceDialog}
                rebootDeviceData={rebootData}
                closeDialog={() => setRebootDeviceDialog(false)}
                />
          :
              null
        }
        {
           deleteGatewayDialog ?
              <DeleteGatewaysDialog
                openDialog={deleteGatewayDialog}
                closeDialog={() =>closeDeleteGatewayDialog()}
                devices={selectedDevices}
              />
           :
              null
        }
        {
           airQualityDialog ?
              <AirQualityDataGraph
                closeDialog = {() => setAirQualityDialog(false)}
                selectedDeviceName={airQualityDialogData.NAME}
                selectedDeviceMac={airQualityDialogData.MAC}
              />
           :
              null
        }
        {
           co2Dialog ?
              <Co2DataGraph
                closeDialog = {() => setCo2Dialog(false)}
                selectedDeviceName={co2DialogData.NAME}
                selectedDeviceMac={co2DialogData.MAC}
              />
           :
              null
        }
      </>
  );
}
