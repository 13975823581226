import React from 'react';
import 'stylesheet/HomePage.css';
import { Box,Tab,Tabs,TextField,Button,Table,TableBody,Divider,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,MenuItem,Select,Avatar,Popper,Paper,Grow,ClickAwayListener,MenuList,Tooltip, CircularProgress } from '@mui/material';
import {visuallyHidden} from '@mui/utils';
import { FirstPage,KeyboardArrowLeft,KeyboardArrowRight,LastPage,Download,PersonAddRounded,Edit,Delete,MoreVert } from '@mui/icons-material';
import { TabContext,TabPanel } from '@mui/lab';
import paginationStyle from "stylesheet/pagination.module.css";
import AuthClient from "clients/AuthClient";
import _ from 'lodash';
import CreateNewCustomerDialog from "./CreateNewCustomerDialog";
import DeleteCustomer from "./DeleteCustomer";
import UserSessionStorage from 'userSession/UserSessionStorage';
import { useNavigate } from "react-router-dom";
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import CsvDownload from 'react-json-to-csv';
import DownloadCSVDialog from 'helper-components/DownloadCSVDialog';
import MqttEventHandler from "components/Mqtt/MqttEventHandler";
import PubSubChannels from "pubsub/PubSubChannels";
import { useSnackbar } from 'notistack';
import { useLocation } from "react-router-dom";




function TablePaginationActions(props) {
  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={paginationStyle.paginationActionIcons}>
      <FirstPage
        onClick={() => {
          if (page > 0) handleFirstPageButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowLeft
        onClick={() => {
          if (page > 0) handleBackButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowRight
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleNextButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
            marginRight: '8px'
        }}
      />
      <LastPage
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleLastPageButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
        }}
      />
    </div>
  );
}


function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    editMode,
    onRequestSort,
    dimensions,
  } = props;

  const [changeTextColor,setChangeTextColor] = React.useState('');
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    setChangeTextColor('');
  };

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
      width: '200px'
    },
    {
      id: 'customerId',
      numeric: false,
      disablePadding: false,
      label: 'Customer ID',
      width: '200px'
    },
    {
      id: 'onlineDevicesCount',
      numeric: false,
      disablePadding: false,
      label: 'Online / Total',
      width: '200px'
    },
    {
      id: 'firmwareVersion',
      numeric: false,
      disablePadding: false,
      label: 'Gateway Firmware',
      width: '200px'
    },
    {
      id: 'osVersion',
      numeric: false,
      disablePadding: false,
      label: 'Gateway OS',
      width: '200px'
    },
    {
      id: 'osPatch',
      numeric: false,
      disablePadding: false,
      label: 'Partial OS',
      width: '150px'
    },
    {
      id: 'edit',
      numeric: false,
      disablePadding: false,
      label: 'Edit',
      width: '100px'
    },
  ];

  const headCell = [
      {
          id: 'name',
          numeric: false,
          disablePadding: false,
          label: 'Name',
          width: '200px'
      }
  ];

  return (
      <TableHead>
        <TableRow>
        { dimensions.width > 1200 ?
           headCells.map((headCell) => (
             <TableCell key={headCell.id} align="left">
                {headCell.label !== 'Edit' ? (
                    <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                        sx={{
                          width: headCell.width,
                          maxWidth: headCell.width,
                          overflowWrap: 'break-word',
                          color: orderBy !== headCell.id ? '#0000008A !important' : '#000000DE !important',
                          fontSize: '16px'
                        }}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                          </Box>
                      ) : null}
                    </TableSortLabel>
                ) : (
                    <Box
                        sx={{
                          width: headCell.width,
                          maxWidth: headCell.width,
                          overflowWrap: 'break-word',
                          color: changeTextColor !== 'Edit' ? '#0000008A !important' : '#000000DE !important',
                          fontSize: '16px'
                        }}
                        onClick={()=>setChangeTextColor('history')}
                    >
                      {headCell.label}
                    </Box>
                )}
             </TableCell>
           ))
           :
              headCell.map((headCell) => (
                <TableCell key={headCell.id} align="left">
                   <TableSortLabel
                       active={orderBy === headCell.id}
                       direction={orderBy === headCell.id ? order : 'asc'}
                       onClick={createSortHandler(headCell.id)}
                       sx={{
                         width: headCell.width,
                         maxWidth: headCell.width,
                         overflowWrap: 'break-word',
                         color: orderBy !== headCell.id ? '#0000008A !important' : '#000000DE !important',
                         fontSize: '16px'
                       }}
                   >
                     {headCell.label}
                     {orderBy === headCell.id ? (
                         <Box component="span" sx={visuallyHidden}>
                           {order === 'desc'
                               ? 'sorted descending'
                               : 'sorted ascending'}
                         </Box>
                     ) : null}
                   </TableSortLabel>
                </TableCell>
              ))
           }
        </TableRow>
      </TableHead>
  );
}

const LandingPage = () => {
    const [tab, setTab] = React.useState('customers');
    const [selected, setSelected] = React.useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [selectValue, setSelectValue] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(sessionStorage.landingPageRPP ? Number(sessionStorage.landingPageRPP) : 10);
    const [logo, setLogo] = React.useState(false);
    const [createCustomerDialog, setCreateCustomerDialog] = React.useState(false);
    const [deleteCustomerDialog, setDeleteCustomerDialog] = React.useState(false);
    const [updateCustomerDialog, setUpdateCustomerDialog] = React.useState(false);
    const [updateDialogData, setUpdateDialogData] = React.useState(null);
    const [deleteData, setDeleteData] = React.useState('');
    const [customer, setCustomer] = React.useState([]);
    let anchorRef = React.useRef(null);
    const [showDownloadCsvDialog, setShowDownloadCsvDialog] = React.useState(false);
    const [downloadCsvData, setDownloadCsvData] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const handleTabChange = (event, newValue) => {

    };

    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
          width,
          height,
        };
    };
    const [dimensions, setDimensions] = React.useState(getWindowDimensions());

    React.useEffect(() => {
        let scopeId = checkDataExistsOrNot(JSON.parse(sessionStorage.userDetails)) ? JSON.parse(sessionStorage.userDetails).user.scopeId : "5947df4362a1280006cdd54b" ;
        subscribeMqttChannel(scopeId);
        function handleResize() {
          setDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        window.tabName = "customers";
        window.oldPreservedLandingPageNumber = 0;
        fetchData();
        return () => {
          window.removeEventListener('resize', handleResize);
          if(window.scopeEvent){
            window.scopeEvent.unsubscribe();
          }
        }
    }, []);

    const fetchData = () => {
        let customersData=[];
        let data1=[];
        AuthClient.getHirarchyOnlineDeviceCount("page").then((data) => {
                _.forEach(data.children,function(value){
                    if (checkDataExistsOrNot(value.scope.firmwareVersion) && value.scope.firmwareVersion !== 0) {
                       let firmware = value.scope.firmwareVersion.toString();
                       let firmwaree = "FW"+firmware.substring(0, 4)+"-"+firmware.substring(4, 6)+"-"+firmware.substring(6);
                       value.scope.firmwareVersion = firmwaree;
                    } else {
                         value.scope.firmwareVersion = "-";
                    }
                    if (!checkDataExistsOrNot(value.scope.osPatch)) {
                        value.scope.osPatch = "-";
                    }
                    customersData.push(value['scope']);
                });
                if (checkDataExistsOrNot(data.scope.firmwareVersion) && data.scope.firmwareVersion !== 0) {
                   let firmware = data.scope.firmwareVersion.toString();
                   let firmwaree = "FW"+firmware.substring(0, 4)+"-"+firmware.substring(4, 6)+"-"+firmware.substring(6);
                   data.scope.firmwareVersion = firmwaree;
                } else {
                     data.scope.firmwareVersion = "-";
                }
                if (!checkDataExistsOrNot(data.scope.osPatch)) {
                    data.scope.osPatch = "-";
                }
                setRows(customersData);
                window.allData = customersData;
                data1.push(data.scope);
                setCustomer(data1);
        });
    };

    const subscribeMqttChannel = (scopeId) => {
        window.scopeEvent = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.SCOPES.replace("scopeId", scopeId), "HomePage", handleScopeEvent);
    }

    const handleScopeEvent = () => {
        setTimeout (() => {
            enqueueSnackbar("Details update successfully");
        },1000)
        fetchData();
    }

    const handleClickIcon = (event,user,state) => {
        if(user.id==="5947df4362a1280006cdd54b")
            user.popper = state !== "close" ? user.popper ? !user.popper : true : false;
        let updatedCustomer = [];
        _.forEach(window.allData, (value) => {
            if (value.id === user.id){
                value.popper = state !== "close" ? user.popper ? !user.popper : true : false;
            }
            updatedCustomer.push(value);
        })
        setRows(updatedCustomer)
        anchorRef.current = event.currentTarget;
    }

    const handleCloseDeleteDialog = (value) =>{
      setDeleteCustomerDialog(value);
    }

    const handleCloseCustomerDialog = (value) =>{
        setCreateCustomerDialog(value);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageNumber(newPage);
        setSelectValue(newPage);
        window.oldPreservedLandingPageNumber = newPage;
    };

    function numberCharacterOrganizer(array, orderOfsort, orderByOfSort){
      let numbers = [], characters = [];
      _.forEach(array, (element) => {
          if(typeof(element[orderByOfSort]) === 'string' ? Number(element[orderByOfSort] ? element[orderByOfSort].replaceAll(" ","") : element[orderByOfSort]) : Number(element[orderByOfSort])){
            numbers.push(element);
          } else{
            characters.push(element);
          }
      })
      array = orderOfsort === 'asc' ? characters.concat(numbers): numbers.concat(characters);
      return array;
    }

    function stableSort(array, orderOfsort, orderByOfSort) {
        array = numberCharacterOrganizer(array, orderOfsort, orderByOfSort);
        array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? typeof(obj[orderByOfSort]) === 'string' ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","")) ? _.get(obj, orderByOfSort, '').toString().toLowerCase() : Number(_.get(obj, orderByOfSort).replaceAll(" ","")) : Number(_.get(obj, orderByOfSort,'')) : ""], [orderOfsort]);
        return array;
    }

    const handleChange = (event) => {
        setPage(event.target.value);
        setPageNumber(event.target.value);
        setSelectValue(event.target.value);
        window.oldPreservedLandingPageNumber = event.target.value;
    };

    const handleRowPerPage = (event) => {
        let rowPerPageValue = event.target.value;
        if (rows.length > 0) {
          let pageNumberCount = Math.ceil((rows.length/rowPerPageValue)) - 1;
          if (window.oldPreservedLandingPageNumber > pageNumberCount) {
            setPage(pageNumberCount);
            setPageNumber(pageNumberCount);
            setSelectValue(pageNumberCount);
            window.oldPreservedLandingPageNumber = pageNumberCount;
          }else {
            setPage(window.oldPreservedLandingPageNumber);
            setPageNumber(window.oldPreservedLandingPageNumber);
            setSelectValue(window.oldPreservedLandingPageNumber);
          }
        }
        sessionStorage.landingPageRPP = rowPerPageValue;
        setRowsPerPage(rowPerPageValue);
    };

    const rowPerPageLabel = (
      <div className={paginationStyle.rowPerPageLabelDiv}>
        <Box className={paginationStyle.page}>
          <span style={{marginRight: '32px'}}>Page</span>
        </Box>
        <Box className={paginationStyle.pageSelectBox}>
          <Select value={pageNumber} onChange={handleChange}>
            {
              Math.ceil(rows.length / rowsPerPage) > 0 ?
                  // eslint-disable-next-line array-callback-return
                  Array(Math.ceil(rows.length / rowsPerPage))
                      .fill()
                      .map((_, index) => {
                        return (
                            <MenuItem value={index} sx={{minWidth: '64px'}}>
                              {index + 1}
                            </MenuItem>
                        );
                      })
                  :
                  <MenuItem value={0} sx={{minWidth: '64px'}}>
                    1
                  </MenuItem>
            }
          </Select>
        </Box>
        <Box className={paginationStyle.rowPerPageLabelDiv}>
          <span style={{marginRight: '32px'}}>Rows Per Page</span>
        </Box>
        <Box className={paginationStyle.rowPerPageSelectBox}>
          <Select value={rowsPerPage} onChange={handleRowPerPage}>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
      </div>
    );

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const deleteCustomer = (tenant) => {
        if(tenant.status !== "DELETE_PENDING"){
            setDeleteData(tenant);
            setDeleteCustomerDialog(true);
        }
    }

    const handleClick = (data,type) => {
        UserSessionStorage.setTenantName(data.name);
        sessionStorage.tenantName = data.name;
        sessionStorage.tenantId = data.id;
        if (type === "customer"){

          if (checkDataExistsOrNot(data.logoUrl)){
            sessionStorage.logoUrl = data.logoUrl;
          }else {
            sessionStorage.logoUrl = "";
          }

          if (checkDataExistsOrNot(data.primaryThemeColor)){
            sessionStorage.tenantPrimaryThemeColor = data.primaryThemeColor.indexOf("#") !== -1 ? data.primaryThemeColor : "#"+data.primaryThemeColor;
          }else {
            sessionStorage.tenantPrimaryThemeColor = "#4190c7"
          }

          if (checkDataExistsOrNot(data.secondaryThemeColor)){
            sessionStorage.tenantSecondaryThemeColor = data.secondaryThemeColor.indexOf("#") !== -1 ? data.secondaryThemeColor : "#"+data.secondaryThemeColor;
          }else {
            sessionStorage.tenantSecondaryThemeColor = "#ffffff"
          }

        }else {
          sessionStorage.tenantPrimaryThemeColor = checkDataExistsOrNot(data.primaryThemeColor) ? data.primaryThemeColor.indexOf("#") !== -1 ? data.primaryThemeColor : "#"+data.primaryThemeColor : "#4190c7";
          sessionStorage.tenantSecondaryThemeColor = checkDataExistsOrNot(data.secondaryThemeColor) ? data.secondaryThemeColor.indexOf("#") !== -1 ? data.secondaryThemeColor : "#"+data.secondaryThemeColor : "#ffffff";
          delete sessionStorage.logoUrl;
        }
        location.hash = "#/cli/dashboard?scopeId="+data.id;
        window.location.hash = "#/cli/dashboard?scopeId="+data.id;
    }

    const getBackgroundColor = (tenantname) => {
        let name = tenantname + 's';
        var hash = 0;
        for (var i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
        var color = (hash & 0x00ffffff).toString(16).toUpperCase();
        return '00000'.substring(0, 6 - color.length) + color;
    };

    const checkElementExist = (data , searchValue) => {
      if (checkDataExistsOrNot(data)){
        return data.toLowerCase().indexOf(searchValue) !== -1;
      }else {
        return false;
      }
    }

    const checkSearchData = (searchValue) => {
      let filteredData = [];
      if (checkDataExistsOrNot(searchValue)){
        _.forEach(window.allData, (value) => {
            if (checkElementExist(value.name, searchValue) || checkElementExist(value.firmwareVersion, searchValue) || checkElementExist(value.customerId, searchValue) || checkElementExist(value.osVersion, searchValue) ||
                checkElementExist(value.osPatch, searchValue)){
              filteredData.push(value);
            }
        })
        setRows(filteredData);
      }else {
        setRows(window.allData);
      }
    }

    const handleOpenCsvDialog = () => {
      setShowDownloadCsvDialog(true);
    }

    const handleCloseCsvDialog = (data) => {
      setShowDownloadCsvDialog(false);
      setDownloadCsvData(data);
      document.getElementById("downloadCustomersCsv").click()
    }

    const checkForDeletePending = (user) => {
      return user.status === "DELETE_PENDING" ? true : false ;
    }

    return (
         <div>
            <div className="homePageContainer">
              <div className="homePagesubContainer">
                  <div className="adminSubBlock blockWidth">
                    <div style={{backgroundColor: '#4190c7',paddingBottom:'5px'}}>
                        <div className="subContainerBlockTitle">
                           <span style={{color: '#fff', fontSize: '20px', fontWeight: '300'}}>
                              Admin
                           </span>
                        </div>
                    </div>
                    <Box style={{width: '100%', height: 'auto'}}>
                       <TableContainer>
                          <Table sx={{whiteSpace:"nowrap"}}>
                             <TableBody>
                               {customer.map((data)=>{
                                 return(
                                   dimensions.width > 1200 ?
                                       <TableRow onClick={()=>{handleClick(data,"admin")}} sx={{cursor:'pointer'}}>
                                         <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize:'16px'}}>
                                            <div style={{display:'flex',flexDirection:'row',position:'relative',top:'-5px'}}>
                                                <div style={{width:"25px",height:"25px",overflow:"hidden",position:"relative",top:"9px"}}>
                                                    <img src="./favicon_caribou.ico" height={26} />
                                                </div>
                                                <div style={{flex:'80%',paddingTop:'15px',marginLeft:"10px",whiteSpace:"break-spaces",lineBreak:'anywhere'}}>
                                                    {data.name}
                                                </div>
                                            </div>
                                         </TableCell>
                                         <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',paddingTop:"21px",lineHeight:'0.99',fontSize: '14px'}}>{data.customerId}</TableCell>
                                         <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',paddingTop:"21px",lineHeight:'0.99',fontSize: '14px'}}>{data.onlineDevicesCount+" / "+data.totalDevicesCount}</TableCell>
                                         <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',paddingTop:"21px",lineHeight:'0.99',fontSize: '14px'}}>{data.firmwareVersion}</TableCell>
                                         <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',paddingTop:"21px",lineHeight:'0.99',fontSize: '14px'}}>{data.osVersion}</TableCell>
                                         <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',paddingTop:"21px",lineHeight:'0.99',fontSize: '14px'}}>{data.osPatch}</TableCell>
                                         <TableCell align="left" onClick={(e) => e.stopPropagation()} sx={{ position:"relative"}}>
                                             <Edit
                                                onClick={()=>{
                                                  setUpdateDialogData(data);
                                                  setUpdateCustomerDialog(true)
                                                }}
                                                sx={{cursor:"pointer",color:'#0000008A',marginRight:'10px',fontSize:"20px"}}
                                             />
                                         </TableCell>
                                       </TableRow>
                                   :
                                       <TableRow
                                         hover
                                         sx={{cursor:'pointer', color: "#808080",display: "flex",flex: "100%"}}
                                       >
                                           <TableCell align="left" onClick={()=>{handleClick(data,"admin")}} sx={{alignItems:'center',cursor:'pointer',flex:"90%",justifyContent:'center',lineHeight:'0.99',fontSize:'16px'}}>
                                              <div style={{display:'flex',flexDirection:'row',position:'relative',top:'-5px'}}>
                                                  <div style={{width:"25px",height:"25px",overflow:"hidden",position:"relative",top:"9px"}}>
                                                      <img src="./favicon_caribou.ico" height={26} />
                                                  </div>
                                                  <div style={{flex:'80%',paddingTop:'15px',marginLeft:"10px",whiteSpace:"break-spaces",lineBreak:'anywhere'}}>
                                                      {data.name}
                                                  </div>
                                              </div>
                                           </TableCell>
                                           <div style={{ flex:"10%",marginTop: "10px",marginLeft: "20px"}}>
                                                 <MoreVert
                                                       ref={anchorRef}
                                                       className="cardIcons"
                                                       onClick={(event) => {handleClickIcon(event, data, "open")}}
                                                 />
                                                 <Popper
                                                       open={data.popper}
                                                       anchorEl={anchorRef.current}
                                                       placement="bottom-start"
                                                       transition
                                                 >
                                                     {({TransitionProps, placement}) => (
                                                         <Grow
                                                             {...TransitionProps}
                                                             style={{
                                                               transformOrigin:
                                                                   placement === 'bottom-start'
                                                                       ? 'left top'
                                                                       : 'left bottom',
                                                             }}
                                                         >
                                                           <Paper>
                                                             <ClickAwayListener onClickAway={(event)=>{handleClickIcon(event,data, "close")}}>
                                                                 <MenuList>
                                                                    <MenuItem onClick={()=>{setUpdateDialogData(data);setUpdateCustomerDialog(true)}} className="menuItems">
                                                                      <Edit sx={{cursor:"pointer",color:'#0000008A',marginRight:'20px',fontSize:"20px"}}/>
                                                                      <span>Edit</span>
                                                                    </MenuItem>
                                                                 </MenuList>
                                                             </ClickAwayListener>
                                                             <Divider/>
                                                             <div style={{display: "flex",flexDirection:"column",padding:"15px 15px"}}>
                                                                  <div style={{display: "flex",flexDirection:"row",padding:"8px"}}>
                                                                     <div>Customer ID :</div>
                                                                     <div style={{fontSize:"16px",fontWeight:"bold",paddingLeft:"10px"}}>{data.customerId}</div>
                                                                  </div>
                                                                  <div style={{display: "flex",flexDirection:"row",padding:"8px"}}>
                                                                     <div>Version :</div>
                                                                     <div style={{fontSize:"16px",fontWeight:"bold",paddingLeft:"10px"}}>{data.firmwareVersion}</div>
                                                                  </div>
                                                                  <div style={{display: "flex",flexDirection:"row",padding:"8px"}}>
                                                                     <div>Gateway OS :</div>
                                                                     <div style={{fontSize:"16px",fontWeight:"bold",paddingLeft:"10px"}}>{data.osVersion}</div>
                                                                  </div>
                                                             </div>
                                                           </Paper>
                                                         </Grow>
                                                     )}
                                                 </Popper>
                                           </div>
                                       </TableRow>
                                );
                               })}
                             </TableBody>
                          </Table>
                       </TableContainer>
                    </Box>
                  </div>
              </div>
              <div className="homePagesubContainer">
                <div className="adminSubBlock blockWidth">
                   <div style={{backgroundColor: '#4190c7',paddingBottom:'1px'}}>
                      <div className="containerBlockTitle">
                          <div className="innerContainer">
                             <span style={{color: '#fff', fontSize: '20px', fontWeight: '300',marginTop:'6px'}}>
                                Customers
                             </span>
                          </div>
                          <div className="searchBlockContainer">
                            <TextField
                                id="searchTextField"
                                onChange={(event) => checkSearchData(event.target.value)}
                                placeholder="Search"
                                variant="standard"
                                type="search"
                                autoFocus
                                sx={{
                                  background: '#fff',
                                  textIndent: '4px',
                                  margin: '2px 15px 2px 2px',
                                  minWidth: dimensions.width > 600 ? '200px':'0px',
                                  width: '100%',
                                  '& .MuiInput-input': {textIndent: '6px', marginRight: '4px'},
                                }}
                            />
                            <Tooltip title="Download CSV" placement="top" arrow>
                              <Download style={{color:'white',marginRight: '15px',marginTop:'7px',cursor: 'pointer'}} onClick={() => handleOpenCsvDialog()} />
                            </Tooltip>
                            <CsvDownload
                                data={downloadCsvData}
                                filename={'Customers.csv'}
                                style={{
                                  border: 'transparent',
                                  background: 'transparent',
                                  paddingLeft: '0px',
                                  visibility: 'hidden',
                                  position:"absolute",
                                  top:"-50%"
                                }}
                                id="downloadCustomersCsv"
                            >
                              <Tooltip title="Download CSV" placement="top" arrow>
                                <Download style={{marginRight: '14px', cursor: 'pointer',color: '#0000008A'}} />
                              </Tooltip>
                            </CsvDownload>
                          </div>
                      </div>
                   </div>
                   <div>
                       <TabContext value={tab}>
                           <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                             <Tabs
                               value={tab}
                               onChange={handleTabChange}
                               variant="scrollable"
                               scrollButtons="auto"
                               allowScrollButtonsMobile
                             >
                               <Tab
                                 label="Customers"
                                 value="customers"
                               />
                             </Tabs>
                           </Box>
                           <TabPanel value='customers'>
                               <Box style={{width: '100%', height: 'auto'}}>
                                   <TableContainer>
                                      <Table sx={{tableLayout:"fixed",whiteSpace:"nowrap"}}>
                                         <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                            tab={tab}
                                            dimensions={dimensions}
                                         />
                                         <TableBody>
                                           {stableSort(rows, order,orderBy)
                                             .slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage)
                                             .map((user,index)=>{
                                             return(
                                              dimensions.width > 1200 ?
                                               <TableRow
                                                 hover
                                                 onClick={()=>{handleClick(user, "customer")}}
                                                 sx={{cursor:'pointer', color: "#808080"}}
                                               >
                                                 <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',lineHeight:'0.99',fontSize: '16px'}}>
                                                    {
                                                      checkDataExistsOrNot(user.favIconUrl) ?
                                                        <div style={{display:'flex',flexDirection:'row'}}>
                                                          <div style={{width:"32px",height:"30px",overflow:"hidden",position:"relative"}}>
                                                            <img alt="" src={user.favIconUrl} height={30} style={{width:"30px", borderRadius:"50%", objectFit:"cover", objectPosition:"center"}}/>
                                                          </div>
                                                          <div style={{flex:'80%',paddingTop:'9px',marginLeft:"10px",whiteSpace:"break-spaces",lineBreak:'anywhere'}}>
                                                            {user.name}
                                                          </div>
                                                        </div>
                                                      :
                                                        <div style={{display:'flex',flexDirection:'row'}}>
                                                          <div style={{width:"32px",height:"30px",overflow:"hidden",position:"relative"}}>
                                                            <Avatar style={{backgroundColor: '#' + getBackgroundColor(user.name),height:'100%',width:'100%',objectFit:'contain'}}>
                                                              {user.name.toUpperCase().replaceAll(' ', '')[0]}
                                                            </Avatar>
                                                          </div>
                                                          <div style={{flex:'80%',paddingTop:'9px',marginLeft:"10px",whiteSpace:"break-spaces",lineBreak:'anywhere'}}>
                                                            {user.name}
                                                          </div>
                                                        </div>
                                                    }
                                                 </TableCell>
                                                 <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',paddingTop:"21px",lineHeight:'0.99',fontSize: '14px'}}>{user.customerId}</TableCell>
                                                 <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',paddingTop:"21px",lineHeight:'0.99',fontSize: '14px'}}>{user.onlineDevicesCount+" / "+user.totalDevicesCount}</TableCell>
                                                 <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',paddingTop:"21px",lineHeight:'0.99',fontSize: '14px'}}>{user.firmwareVersion}</TableCell>
                                                 <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',paddingTop:"21px",lineHeight:'0.99',fontSize: '14px'}}>{user.osVersion}</TableCell>
                                                 <TableCell align="left" sx={{alignItems:'center',justifyContent:'center',paddingTop:"21px",lineHeight:'0.99',fontSize: '14px'}}>{user.osPatch}</TableCell>
                                                 <TableCell align="left" onClick={(e) => e.stopPropagation()} sx={{ position:"relative"}}>
                                                     <Edit
                                                        onClick={()=>{
                                                          setUpdateDialogData(user);
                                                          setUpdateCustomerDialog(true)
                                                        }}
                                                        sx={{cursor:"pointer",color:'#0000008A',marginRight:'10px',fontSize:"20px"}}
                                                     />
                                                     {
                                                        checkForDeletePending(user) ?
                                                            <>
                                                                <Tooltip title="customer is being deleted" placement="top" arrow>
                                                                <Delete onClick={()=>{ deleteCustomer(user) }} sx={{cursor:"pointer",color:"#0000003A" ,fontSize:"20px"}} />
                                                                </Tooltip>
                                                                <CircularProgress thickness="6.4" size="8px" sx={{ position: "absolute"}}> </CircularProgress>
                                                            </>
                                                        :
                                                            <Delete onClick={()=>{ deleteCustomer(user) }} sx={{cursor:"pointer",color:'#0000008A' ,fontSize:"20px"}} />
                                                     }
                                                 </TableCell>
                                               </TableRow>
                                               :
                                                  <TableRow
                                                       hover
                                                       sx={{cursor:'pointer', color: "#808080",display: "flex",flex: "100%"}}
                                                     >
                                                       <TableCell onClick={()=>{handleClick(user, "customer")}} align="left" sx={{alignItems:'center',flex: "90%", justifyContent:'center',lineHeight:'0.99',fontSize: '16px'}}>
                                                          {
                                                            checkDataExistsOrNot(user.favIconUrl) ?
                                                              <div style={{display:'flex',flexDirection:'row'}}>
                                                                <div style={{width:"32px",height:"30px",overflow:"hidden",position:"relative"}}>
                                                                  <img alt="" src={user.favIconUrl} height={30} />
                                                                </div>
                                                                <div style={{flex:'80%',paddingTop:'9px',marginLeft:"15px",whiteSpace:"break-spaces",lineBreak:'anywhere'}}>
                                                                  {user.name}
                                                                </div>
                                                              </div>
                                                            :
                                                              <div style={{display:'flex',flexDirection:'row'}}>
                                                                <div style={{width:"32px",height:"30px",overflow:"hidden",borderRadius:'50%',position:"relative"}}>
                                                                  <Avatar style={{backgroundColor: '#' + getBackgroundColor(user.name),height:'100%',width:'100%',objectFit:'contain'}}>
                                                                    {user.name.toUpperCase().replaceAll(' ', '')[0]}
                                                                  </Avatar>
                                                                </div>
                                                                {
                                                                  dimensions.width > 350 ?
                                                                    <div style={{flex:'80%',paddingTop:'9px',marginLeft:"15px"}}>
                                                                      {user.name}
                                                                    </div>
                                                                  :
                                                                    <div style={{flex:'80%',paddingTop:'9px',marginLeft:"15px",whiteSpace:"break-spaces",lineBreak:'anywhere'}}>
                                                                      {user.name}
                                                                    </div>
                                                                }
                                                              </div>
                                                          }
                                                       </TableCell>
                                                       <div style={{ flex:"10%",marginTop: "10px",marginLeft: "20px"}}>
                                                             <MoreVert
                                                                   ref={anchorRef}
                                                                   className="cardIcons"
                                                                   onClick={(event) => {handleClickIcon(event, user, "open")}}
                                                             />
                                                             <Popper
                                                                   open={user.popper}
                                                                   anchorEl={anchorRef.current}
                                                                   placement="bottom-start"
                                                                   transition
                                                             >
                                                                 {({TransitionProps, placement}) => (
                                                                     <Grow
                                                                         {...TransitionProps}
                                                                         style={{
                                                                           transformOrigin:
                                                                               placement === 'bottom-start'
                                                                                   ? 'left top'
                                                                                   : 'left bottom',
                                                                         }}
                                                                     >
                                                                       <Paper>
                                                                         <ClickAwayListener onClickAway={(event)=>{handleClickIcon(event,user, "close")}}>
                                                                             <MenuList>
                                                                                <MenuItem onClick={()=>{setUpdateDialogData(user);setUpdateCustomerDialog(true)}} className="menuItems">
                                                                                  <Edit sx={{cursor:"pointer",color:'#0000008A',marginRight:'20px',fontSize:"20px"}}/>
                                                                                  <span>Edit</span>
                                                                                </MenuItem>
                                                                                <MenuItem  onClick={()=>{deleteCustomer(user)}} className="menuItems" style={{position:"relative"}}>
                                                                                {
                                                                                    checkForDeletePending(user) ?
                                                                                        <>
                                                                                            <Tooltip title="customer is being deleted" placement="top" arrow>
                                                                                            <Delete sx={{cursor:"pointer",color: "#0000003A", fontSize:"20px", marginRight:"20px"}}/>
                                                                                            </Tooltip>
                                                                                            <CircularProgress thickness="6.4" size="8px" sx={{ position: "absolute", marginLeft:'19px', marginTop:"-14px"}}> </CircularProgress>
                                                                                        </>
                                                                                    :
                                                                                         <Delete sx={{cursor:"pointer",color:'#0000008A',fontSize:"20px", marginRight:"20px"}}/>
                                                                                }
                                                                                  <span>Delete</span>
                                                                                </MenuItem>
                                                                             </MenuList>
                                                                         </ClickAwayListener>
                                                                             <Divider/>
                                                                             <div style={{display: "flex",flexDirection:"column",padding:"15px 15px"}}>
                                                                                  <div style={{display: "flex",flexDirection:"row",padding:"8px"}}>
                                                                                     <div>Customer ID :</div>
                                                                                     <div style={{fontSize:"16px",fontWeight:"bold",paddingLeft:"10px"}}>{user.customerId}</div>
                                                                                  </div>
                                                                                  <div style={{display: "flex",flexDirection:"row",padding:"8px"}}>
                                                                                     <div>Version :</div>
                                                                                     <div style={{fontSize:"16px",fontWeight:"bold",paddingLeft:"10px"}}>{user.firmwareVersion}</div>
                                                                                  </div>
                                                                                  <div style={{display: "flex",flexDirection:"row",padding:"8px"}}>
                                                                                     <div>Gateway OS :</div>
                                                                                     <div style={{fontSize:"16px",fontWeight:"bold",paddingLeft:"10px"}}>{user.osVersion}</div>
                                                                                  </div>
                                                                             </div>
                                                                       </Paper>
                                                                     </Grow>
                                                                 )}
                                                             </Popper>
                                                       </div>
                                                  </TableRow>
                                             );
                                           })}
                                         </TableBody>
                                      </Table>
                                   </TableContainer>
                                   <TablePagination
                                     sx={{width: '100%', overflowX: 'hidden'}}
                                     labelRowsPerPage={rowPerPageLabel}
                                     component="div"
                                     count={rows.length}
                                     rowsPerPage={rowsPerPage}
                                     className="pagination"
                                     page={pageNumber}
                                     SelectProps={{
                                       inputProps: {'aria-label': 'rows per page'},
                                       native: true,
                                     }}
                                     onPageChange={handleChangePage}
                                     onRowsPerPageChange={handleChangeRowsPerPage}
                                     ActionsComponent={TablePaginationActions}
                                   />
                               </Box>
                           </TabPanel>
                       </TabContext>
                   </div>
                </div>
              </div>
            </div>
            <div>
                <Button
                    onClick={()=> {
                        setCreateCustomerDialog(true);
                    }}
                    style={{
                      position: 'fixed',
                      bottom: '37px',
                      right: '25px',
                      height: '62px',
                      miWwidth: '55px',
                      maxWidth: '55px',
                      borderRadius: '50%',
                      backgroundColor: '#4190c7',
                    }}
                >
                  <PersonAddRounded style={{color: 'white'}} />
                </Button>
            </div>


            {createCustomerDialog ? (
              <>
                <CreateNewCustomerDialog
                  show_create_customer_dialog={createCustomerDialog}
                  closeDialog={(value)=>{handleCloseCustomerDialog(value)}}
                  dialog = "createCustomerDialog"
                />
              </>
            ) : null}

            {updateCustomerDialog ? (
              <>
                <CreateNewCustomerDialog
                  show_update_dialog={updateCustomerDialog}
                  closeDialog={()=>{setUpdateCustomerDialog(false)}}
                  data={updateDialogData}
                  dialog = "updateCustomerDialog"
                />
              </>
            ) : null}

            {deleteCustomerDialog ? (
              <>
                <DeleteCustomer
                  show_delete_dialog={deleteCustomerDialog}
                  closeDialog={(value)=>{handleCloseDeleteDialog(value)}}
                  deleteData={deleteData}
                  dialog = "deleteCustomerDialog"
                />
              </>
            ) : null}

            {showDownloadCsvDialog ? (
              <>
                <DownloadCSVDialog
                  dialog="downloadCustomersCSVDialog"
                  data={[]}
                  showDialog={showDownloadCsvDialog}
                  closeProcess={() => setShowDownloadCsvDialog(false)}
                  closeCsvDialog={(data)=> handleCloseCsvDialog(data)}
                />
              </>
            ) : null}
         </div>
    );
};

export default LandingPage;