import React from 'react';
import { Box,Button,Tooltip,Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,Select,MenuItem,Switch } from '@mui/material';
import { FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,Close,Search,Refresh,PersonAdd,Edit,Delete } from '@mui/icons-material';
import {visuallyHidden} from '@mui/utils';
import 'stylesheet/DashBoardUsersPage.css';
import paginationStyle from "stylesheet/pagination.module.css";
import "stylesheet/common.css";
import DraggableComponent from '../DraggableComponent';
import AuthClient from 'clients/AuthClient';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import PubSubChannels from 'pubsub/PubSubChannels';
import CreatePortalUserDialog from './CreatePortalUserDialog';
import DeletePortalUserDialog from './DeletePortalUserDialog';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import TwoFactorAuthentication from 'components/accountMenu/TwoFactorAuthentication';
import DisableTwoFactorAuthentication from 'components/accountMenu/DisableTwoFactorAuthentication';

function TablePaginationActions(props) {
    const {count, page, rowsPerPage, onPageChange} = props;
    function handleFirstPageButtonClick(event) {
      onPageChange(event, 0);
    }
    function handleBackButtonClick(event) {
      onPageChange(event, page - 1);
    }
    function handleNextButtonClick(event) {
      onPageChange(event, page + 1);
    }
    function handleLastPageButtonClick(event) {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }
    return(
      <div className={paginationStyle.paginationActionIcons}>
        <FirstPage
          onClick={() => {
            if (page > 0) handleFirstPageButtonClick();
          }}
          sx={{
            color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
            cursor: page === 0 ? 'auto' : 'pointer',
            marginRight: '8px'
          }}
        />
        <KeyboardArrowLeft
          onClick={() => {
            if (page > 0) handleBackButtonClick();
          }}
          sx={{
            color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
            cursor: page === 0 ? 'auto' : 'pointer',
            marginRight: '8px'
          }}
        />
        <KeyboardArrowRight
          onClick={() => {
            if (page !== Math.ceil(count / rowsPerPage) - 1)
              handleNextButtonClick();
          }}
          sx={{
            color:
              page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
                ? 'black'
                : 'rgba(0,0,0,0.26)',
            cursor:
              page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
              marginRight: '8px'
          }}
        />
        <LastPage
          onClick={() => {
            if (page !== Math.ceil(count / rowsPerPage) - 1)
              handleLastPageButtonClick();
          }}
          sx={{
            color:
              page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
                ? 'black'
                : 'rgba(0,0,0,0.26)',
            cursor:
              page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
          }}
        />
      </div>
    );
}
function EnhancedTableHead(props) {
    const {onSelectAllClick, order, orderBy, onRequestSort} = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    const headCells = [
      {
        id: 'mergedName',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        width: '200px',
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
        width: '200px',
      },
      {
        id: 'Secure',
        numeric: false,
        disablePadding: false,
        label: 'Two FA',
        width: '80px',
      },
    ];
    const [clickCount, setClickCount] = React.useState(1);
    const selectAll = (event) => {
      setClickCount(clickCount + 1);
      onSelectAllClick(clickCount);
    };
    const [changeTextColor,setChangeTextColor] = React.useState('');
    return(
       <TableHead>
         <TableRow>
            {headCells.map((headCell) => (
               <TableCell key={headCell.id} align="left">
                 <TableSortLabel
                   active={orderBy === headCell.id}
                   direction={orderBy === headCell.id ? order : 'asc'}
                   onClick={createSortHandler(headCell.id)}
                   sx={{
                     width: headCell.width,
                     overflowWrap: 'break-word',
                     color: orderBy !== headCell.id ? '#0000008A !important' : '#000000DE !important',
                   }}
                 >
                   {headCell.label}
                   {orderBy === headCell.id ? (
                     <Box component="span" sx={visuallyHidden}>
                       {order === 'desc'
                         ? 'sorted descending'
                         : 'sorted ascending'}
                     </Box>
                   ) : null}
                 </TableSortLabel>
               </TableCell>
            ))}
         </TableRow>
       </TableHead>
    );
}
const PortalUsers = () => {
    const [SearchBar, setSearchBar] = React.useState(true);
    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState([]);
    const [createPermissionDialog, setCreatePermissionDialog] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('mergedName');
    const [checkAll, setCheckAll] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(sessionStorage.dashboardUsersRPP ? Number(sessionStorage.dashboardUsersRPP) : 10);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [editMode, setEdiitMode] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [viewPermissionDialog, setViewPermissionDialog] = React.useState(false);
    const [editPermissionDialog, setEditPermissionDialog] = React.useState(false);
    const [permissionsData, setPermissionsData] = React.useState([]);
    const [deletePermissionDialog, setDeletePermissionDialog] = React.useState(false);
    const [rootScopeId, setRootScopeId] = React.useState("");
    const [rootTenantId, setRootTenantId] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();
    const[themePrimaryColor, setThemePrimaryColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
    const[themeSecondaryColor, setThemeSecondaryColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
    const [refresh,setRefresh] = React.useState(true);
    const [showEnablingDialog, setShowEnablingDialog] = React.useState(false);
    const [showDisableDialog, setShowDisableDialog] = React.useState(false);
    const [toggleState, setToggleState] = React.useState(false);
    const [userDetails, setUserDetails] = React.useState({});


    const getPortalUsers = () => {
      let isRefreshingWithFilter = searchText.length > 0;
      AuthClient.getPortalUsers(sessionStorage.tenantId, "usersPage").then((portalUserData) => {
        _.forEach(portalUserData,(element)=>{
          element.mergedName = element.firstName ? (element.lastName ? element.firstName+ " " +element.lastName : element.firstName) : (element.lastName ? element.lastName : "No Name");
          element.email = element.email ? element.email : 'No-email';
        })
        window.portalUserData = portalUserData;
        if(!isRefreshingWithFilter){
          setRows(portalUserData);
          resetPaginationData([]);
        }else {
           setTimeout(function(){
               handleTextCange(searchText);
           }, 2000);
        }
         AuthClient.getRootTenantId().then((response) => {
           let rootScopeId = checkDataExistsOrNot(response.rootScopeId) ? response.rootScopeId : "";
           let rootTenantId = checkDataExistsOrNot(response.rootTenantId) ? response.rootTenantId : "";
           setRootScopeId(rootScopeId);
           setRootTenantId(rootTenantId);
         })
      })
    }
    const handleEvent = (data) => {
       let usersAllData = [];
       let message = data.message ? data.message : "";
       let payload = data.payload ? data.payload : "";
       let updateDataInUI = false;
       if (message === "updateUser" || message === "resetUser") {
         _.forEach(window.portalUserData, (val) => {
           if (val.id === payload.id) {
             val.firstName = payload.firstName;
             val.lastName = payload.lastName;
             val.owner = payload.owner;
             val.permissions = payload.permissions;
             val.status = payload.status;
             val.mergedName = payload.firstName ? (payload.lastName ? payload.firstName+ " " +payload.lastName : payload.firstName) : (payload.lastName ? payload.lastName : "No Name");
             val.email = payload.email ? payload.email : 'No-email';
           }
           usersAllData.push(val);
         })
         updateDataInUI = true;
       }else if (message === "deleteUser") {
         _.forEach(window.portalUserData, (val) => {
           if (val.id !== payload.id) {
             usersAllData.push(val);
           }
         })
         updateDataInUI = true;
       }else if (message === "newUser") {
         payload.mergedName = payload.firstName ? (payload.lastName ? payload.firstName+ " " +payload.lastName : payload.firstName) : (payload.lastName ? payload.lastName : "No Name");
         payload.email = payload.email ? payload.email : 'No-email';
         _.forEach(window.portalUserData, (val) => {
           usersAllData.push(val);
         });
         usersAllData.push(payload);
         updateDataInUI = true;
       }else if(checkDataExistsOrNot(data)){
           _.forEach(window.portalUserData, (val) => {
               if (val.id === data.id) {
                   val.firstName = data.firstName;
                   val.lastName = data.lastName;
                   val.owner = data.owner;
                   val.permissions = data.permissions;
                   val.status = data.status;
                   val.mergedName = data.firstName ? (data.lastName ? data.firstName+ " " +data.lastName : data.firstName) : (data.lastName ? data.lastName : "No Name");
                   val.email = data.email ? data.email : 'No-email';
                   val.phoneNumber = data.phoneNumber;
                   val.countryShortCode = data.countryShortCode;
                   val.secureAuthentication = data.secureAuthentication;
               }
               usersAllData.push(val);
           })
           updateDataInUI = true;
       }
       if (updateDataInUI){
         window.portalUserData = usersAllData;
         let text = document.getElementById("searchText") ? document.getElementById("searchText").value : "";
         handleTextCange(text);
       }
    }

    const toggleTwoFaStatus = (data) => {
       enqueueSnackbar("Refreshing Portal Users ...");
       data = data ? JSON.parse(data.toString()) : "" ;
       if (data.hasOwnProperty("message")){
          handleEvent(data);
       }
    }

    const enableOrDisable2FA = (rowDetails) => {
        if (rowDetails.secureAuthentication === true) {
            setShowDisableDialog(true);
            setUserDetails(rowDetails);
            rowDetails.secureAuthentication = false;
        } else {
            setShowEnablingDialog(true);
            setUserDetails(rowDetails);
            rowDetails.secureAuthentication = true;
        }
    }

     const grayOut2FaButton = (data) => {
       if (sessionStorage.isRootUser === "true" && sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE")) {
         if (JSON.parse(sessionStorage.userDetails).user.email === data.email) {
              return false;
         } else {
            if (data.secureAuthentication === true) {
                 return false;
            } else {
                 return true;
            }
         }
       } else if (JSON.parse(sessionStorage.userDetails).user.owner) {
          if (JSON.parse(sessionStorage.userDetails).user.email === data.email) {
                 return false;
          } else {
             if (data.secureAuthentication === true) {
                 return false;
             } else {
                 return true;
             }
          }
       } else {
          if (JSON.parse(sessionStorage.userDetails).user.email === data.email) {
              return false;
          } else {
              return true;
          }
       }
     }

     const onPortalUsersEvent = (data) => {
        data = data.toString();
        enqueueSnackbar("Refreshing Portal Users ...");
        if(data.indexOf("deleted") !== -1 || data.indexOf("Created") !== -1 ){
             getPortalUsers();
        }else if(data.indexOf("{") !== -1){
            data = data ? JSON.parse(data) : "" ;
            handleEvent(data);
        }else{
            getPortalUsers();
        }
    }
    const subscribeMqttChannels = () => {
      window.portalUsersMqttEventHandler = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.PORTAL_USERS.replace("scopeId",sessionStorage.tenantId), "PortalUSersController", onPortalUsersEvent)
      window.TwoFAStatusChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.TWO_FACTOR_AUTHENTICATION_CHANNEL.replace("scopeId",sessionStorage.tenantId), "Appheader",toggleTwoFaStatus);
    }
    React.useEffect(() => {
      window.oldPreservedtenantUsersPageNumber = 0;
      getPortalUsers();
      subscribeMqttChannels()
      enqueueSnackbar("Fetching Portal Users ...");
      setThemePrimaryColor(sessionStorage.tenantPrimaryThemeColor);
      setThemeSecondaryColor(sessionStorage.tenantSecondaryThemeColor);
      return () => {
        if (window.portalUsersMqttEventHandler){
          window.portalUsersMqttEventHandler.unsubscribe();
        }
        AuthClient.abortSignal("usersPage");
      }
    }, []);
    const refreshData = () => {
      setRefresh(false);
          setTimeout(() => {
            setRefresh(true);
      }, 1050);
      enqueueSnackbar("Refreshing Portal Users ...")
      getPortalUsers();
    }
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handleClick = (event, row) => {
      let newSelected = [];
      if (event.target.checked) {
        if (selected.length > 0) {
          selected.forEach((element) => {
            newSelected.push(element);
          });
          if (JSON.stringify(newSelected).indexOf(row.id) === -1) {
            newSelected.push(row);
          }
        } else {
          newSelected.push(row);
        }
      } else {
        selected.forEach((element) => {
          if (element.id !== row.id) {
            newSelected.push(element);
          }
        });
      }
      setSelected(newSelected);
    }
    const handleSelectAllClick = (checkboxClickCount) => {
      if (checkboxClickCount % 2 !== 0) {
        setCheckAll(true);
        // eslint-disable-next-line array-callback-return
        const newSelecteds = rows.filter((n) => {
          if (
            n.Status !== 'INACTIVE' &&
            typeof n.id !== 'undefined' &&
            n.id !== null
          ) {
            return n;
          }
        });
        setSelected([]);
        setSelected(newSelecteds);
        return;
      }
      setCheckAll(false);
      setSelected([]);
    }
    function numberCharacterOrganizer(array, orderOfsort, orderByOfSort){
      let numbers = [], characters = [];
      _.forEach(array, (element) => {
          if(typeof(element[orderByOfSort]) === 'string' ? Number(element[orderByOfSort] ? element[orderByOfSort].replaceAll(" ","") : element[orderByOfSort]) : Number(element[orderByOfSort])){
            numbers.push(element);
          }
          else{
            orderByOfSort === 'email' && element[orderByOfSort]!== null && Number(element[orderByOfSort].replaceAll(" ","").substring(0,element[orderByOfSort].indexOf('@'))) ? numbers.push(element) : characters.push(element);
          }
      })
      array = orderOfsort === 'asc' ? characters.concat(numbers): numbers.concat(characters);
      return array;
    }
    function stableSort(array, orderOfsort, orderByOfSort) {
       array = numberCharacterOrganizer(array, orderOfsort, orderByOfSort);
       if(orderByOfSort !== 'email'){
           array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? typeof(obj[orderByOfSort]) === 'string' ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","")) ? _.get(obj, orderByOfSort, '').toString().toLowerCase() : Number(_.get(obj, orderByOfSort).replaceAll(" ","")) : Number(_.get(obj, orderByOfSort,'')) : ""], [orderOfsort]);
       }else{
           array = _.orderBy(array, [obj => obj[orderByOfSort] !== null ? typeof(obj[orderByOfSort]) === 'string' ? !Number(_.get(obj, orderByOfSort, '').replaceAll(" ","").substring(0,obj[orderByOfSort].indexOf('@'))) ? _.get(obj, orderByOfSort, '').toString().toLowerCase() : Number(_.get(obj, orderByOfSort).replaceAll(" ","").substring(0,obj[orderByOfSort].indexOf('@'))) : Number(_.get(obj, orderByOfSort,'')) : ""], [orderOfsort]);
       }
       return array;
    }
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      setPageNumber(newPage);
      window.oldPreservedtenantUsersPageNumber = newPage;
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 25));
      setPage(0);
    };
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
    const handleChange = (event) => {
      setPageNumber(event.target.value);
      setPage(event.target.value);
      window.oldPreservedtenantUsersPageNumber = event.target.value;
    };
    const handleRowPerPage = (event) => {
      let rowPerPageValue = event.target.value;
      if (rows.length > 0) {
        let pageNumberCount = Math.ceil((rows.length/rowPerPageValue)) - 1;
        if (window.oldPreservedtenantUsersPageNumber > pageNumberCount) {
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedtenantUsersPageNumber = pageNumberCount;
        }else {
          setPage(window.oldPreservedtenantUsersPageNumber);
          setPageNumber(window.oldPreservedtenantUsersPageNumber);
        }
      }
      sessionStorage.dashboardUsersRPP = rowPerPageValue;
      setRowsPerPage(rowPerPageValue);
    };
    const checkSearchTextExist = (data, key, text) => {
      let value = data[key];
      return value !== null && typeof value !== 'undefined' && value !== "" ? value.toString().toLowerCase().replaceAll(" ","").indexOf(text) !== -1 : false;
    };
    const handleTextCange = (textValue) => {
       setSearchText(textValue);
       let sortedDevices = [];
       let searchText = document.getElementById("searchText") ? document.getElementById("searchText").value : "";
       if (searchText !== "" && typeof searchText !== 'undefined' && searchText !==  null){
           let text = searchText.toLowerCase().replaceAll(" ","");
           _.forEach(window.portalUserData,(value)=>{
             if (checkSearchTextExist(value, "mergedName", text) || checkSearchTextExist(value, "email", text)){
               sortedDevices.push(value);
             }
           })
           sortedDevices = _.uniq(sortedDevices);
           setRows(sortedDevices);
       }else {
         setRows(window.portalUserData);
       }
       resetPaginationData(sortedDevices);
    }
    const resetPaginationData = (data) => {
        if (window.oldPreservedtenantUsersPageNumber) {
          if (data.length > 0) {
            let pageNumberCount = Math.ceil((data.length/rowsPerPage)) - 1;
            if (window.oldPreservedtenantUsersPageNumber > pageNumberCount) {
              setPage(pageNumberCount);
              setPageNumber(pageNumberCount);
              window.oldPreservedtenantUsersPageNumber = pageNumberCount;
            }
          }else {
            let pageNumberCount = Math.ceil((window.portalUserData.length/rowsPerPage)) - 1;
            if (window.oldPreservedtenantUsersPageNumber > pageNumberCount){
              setPage(pageNumberCount);
              setPageNumber(pageNumberCount);
              window.oldPreservedtenantUsersPageNumber = pageNumberCount;
            }else if (window.oldPreservedtenantUsersPageNumber <= pageNumberCount && pageNumberCount > 0) {
              setPage(window.oldPreservedtenantUsersPageNumber);
              setPageNumber(window.oldPreservedtenantUsersPageNumber);
            }else {
              setPage(0);
              setPageNumber(0);
              window.oldPreservedtenantUsersPageNumber = 0;
            }
          }
        }else {
          setPageNumber(0);
          setPage(0);
          window.oldPreservedtenantUsersPageNumber = 0;
        }
    }
    const rowPerPageLabel = (
      <div className={paginationStyle.rowPerPageLabelDiv}>
        <Box className={paginationStyle.page}>
          <span style={{marginRight: '32px'}}>Page</span>
        </Box>
        <Box className={paginationStyle.pageSelectBox}>
          <Select value={pageNumber} onChange={handleChange}>
            {
              // eslint-disable-next-line array-callback-return
              Array(Math.ceil(rows.length / rowsPerPage))
                .fill()
                .map((_, index) => {
                  return (
                    <MenuItem value={index} sx={{minWidth: '64px'}}>
                      {index + 1}
                    </MenuItem>
                  );
                })
            }
          </Select>
        </Box>
        <Box className={paginationStyle.rowPerPageLabelDiv}>
          <span style={{marginRight: '32px'}}>Rows Per Page</span>
        </Box>
        <Box className={paginationStyle.rowPerPageSelectBox}>
          <Select value={rowsPerPage} onChange={handleRowPerPage}>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
          </Select>
        </Box>
      </div>
    );
    const handleCloseEditPermissionDialog = (value) => {
      //getPortalUsers();
      setEditPermissionDialog(value);
      setCreatePermissionDialog(value);
      setViewPermissionDialog(value);
      setDeletePermissionDialog(value);
    }
    return (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              padding: '20px 0px 20px 24px',
            }}
          >
            <span style={{fontSize: '20px', marginRight: 'auto'}}>
              Users
            </span>
            <input
              id="searchText"
              className= {SearchBar ? "search" : "searchAnimation"}
              type="text"
              onChange={(event)=>handleTextCange(event.target.value)}
              placeholder="Search"
              autoFocus
            />
            <Close
              style={{
                marginRight: '14px',
                cursor: 'pointer',
                display: SearchBar ? 'flex' : 'none',
                color:'#0000008A',
              }}
              onClick={() => {
                setSearchText('');
                document.getElementById('searchText').value = '';
                setSearchBar(false);
                setRows(window.portalUserData);
              }}
            />
            {
              !SearchBar ? 
                <Tooltip title="Search" placement="top" arrow>
                  <Search
                    style={{
                      marginRight: '15px',
                      cursor: 'pointer',
                      display: !SearchBar ? 'flex' : 'none',
                      color:'#0000008A',
                    }}
                    onClick={() => {
                      setSearchBar(true);
                      setTimeout(() => {document.getElementById("searchText").focus();},100);
                    }}
                  />
                </Tooltip>
              :
                null
            }
            <Tooltip title="Refresh" placement="top" arrow>
              <Refresh className={refresh?"refreshIcon":"refreshIconAnimation"} sx={{marginRight:"20px",color:'#0000008A'}} onClick={()=>refreshData()} />
            </Tooltip>
          </Box>
          <Box style={{width: '100%', height: 'auto', marginBottom: '86px'}}>
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  check_all={checkAll}
                  onSelectAllClick={(checked) => handleSelectAllClick(checked)}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows,order, orderBy)
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return(
                           <TableRow
                             hover
                             onClick={(event) => handleClick(event, row.name)}
                             role="checkbox"
                             aria-checked={isItemSelected}
                             tabIndex={-1}
                             key={row.id}
                             selected={isItemSelected}
                             className={paginationStyle.tableBodyRow}
                             sx={{borderBottom:'1px solid #c1a6a6'}}
                           >
                              <TableCell align="left">{row.mergedName || "-"}</TableCell>
                              <TableCell align="left">{row.email || "-"}</TableCell>
                              <TableCell align="left">
                               { JSON.parse(sessionStorage.userDetails).user.email === row.email || JSON.parse(sessionStorage.userDetails).user.owner || (sessionStorage.isRootUser === "true" && sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE")) ?
                                   <Switch size='medium' disabled={grayOut2FaButton(row)} checked={row.secureAuthentication === true} onClick={(event)=> { event.stopPropagation();enableOrDisable2FA(row)}} />
                                : null
                               }
                              </TableCell>
                              <TableCell>
                                <Box className="usersActions cursorPointer">
                                {
                                   JSON.parse(sessionStorage.userDetails).permissions.includes("PORTAL_USER_WRITE") ?
                                     (JSON.parse(sessionStorage.userDetails).user.email !== row.email && !row.owner) || (JSON.parse(sessionStorage.userDetails).user.id === rootTenantId && row.scopeId !== rootScopeId) ?
                                       <Box sx={{margin: '0px 20px 0px 0px'}}>
                                         <Edit
                                           onClick={() => {
                                             setEditPermissionDialog(true);
                                             row.firstNameTextLength = row.firstName !== "" && typeof row.firstName !== 'undefined' && row.firstName ? row.firstName.length + "/20" : "0/20";
                                             row.lastNameTextLength = row.lastName !== "" && typeof row.lastName !== 'undefined' && row.lastName ? row.lastName.length + "/20" : "0/20";
                                             setPermissionsData(row);
                                           }}
                                           sx={{color:'#0000008A'}}
                                         />
                                       </Box>
                                     :
                                       null
                                   :
                                     null
                                }
                                {
                                  JSON.parse(sessionStorage.userDetails).permissions.includes("PORTAL_USER_DELETE") ?
                                    (JSON.parse(sessionStorage.userDetails).user.email !== row.email && !row.owner) ?
                                      <Box sx={{margin: '0px 20px 0px 0px'}}>
                                        <Delete
                                          onClick={() => {
                                            setDeletePermissionDialog(true);
                                            setPermissionsData(row);
                                          }}
                                          sx={{color:'#0000008A',cursor:'pointer'}}
                                        />
                                      </Box>
                                    :
                                      null
                                  :
                                    null
                                }
                                </Box>
                              </TableCell>
                           </TableRow>
                        );
                      })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{width: '100%', overflowX: 'hidden'}}
              labelRowsPerPage={rowPerPageLabel}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              editMode={editMode}
              page={pageNumber}
              className="pagination"
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Box>
          <Button
            onClick={() => {
              setCreatePermissionDialog(true);
            }}
            style={{
              position: 'fixed',
              bottom: '37px',
              right: '40px',
              height: '62px',
              maxWidth: '55px',
              borderRadius: '50%',
              backgroundColor: themePrimaryColor,
              display: JSON.parse(sessionStorage.userDetails).permissions.includes("PORTAL_USER_WRITE") ? 'flex' : 'none',
            }}
          >
            <PersonAdd sx={{color: themeSecondaryColor}} />
          </Button>
          {/* CREATE PERMISSION DIALOG */}
          {createPermissionDialog ? (
            <>
              <CreatePortalUserDialog
                title="Create User"
                disabled={false}
                button_name="Create"
                role="superadmin"
                data={[]}
                mode="view"
                show_permission_dialog={createPermissionDialog}
                close_permission_dialog={(value) =>
                  handleCloseEditPermissionDialog(value)
                }
                dialog="permissionDialog"
              />
            </>
          ) : null}
          {/* EDIT PERMISSION DIALOG */}
          {editPermissionDialog ? (
            <>
              <CreatePortalUserDialog
                title="Update User"
                disabled={false}
                button_name="Update"
                role={
                  permissionsData.permissions.length >= 27
                    ? 'superadmin'
                    : permissionsData.permissions.length === 13
                    ? 'admin'
                    : 'custom'
                }
                data={permissionsData}
                mode="view"
                show_permission_dialog={editPermissionDialog}
                close_permission_dialog={(value) =>
                  handleCloseEditPermissionDialog(value)
                }
                dialog="permissionDialog"
              />
            </>
          ) : null}
          {/* DELETE USER DIALOG */}
          {deletePermissionDialog ? (
            <>
              <DeletePortalUserDialog
                title="Delete Portal User"
                disable={false}
                data={permissionsData}
                mode="view"
                show_delete_permission_dialog={deletePermissionDialog}
                close_delete_permission_dialog={(value) =>
                  handleCloseEditPermissionDialog(value)
                }
                dialog="deletePermissionDialog"
              />
            </>
          ) : null}
          {/*  Enable 2FA Dialog */}
          {showEnablingDialog ?
              <TwoFactorAuthentication
                 openDialog={showEnablingDialog}
                 setToggleToPreviousState={(res) => {
                    if (res === false) {
                      userDetails.secureAuthentication = false
                    }
                 }}
                 userDetails={userDetails}
                 closeDialog={() => setShowEnablingDialog(false)}
              />
          :
             null
          }
          {/*  Disable 2FA Dialog */}
          { showDisableDialog ?
               <DisableTwoFactorAuthentication
                  openDialog={showDisableDialog}
                  setToggleToPreviousState={() => {userDetails.secureAuthentication = true}}
                  userDetails={userDetails}
                  closeDialog={() => setShowDisableDialog(false)}
               />
          :
            null
          }
        </>
    );
};
export default PortalUsers;