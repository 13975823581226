import React, {Component} from "react";

export default class PubSubChannels extends Component {
    static get pubsub_channels() {
        return {
            GATEWAY_SERVICE_STATUS: "gateway-service/status",
            INSTRUCTIONS: "frontend/instruction/scopeId",
            IOT_SERVICE_STATUS: "iot-service/status",
            AUTH_SERVICE_STATUS: "auth-service/status",
            GATEWAYS: "gateway-service/scopeId/gateways",
            DEVICES: "iot-service/scopeId/devices",
            USERS: "iot-service/scopeId/users",
            DEVICE_USER_MAPPINGS: "iot-service/scopeId/devices/device-user-mappings",
            SCHEDULED_USER_MAPPINGS: "gateway-service/devices/scheduled-device-user-mappings",
            SCOPES: "auth-service/scopeId/scopes",
            PORTAL_USERS: "auth-service/scopeId/users",
            //GATEWAY_STATUS: "gateways/status",
            GATEWAY_ONBOARDING_STATUS:"gateways/scopeId/onboarding/status/gatewayId",
            //GATEWAY_COMMAND: "gateways/command",
            GATEWAY_STATUS: "gateways/scopeId/status/gatewayId",
            GATEWAY_COMMAND: "gateways/scopeId/command/gatewayId",
            GATEWAY_ONBOARDING_COMMAND:"gateways/scopeId/onboarding/command/gatewayId",
            STOP_GATEWAY_ONBOARDING_COMMAND:"gateways/scopeId/onboarding/command/stop/gatewayId",
            PULL_DEVICE_LOGS: "frontend/device/pull-logs/macId/command",
            PULL_DEVICE_LOGS_RESPONSE: "frontend/device/pull-logs/macId/response",
            GATEWAY_REGISTER: "frontend/gateway/macId/register",
            GATEWAY_WHITELIST_RECEIVE_FROM_DEVICE: "gateways/gatewayId/publish/devices/whitelist",
            GATEWAY_WHITELIST_SEND_TO_DEVICE: "gateways/gatewayId/receive/devices/whitelist",
            ALERTS: "job-scheduler/scopeId/alerts",
            CONSOLE_COMMAND: "iot-service/mgmtconsole/devices/gateway/command",
            REQUEST_WHITELISTED_LOCKS: "gateway-service/push-message/gateways/gatewayId",
            CONSOLE_OUTPUT: "iot-service/mgmtconsole/devices/gateway/output",
            GATEWAY_OS_PATCH_UPLOAD_OR_APPLY: "auth-service/+/partial-os-patch-upgraded-details",
            GATEWAY_FIRMWARE_UPLOAD: "auth-service/+/firmware-upgraded",
            GATEWAY_FIRMWARE_APPLY: "auth-service/+/firmware-upgraded-details",
            GATEWAY_ACTIVITY_LOGS: "gateway-service/activityLogs/macId",
            GET_DEBUG_LOG_AWS_URL: "iot-service/debugLogAwsUrl/macId",
            GATEWAY_DEBUG_LOGS_UPLOADED_PERCENTAGE: "frontend/devices/macId/logs-uploaded",
            DEVICE_GATEWAY_MAPPINGS_REMOVED: "gateway-service/gateway-device-mapping/removed/scopeId",
            GATEWAY_ADD_INSTRUCTION_SUCCESS_OR_FAILURE: "gateway-service/gateway-device/scopeId/successOrFailedStatus/gatewayMacId/lockMacId/instructionId/Add",
            GATEWAY_REMOVE_INSTRUCTION_SUCCESS_OR_FAILURE: "gateway-service/gateway-device/scopeId/successOrFailedStatus/gatewayMacId/lockMacId/instructionId/remove",
            GATEWAY_CHANGE_LOCK_MODE_INSTRUCTION : "gateway-service/gateway-device/scopeId/successOrFailedStatus/gatewayMacId/lockMacId/lockMode/instructionId",
            GATEWAY_LOCK_UNLOCK_INSTRUCTION_SUCCESS_OR_FAILURE: "gateway-service/gateway-device/scopeId/successOrFailedStatus/lock-unlock/gatewayMacId/lockMacId/instructionId",
            PENDING_INSTRUCTION_ICONS_CHANNEL: "frontend/pending-instruction/show-icon/scopeId",
            GENERATE_SCANNED_LOCKS_CSV: "iot-service/scanned-locks/generate-csv/macId/scopeId",
            CAPTURE_IMAGE_REQUEST:"frontend/devices/macId/capture-image/request",
            CAPTURE_IMAGE_RESPONSE:"frontend/devices/macId/capture-image/response",
            DIAGNOSTIC_RESPONSE: "iot-service/devices/macId/diagnostic-info/response",
            DIAGNOSTIC_REQUEST: "frontend/devices/macId/diagnostic-info/request",
            REBOOT_DEVICE: "iot-service/devices/macId",
            DEVICE_UNREGISTERED: "iot-service/devices/macId/states/onlinepayload",
            PORTAL_CONFIGURATION: "iot-service/portal-configuration/scopeId",
            GENERIC_PUBLISH_CHANNEL: "iot-service/device/instruction-initiated/MAC",
            GRAPH_CHANNEL: "iot-service/graph-data/macId/scopeId",
            TWO_FACTOR_AUTHENTICATION_CHANNEL: "auth-service/two-factor-authentication/scopeId/status"
        }
    }
}
