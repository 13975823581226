import React from 'react';
import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, useTheme, useMediaQuery } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import IotServiceClient from 'clients/IotServiceClient';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import PaperComponent from 'helper-components/PaperComponent';

const MigrateUnregisteredDevice = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7");
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff");
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        setTimeout(() => {
            setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7");
            setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff");
        }, 1000);
    },[]);

    const migrateUnregisteredDevice = () => {
        let data = Object.assign({},props.deviceData)
        let payload = {
          "deviceId": data.id,
          "scopeId": props.customerData.scopeId
        }
        IotServiceClient.migrateGateway(payload,"devicePage").then((response) => {
            if (!response.hasOwnProperty("status")) {
                enqueueSnackbar("Successfully migrated "+ data.MAC +" device");
                props.closeDialog();
            } else {
                enqueueSnackbar("Failed to migrate "+ data.MAC +" device");
            }
        });
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.openDialog}
                onClose={() => props.close()}
                PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle
                    style={{
                      background: primaryThemeColor,
                      display: 'flex',
                      flexDirection: 'row',
                      color: 'white',
                    }}
                >
                    <Box sx={{flex: '95'}}>{props.deviceData.NAME} Device Migration</Box>
                    <Box sx={{flex: '5', marginTop: '6px'}}>
                        <Close onClick={() => props.close()}/>
                    </Box>
                </DialogTitle>
                <DialogContent style={{padding:"20px"}}>
                    Are you sure, you want to make the move to {props.customerData.name}?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.close()}>cancel</Button>
                    <Button sx={{color:primaryThemeColor}} onClick={() => migrateUnregisteredDevice()}>yes</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MigrateUnregisteredDevice;
