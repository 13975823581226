import React from 'react';
import { Button, Box, useTheme, useMediaQuery,Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Snackbar } from '@mui/material';
import AuthClient from "clients/AuthClient";
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import {useSnackbar} from "notistack";
import PaperComponent from 'helper-components/PaperComponent';

const DeletePortalUserDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [showSnackBarMessage, setShowSnackBarMessage] = React.useState("");
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
      setTimeout(() => {
        setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
        setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
      }, 1000);
    }, []);
    const handleDeletePortalUserDialog = () => {
      props.close_delete_permission_dialog(false);
    }
    const deletePortalUser = () => {
      AuthClient.deletePortalUser(props.data.id,sessionStorage.tenantId).then((response)=>{
        if (response.status === 200 || response.status ===201 || response.status === 204){
          enqueueSnackbar("User successfully removed");
          props.close_delete_permission_dialog(false);
        }
      });
    }
    return (
      <div>
        {props.dialog === 'deletePermissionDialog' ? (
           <Dialog
              fullWidth={true}
              fullScreen={fullScreen}
              open={props.show_delete_permission_dialog}
              onClose={handleDeletePortalUserDialog}
              PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
              aria-labelledby="draggable-dialog-title"
           >
             <DialogTitle>
               <Box id="draggable-dialog-title" style={{marginRight:'auto',fontWeight:'bold'}}>
                 {props.title}
               </Box>
             </DialogTitle>
             <DialogContent>
               <DialogContentText style={{color:'black'}}>
                 Are you sure, you want to delete this portal user?
               </DialogContentText>
             </DialogContent>
             <DialogActions>
               <Button onClick={() => handleDeletePortalUserDialog()}>Cancel</Button>
               <Button style={{color:primaryThemeColor}} onClick={() => deletePortalUser()}>Yes</Button>
             </DialogActions>
           </Dialog>
        ) : null}
        {
          showSnackBar ?
          <Snackbar
            anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
            open={showSnackBar}
            autoHideDuration={3000}
            message={showSnackBarMessage}
          />
        :
          null
        }
      </div>
    );
};
export default DeletePortalUserDialog;
