import React from 'react';
import { Box,Dialog,Button,DialogActions,DialogContent,DialogTitle,DialogContentText,Paper,useMediaQuery,useTheme,Tooltip,Select,MenuItem,Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow } from '@mui/material';
import { Close,Refresh,CheckCircle } from '@mui/icons-material';
import 'stylesheet/UpdateFirmware.css';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
      <Draggable
          handle="#draggable-dialog-title"
          cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
  );
}

const ControlFirmwareUpdateDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const [selectedOption, setSelectedOption] = React.useState('');

    React.useEffect(() => {

    },[])

    const handleCloseDialog = () => {
        props.closeDialog(false);
    }

    const handleChange = (value) => {
        setSelectedOption(value);
    }

    return (
        <div>
           {props.dialog === 'controlFirmwareUpdateDialog' ? (
              <Dialog
                  fullWidth={true}
                  maxWidth="md"
                  fullScreen={fullScreen}
                  open={props.show_control_firmware_dialog}
                  onClose={handleCloseDialog}
                  PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
                  aria-labelledby="draggable-dialog-title"
              >
                  <DialogTitle
                    style={{background: '#616161',
                     display: 'flex',
                     flexDirection: 'row',
                     color: 'white',
                  }}>
                     <Box sx={{flex: '95',fontWeight:'bold',fontSize:'20px'}}>Control Firmware Update</Box>
                     <Box sx={{flex: '5', marginTop: '6px'}}>
                         <Close onClick={()=>{handleCloseDialog()}}/>
                     </Box>
                  </DialogTitle>
                  <DialogContent>
                     <div>
                        <div style={{backgroundColor:'#f1f1f1',display:'flex',flexDirection:'column',marginTop:'15px'}}>
                          <div style={{display:'flex',backgroundColor:'#4190c7'}}>
                            <TableContainer>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell sx={{fontSize: '16px',color:'white'}}>Number of Gateways</TableCell>
                                    <TableCell sx={{fontSize: '16px',color:'white'}}></TableCell>
                                    <TableCell sx={{fontSize: '16px',color:'white'}}>Number of Gateways Selected for Upgrade</TableCell>
                                    <TableCell sx={{fontSize: '16px',color:'white'}}></TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell sx={{fontSize: '16px',color:'white'}}>Number of Online Gateways</TableCell>
                                    <TableCell sx={{fontSize: '16px',color:'white'}}></TableCell>
                                    <TableCell sx={{fontSize: '16px',color:'white'}}>Number of Gateways Completed Upgrade</TableCell>
                                    <TableCell sx={{fontSize: '16px',color:'white'}}></TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell sx={{fontSize: '16px',color:'white'}}>Number of Offline Gateways</TableCell>
                                    <TableCell sx={{fontSize: '16px',color:'white'}}></TableCell>
                                    <TableCell sx={{fontSize: '16px',color:'white'}}>Number of Gateways Initiated/Pending Upgrade</TableCell>
                                    <TableCell sx={{fontSize: '16px',color:'white'}}></TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                          <div className='selectBlock' style={{justifyContent:'flex-end'}}>
                            <Tooltip title='Refresh' placement='top'>
                              <Button>
                                <Refresh sx={{cursor:'pointer',color:'#0000008a'}}/>
                              </Button>
                            </Tooltip>
                            <div style={{fontSize:'14px',fontWeight:'600',color:'#4190c7',marginRight:'-4px'}}>
                              SELECT NEXT
                              <Select
                                value={selectedOption}
                                onChange={(event)=>{handleChange(event.target.value)}}
                                sx={{width:'130px',height:'30px',marginTop:'6px'}}
                              >
                                <MenuItem value={10}>10 Devices</MenuItem>
                                <MenuItem value={30}>30 Devices</MenuItem>
                                <MenuItem value={50}>50 Devices</MenuItem>
                                <MenuItem value={100}>100 Devices</MenuItem>
                              </Select>
                              <span style={{display:selectedOption ? 'inline' : 'none'}}>
                                <Tooltip title='Apply' placement='top'>
                                  <CheckCircle sx={{cursor:'pointer',marginBottom:'-7px',marginLeft:'10px'}}/>
                                </Tooltip>
                              </span>
                            </div>
                            <Button style={{cursor:'pointer',fontWeight:'600',color:'#4190c7',marginRight:'10px'}}>SELECT ALL</Button>
                            <Button style={{cursor:'pointer',fontWeight:'600',color:'#4190c7'}}>UNSELECT ALL</Button>
                          </div>
                          <Box sx={{height:'400px'}}></Box>
                        </div>
                     </div>
                  </DialogContent>
                  <DialogActions>
                     <Button style={{color: '4190c7',fontWeight:'600'}} onClick={() => {handleCloseDialog()}}>
                       Cancel
                     </Button>
                     <Button style={{color: '4190c7',fontWeight:'600'}}onClick={() => {}}>Apply</Button>
                  </DialogActions>

              </Dialog>
           ) : null }
        </div>
    );
};

export default ControlFirmwareUpdateDialog;
