import FingerprintJS from '@fingerprintjs/fingerprintjs';

function fingerprintGenerator() {
  return FingerprintJS.load()
    .then(res => {
      return res.get();
    }).then(result => {
       return result.visitorId;
    })
}
export default fingerprintGenerator;