import React, {Component} from "react";

export default class Enums extends Component {
    static get instruction_enums() {
        return {
            LOCK_UNLOCK_INSTRUCTION: "LOCK_DEVICE_LOCK_UNLOCK",
            REMOVE_LOCK_DEVICE_USER: "REMOVE_LOCKDEVICE_USER",
            ADD_LOCK_DEVICE_USER:"ADD_LOCKDEVICE_USER",
            REMOVE_IOT_USER: "REMOVE_IOT_USER",
        }
    }

    static get enums() {
        return {
            LOCK_UNLOCK_INSTRUCTION: "LOCK_DEVICE_LOCK_UNLOCK",
        }
    }

    static get globals() {
        return {
            ADMIN_TENANT_ID: "5947df4362a1280006cdd54b",
            SUPER_ADMIN_ROLE: "superadmin",
            ADMIN_ROLE: "admin",
            CUSTOM_ROLE: "custom"
        }
    }
}
