import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css'; // Optional: If you have a global CSS file
import { BrowserRouter } from 'react-router-dom';
import Appheader from 'components/header/Appheader';
import LogoHeader from 'components/header/LogoHeader';


const headerContainer = document.getElementById('rootHeader');
const rootHeader = createRoot(headerContainer);
const container = document.getElementById('root');
const root = createRoot(container);

const RenderHeader = () => {
    const [hasUserDetails, setHasUserDetails] = React.useState(sessionStorage.hasOwnProperty("userDetails"));


    React.useEffect(() => {
        const checkForUserDetails = () => {
            setHasUserDetails(sessionStorage.hasOwnProperty("userDetails"));
        }
        window.addEventListener('hashchange', checkForUserDetails);

        return () => {
          window.removeEventListener('hashchange', checkForUserDetails);
        };
    })

    return hasUserDetails ? <Appheader /> : <LogoHeader /> ;
}

rootHeader.render(
    <BrowserRouter>
        <RenderHeader />
    </BrowserRouter>
)
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

/*  // : TODO NEED TO ADD HEADER COMPONENT/ELEMENT
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
          <Route path="/*" element={
            sessionStorage.hasOwnProperty("userDetails") ?
              <Appheader />
            :
              <LogoHeader />                
          } />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('rootHeader')
);
ReactDOM.render(
  <React.StrictMode>
    {
      window.location.href.indexOf("portal-user/reset-password/") === -1 && window.location.href.indexOf("com/reset-password/") === -1  ?
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={
            <Login />} />
            <Route exact path="/global-search" element={
               <Suspense fallback = { FallbackUi } >
                  <GlobalDashboard />
               </Suspense>
            } />
            <Route exact path="/change-password" element={
              !sessionStorage.hasOwnProperty("userDetails") ?
                <Login />
              :
              <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                <Suspense fallback = { FallbackUi } >
                   <ChangePassword />
                </Suspense>
              </SnackbarProvider>
            } />
            <Route exact path="/reset-password/:activationCode" element={
               <Suspense fallback = { FallbackUi } >
                  <ChangePassword isResetPassword={true} />
               </Suspense>
            } />
            <Route exact path="/admin/tenants" element={
                !sessionStorage.hasOwnProperty("userDetails") ?
                <Login />
              :
                <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                   <Suspense fallback = { FallbackUi } >
                      <LandingPage />
                   </Suspense>
                </SnackbarProvider>
            } />
            <Route exact path="/cli/*" element={
                !sessionStorage.hasOwnProperty("userDetails") ?
                <Login />
              :
                <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                  <SideNav />
                </SnackbarProvider>
            }
            />
            <Route exact path="/upgrade-firmware" element={
                !sessionStorage.hasOwnProperty("userDetails") ?
                    <Login />
                :
                 <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                     <Suspense fallback = { FallbackUi } >
                         <UpgradeFirmware />
                     </Suspense>
                 </SnackbarProvider>
            } />
            <Route exact path="/logout" element={<Login />} />
            <Route exact path="/update-OS" element={
                !sessionStorage.hasOwnProperty("userDetails") ?
                <Login />
              :
               <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                   <Suspense fallback = { FallbackUi } >
                      <UpdateOs />
                   </Suspense>
               </SnackbarProvider>
            }/>
            <Route exact path="/portal-config" element={
                !sessionStorage.hasOwnProperty("userDetails") ?
                <Login />
              :
               <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                  <Suspense fallback = { FallbackUi } >
                      <PortalConfig />
                  </Suspense>
               </SnackbarProvider>
            }/>
            <Route exact path="/theme-config" element={
                !sessionStorage.hasOwnProperty("userDetails") ?
                <Login />
              :
               <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
                  <Suspense fallback = { FallbackUi } >
                     <ThemeConfig />
                  </Suspense>
               </SnackbarProvider>
            }/>
          </Routes>
        </Router>
      :            
        <Router>
          <Routes>
          <Route path="/*" element={
            <SnackbarProvider autoHideDuration={2000} maxSnack={3} TransitionComponent={TransitionUp} anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}>
               <Suspense fallback = { FallbackUi } >
                 <ChangePassword isResetPassword={true} resetIotUserPassword={window.location.href.indexOf("com/reset-password/") !== -1 ? true : false} navigatedThroughMail={true} />
               </Suspense>
            </SnackbarProvider>
          }/>
          </Routes>
        </Router>
    }
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();*/
