import React, {Component} from 'react'

export default class utils extends Component {

    static getUniqueKey = function(salt) {
        //initialize a variable having alpha-numeric characters
        var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";

        // Check if salt is provided, otherwise initialize it as an empty string
        salt = salt || '';

        //specify the length for the new string to be generated
        var string_length = 8;
        var randomstring = '';

                //put a loop to select a character randomly in each iteration
        for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
        }

        return salt + randomstring;
    }

    static isBase64 = function(str) {
        try {
            atob(str);
            return true;
        } catch (err) {
            return false;
        }
    }

    render() {

            return(<></>);
        }
}