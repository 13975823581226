import React from 'react';
import { Button, Box, useTheme, useMediaQuery,Dialog, DialogActions, FormControl, FormControlLabel, DialogContent, DialogTitle, TextField, Checkbox, InputLabel, Select, MenuItem, CircularProgress, Snackbar } from '@mui/material';
import { Close } from '@mui/icons-material';
import 'stylesheet/HomePage.css';
import "stylesheet/common.css";
import Enums from 'constants/Enums';
import PhoneInput, { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import AuthClient from "clients/AuthClient";
import { useSnackbar } from 'notistack';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import _ from 'lodash';
import PaperComponent from 'helper-components/PaperComponent';


const CreatePortalUserDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getWindowDimensions = () => {
       const {innerWidth: width, innerHeight: height} = window;
       return {
         width,
         height,
       };
    };
    let propsPermissions = _.cloneDeep(props.data);
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());
    const [userNetworkLoading, setUserNetworkLoading] = React.useState(false);
    const [permissionData, setPermissionData] = React.useState({});
    const [userFirstName, setUserFirstName] = React.useState(props.data ? props.data.firstName : "");
    const [userLastName, setUserLastNAme] = React.useState(props.data ? props.data.lastName : "");
    const [userEmail, setUserEmail] = React.useState(props.data ? props.data.email : "");
    const [userMobileNumber, setUserMobileNumber] = React.useState(props.data ? props.data.phoneNumber : "");
    const [countryCode, setCountryCode] = React.useState('');
    const [userRole, setUserRole] = React.useState(Enums.globals.SUPER_ADMIN_ROLE);
    const [customPermissionData, setCustomPermissionData] = React.useState({});
    const [justPermissions, setJustPermissions] = React.useState([]);
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [showSnackBarMessage, setShowSnackBarMessage] = React.useState("");
    const [isValidDataOrNot, setIsValidDataOrNot] = React.useState(false);

    const allSuperAdminPermissions = ["IOT_DEVICE_READ","IOT_DEVICE_WRITE","IOT_DEVICE_BULK_WRITE","PORTAL_USER_READ","PORTAL_USER_WRITE","PORTAL_USER_DELETE","IOT_USER_READ","IOT_USER_WRITE","ALERTS_READ","ALERTS_WRITE","SCOPE_READ","SCOPE_WRITE","SCOPE_UPDATE","SCOPE_DELETE","FIRMWARE_READ","FIRMWARE_WRITE","FIRMWARE_DOWNLOAD","CAN_BE_SCOPE_OWNER","GATEWAY_INSTRUCTIONS_READ","GATEWAY_INSTRUCTIONS_WRITE","GATEWAY_READ","GATEWAY_WRITE","GENERATE_GATEWAY_TOKEN","TOP_LEVEL_SCOPE_READ","APP_DETAILS","MQTT_AUTH","PORTAL_USER_DETAILS","PORTAL_USER_PASSWORD_CHANGE","HIERARCHY_READ"];
    const allTenantSuperAdminPermissions = ["IOT_DEVICE_READ","IOT_DEVICE_WRITE","IOT_DEVICE_BULK_WRITE","PORTAL_USER_READ","PORTAL_USER_WRITE","PORTAL_USER_DELETE","IOT_USER_READ","IOT_USER_WRITE","ALERTS_READ","ALERTS_WRITE","SCOPE_READ","SCOPE_WRITE","SCOPE_UPDATE","SCOPE_DELETE","FIRMWARE_READ","FIRMWARE_DOWNLOAD","CAN_BE_SCOPE_OWNER","GATEWAY_INSTRUCTIONS_READ","GATEWAY_INSTRUCTIONS_WRITE","GATEWAY_READ","GATEWAY_WRITE","GENERATE_GATEWAY_TOKEN","APP_DETAILS","MQTT_AUTH","PORTAL_USER_DETAILS","PORTAL_USER_PASSWORD_CHANGE","HIERARCHY_READ"];
    const allAdminPermissions = ["IOT_DEVICE_READ","PORTAL_USER_READ","IOT_USER_READ","ALERTS_READ","SCOPE_READ","FIRMWARE_READ","GATEWAY_INSTRUCTIONS_READ","GATEWAY_READ","APP_DETAILS","MQTT_AUTH","PORTAL_USER_DETAILS","PORTAL_USER_PASSWORD_CHANGE","HIERARCHY_READ"]
    const { enqueueSnackbar } = useSnackbar();
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
    const [countryFlag,setCountryFlag] = React.useState('');


    React.useEffect(() => {
       window.portalUser = {
            firstName : null,
            lastName : null,
            email : null,
            phoneNumber : null,
            countryCode : null,
            permissions : null
       }

      handleResize();
      setTimeout(() => {
        setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
        setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
      }, 1000);
      window.addEventListener('resize', handleResize);
      if (props.dialog === "permissionDialog"){
        processPermissionsData();
      }
      return () => {
        window.removeEventListener('resize', handleResize);
        delete window.portalUser;
      }
    }, []);

    const processPermissionsData = () => {
       if(props.data.permissions) {
          if (props.data.permissions.length >= 27) {
             setUserRole(Enums.globals.SUPER_ADMIN_ROLE);
          } else if (props.data.permissions.length == 13) {
             setUserRole(Enums.globals.ADMIN_ROLE);
          } else {
             setUserRole(Enums.globals.CUSTOM_ROLE);
          }
       }

        if (props.title !== 'Create User') {
           if (checkDataExistsOrNot(propsPermissions.phoneNumber)) {
              let shortCode = checkDataExistsOrNot(propsPermissions.countryShortCode) ? propsPermissions.countryShortCode : "";
              let countryCode = checkDataExistsOrNot(shortCode) ? "+"+getCountryCallingCode(shortCode) : "";
              let number = propsPermissions.phoneNumber;
              propsPermissions.countryCode = countryCode;
              propsPermissions.phoneNumber = number.replace(countryCode,"");
              setPermissionData(propsPermissions);
              setCountryCode(countryCode);
              setCountryFlag(shortCode);
           } else {
              setPermissionData(propsPermissions);
           }
        } else {
            if (sessionStorage.tenantId === Enums.globals.ADMIN_TENANT_ID){
              propsPermissions.permissions = allSuperAdminPermissions;
            } else {
              propsPermissions.permissions = allTenantSuperAdminPermissions;
            }
            setPermissionData(propsPermissions);
        }

       let originalPropsData = { ...propsPermissions };
       setCustomPermissionData({ ...originalPropsData });

       window.portalUser = {
              firstName : propsPermissions.firstName,
              lastName : propsPermissions.lastName,
              email : propsPermissions.email,
              phoneNumber : propsPermissions.phoneNumber,
              permissions : propsPermissions.permissions,
              countryCode : propsPermissions.countryCode,
              countryShortCode : propsPermissions.countryShortCode
       }
    }

    const isValidName = (name) => {
       return !name.match("^[a-zA-Z0-9+_.\-]+@[a-zA-Z0-9_.\-]+[.]{1}[a-z]{2,3}$")
    }

    const validMobileNumber = (number) => {
       if (checkDataExistsOrNot(number)) {
          if (number.match("^[0-9]+$") && (number.length >= 7 && number.length <= 15)) {
             return true;
          } else {
             return false;
          }
       } else {
         return false;
       }
    }

    const isValidEmail = (email) => {
       return email.match("^[a-zA-Z0-9+_.\-]+@[a-zA-Z0-9_.\-]+[.]{1}[a-z]{2,3}$")
    }

    const checkIsValidDataOrNot = () => {
       if (props.title === 'Create User') {
          if (checkDataExistsOrNot(window.portalUser.firstName) && isValidName(window.portalUser.firstName) && checkDataExistsOrNot(window.portalUser.lastName) && isValidName(window.portalUser.lastName) && checkDataExistsOrNot(window.portalUser.email) && isValidEmail(window.portalUser.email)) {
                 if (checkDataExistsOrNot(window.portalUser.phoneNumber) || checkDataExistsOrNot(window.portalUser.countryCode)) {
                       checkDataExistsOrNot(window.portalUser.countryCode) ? checkDataExistsOrNot(window.portalUser.phoneNumber) ? setIsValidDataOrNot(validMobileNumber(window.portalUser.phoneNumber)) : setIsValidDataOrNot(true) : setIsValidDataOrNot(false);
                 } else {
                      setIsValidDataOrNot(true);
                      setCountryCode('');
                 }
          } else {
            setIsValidDataOrNot(false);
          }
       } else {
          if (checkDataExistsOrNot(window.portalUser.firstName) && isValidName(window.portalUser.firstName) && checkDataExistsOrNot(window.portalUser.lastName) && isValidName(window.portalUser.lastName)) {
              if (checkDataExistsOrNot(window.portalUser.phoneNumber) || checkDataExistsOrNot(window.portalUser.countryCode)) {
                   let mobileNumber = props.data.phoneNumber;
                   mobileNumber = checkDataExistsOrNot(mobileNumber) ? mobileNumber.replace(window.portalUser.countryCode,"") : mobileNumber;
                   if (props.data.firstName === window.portalUser.firstName && props.data.lastName === window.portalUser.lastName && mobileNumber === window.portalUser.phoneNumber && JSON.stringify(window.portalUser.permissions.sort()) === JSON.stringify(props.data.permissions.sort()) ) {
                      setIsValidDataOrNot(false);
                   } else {
                      if (checkDataExistsOrNot(window.portalUser.phoneNumber) || checkDataExistsOrNot(window.portalUser.countryCode)) {
                        checkDataExistsOrNot(window.portalUser.countryCode) ? checkDataExistsOrNot(window.portalUser.phoneNumber) ? setIsValidDataOrNot(validMobileNumber(window.portalUser.phoneNumber)) : setIsValidDataOrNot(false) : setIsValidDataOrNot(false);
                      } else {
                        setIsValidDataOrNot(true);
                      }
                   }
              } else {
                  if (props.data.firstName === window.portalUser.firstName && props.data.lastName === window.portalUser.lastName &&
                        (props.data.phoneNumber !== window.portalUser.phoneNumber ? (checkDataExistsOrNot(window.portalUser.phoneNumber) ? (validMobileNumber(window.portalUser.phoneNumber) ? !checkDataExistsOrNot(window.portalUser.countryCode) : true) : false) : true)  &&
                        JSON.stringify(window.portalUser.permissions.sort()) === JSON.stringify(props.data.permissions.sort()) ){
                        setIsValidDataOrNot(false);
                  } else {
                        if (props.data.firstName !== window.portalUser.firstName || props.data.lastName !== window.portalUser.lastName ||
                         JSON.stringify(window.portalUser.permissions.sort()) !== JSON.stringify(props.data.permissions.sort()) ||
                          props.data.phoneNumber !== window.portalUser.phoneNumber){
                             if (checkDataExistsOrNot(window.portalUser.phoneNumber) ? validMobileNumber(window.portalUser.phoneNumber) && checkDataExistsOrNot(window.portalUser.countryCode) : true) {
                                setIsValidDataOrNot(true);
                             } else {
                                setIsValidDataOrNot(false);
                             }
                          } else {
                             setIsValidDataOrNot(false);
                          }
                  }
              }
          } else {
               setIsValidDataOrNot(false)
          }
       }
    }

    const handleResize = () => {
      setDimensions(getWindowDimensions());
    }
    const handlePermissionDialog = () => {
      props.close_permission_dialog(false);
    };
    const changePermission = (event,permissionValue) => {
      let data = { ...permissionData };
      if (event.target.checked){
        data.permissions.push(permissionValue);
      }else {
        data.permissions.splice(data.permissions.indexOf(permissionValue),1);
      }
      setPermissionData({ ...data });
      window.portalUser.permissions = data.permissions;
      checkIsValidDataOrNot();
    }
    const checkSpace = (name) => {
      if(name === " " || name.indexOf(" ") === 0){
          if(name.length <= 1){
              return "";
          }else{
              return name ? name.trim() : "";
          }
      }
    }
    const handleOnChange = (val) => {
       setCountryCode(val);
       window.portalUser.countryCode = val;
       checkIsValidDataOrNot();
       document.getElementById("mobileField").focus();
    }
    const handleMobileNumber = (event) => {
        let number = event.target.value;
        setUserMobileNumber(number);
        let data = { ...permissionData };
        data.phoneNumber = number;
        setPermissionData({ ...data });
    }
    const handleCreatePortalUser = () => {
      setUserNetworkLoading(true);
      permissionData.scopeId = sessionStorage.tenantId;
      let data = { ...permissionData };
      data.phoneNumber = checkDataExistsOrNot(window.portalUser.countryCode) && checkDataExistsOrNot(window.portalUser.phoneNumber) ? window.portalUser.countryCode+data.phoneNumber : "";
      data.countryShortCode = checkDataExistsOrNot(window.portalUser.countryShortCode) && checkDataExistsOrNot(window.portalUser.phoneNumber) ? window.portalUser.countryShortCode : "";
      data.email = checkDataExistsOrNot(window.portalUser.email) ? window.portalUser.email.trim().toLowerCase() : "";
      AuthClient.createPortalUser(data).then((response) => {
          if (response.status === 200 || response.status === 201){
            setUserNetworkLoading(false);
            setShowSnackBar(true);
            enqueueSnackbar("User successfully added");
            setTimeout(() => {
              setShowSnackBar(false)
            }, 2001);
            props.close_permission_dialog(false);
          }else {
            setShowSnackBar(true);
            setShowSnackBarMessage("Failed to create user");
            setTimeout(() => {
              setShowSnackBar(false)
            }, 3001);
            setUserNetworkLoading(false);
          }
      });
    }
    const handleUpdatePortalUser = () => {
      setUserNetworkLoading(true);
      let payload = {
        "id": permissionData.id,
        "email": permissionData.email,
        "firstName": permissionData.firstName,
        "lastName": permissionData.lastName,
        "scopeId": sessionStorage.tenantId,
        "owner": permissionData.owner,
        "status": permissionData.status,
        "permissions": permissionData.permissions,
        "phoneNumber": checkDataExistsOrNot(window.portalUser.countryCode) && checkDataExistsOrNot(window.portalUser.phoneNumber) ? window.portalUser.countryCode+permissionData.phoneNumber : "",
        "countryShortCode": checkDataExistsOrNot(window.portalUser.countryShortCode) && checkDataExistsOrNot(window.portalUser.phoneNumber) ? window.portalUser.countryShortCode : ""
      }
      setShowSnackBar(true);
      setShowSnackBarMessage("Updating ...");
      setTimeout(() => {
        setShowSnackBar(false)
      }, 2001);
      permissionData.scopeId = sessionStorage.tenantId;
      AuthClient.updatePortalUser(payload).then((response) => {
          if (response.status === 200 || response.status === 201){
            enqueueSnackbar("Details updated successfully");
            setUserNetworkLoading(false);
            props.close_permission_dialog(false);
          }else {
            enqueueSnackbar("Failed to update details");
            setUserNetworkLoading(false);
          }
      });
    }
    return (
       <>
         {props.dialog === 'permissionDialog' ? (
            <Dialog
              fullWidth={true}
              fullScreen={fullScreen}
              open={props.show_permission_dialog}
              onClose={handlePermissionDialog}
              PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
              aria-labelledby="draggable-dialog-title"
              maxWidth="md"
            >
              <DialogTitle
                  style={{
                    color: 'white',
                    backgroundColor: primaryThemeColor,
                    display: 'flex',
                    flexDirection: 'row',
                  }}
              >
                <Box id="draggable-dialog-title" style={{marginRight: 'auto'}}>
                  {props.title}
                </Box>
                <span style={{marginTop: '6px',cursor:"pointer"}}>
                  <Close sx={{cursor:'pointer'}} onClick={() => handlePermissionDialog()}/>
                </span>
              </DialogTitle>
              <DialogContent
                sx={{
                  padding: '0px',
                  height: '100%',
                  overflow: Dimensions.width > 600 ? 'hidden' : 'auto',
                  overflowX: 'hidden',
                  opacity: userNetworkLoading ? 0.5 : null
                }}
              >
                <Box
                    sx={{
                      display: Dimensions.width > 600 ? 'flex' : 'none',
                      flexDirection: Dimensions.width <= 600 ? 'column' : 'row',
                      margin: '14px 14px 0px 14px',
                    }}
                >
                  <Box sx={{width: '46%'}}>
                    <p style={{fontSize: '16px', fontWeight: 'bold'}}>
                      Enter User Details
                    </p>
                  </Box>
                  <Box sx={{margin: '14px 30px'}}>&nbsp;</Box>
                  <Box sx={{width: '50%'}}>
                    <p
                        style={{fontSize: '16px', fontWeight: 'bold', width: '100%'}}
                    >
                      Select Permissions
                    </p>
                  </Box>
                </Box>
                <Box
                    sx={{
                      display: 'flex',
                      flexDirection: Dimensions.width <= 600 ? 'column' : 'row',
                      margin:
                          Dimensions.width > 600
                              ? '0px 14px 0px 14px '
                              : '0px 14px 0px',
                      width: '98%',
                    }}
                >
                  <Box sx={{width: '100%'}} className="usersTextBox">
                    <p
                        style={{
                          display: Dimensions.width <= 600 ? 'flex' : 'none',
                          fontSize: '16px',
                          fontWeight: 'bold',
                        }}
                    >
                      Enter User Details
                    </p>
                    <Box className="UsersDialog">
                      <Box className="nameTextField" sx={{ position:"relative"}}>
                        <TextField
                            disabled={props.disable}
                            label="First Name *"
                            variant="standard"
                            sx={{width: '100%', margin: '0px 0px'}}
                            //error={permissionData.firstName === ""}
                            helperText={permissionData.firstName === "" ||  permissionData.firstName ? !isValidName(permissionData.firstName) ? "Invalid First Name." : null : null}
                            inputProps={{
                              maxlength: 20
                            }}
                            //helperText={portalUserFirstNameHelperText()}
                            value={permissionData.firstName}
                            onChange={(event) => {
                              let value = event.target.value;
                              value = value === " " || value.indexOf(" ") === 0 ? checkSpace(value) : value;
                              setUserFirstName(value);
                              let data = { ...permissionData };
                              data.firstName = value;
                              data.firstNameTextLength = value !== "" && typeof value !== 'undefined' && value ? value.length + "/20" : "0/20";
                              setPermissionData({ ...data });
                              window.portalUser.firstName = value;
                              checkIsValidDataOrNot();
                            }}
                            onBlur={() => checkIsValidDataOrNot()}
                        />
                        <Box sx={{position:"absolute",left:"94%",top:"50px",fontSize:"12px",right:"51%",color: permissionData.firstName !== "" && permissionData.firstName && typeof permissionData.firstName !== 'undefined' ? "#000000DE" : "red"}}>
                          {permissionData.firstName !== "" && permissionData.firstName && typeof permissionData.firstName !== 'undefined' ? permissionData.firstName.length + "/20" : "0/20"}
                        </Box>
                      </Box>
                    </Box>
                    <Box className="UsersDialog">
                       <Box className="nameTextField" sx={{position:"relative"}}>
                          <TextField
                              disabled={props.disable}
                              label="Last Name *"
                              variant="standard"
                              sx={{width: '100%', margin: '10px 0px', position: 'relative'}}
                              inputProps={{
                                maxlength: 20
                              }}
                              //helperText={portalUserLastNameHelperText()}
                              value={permissionData.lastName}
                              //error={permissionData.lastName === ""}
                              helperText={permissionData.lastName === "" ||  permissionData.lastName ? !isValidName(permissionData.lastName) ? "Invalid Last Name." : null : null}
                              onChange={(event) => {
                                let value = event.target.value;
                                value = value === " " || value.indexOf(" ") === 0 ? checkSpace(value) : value;
                                setUserLastNAme(value);
                                let data = { ...permissionData };
                                data.lastName = value;
                                data.lastNameTextLength =  value !== "" && typeof value !== 'undefined' && value ? value.length + "/20" : "0/20";
                                setPermissionData({ ...data });
                                window.portalUser.lastName = value;
                                checkIsValidDataOrNot();
                              }}
                              onBlur={() => checkIsValidDataOrNot()}
                          />
                          <Box sx={{position:"absolute",left:"94%",top :"60px",fontSize:"12px",color: permissionData.lastName !== "" && permissionData.lastName && typeof permissionData.lastName !== 'undefined' ? "#000000DE" : "red"}}>
                            {permissionData.lastName !== "" && permissionData.lastName && typeof permissionData.lastName !== 'undefined' ? permissionData.lastName.length + "/20" : "0/20"}
                          </Box>
                       </Box>
                    </Box>
                    <Box className="UsersDialog">
                      <Box className="nameTextField"  sx={{position:"relative"}}>
                        <TextField
                            disabled={props.disable || props.button_name === 'Update'}
                            label="Email *"
                            variant="standard"
                            sx={{width: '100%', margin: '10px 0px'}}
                            inputProps={{
                              maxlength: 40
                            }}
                            helperText={props.title === "Create User" ? permissionData.email === "" ||  permissionData.email ? !isValidEmail(permissionData.email) ? "Email is required." : null : null : null}
                            value={permissionData.email}
                            onChange={(event) => {
                              let value = event.target.value;
                              value = value === " " || value.indexOf(" ") === 0 ? checkSpace(value) : value;
                              setUserEmail(value);
                              let data = { ...permissionData };
                              data.email = value;
                              setPermissionData({ ...data });
                              window.portalUser.email = value;
                              checkIsValidDataOrNot();
                            }}
                            onBlur={() => checkIsValidDataOrNot()}
                        />
                        <Box sx={{position:"absolute",left:"94%",top :"60px",fontSize:"12px",color: permissionData.email !== "" && permissionData.email && typeof permissionData.email !== 'undefined' ? "#000000DE" : "red"}}>
                            {permissionData.email !== "" && permissionData.email && typeof permissionData.email !== 'undefined' ? permissionData.email.length + "/40" : "0/40"}
                        </Box>
                      </Box>
                    </Box>
                    <Box className="UsersDialog">
                       <Box style={{display:'flex',flexDirection:'row',position:'relative'}}>
                          <FormControl
                              disabled={props.disable}
                              variant="standard"
                              sx={{width: '35%',margin:'30px 4px 10px 0px',fontSize:'20px'}}
                          >
                            <PhoneInput
                              international
                              defaultCountry={checkDataExistsOrNot(countryFlag) ? countryFlag : ""}
                              onCountryChange = {(val) => {
                                window.portalUser.countryShortCode = val;
                                setCountryFlag(val);
                              }}
                              countryCallingCodeEditable={false}
                              placeholder="Country Code"
                              value={countryCode}
                              onChange={(value) => handleOnChange(value)}
                              onBlur={() => checkIsValidDataOrNot()}
                            />
                          </FormControl>
                          <TextField
                            id="mobileField"
                            label="Mobile Number"
                            variant="standard"
                            sx={{width: '100%', margin: '10px 0px', position: 'relative',left:'-63px',textIndent:'1px'}}
                            value={permissionData.phoneNumber}
                            helperText={permissionData.phoneNumber === "" ||  permissionData.phoneNumber ? !permissionData.phoneNumber.match("^[0-9]+$") ? "Mobile Number is required." : null : null}
                            InputLabelProps = {{shrink:true}}
                            inputProps={{
                              maxlength: 15,
                              style: {textIndent: '62px'},
                            }}
                            onChange={(event) => {
                              let number = event.target.value;
                              setUserMobileNumber(number);
                              let data = { ...permissionData };
                              data.phoneNumber = number;
                              setPermissionData({ ...data });
                              window.portalUser.phoneNumber = number ? number : "";
                              if (!checkDataExistsOrNot(window.portalUser.phoneNumber)) {
                                window.portalUser.countryCode = '';
                                setCountryFlag('');
                                setCountryCode('');
                              }
                              checkIsValidDataOrNot()
                            }}
                            onBlur={() => checkIsValidDataOrNot()}
                          />
                       </Box>
                    </Box>
                    <FormControl
                        disabled={props.disable}
                        variant="standard"
                        sx={{width: '100%', margin: '15px 0px'}}
                    >
                      <InputLabel id="role">Role</InputLabel>
                      <Select
                        id="role"
                        value={userRole}
                        onChange={(event) => {
                          let value = event.target.value;
                          setUserRole(event.target.value);
                          let data = Object.assign({},permissionData);
                          if (value === Enums.globals.SUPER_ADMIN_ROLE){
                            if (sessionStorage.tenantId === Enums.globals.ADMIN_TENANT_ID){
                              permissionData.permissions = allSuperAdminPermissions;
                            }else {
                              permissionData.permissions = allTenantSuperAdminPermissions;
                            }
                          }else if (value === Enums.globals.ADMIN_ROLE){
                            permissionData.permissions = allAdminPermissions;
                          }else if (value === Enums.globals.CUSTOM_ROLE){
                            permissionData.permissions = customPermissionData.permissions;
                          }
                          window.portalUser.permissions = permissionData.permissions;
                          checkIsValidDataOrNot();
                        }}
                        onBlur={() => checkIsValidDataOrNot()}
                        sx={{width: '100%', margin: '10px 0px', textAlign: 'left'}}
                      >
                        <MenuItem value={Enums.globals.SUPER_ADMIN_ROLE}>Super Admin</MenuItem>
                        <MenuItem value={Enums.globals.ADMIN_ROLE}>Admin</MenuItem>
                        { (props.title != "Create User" ) ? <MenuItem value={Enums.globals.CUSTOM_ROLE}>Custom</MenuItem> : null }
                      </Select>
                    </FormControl>
                  </Box>
                  {Dimensions.width > 600 ? <hr style={{margin: '14px'}} /> : null}
                  <Box
                      sx={{
                        width: '100%',
                        overflowY: 'auto',
                        height: Dimensions.width <= 600 ? 'auto' : '350px',
                      }}
                  >
                    <Box>
                      <Box>
                        <p
                            style={{
                              display: Dimensions.width <= 600 ? 'flex' : 'none',
                              fontSize: '16px',
                              fontWeight: 'bold',
                              width: '100%',
                            }}
                        >
                          Select Permissions
                        </p>
                        {sessionStorage.tenantId === Enums.globals.ADMIN_TENANT_ID ?
                          <Box>
                            <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                              Firmware
                            </p>
                            <FormControlLabel
                              disabled={props.disable}
                              control={
                                <Checkbox
                                    checked={permissionData.permissions ? permissionData.permissions.includes("FIRMWARE_WRITE"): false}
                                    onChange={(event) => {
                                      changePermission(event,"FIRMWARE_WRITE");
                                    }}
                                    name="firmwareUpload"
                                    onBlur={() => checkIsValidDataOrNot()}
                                />
                              }
                              label="Upload Firmware"
                            />
                          </Box>
                        : null
                        }
                      </Box>
                      <Box>
                        <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                          Instructions
                        </p>
                        <FormControlLabel
                          disabled={props.disable}
                          control={
                            <Checkbox
                               checked={permissionData.permissions ? permissionData.permissions.includes("GATEWAY_INSTRUCTIONS_WRITE"): false}
                               onChange={(event) => {
                                   changePermission(event,"GATEWAY_INSTRUCTIONS_WRITE");
                               }}
                               name="instructionsEdit"
                               onBlur={() => checkIsValidDataOrNot()}
                            />
                          }
                          label="Edit"
                        />
                      </Box>
                      <Box>
                        <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                          Devices
                        </p>
                        <FormControlLabel
                          disabled={props.disable}
                          control={
                            <Checkbox
                            checked={permissionData.permissions ? permissionData.permissions.includes("IOT_DEVICE_READ"): false}
                               onChange={(event) => {
                                  changePermission(event,"IOT_DEVICE_READ");
                               }}
                               name="deviceView"
                               onBlur={() => checkIsValidDataOrNot()}
                            />
                          }
                          label="View"
                        />
                        <FormControlLabel
                          disabled={props.disable}
                          control={
                            <Checkbox
                               checked={permissionData.permissions ? permissionData.permissions.includes("IOT_DEVICE_WRITE"): false}
                               onChange={(event) => {
                                   changePermission(event,"IOT_DEVICE_WRITE")
                               }}
                               name="deviceEdit"
                               onBlur={() => checkIsValidDataOrNot()}
                            />
                          }
                          label="Edit"
                        />
                      </Box>
                      <Box>
                        <p style={{fontSize: '14px', fontWeight: 'bold'}}>
                          Alerts
                        </p>
                        <FormControlLabel
                          disabled={props.disable}
                          control={
                            <Checkbox
                               checked={permissionData.permissions ? permissionData.permissions.includes("ALERTS_READ"): false}
                               onChange={(event) => {
                                  changePermission(event,"ALERTS_READ");
                               }}
                               name="alertsView"
                               onBlur={() => checkIsValidDataOrNot()}
                            />
                          }
                          label="View"
                        />
                        <FormControlLabel
                          disabled={props.disable}
                          control={
                            <Checkbox
                               checked={permissionData.permissions ? permissionData.permissions.includes("ALERTS_WRITE"): false}
                               onChange={(event) => {
                                  changePermission(event,"ALERTS_WRITE");
                               }}
                               name="alertsEdit"
                               onBlur={() => checkIsValidDataOrNot()}
                            />
                          }
                          label="Edit"
                        />
                      </Box>
                      <Box>
                        <p style={{fontSize:'14px', fontWeight:'bold'}}>
                          Hierarchy
                        </p>
                        <FormControlLabel
                          disabled={props.disable}
                          control={
                            <Checkbox
                               checked={permissionData.permissions ? permissionData.permissions.includes("SCOPE_READ"): false}
                               onChange={(event) => {
                                  changePermission(event,"SCOPE_READ");
                               }}
                               name="hierarchyView"
                               onBlur={() => checkIsValidDataOrNot()}
                            />
                          }
                          label="View"
                        />
                        <FormControlLabel
                          disabled={props.disable}
                          control={
                            <Checkbox
                               checked={permissionData.permissions ? permissionData.permissions.includes("SCOPE_WRITE"): false}
                               onChange={(event) => {
                                  changePermission(event,"SCOPE_WRITE");
                                  changePermission(event,"SCOPE_UPDATE");
                               }}
                               name="hierarchyEdit"
                               onBlur={() => checkIsValidDataOrNot()}
                            />
                          }
                          label="Edit"
                        />
                        <FormControlLabel
                          disabled={props.disable}
                          control={
                            <Checkbox
                               checked={permissionData.permissions ? permissionData.permissions.includes("SCOPE_DELETE"): false}
                               onChange={(event) => {
                                  changePermission(event,"SCOPE_DELETE");
                               }}
                               name="hierarchyDelete"
                               onBlur={() => checkIsValidDataOrNot()}
                            />
                          }
                          label="Delete"
                        />
                      </Box>
                      <Box>
                        <p style={{fontSize:'14px', fontWeight: 'bold'}}>
                          Users
                        </p>
                        <FormControlLabel
                          disabled={props.disable}
                          control={
                            <Checkbox
                               checked={permissionData.permissions ? permissionData.permissions.includes("PORTAL_USER_READ"): false}
                               onChange={(event) => {
                                  changePermission(event,"PORTAL_USER_READ");
                               }}
                               name="portalUsersView"
                               onBlur={() => checkIsValidDataOrNot()}
                            />
                          }
                          label="View"
                        />
                        <FormControlLabel
                          disabled={props.disable}
                          control={
                            <Checkbox
                               checked={permissionData.permissions ? permissionData.permissions.includes("PORTAL_USER_WRITE"): false}
                               onChange={(event) => {
                                 changePermission(event,"PORTAL_USER_WRITE");
                               }}
                               name="portalUsersEdit"
                               onBlur={() => checkIsValidDataOrNot()}
                            />
                          }
                          label="Edit"
                        />
                        <FormControlLabel
                          disabled={props.disable}
                          control={
                            <Checkbox
                               checked={permissionData.permissions ? permissionData.permissions.includes("PORTAL_USER_DELETE"): false}
                               onChange={(event) => {
                                 changePermission(event,"PORTAL_USER_DELETE");
                               }}
                               name="portalUsersDelete"
                               onBlur={() => checkIsValidDataOrNot()}
                            />
                          }
                          label="Delete"
                        />
                        <FormControlLabel
                          disabled={props.disable}
                          control={
                            <Checkbox
                               checked={permissionData.permissions ? permissionData.permissions.includes("CAN_BE_SCOPE_OWNER"): false}
                               onChange={(event) => {
                                 changePermission(event,"CAN_BE_SCOPE_OWNER");
                               }}
                               name="portalUserAdmin"
                               onBlur={() => checkIsValidDataOrNot()}
                            />
                          }
                          label="Eligible to be Root Admin"
                        />
                      </Box>
                      {
                        JSON.parse(sessionStorage.userDetails).isRootUser && JSON.parse(sessionStorage.userDetails).user.scopeId === sessionStorage.tenantId ?
                          <Box>
                            <p style={{fontSize:'14px', fontWeight:'bold'}}>
                              Customers
                            </p>
                            <FormControlLabel
                              disabled={props.disable}
                              control={
                                <Checkbox
                                   checked={permissionData.permissions ? permissionData.permissions.includes("TOP_LEVEL_SCOPE_READ"): false}
                                   onChange={(event) => {
                                       changePermission(event,"TOP_LEVEL_SCOPE_READ");
                                   }}
                                   name="customerView"
                                   onBlur={() => checkIsValidDataOrNot()}
                                />
                              }
                              label="Manage Customers"
                            />
                          </Box>
                        :
                          null
                      }
                    </Box>
                  </Box>
                  {
                    userNetworkLoading ?
                      <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '70%',position: 'absolute',top: '70px',right:'8px'}}>
                          <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                      </Box>
                    :
                      null
                  }
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handlePermissionDialog()}>Cancel</Button>
                {
                  props.title === 'Create User' ?
                    <Button
                      sx={{color:primaryThemeColor}}
                      disabled={!isValidDataOrNot}
                      onClick={() => handleCreatePortalUser()}
                    >
                      Create
                    </Button>
                  :
                    <Button
                      sx={{color:primaryThemeColor}}
                      disabled={!isValidDataOrNot}
                      onClick={() => handleUpdatePortalUser()}
                    >
                      Update
                    </Button>
                }
              </DialogActions>
            </Dialog>
         ) : null}
         {
           showSnackBar ?
           <Snackbar
             anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
             open={showSnackBar}
             autoHideDuration={3000}
             message={showSnackBarMessage}
           />
         :
           null
         }
       </>
    );
};
export default CreatePortalUserDialog;

