import * as React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme, Checkbox, Select, MenuItem, Tooltip, Typography } from '@mui/material';
import { Close,ContentCopy, Visibility, VisibilityOff, KeyboardDoubleArrowRight, ArrowBack} from '@mui/icons-material';
import { Button, FormControl, TextField, InputLabel, Chip, Tab, Tabs } from '@mui/material';
import {ColorPicker} from 'material-ui-color'
import styles from 'stylesheet/users.module.css';
import "stylesheet/common.css";
import _ from "lodash";
import { prominent } from 'color.js'
import { validate } from 'react-email-validator';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot.js';
import { useState } from 'react';
import AuthClient from 'clients/AuthClient.js';
import UserDetailService from 'services/UserDetailService.js';
import { useSnackbar } from 'notistack';
import IotServiceClient from 'clients/IotServiceClient.js';
import moment from 'moment';
import MigrateDialogAlert from './MigrateDialogAlert.js';
import Autocomplete from '@mui/material/Autocomplete';
import countryList from "react-select-country-list";
import HotSpotToggleDialog from './HotSpotToggleDialogAlert.js'
import utils from 'helper-components/utils.js'
import { TabContext,TabPanel } from '@mui/lab';
import MigrateUnregisteredDevice from './MigrateUnregisteredDevice.js';
import PaperComponent from 'helper-components/PaperComponent.js';


export default function InformationDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));    
    const getWindowDimensions = () => {
        const {innerWidth: width, innerHeight: height} = window;
        return {
        width,
        height,
        };
    };
    const [Dimensions, setDimensions] = React.useState(getWindowDimensions());  
    const [macId, setMacId] = React.useState("");
    const [manufacturedDate, setManuFacturedDate] = React.useState("");
    const [merchandiserName, setMerchandiserName] = React.useState("");
    const [street, setStreet] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [zipCode, setZipCode] = React.useState("");
    const [tags, setTags] = React.useState([]);
    const [registerMe, setRegisterMe] = React.useState(props.infoData.REG);
    const [tenantName, setTenantName] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [hotspotPassword, setHotSpotPassword] = React.useState("");
    const [currentTime, setCurrentTime] = React.useState(new Date().getTime());
    const [selectedDate, setSelectedDate] = React.useState(new Date().getTime());
    const [customer, setCustomer] = React.useState('');
    const [allCustomers, setAllCustomers] = React.useState([]);
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
    const [showMigrateAlertDialog, setShowMigrateAlertDialog] = React.useState(false);
    const [unRegisterDevice, setUnRegisterDevice] = React.useState(false);
    const [hotSpotStatus, setHotSpotStatus] = React.useState(props.infoData.HS === 1 ? true : false);
    const [isValid, setIsValid] = React.useState(false);
    const [showHotSpotToggleDialog, setShowHotSpotToggleDialog] = React.useState(false);
    const [hotSpotFlag, setHotSpotFlag] = React.useState(false);
    const [hotspotPwd, setHotspotPwd] = React.useState(checkDataExistsOrNot(props.infoData.HSPWD) ? props.infoData.HSPWD : "");
    const [showHotspotPassword, setShowHotspotPassword] = React.useState(false);
    const [copied, setCopied] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState("info");
    const [currentCustomer, setCurrentCustomer] = React.useState();
    const [changeCustomerOptions, setChangeCustomerOptions] = React.useState([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState();
    const [infoTabFlag, setInfoTabFlag] = React.useState(true);
    const [changeCustomerTabFlag, setChangeCustomerTabFlag] = React.useState(false);
    const [showMigrateUnregisteredDialog,setShowMigrateUnregisteredDialog] = React.useState(false);

    const migrateGateway = () => {
        let deviceData = Object.assign({},props.infoData)

        let migrateData = {
            "deviceId" : deviceData.id,
            "scopeId": customer
        }

        IotServiceClient.migrateGateway(migrateData, "devicePage").then((response) => {
            if (!response.hasOwnProperty("status")){
                if (!unRegisterDevice){
                    enqueueSnackbar("Successfully migrated "+ deviceData.MAC + " device");
                }
            }else {
                enqueueSnackbar("Failed to migrate "+ deviceData.MAC + " device");
            }
        })

        registerForMigrationOrHotSpot(unRegisterDevice, "fromMigration");
    }

    const registerForMigrationOrHotSpot = (booleanValue, fromValue) => {
        let deviceData = Object.assign({},props.infoData)
        if (unRegisterDevice){
            let payload = {
                "MAC" : deviceData.MAC,
                "NAME": merchandiserName,
                "SID": customer,
                "CRTD" : selectedDate,
                "REG": 1,
                "HS" : fromValue === "fromMigration" ? hotSpotStatus === true ? 1 : 0 : hotSpotStatus === true ? 0 : 1,
                "LCTN" : {
                    "CNTRY": country,
                    "CTY": city,
                    "ZCODE": zipCode,
                    "STATE": state,
                    "SRT": street,                    
                }                
            }
            
            if (checkDataExistsOrNot(deviceData.LCTN)){
                payload.LCTN.LNG =  deviceData.LCTN.LNG;
                payload.LCTN.LAT =  deviceData.LCTN.LAT;
            }

            if (props.infoData.REG !== payload.REG){
                let portalEventsPayload = {
                    "MAC" : deviceData.MAC,
                    "TI": new Date().getTime(),            
                    "SE" : [{
                        "nme": "Gateway Register Initiated",
                        "val": JSON.parse(sessionStorage.userDetails).user.email
                    }]    
                }
                IotServiceClient.savePortalEveents(portalEventsPayload).then((res)=>{});
            }

            if (props.infoData.HS !== (hotSpotStatus === true ? 1 : 0) ){
                let portalEventsPayload = {
                    "MAC" : deviceData.MAC,
                    "TI": new Date().getTime(),            
                    "SE" : [{
                        "nme": "Hotspot Status Initiated",
                        "val": JSON.parse(sessionStorage.userDetails).user.email,
                        "val2": props.infoData.HS === 0 ? "OFF -> ON" : "ON -> OFF"
                    }]    
                }
                IotServiceClient.savePortalEveents(portalEventsPayload).then((res)=>{});
            }

            if(deviceData.REG === 1){
              delete payload.CRTD;
            }

            IotServiceClient.registerDevice(payload,"devicePage").then((res)=>{
                if (res.status === 200){
                    if(fromValue === "fromMigration"){
                        enqueueSnackbar("Successfully Registered and Migrated"+ deviceData.MAC + " device");
                    }else if(fromValue === "fromHotSpot"){
                        IotServiceClient.updateHotSpotStatusToProcessing(props.infoData.MAC).then((response) => {
                            if(response.status >= 200 && response.status< 300){
                                enqueueSnackbar("Successfully published the instruction.")
                            }else{
                                enqueueSnackbar("Failed to publish the instruction.")
                            }
                        })
                        if(deviceData.REG === 0){
                            enqueueSnackbar("Successfully Registered"+ deviceData.MAC + " device");
                        }else{
                            enqueueSnackbar("Successfully updated details for"+ deviceData.MAC + " device");
                        }
                    }
                }else {
                    if(fromValue === "fromHotSpot"){
                        IotServiceClient.updateHotSpotStatusToProcessing(props.infoData.MAC).then((response) => {
                            if(response.status >= 200 && response.status< 300){
                                enqueueSnackbar("Successfully published the instruction.")
                            }else{
                                enqueueSnackbar("Failed to publish the instruction.")
                            }
                        })
                    }
                    if(deviceData.REG === 0){
                        enqueueSnackbar("Failed to register "+ deviceData.MAC + " device");
                    }else{
                        enqueueSnackbar("Failed to update details for"+ deviceData.MAC + " device");
                    }
                }
            })
        }
        setUnRegisterDevice(false);
    }

    const registerDevice = () => {
        let deviceData = Object.assign({},props.infoData)        
        let alertDialogShowed = false;
        let payload = {
            "tags": tags,
            "MAC" : deviceData.MAC,
            "NAME": merchandiserName,
            "SID": customer,
            "CRTD" : selectedDate,
            "REG": 1,
            "HS" : hotSpotStatus === true ? 1 : 0,
            "LCTN" : {
                "CNTRY": country,
                "CTY": city,
                "ZCODE": zipCode,
                "STATE": state,
                "SRT": street,
            }
        }

        if (checkDataExistsOrNot(deviceData.LCTN)){
            payload.LCTN.LNG =  deviceData.LCTN.LNG;
            payload.LCTN.LAT =  deviceData.LCTN.LAT;
        }

         if(deviceData.CS === 1 && deviceData.HS !== (hotSpotStatus === true ? 1 : 0)){
             setUnRegisterDevice(true);
             if(deviceData.REG === 0){
                setHotSpotFlag(true);
                alertDialogShowed = true;
                setShowHotSpotToggleDialog(true);
             }else if(deviceData.REG === 1) {
                alertDialogShowed = true;
                setShowHotSpotToggleDialog(true);
             }
        }else{
            if (props.infoData.REG === 1 && props.infoData.SID !== customer){
                alertDialogShowed = true;
                setShowMigrateAlertDialog(true);
            }else if(props.infoData.REG === 1 && props.infoData.SID === customer) {
                /*if (props.infoData.HS !== payload.HS ){
                    let portalEventsPayload = {
                        "MAC" : deviceData.MAC,
                        "TI": new Date().getTime(),
                        "SE" : [{
                            "nme": "Hotspot Status Initiated",
                            "val": JSON.parse(sessionStorage.userDetails).user.email,
                            "val2": props.infoData.HS === 0 ? "OFF -> ON" : "ON -> OFF"
                        }]
                    }
                    IotServiceClient.savePortalEveents(portalEventsPayload).then((res)=>{});
                }*/
                IotServiceClient.registerDevice(payload,"devicePage").then((response)=>{
                    if (response.status === 200){
                        enqueueSnackbar("Successfully updated details for "+ deviceData.MAC + " device");
                    }else {
                        enqueueSnackbar("Failed to updated details for "+ deviceData.MAC + " device");
                    }
                })
            }

            if (deviceData.REG === 0 && props.infoData.SID === customer){
                if (props.infoData.REG !== payload.REG){
                    let portalEventsPayload = {
                        "MAC" : deviceData.MAC,
                        "TI": new Date().getTime(),
                        "SE" : [{
                            "nme": "Gateway Register Initiated",
                            "val": JSON.parse(sessionStorage.userDetails).user.email
                        }]
                    }
                    IotServiceClient.savePortalEveents(portalEventsPayload).then((res)=>{});
                }

                /*if (props.infoData.HS !== payload.HS ){
                    let portalEventsPayload = {
                        "MAC" : deviceData.MAC,
                        "TI": new Date().getTime(),
                        "SE" : [{
                            "nme": "Hotspot Status Initiated",
                            "val": JSON.parse(sessionStorage.userDetails).user.email,
                            "val2": props.infoData.HS === 0 ? "OFF -> ON" : "ON -> OFF"
                        }]
                    }
                    IotServiceClient.savePortalEveents(portalEventsPayload).then((res)=>{});
                }*/

                IotServiceClient.registerDevice(payload,"devicePage").then((response)=>{
                    if (response.status === 200){
                        enqueueSnackbar("Successfully Registered "+ deviceData.MAC + " device");
                    }else {
                        enqueueSnackbar("Failed to register "+ deviceData.MAC + " device");
                    }
                })
            }else if (deviceData.REG === 0 && props.infoData.SID !== customer) {
                alertDialogShowed = true;
                setUnRegisterDevice(true);
                setShowMigrateAlertDialog(true);
            }
        }

        if (!alertDialogShowed){
            props.closeDialog();
        }
    }

    const copyText = () => {
        var range = document.createRange();
        range.selectNode(document.getElementById("HSP"));
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy");
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        },1500);
     }

   const checkRegExp = (value) => {
     let regex = new RegExp("^[0-9a-zA-Z-_;#@]+$");
      return regex.test(value);
   }

   const handleAddTags = (event,value) => {
     let newTag = event.target.value;
     if (!checkDataExistsOrNot(newTag)) {
        setTags(value);
        window.infoData.tags = value;
        setIsValid(checkForValidity());
     } else if (checkRegExp(newTag) && tags.indexOf(newTag) === -1) {
        setTags(value);
        window.infoData.tags = value;
        setIsValid(checkForValidity());
     }
   }

    const toggleHotSpotStatus = () => {
        registerForMigrationOrHotSpot(unRegisterDevice, "fromHotSpot");
        /*IotServiceClient.updateHotSpotStatusToProcessing(props.infoData.MAC).then((response) => {
            if(response.status >= 200 && response.status< 300){
                enqueueSnackbar("Successfully published the instruction.")
            }else{
                enqueueSnackbar("Failed to publish the instruction.")
            }
        })*/
    }


    React.useEffect(() => {
        fetchData();
        setTimeout(() => {
            if (props.infoData) {
                let data = props.infoData;
                setMacId(checkDataExistsOrNot(data.MAC) ? data.MAC.toUpperCase().replaceAll(":", "") : null);
                setManuFacturedDate(checkDataExistsOrNot(data.CRTD) ? data.CRTD : null)
                setMerchandiserName(data.NAME);
                setStreet(checkDataExistsOrNot(data.LCTN) ? data.LCTN.SRT : null);
                setCity(checkDataExistsOrNot(data.LCTN) ? data.LCTN.CTY : null)
                setState(checkDataExistsOrNot(data.LCTN) ? data.LCTN.STATE : null)
                setCountry(checkDataExistsOrNot(data.LCTN) ? checkDataExistsOrNot(data.LCTN.CNTRY) ? data.LCTN.CNTRY : null : null)
                setZipCode(checkDataExistsOrNot(data.LCTN) ? data.LCTN.ZCODE : null)
                setTags(checkDataExistsOrNot(data.tags) ? data.tags : []);
                setRegisterMe(data.REG);
                setHotSpotPassword(data.HSPWD);
                setTenantName(sessionStorage.tenantName);
                setHotSpotStatus(data.HS === 1 ? true : false);
            }
            window.infoData = {
                        macId : null,
                        manuFacturedDate : null,
                        merchandiserName : props.infoData.NAME,
                        customer : props.infoData.SID,
                        street : checkDataExistsOrNot(props.infoData.LCTN) ? props.infoData.LCTN.SRT : null,
                        city : checkDataExistsOrNot(props.infoData.LCTN) ? props.infoData.LCTN.CTY : null,
                        state : checkDataExistsOrNot(props.infoData.LCTN) ? props.infoData.LCTN.STATE : null,
                        country : checkDataExistsOrNot(props.infoData.LCTN) ? props.infoData.LCTN.CNTRY : null,
                        zipCode : checkDataExistsOrNot(props.infoData.LCTN) ? props.infoData.LCTN.ZCODE : null,
                        registerMe : null,
                        hotSpotStatus : props.infoData.HS,
                        tags : checkDataExistsOrNot(props.infoData.tags) ? props.infoData.tags : []
            }
            setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
            setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
        }, 1000);
    },[])

    const fetchData = () => {
       let customersData = [];
       let CustomersDataForMigration = [];
       AuthClient.getHirarchy().then((data) => {
          customersData.push({"name": data["scope"].name , "scopeId" : data["scope"].id})
          data["children"].forEach((ele) => {
            customersData.push({"name": ele["scope"].name , "scopeId" : ele["scope"].id})
          });
          setAllCustomers(customersData);
          setCustomer(sessionStorage.tenantId);
          _.forEach(customersData, (value) => {
             if (value.scopeId === props.infoData.SID) {
                setCurrentCustomer(value.name);
             } else {
                CustomersDataForMigration.push({"name": value.name , "scopeId": value.scopeId});
             }
          });
          setChangeCustomerOptions(CustomersDataForMigration);
       });
    }

    const handleManufacturedDateChange = (newValue) => {
       window.infoData.manuFacturedDate = new Date(newValue + " 00:00").getTime();
       setSelectedDate(new Date(newValue + " 00:00").getTime());
       checkDataExistsOrNot()
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        if (newValue === "changeCustomer") {
          setChangeCustomerTabFlag(true);
          setInfoTabFlag(false);
        } else if (newValue === "info") {
          setInfoTabFlag(true);
          setChangeCustomerTabFlag(false);
        }
    }

    const migrateUnregisteredDevice = () => {
        setShowMigrateUnregisteredDialog(true);
    }

    const backToInfoTab = () => {
        setSelectedTab("info");
        setChangeCustomerTabFlag(false);
        setInfoTabFlag(true);
    }

    const checkSpace = (name) => {
       if(name === " " || name.indexOf(" ") === 0){
         if(name.length <= 1){
           return "";
         }else{
           return name ? name.trim() : "";
         }
       }
       return name;
    }

    const checkWithOldValue = (newValue, oldValue) => {
        return newValue === oldValue ? false : true;
    }

    const checkForValidity = () => {
        if(props.infoData.REG === 0){
            if(checkDataExistsOrNot(merchandiserName) && checkDataExistsOrNot(selectedDate)){
                return true;
            }
            return false;
        }else{
            return checkDataExistsOrNot(window.infoData.merchandiserName) ? ((checkDataExistsOrNot(props.infoData.LCTN) ?
                     checkDataExistsOrNot(props.infoData.LCTN.STATE) ?
                                                 checkWithOldValue(window.infoData.state , props.infoData.LCTN.STATE) :
                                                    checkDataExistsOrNot(window.infoData.state) : checkDataExistsOrNot(window.infoData.state)) ||
                   (checkDataExistsOrNot(props.infoData.LCTN) ?
                                        checkDataExistsOrNot(props.infoData.LCTN.SRT) ?
                                                                    checkWithOldValue(window.infoData.street , props.infoData.LCTN.SRT) :
                                                                       checkDataExistsOrNot(window.infoData.street) : checkDataExistsOrNot(window.infoData.street)) ||
                   (checkDataExistsOrNot(props.infoData.LCTN) ?
                                        checkDataExistsOrNot(props.infoData.LCTN.CNTRY) ?
                                                                    checkWithOldValue(window.infoData.country , props.infoData.LCTN.CNTRY) :
                                                                       checkDataExistsOrNot(window.infoData.country) : checkDataExistsOrNot(window.infoData.country)) ||
                   (checkDataExistsOrNot(props.infoData.LCTN) ?
                                        checkDataExistsOrNot(props.infoData.LCTN.ZCODE) ?
                                                                    checkWithOldValue(window.infoData.zipCode , props.infoData.LCTN.ZCODE) :
                                                                       checkDataExistsOrNot(window.infoData.zipCode) : checkDataExistsOrNot(window.infoData.zipCode)) ||
                   (checkDataExistsOrNot(props.infoData.LCTN) ?
                                        checkDataExistsOrNot(props.infoData.LCTN.CTY) ?
                                                                    checkWithOldValue(window.infoData.city , props.infoData.LCTN.CTY) :
                                                                       checkDataExistsOrNot(window.infoData.city) : checkDataExistsOrNot(window.infoData.city)) ||
                   checkWithOldValue(window.infoData.merchandiserName , props.infoData.NAME) ||
                   checkWithOldValue(window.infoData.customer, props.infoData.SID) ||
                   checkWithOldValue(window.infoData.tags.toString(), props.infoData.tags.toString()) ||
                   (props.infoData.CS === 1 ? checkWithOldValue(window.infoData.hotSpotStatus, props.infoData.HS) : false)) : false
        }
    }

    return (
      <>
         <Dialog
            fullWidth={true}
            fullScreen={fullScreen}
            open={props.openDialog}
            onClose={() => props.closeDialog()}
            PaperComponent={Dimensions.width >= 600 ? PaperComponent : null}
            aria-labelledby="draggable-dialog-title"
            >
            <DialogTitle style={{
                background: primaryThemeColor,
                display: 'flex',
                flexDirection: 'row',
                color: 'white',
            }}>
                <Box sx={{flex: '95'}}>Gateway information</Box>
                <Box sx={{flex: '5', marginTop: '6px'}}>
                    <Close onClick={()=>props.closeDialog()}/>
                </Box>
            </DialogTitle>
            <TabContext value={selectedTab}>
              <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  TabIndicatorProps={{style: {background: primaryThemeColor}}}
                >
                  <Tab
                    label="Info"
                    value="info"
                    sx={{color: primaryThemeColor, '&.Mui-selected': {color: primaryThemeColor}}}
                  />
                  {
                    props.infoData.REG === 0 ?
                      <Tab
                        label="Change Customer"
                        value="changeCustomer"
                        sx={{color: primaryThemeColor, '&.Mui-selected': {color: primaryThemeColor}}}
                      />
                    : null
                  }
                </Tabs>
              </Box>
              <TabPanel value="info" sx={{padding:"0px"}}>
                 <DialogContent sx={{fontSize:"18px !important",paddingTop:"20px !important"}}>
                    <Box className="paddingBottom" sx={{position:"relative",marginBottom:'24px'}}>
                        <TextField
                            disabled={true}
                            inputProps={{ maxLength: 20 }}
                            label="MacId *"
                            variant="standard"
                            sx={{width: '100%'}}
                            value={macId}
                        />
                    </Box>

                    <Box className="paddingBottom" sx={{position:"relative",marginBottom:'24px'}}>
                        <TextField
                            inputProps={{ maxLength: 40 }}
                            label="Gateway Name *"
                            variant="standard"
                            sx={{width: '100%'}}
                            value={merchandiserName}
                            onChange={(event) => {
                                let name = event.target.value;
                                name = name === " " || name.indexOf(" ") === 0 ? checkSpace(name) : name;
                                window.infoData.merchandiserName = name;
                                setMerchandiserName(name);
                                setIsValid(checkForValidity())
                            }}
                        />
                    </Box>

                    <Box className="nameTextField " style={{marginBottom:'24px',marginTop:"-10px"}}>
                        <Box className="firstName paddingBottom" sx={{position:'relative'}}>
                            <TextField
                                type="date"
                                label="Gateway Manufactured date *"
                                variant="standard"
                                sx={{width: '100%'}}
                                disabled={props.infoData.REG === 1}
                                value={props.infoData.REG === 1 ? moment(new Date(props.infoData.CRTD)).format("yyyy-MM-DD") : moment(new Date(selectedDate)).format("yyyy-MM-DD")}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  min: "2022-01-01",
                                  max: moment(new Date()).format("yyyy-MM-DD")
                                }}
                                onChange={(event) => {handleManufacturedDateChange(event.target.value)}}
                            />
                        </Box>
                        <Box className="firstName paddingBottom" sx={{position:'relative',marginRight:"0px"}}>
                          <FormControl
                              variant="standard"
                              sx={{width: '100%', margin: '10px', marginTop: '0px'}}
                          >
                             <InputLabel id="selectCustomer" sx={{margin: '0px'}}>
                                Register to customer
                             </InputLabel>
                             <Select
                                id="selectCustomer"
                                value={customer}
                                MenuProps={{PaperProps: {style: {maxHeight: '150px'}}}}
                                onChange={(event) => { window.infoData.customer = event.target.value; setCustomer(event.target.value); setIsValid(checkForValidity())}}
                                style={{
                                  width:'100%',
                                  marginTop:'16px',
                                  textAlign:'left'
                                }}
                             >
                               {
                                Object.keys(allCustomers).length > 0 ?
                                   (Object.values(allCustomers).sort((first, next) =>
                                     first.name.localeCompare(next.name)
                                   )).map((element, index) => {
                                       return <MenuItem value={element.scopeId}>{element.name}</MenuItem>;
                                   })
                               :
                                 null
                               }
                             </Select>
                          </FormControl>
                        </Box>
                    </Box>

                    <Box className="nameTextField " style={{marginBottom:'24px',marginTop:"30px"}}>
                        <Box className="firstName paddingBottom" sx={{position:'relative'}}>
                            <TextField
                                inputProps={{ maxLength: 40 }}
                                label="Street"
                                variant="standard"
                                sx={{width: '100%'}}
                                value={street}
                                onChange={(event) => {
                                 let value = event.target.value;
                                 value = checkSpace(value);
                                 window.infoData.street = value;
                                 setStreet(value);
                                 setIsValid(checkForValidity());
                                }}
                            />
                        </Box>
                        <Box className="firstName paddingBottom" sx={{position:'relative'}}>
                            <TextField
                                inputProps={{ maxLength: 40 }}
                                label="City"
                                variant="standard"
                                sx={{width: '100%'}}
                                value={city}
                                onChange={(event) => {
                                 let cityName = event.target.value;
                                 cityName = checkSpace(cityName);
                                 window.infoData.city = cityName;
                                 setCity(cityName)
                                 setIsValid(checkForValidity());
                                }}
                            />
                        </Box>
                    </Box>

                    <Box className="nameTextField " style={{marginBottom:'24px',marginTop:"30px"}}>
                        <Box className="firstName paddingBottom" sx={{position:'relative'}}>
                            <TextField
                                inputProps={{ maxLength: 40 }}
                                label="State/Province"
                                variant="standard"
                                sx={{width: '100%'}}
                                value={state}
                                onChange={(event) => {
                                 let stateName = event.target.value;
                                 stateName = checkSpace(stateName);
                                 window.infoData.state = stateName;
                                 setState(stateName)
                                 setIsValid(checkForValidity());
                                }}
                            />
                        </Box>
                        <Box className="firstName paddingBottom" sx={{position:'relative'}}>
                            <Autocomplete
                              id="country-select-demo"
                              sx={{ width: 200 }}
                              options={countryList().getData()}
                              autoHighlight
                              getOptionLabel={(option) => option.label}
                              defaultValue={checkDataExistsOrNot(props.infoData.LCTN) && checkDataExistsOrNot(props.infoData.LCTN.CNTRY) ? countryList().getData().find(country => country.label === props.infoData.LCTN.CNTRY) : null}
                              onChange = {(event, value) => {
                                   let countryName = value != null ? value.label : "" ;
                                   window.infoData.country = countryName;
                                   setCountry(countryName);
                                   setIsValid(checkForValidity());
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                                    alt=""
                                  />
                                  {option.label}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Choose a country"
                                  variant="standard"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                        </Box>
                        <Box className="firstName paddingBottom" sx={{position:'relative'}}>
                            <TextField
                                inputProps={{ maxLength: 20 }}
                                label="ZIP/Postal Code"
                                variant="standard"
                                sx={{width: '100%'}}
                                value={zipCode}
                                onChange={(event) => {
                                 let zipCodeNo = event.target.value;
                                 zipCodeNo = checkSpace(zipCodeNo);
                                 window.infoData.zipCode = zipCodeNo;
                                 setZipCode(zipCodeNo)
                                 setIsValid(checkForValidity());
                                }}
                            />
                        </Box>
                    </Box>
                    <Box className="paddingBottom" sx={{position:"relative",marginBottom:'24px'}}>
                        <Autocomplete
                              multiple
                              options={[]}
                              freeSolo
                              value={tags}
                              onChange={(event, value) => handleAddTags(event,value)}
                              renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                      <Chip
                                          variant="outlined"
                                          label={option}
                                          {...getTagProps({index})}
                                      />
                                  ))
                              }
                              renderInput={(params) => (
                                  <TextField id="tags" {...params} sx={{width: '100%'}} variant="standard" placeholder="Search or add a tag" />
                              )}
                        />
                    </Box>
                    <Box sx={{display:"flex", flexDirection:"column"}}>
                        <Box>
                            <Typography>
                                After typing each tag name, press 'return' key.Tags may contain letters, numbers and symbols _-;#@
                            </Typography>
                        </Box>
                        <Box sx={{marginTop: "10px"}}>
                           <Typography sx={{display: "flex",flexDirection: "row"}}>
                                Gateway Cellular HotSpot
                              &nbsp;
                              <Checkbox
                                disabled={props.infoData.CS !== 1 || props.infoData.HSIPRC === 1}
                                checked={hotSpotStatus}
                                onChange={(event) => {window.infoData.hotSpotStatus = event.target.checked === true ? 1 : 0 ; setHotSpotStatus(event.target.checked); setIsValid(checkForValidity())}}
                                color="primary"
                                inputProps={{
                                    color:"#4190c7"
                                }}
                                sx={{margin: '0px', padding: '0px', position:"relative", top:"-2px"}}
                              />
                           </Typography>
                        </Box>
                        <Box sx={{marginTop: "10px"}}>
                           <Typography className="nameTextField" sx={{display: "flex"}}>
                              <Button className="paswdButton" variant="contained" size="small" sx={{
                                 '&.MuiButtonBase-root:hover': {background: primaryThemeColor},
                                  backgroundColor:primaryThemeColor, marginLeft : "0px", marginTop: "5px"
                              }} onClick={() => setShowHotspotPassword(!showHotspotPassword)}>
                                 Factory Password { showHotspotPassword === true ? <Visibility style={{marginLeft: "5px",fontSize: "20px"}}/>
                                  : <VisibilityOff style={{marginLeft: "5px",fontSize: "20px"}}/> }
                              </Button>
                              <Box className="passwdField" sx={{display:"flex", flexDirection:"row"}}>
                                   <Typography className="subPasswd" sx={{display: showHotspotPassword === true ? "block" : "none", backgroundColor: "lightgrey",height: "34px"}}>
                                      <span id="HSP" style={{padding: "5px",display:"flex",justifyContent: "center",fontSize: "16px",fontWeight: "bold"}}>
                                        {hotspotPwd}
                                      </span>
                                   </Typography>
                                   <Tooltip title={copied === true ? 'Copied': 'Click to Copy'} placement='top' arrow>
                                      <ContentCopy sx={{display: showHotspotPassword === true ? "block" : "none",marginLeft: "12px",color: "grey",cursor:'pointer',marginTop: "5px"}}
                                       onClick={() => copyText()}/>
                                   </Tooltip>
                              </Box>
                           </Typography>
                        </Box>
                    </Box>
                 </DialogContent>
              </TabPanel>
              <TabPanel value="changeCustomer" sx={{padding:"0px"}}>
                <DialogContent sx={{fontSize:"18px !important",paddingTop:"20px !important"}}>
                    <Box sx={{display:"flex"}}>
                      <Box sx={{flex:"5"}}></Box>
                      <Box sx={{display:"flex",flex:"85",flexDirection:"row"}}>
                        <Box sx={{flex:"30"}}>
                            <TextField
                              disabled={true}
                              label="Current Customer"
                              variant="standard"
                              sx={{width: '100%'}}
                              value={currentCustomer}
                            />
                        </Box>
                        <Box sx={{flex:"5"}}>
                          <KeyboardDoubleArrowRight sx={{margin:"14px",color:primaryThemeColor}}/>
                        </Box>
                        <Box sx={{flex:"30"}}>
                          <FormControl
                            variant="standard"
                            sx={{width: '100%', margin: '10px', marginTop: '0px'}}
                          >
                            <InputLabel id="selectCustomer" sx={{margin: '0px'}}>
                              Select New Customer
                            </InputLabel>
                            <Select
                              id="selectCustomer"
                              MenuProps={{PaperProps: {style: {maxHeight: '150px'}}}}
                              value={checkDataExistsOrNot(selectedCustomer) ? selectedCustomer : null}
                              onChange={(event) => {
                                setSelectedCustomer(event.target.value);
                              }}
                              style={{
                                width:'100%',
                                marginTop:'16px',
                                textAlign:'left'
                              }}
                            >
                              {
                                Object.keys(changeCustomerOptions).length > 0 ?
                                  (Object.values(changeCustomerOptions).sort((first,next) =>
                                    first.name.localeCompare(next.name)
                                  )).map((element, index) => {
                                    return <MenuItem value={element}>{element.name}</MenuItem>
                                  })
                                :
                                  null
                              }
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box sx={{flex:"10"}}></Box>
                    </Box>
                </DialogContent>
              </TabPanel>
            </TabContext>
            <DialogActions>
                <>
                    { changeCustomerTabFlag ?
                        <Tooltip placement="top" title="Go Back To Info Tab" arrow>
                            <Button sx={{marginRight:"auto"}} onClick={() => backToInfoTab()}><ArrowBack/></Button>
                        </Tooltip>
                    :   null
                    }
                    <Button onClick={() => {props.closeDialog()}}>
                        cancel
                    </Button>                                  
                    { infoTabFlag ?
                        <Button sx={{color:primaryThemeColor}} disabled={ props.infoData.REG === 0 ? !checkForValidity() : !isValid } onClick={() => registerDevice()}>
                          submit
                        </Button>
                    :
                        <Button sx={{color:primaryThemeColor}} disabled={!checkDataExistsOrNot(selectedCustomer)} onClick={() => migrateUnregisteredDevice()}>submit</Button>
                    }
                </>
            </DialogActions>
            {
                showMigrateAlertDialog ?
                    <MigrateDialogAlert
                        close={()=>setShowMigrateAlertDialog(false)}
                        migrateGateway={() => migrateGateway()}
                        closeDialog={()=> {props.closeDialog();setUnRegisterDevice(false)}}
                        unRegisterFlag={unRegisterDevice}
                    />
                :
                    null
            }
            {
                showHotSpotToggleDialog ?
                    <HotSpotToggleDialog
                        openDialog={showHotSpotToggleDialog}
                        hotSpotFlag={hotSpotFlag}
                        toggleState={hotSpotStatus === true ? "Enable" : "Disable"}
                        toggleHotSpotStatus={() => toggleHotSpotStatus()}
                        close={() => setShowHotSpotToggleDialog(false)}
                        closeDialog={() => {props.close(); setUnRegisterDevice(false); setHotSpotFlag(false)}}
                    />
                :
                    null
            }
            {
                showMigrateUnregisteredDialog ?
                    <MigrateUnregisteredDevice
                        openDialog={showMigrateUnregisteredDialog}
                        close={() => setShowMigrateUnregisteredDialog(false)}
                        closeDialog={() => props.closeDialog()}
                        deviceData={props.infoData}
                        customerData={selectedCustomer}
                    />
                :
                    null
            }
        </Dialog>
      </>
    )
}
