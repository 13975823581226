var UserSessionStorage = (function() {
    var tenantName = "";
    var mqttPahoClient = window.client;
    var mqttStatus = false;
    
    var setMqttStatus = function (status) {
      mqttStatus = status
    } 

    var getMqttStatus = function () {
      return mqttStatus;
    }

    var getTenantName = function() {
      return tenantName;    
    };
  
    var setTenantName = function(name) {
      tenantName = name;
    };

    var setClient = function (pahoClient) {
      mqttPahoClient = pahoClient;
    } 

    var getClient = () => {
      return mqttPahoClient;
    }
  
    return {
      getTenantName: getTenantName,
      getClient: getClient,
      getMqttStatus: getMqttStatus,

      setTenantName: setTenantName,
      setClient: setClient,
      setMqttStatus: setMqttStatus
    }
  
  })();
  
  export default UserSessionStorage;