import React from 'react';
import { Button,Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow,TableSortLabel,Box,Checkbox,Select,MenuItem,Tooltip } from '@mui/material';
import { Edit,Close,Search,Refresh,FirstPage,LastPage,KeyboardArrowLeft,KeyboardArrowRight,VerifiedUser,MailOutline,Delete,Add } from '@mui/icons-material';
import {visuallyHidden} from '@mui/utils';
import 'stylesheet/DashboardGatewayPage.css';
import DraggableComponent from '../DraggableComponent';
import CreateAlert from './CreateNewAlert.js';
import 'stylesheet/DashBoardUsersPage.css';
import paginationStyle from "stylesheet/pagination.module.css";
import "stylesheet/common.css";
import AuthClient from "clients/AuthClient";
import IotServiceClient from "clients/IotServiceClient";
import JobSchedulerClient from "clients/JobSchedulerClient";
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import PubSubChannels from 'pubsub/PubSubChannels';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot.js';
import ConfigureAlert from './ConfigureAlert.js';

function TablePaginationActions(props) {
  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className="navigationIcons">
      <FirstPage
        onClick={() => {
          if (page > 0) handleFirstPageButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowLeft
        onClick={() => {
          if (page > 0) handleBackButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowRight
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleNextButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
            marginRight: '8px'
        }}
      />
      <LastPage
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleLastPageButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
        }}
      />
    </div>
  );
}

function EnhancedTableHead(props) {
  const {onSelectAllClick, order, orderBy, onRequestSort} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [changeTextColor,setChangeTextColor] = React.useState('');
  const headCells = [
    {
      id: 'alertType',
      numeric: false,
      disablePadding: false,
      label: 'Title',
      width: '120px'
    },
    {
      id: 'intervalTime',
      numeric: false,
      disablePadding: false,
      label: 'Users',
      width: '90px'
    },
    {/*{
      id: 'alertTriggers',
      numeric: false,
      disablePadding: false,
      label: 'Targets',
      width: '120px'
    },
    {
      id: 'intervalTime',
      numeric: false,
      disablePadding: false,
      label: 'Users',
      width: '90px'
    },
    {
      id: 'receiversLength',
      numeric: true,
      disablePadding: false,
      label: 'Status',
      width: '90px'
    },
    {
      id: 'Action',
      numeric: false,
      disablePadding: false,
      label: '',
      width: '90px'
    },*/}
  ];

  const [clickCount, setClickCount] = React.useState(1);


  const selectAll = (event) => {
    setClickCount(clickCount + 1);
    onSelectAllClick(clickCount);
  };


  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            {headCell.label !== '' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  width: headCell.width,
                  overflowWrap: 'break-word',
                  color: orderBy !== headCell.id ? '#0000008A !important' : '#000000DE !important',
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Box
                sx={{
                  width: headCell.width,
                  overflowWrap: 'break-word',
                  color: changeTextColor !== '' ? '#0000008A !important' : '#000000DE !important',
                }}
              >
                {headCell.label}
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Alerts() {
  const [SearchBar, setSearchBar] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(sessionStorage.dashboardAlertRPP ? Number(sessionStorage.dashboardAlertRPP) : 10);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [editMode, setEdiitMode] = React.useState(false);
  const [showEditIcon, setShowEditIcon] = React.useState(true);
  const [searchText, setSearchText] = React.useState('');
  const [checkAll, setCheckAll] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [iotDevices, setIotDevices] = React.useState([]);
  const [alertsListData,setAlertListData] = React.useState([]);
  const [PortalUsersData, setPortalUserdData] = React.useState([]);
  const alertList = [];
  const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
  const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
  const [refresh,setRefresh] = React.useState(true)
  const { enqueueSnackbar } = useSnackbar();
  const [portalUserData, setPortalUserData] = React.useState([]);
  const[selectedUsers, setSelectedUsers] = React.useState([]);

  const fetchAlerts = (isMqttEvent) => {
    let res = [];
    let isRefreshingWithFilter = searchText.length > 0;
    AuthClient.getPortalUsers(sessionStorage.tenantId, "alertsPage").then((portalUserData) => {
      _.forEach(portalUserData,(element)=>{
        element.mergedName = element.mergedName = element.firstName ? (element.lastName ? element.firstName+ " " +element.lastName : element.firstName) : (element.lastName ? element.lastName : "No Name");
        element.email = element.email ? element.email : 'No-email';
      })

      portalUserData = portalUserData.sort((first,second) => first.mergedName.localeCompare(second.mergedName));
      setPortalUserdData(portalUserData);
    })
    // Network call for fetching all devices
     IotServiceClient.getReachableIotDevices(sessionStorage.tenantId,"Alerts").then((devicesData) => {
        setIotDevices(devicesData);
     });

    JobSchedulerClient.getAlerts(sessionStorage.tenantId, "alertsPage").then((data) => {
      res = data;
      let filteredData = [];
      if (!res.hasOwnProperty("status")) {
          _.forEach(res, (element) => {
            element.alertTypeName = element.alertType === "HIGH_CPU" ? "CPU ALERT" : element.alertType === "HIGH_MEMORY_USAGE" ? "MEMORY USAGE ALERT" : "CAMERA MODULE ALERT";
            element.receiversLength = element.receivers.length > 1 ? element.receivers.length + " Users" : element.receivers.length + " User" ;
            element.frequencyTime = element.alertType === 'UNLOCK' ? "-" : element.alertType === "RFID" ? (Number(element.rfidScheduleTime) / 3600000 === 1 ? '1 hr' : (Number(element.rfidScheduleTime) / 3600000 )% 24 === 0 ?  (Number(element.rfidScheduleTime) / 3600000 ) / 24 + (Number(element.rfidScheduleTime) / 3600000 ) / 24 === 1 ? (Number(element.rfidScheduleTime) / 3600000 ) / 24 + ' day' : (Number(element.rfidScheduleTime) / 3600000 ) / 24+" days": Number(element.rfidScheduleTime) / 3600000 + ' hrs'  ) : element.intervalTime / 3600000 === 1 ? '1 hr' : element.intervalTime / 3600000 === 24 ? '1 day': element.intervalTime / 3600000 + ' hrs'
            element.waitingTimeTextValue = element.waitingTime + " seconds";
            filteredData.push(element);
          });
          window.AlertsRawData = filteredData;
          if(!isRefreshingWithFilter) {
              setRows(filteredData);
              resetPaginationData([]);
            } else {
              let text = document.getElementById("searchText") ? document.getElementById("searchText").value : null;
              handleTextCange(text);
            }
      } else {
          setRows(filteredData);
          resetPaginationData([]);
      }
    });
  }

  const handleEvent = (data) => {
    let latestAlertData = [];
    let message = data.message ? data.message : "";
    let payload = data.payload ? Array.isArray(data.payload) ? data.payload[0] : data.payload : "";
    let refreshUi = false;
    if (message === "deleteAlert"){
      _.forEach(window.AlertsRawData, (val)=>{
        if (val.id !== payload.id) {
          latestAlertData.push(val);
        }
      })
      refreshUi = true;
    }else if (message === "updateAlert"){
      if (payload.alertType !== "RFID"){
        _.forEach(window.AlertsRawData, (element)=>{
          if (element.id === payload.id) {
            element.alertTypeName = payload.alertType === "HIGH_CPU" ? "CPU ALERT" : payload.alertType === "HIGH_MEMORY_USAGE" ? "MEMORY USAGE ALERT" : "CAMERA MODULE ALERT";
            element.intervalTime = payload.alertType !== 'UNLOCK' && payload.alertType !== 'RFID' ? payload.intervalTime : payload.alertType === 'RFID' ? Number(payload.rfidScheduleTime) : 0;
            element.receivers = payload.receivers;
            element.receiversLength = payload.receivers.length > 1 ? payload.receivers.length + " Users" : payload.receivers.length + " User" ;
            element.frequencyTime = payload.alertType === 'UNLOCK' ? "-" : payload.alertType === "RFID" ? (Number(payload.rfidScheduleTime) / 3600000 === 1 ? '1 hr' : (Number(payload.rfidScheduleTime) / 3600000 )% 24 === 0 ?  (Number(payload.rfidScheduleTime) / 3600000 ) / 24 + (Number(payload.rfidScheduleTime) / 3600000 ) / 24 === 1 ? (Number(payload.rfidScheduleTime) / 3600000 ) / 24 + ' day' : (Number(payload.rfidScheduleTime) / 3600000 ) / 24+" days": Number(payload.rfidScheduleTime) / 3600000 + ' hrs'  ) : payload.intervalTime / 3600000 === 1 ? '1 hr' : payload.intervalTime / 3600000 === 24 ? '1 day': payload.intervalTime / 3600000 + ' hrs'
            element.waitingTimeTextValue = element.waitingTime + " seconds";
            if (typeof payload.rfidScheduleTime !== 'undefined' && payload.rfidScheduleTime !== null){
              element.intervalTime = payload.rfidScheduleTime;
            }
          }
          latestAlertData.push(element);
        })
        refreshUi = true;
      }else {
        enqueueSnackbar("Refreshing Alerts ...");
        fetchAlerts(false);
      }
    }else if (message === "createAlert") {
      _.forEach(window.AlertsRawData, (element)=>{
        latestAlertData.push(element);
      })
      payload.alertTypeName = payload.alertType === "HIGH_CPU" ? "CPU ALERT" : payload.alertType === "HIGH_MEMORY_USAGE" ? "MEMORY USAGE ALERT" : "CAMERA MODULE ALERT";
      payload.intervalTime = payload.alertType !== 'UNLOCK' && payload.alertType !== 'RFID' ? payload.intervalTime : payload.alertType === 'RFID' ? Number(payload.rfidScheduleTime) : 0;
      payload.receiversLength = payload.receivers.length > 1 ? payload.receivers.length + " Users" : payload.receivers.length + " User" ;
      payload.frequencyTime = payload.alertType === 'UNLOCK' ? "-" : payload.alertType === "RFID" ? (Number(payload.rfidScheduleTime) / 3600000 === 1 ? '1 hr' : (Number(payload.rfidScheduleTime) / 3600000 )% 24 === 0 ?  (Number(payload.rfidScheduleTime) / 3600000 ) / 24 + (Number(payload.rfidScheduleTime) / 3600000 ) / 24 === 1 ?(Number(payload.rfidScheduleTime) / 3600000 ) / 24 + ' day' : (Number(payload.rfidScheduleTime) / 3600000 ) / 24+" days": Number(payload.rfidScheduleTime) / 3600000 + ' hrs'  ) : payload.intervalTime / 3600000 === 1 ? '1 hr' : payload.intervalTime / 3600000 === 24 ? '1 day': payload.intervalTime / 3600000 + ' hrs'
      payload.waitingTimeTextValue = payload.waitingTime + " seconds";
      if (typeof payload.rfidScheduleTime !== 'undefined' && payload.rfidScheduleTime !== null){
        payload.intervalTime = payload.rfidScheduleTime;
      }
      latestAlertData.push(payload);
      refreshUi = true;
    }else if (message === "userRemovedFromPortal") {
      enqueueSnackbar("Refreshing Alerts ...");
      fetchAlerts(false);
    }

    if (refreshUi){
      enqueueSnackbar("Refreshing Alerts ...");
      window.AlertsRawData = latestAlertData;
      let text = document.getElementById("searchText") ? document.getElementById("searchText").value : null;
      handleTextCange(text);
    }
  }

  const onAlertEvent = (data) => {
    if(true){
        fetchAlerts(false);
    } else {
        data = data ? JSON.parse(data.toString()) : "" ;
        if (data.hasOwnProperty("message")){
          handleEvent(data);
        }
    }
  }

  const subscribeMqttChannels = () => {
    window.alertsMqttEventHandler = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.ALERTS.replace("scopeId",sessionStorage.tenantId),"alertController",onAlertEvent)
  }

  React.useEffect(() => {
    enqueueSnackbar("Fetching Alerts ...");
    setTimeout(() => {
      setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
      setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
    }, 1000);
    fetchAlerts();
    window.oldPreservedAlertsPageNumber = 0;
    subscribeMqttChannels();
    return () =>{
      if (window.alertsMqttEventHandler){
        window.alertsMqttEventHandler.unsubscribe();
      }
      AuthClient.abortSignal("alertsPage");
      JobSchedulerClient.abortSignal("alertsPage")
    }
  }, []);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el && el !== null && el !== "" ? el : el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
    window.oldPreservedAlertsPageNumber = newPage;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleSelectAllClick = (checkboxClickCount) => {
    if (checkboxClickCount % 2 !== 0) {
      setCheckAll(true);
      // eslint-disable-next-line array-callback-return
      const newSelecteds = rows.filter((n) => {
        if (
          n.Status !== 'INACTIVE' &&
          typeof n.id !== 'undefined' &&
          n.id !== null
        ) {
          return n;
        }
      });
      setSelected([]);
      setSelected(newSelecteds);
      return;
    }
    setCheckAll(false);
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [pageNumber, setPageNumber] = React.useState(0);

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    setPage(event.target.value);
    window.oldPreservedAlertsPageNumber = event.target.value;
  };

  const handleRowPerPage = (event) => {
    let rowPerPageValue = event.target.value;
    if (rows.length > 0) {
      let pageNumberCount = Math.ceil((rows.length/rowPerPageValue)) - 1;
      if (window.oldPreservedAlertsPageNumber > pageNumberCount) {
        setPage(pageNumberCount);
        setPageNumber(pageNumberCount);
        window.oldPreservedAlertsPageNumber = pageNumberCount;
      }else {
        setPage(window.oldPreservedAlertsPageNumber);
        setPageNumber(window.oldPreservedAlertsPageNumber);
      }
    }
    sessionStorage.dashboardAlertRPP = rowPerPageValue;
    setRowsPerPage(rowPerPageValue);
  };

  const checkSearchTextExist = (data, key,  text) => {
      let value = data[key];
      return value !== null && typeof value !== 'undefined' && value !== "" ? value.toString().toLowerCase().replaceAll(" ","").indexOf(text) !== -1 : false;
    };

  const handleTextCange = (textValue) => {
    textValue = document.getElementById("searchText") ? document.getElementById("searchText").value : "";
    setSearchText(textValue);
    let sortedDevices = [];
    let searchText = textValue;
    if (searchText !== "" && searchText !== undefined && searchText !==  null){
        let text = searchText.toLowerCase().replaceAll(" ","");
        _.forEach(window.AlertsRawData,(value)=>{
          if (checkSearchTextExist(value, "alertType", text) || checkSearchTextExist(value, "alertTriggers", text) || checkSearchTextExist(value, "receiversLength", text) || checkSearchTextExist(value, "frequencyTime", text)){
            sortedDevices.push(value);
          }
        })
        sortedDevices = _.uniq(sortedDevices);
        setRows(sortedDevices);
    } else {
        setRows(window.AlertsRawData);
    }
    resetPaginationData(sortedDevices);
  };

  const resetPaginationData = (data)=> {
    if (window.oldPreservedAlertsPageNumber) {
      if (data.length > 0) {
        let pageNumberCount = Math.ceil((data.length/rowsPerPage)) - 1;
        if (window.oldPreservedAlertsPageNumber > pageNumberCount) {
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedAlertsPageNumber = pageNumberCount;
        }
      }else {
        let pageNumberCount = Math.ceil((window.AlertsRawData.length/rowsPerPage)) - 1;
        if (window.oldPreservedAlertsPageNumber > pageNumberCount){
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedAlertsPageNumber = pageNumberCount;
        }else if (window.oldPreservedAlertsPageNumber <= pageNumberCount && pageNumberCount > 0) {
          setPage(window.oldPreservedAlertsPageNumber);
          setPageNumber(window.oldPreservedAlertsPageNumber);
        }else {
          setPage(0);
          setPageNumber(0);
          window.oldPreservedAlertsPageNumber = 0;
        }
      }
    }else {
      setPageNumber(0);
      setPage(0);
      window.oldPreservedAlertsPageNumber = 0;
    }
  }

  const clearSearchField = () => {
    setSearchText('');
    document.getElementById('searchText').value = '';
    setSearchBar(false);
    setRows(window.AlertsRawData);
  }

const rowPerPageLabel = (
          <div className={paginationStyle.rowPerPageLabelDiv}>
            <Box className={paginationStyle.page}>
              <span style={{marginRight: '32px'}}>Page</span>
            </Box>
            <Box className={paginationStyle.pageSelectBox}>
              <Select value={pageNumber} onChange={handleChange}>
                {
                  Math.ceil(rows.length / rowsPerPage) > 0 ?
                    // eslint-disable-next-line array-callback-return
                    Array(Math.ceil(rows.length / rowsPerPage))
                      .fill()
                      .map((_, index) => {
                        return (
                          <MenuItem value={index} sx={{minWidth: '64px'}}>
                            {index + 1}
                          </MenuItem>
                        );
                      })
                  :
                    <MenuItem value={0} sx={{minWidth: '64px'}}>
                      1
                    </MenuItem>
                }
              </Select>
            </Box>
            <Box className={paginationStyle.rowPerPageLabelDiv}>
              <span style={{marginRight: '32px'}}>Rows Per Page</span>
            </Box>
            <Box className={paginationStyle.rowPerPageSelectBox}>
              <Select value={rowsPerPage} onChange={handleRowPerPage}>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </Box>
          </div>
        );

  // Edit permission dialog & View Permission Dialog

  const [editUnlockAlert, setEditUnlockAlert] = React.useState(false);

  const [alertsData, setAlertsData] = React.useState([]);

  // DELETE PERMISSION DIALOG

  const handleCloseEditUnlockAlert = (value) => {
    setEditUnlockAlert(value);
  };

  // ADD ALERT

  const [createAlert, setCreateAlert] = React.useState(false);

  const handleCreateAlert = (value) => {
    setCreateAlert(value);
  };

  //DELETE ALERT

  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [deleteAlertData, setDeleteAlertData] = React.useState([]);

  const handleDeleteAlert = (value) => {
    setDeleteAlert(value);
  };

  const handleDeleteAlertDialog = (data) => {
    setDeleteAlertData(data);
    setDeleteAlert(true);
  };

  const handleRefresh = () => {
    setRefresh(false);
        setTimeout(() => {
          setRefresh(true);
    }, 1050);
    enqueueSnackbar("Refreshing Alerts ...")
    fetchAlerts();
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '20px 0px 20px 24px',
        }}
      >
        <span style={{fontSize: '20px', marginRight: 'auto'}}>Alerts</span>
        <input
          id="searchText"
          className= {SearchBar ? "search" : "searchAnimation"}
          type="text"
          onChange={(event)=>handleTextCange(event.target.value)}
          placeholder="Search Alerts..."
          autoFocus
        />
        <Close
          style={{
            marginRight: '4px',
            cursor: 'pointer',
            display: SearchBar ? 'flex' : 'none',
          }}
          onClick={() => clearSearchField()}
        />
        <Tooltip title="Search" placement="top" arrow>
          <Search
            style={{
              marginRight: '4px',
              cursor: 'pointer',
              display: !SearchBar ? 'flex' : 'none',
            }}
            onClick={() => {setSearchBar(true);
                setTimeout(() => {document.getElementById("searchText").focus();},100);
            }}
          />
        </Tooltip>
        <Tooltip title="Refresh" placement="top" arrow>
          <Refresh className={refresh?"refreshIcon":"refreshIconAnimation"} sx={{marginRight:"4px"}} onClick={()=> handleRefresh()} />
        </Tooltip>
      </Box>
      <Box style={{width: '100%', height: 'auto', marginBottom: '86px'}}>
        <TableContainer>
          <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              check_all={checkAll}
              onSelectAllClick={(checked) => handleSelectAllClick(checked)}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody sx={{background: '#fff'}}>
              {stableSort(rows,getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                // eslint-disable-next-line array-callback-return
                .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        className={paginationStyle.tableBodyRow}
                        sx={{borderBottom:'1px solid #c1a6a6'}}
                      >
                        <TableCell>{row.alertTypeName}</TableCell>
                        <TableCell align="left">{row.receiversLength}</TableCell>
                        <TableCell align="left" sx={{margin: '0px'}}>
                          <Box className="usersActions cursorPointer">
                            <Box
                              sx={{
                                margin: '0px 32px 0px 19px',
                              }}
                            >
                              <Edit
                                onClick={() => {
                                  let filteredUsers = [];
                                  let user = {};
                                  let allReceiversData = [];
                                  _.forEach(PortalUsersData,(allReceivers)=>{
                                     let users = {};
                                     users.id = allReceivers.id;
                                     users.name = allReceivers.mergedName;
                                     users.email = allReceivers.email;
                                     users.phoneNumber = allReceivers.phoneNumber;
                                     _.forEach(row.receivers, (val) => {
                                         if(val.email === allReceivers.email){
                                           filteredUsers.push(users);
                                         }
                                     })
                                     allReceiversData.push(users);
                                  })
                                  setPortalUserData(allReceiversData);
                                  setSelectedUsers(filteredUsers);
                                  setAlertsData(row);
                                  setEditUnlockAlert(true);
                                }}
                                sx={{color:'#0000008A',fontSize: '20px'}}
                              />
                            </Box>
                            <Box>
                              <Delete
                                className="dashboardDeleteIcon cursorPointer"
                                onClick={() => handleDeleteAlertDialog(row)}
                                sx={{color:'#0000008A',fontSize: '20px'}}
                              />
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{width: '100%', overflowX: 'hidden'}}
          labelRowsPerPage={rowPerPageLabel}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          editMode={editMode}
          page={pageNumber}
          SelectProps={{
            inputProps: {'aria-label': 'rows per page'},
            native: true,
          }}
          className="pagination"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
      {
        JSON.parse(sessionStorage.userDetails).user.permissions.includes("ALERTS_WRITE") ?
          <Button
            style={{
              position: 'fixed',
              bottom: '37px',
              right: '40px',
              height: '62px',
              miWwidth: '55px',
              maxWidth: '55px',
              borderRadius: '50%',
              backgroundColor: primaryThemeColor,
            }}
            onClick={() => {
                let filteredUsers = [];
                let user = null;
                _.forEach(PortalUsersData,(allReceivers)=>{
                   if(allReceivers.mergedName !== 'undefined'){
                       user = {};
                       user.id = allReceivers.id;
                       user.name = allReceivers.mergedName;
                       user.email = allReceivers.email;
                       user.phoneNumber = allReceivers.phoneNumber;
                       filteredUsers.push(user);
                   }
                })
                setPortalUserData(filteredUsers);
                setCreateAlert(true)
            }}
          >
            <Add sx={{color: '#fff'}} />
          </Button>
        :
          null
      }

      {/* EDIT PERMISSION DIALOG */}

      {editUnlockAlert ? (
        <>
          <ConfigureAlert
            title="Update Alert"
            portal_users_data={PortalUsersData}
            data={alertsData}
            allData={rows}
            show_edit_alert_dialog={editUnlockAlert}
            close_edit_alert_dialog={(value) =>
              handleCloseEditUnlockAlert(value)
            }
            portalUserData={portalUserData}
            selectedUsers={selectedUsers}
            themeColor={primaryThemeColor}
            button_name="Update"
            dialog="CreateNewAlert"
          />
        </>
      ) : createAlert ? (
        <>
          <ConfigureAlert
            title="Create New Alert"
            portal_users_data={PortalUsersData}
            portalUserData={portalUserData}
            data={[]}
            merchandisers_data={iotDevices}
            allData={rows}
            show_edit_alert_dialog={createAlert}
            close_edit_alert_dialog={(value) => handleCreateAlert(value)}
            button_name="Create"
            themeColor={primaryThemeColor}
            dialog="CreateNewAlert"
          />
        </>
      ) : deleteAlert ? (
        <>
          <ConfigureAlert
            delete_alert={true}
            title="Delete Alert"
            portal_users_data={[]}
            data={deleteAlertData}
            themeColor={primaryThemeColor}
            show_edit_alert_dialog={deleteAlert}
            close_edit_alert_dialog={(value) => handleDeleteAlert(value)}
            button_name="Yes"
            dialog="CreateNewAlert"
          />
        </>
      ) : null}
    </>
  );
}
