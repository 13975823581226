import * as React from 'react';
import utils from "./utils.js";
import MqttEventHandler from "components/Mqtt/MqttEventHandler.js";
import PubSubChannels from "pubsub/PubSubChannels.js";
import IotServiceClient from "clients/IotServiceClient.js";


export default function GenericChannel(){

    const publish = (deviceData, type, value, uid) => {
        let json = {
            "type" : type,
            "uid" : uid,
            "status" : "PENDING"
        }
        IotServiceClient.createInstruction(json).then((response) => {
            delete json["status"];
            if(type === "register"){
                json["value"] = value;
            }
            if(response.status >= 200 && response.status < 300){
               MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC", deviceData.MAC), JSON.stringify(json), {qos: 2, retain: false}, function() {});
               return "Successfully created instruction";
            }else {
               return "Failed to create instruction.";
            }
        });
    }

    const updateStatusToFailed = (deviceData, name, uid) => {
        let portalEventsPayload = {
            "MAC" : deviceData.MAC,
            "TI": new Date().getTime(),
            "SA" : [{
                "nme": name,
                "val": "Timed-out"
            }]
        }
        IotServiceClient.savePortalEveents(portalEventsPayload).then((res)=>{});
        IotServiceClient.updateInstructionStatus(uid).then((response) => {
            if(response.status >= 200 && response.status < 300){
                return "Successfully updated the instruction.";
            }else {
                return "Failed to update the instruction.";
            }
        })
    }

    const deleteInstruction = (uid) => {
        IotServiceClient.deleteInstruction(uid).then((response) => {
            if(response.status >= 200 && response.status < 300){
                return "Successfully deleted the instruction.";
            }else {
                return "Failed to delete the instruction.";
            }
        });
    }

    return {
        publish,
        updateStatusToFailed,
        deleteInstruction
    }
}