import React from 'react';
import NProgress from 'nprogress';
import checkDataExistsOrNot from './checkDataExistsOrNot';

export default function handleProgressBarStatus() {
    var progressBarIntervalStatus = window.setInterval(function(){
       if (document.querySelector('#nprogress .bar')) {
           document.querySelector('#nprogress .bar').style.backgroundColor = checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : '#4190c7';
           window.clearInterval(progressBarIntervalStatus)
       }
     },500);
     NProgress.set(0.48);
     NProgress.set(0.88);
     NProgress.done();
}